import React, { useState, useEffect } from "react";
import Notiflix from "notiflix";
import { useDispatch, useSelector } from "react-redux";
import api from "../services/api";
import {
  getEnterprisePlans,
  getPlans,
  loadSeverStatus,
} from "../redux/actions/languiseAction";
import AddEnterpriseAgentModal from "../components/Modal/AdminPage/AddEnterpriseAgentModal";
import UpdateEnterpriseAgentPlanModal from "../components/Modal/AdminPage/UpdateEnterpriseAgentPlanModal";
import ConfirmModal from "../components/Modal/ConfirmModal";

const SearchControl = ({ value, onChange, onClick }) => {
  return (
    <div className="flex items-center gap-2">
      <input
        type="text"
        value={value}
        onChange={onChange}
        onKeyUp={(e) => {
          if (e.key === "Enter") onClick(e);
        }}
        className="h-9 sm:w-auto w-[180px] border border-[#4b5090] rounded-md outline-none px-2"
      ></input>
      <svg
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32.414 32.413"
        className="w-6 h-6 cursor-pointer"
      >
        <g transform="translate(4 4)">
          <circle
            cx="10.656"
            cy="10.656"
            r="10.656"
            transform="translate(0 0)"
            fill="#fff"
            stroke="#333876"
            strokeWidth="2"
          />
          <line
            x1="11.5"
            y1="11.499"
            transform="translate(18.5 18.5)"
            fill="none"
            stroke="#333876"
            strokeLinecap="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    </div>
  );
};

export default function AdminPage() {
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.LanguiseReducer.userInfo);
  const isUnderMaintenance = useSelector(
    (state) => state.LanguiseReducer.isUnderMaintenance
  );
  const [selectedTab, setSelectedTab] = useState(1);
  const [tableItems, setTableItems] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [tempKeyword, setTempKeyword] = useState("");
  const [shutDownTime, setShutDownTime] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [serverStatus, setSeverStatus] = useState("default");
  const [checkedList, setCheckedList] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(100);
  const [addAgentModalOpen, setAddAgentModalOpen] = useState(false);
  const [updateAgentModalOpen, setUpdateAgentModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [confirmDeleteUserModalOpen, setConfirmDeleteUserModalOpen] =
    useState(false);
  const [
    confirmDeleteEnterpriseModalOpen,
    setConfirmDeleteEnterpriseModalOpen,
  ] = useState(false);
  const [confirmDeleteAgentModalOpen, setConfirmDeleteAgentModalOpen] =
    useState(false);
  const [confirmDeleteContactModalOpen, setConfirmDeleteContactModalOpen] =
    useState(false);
  const [confirmDeleteDlModalOpen, setConfirmDeleteDlModalOpen] =
    useState(false);
  const [detailText, setDetailText] = useState("");
  const [detailInfo, setDetailInfo] = useState({});

  const loadUsers = () => {
    api
      .get("/management/user/", {
        params: {
          category: selectedTab - 1,
          offset: (currentPage - 1) * itemsPerPage || 0,
          limit: parseInt(itemsPerPage) || 0,
          keyword,
        },
      })
      .then((res) => {
        setTableItems(res.data.items);
        setTotalCount(res.data.total);
        const temp = new Array(res.data.length).fill(false);
        setCheckedList([...temp]);
        setSelectedItems([]);
        setCheckedAll(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setTableItems([]);
        setLoading(false);
      });
  };

  const loadEnterpriseAgent = () => {
    api
      .get("/management/agent/", {
        params: {
          category: selectedTab - 1,
          offset: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
          keyword,
        },
      })
      .then((res) => {
        setTableItems(res.data.items);
        setTotalCount(res.data.total);
        const temp = new Array(res.data.length).fill(false);
        setCheckedList([...temp]);
        setSelectedItems([]);
        setCheckedAll(false);
        setLoading(false);
      })
      .catch((err) => {
        setTableItems([]);
        setLoading(false);
        console.log(err);
      });
  };

  const loadContactList = () => {
    api
      .get("/management/contact/", {
        params: {
          category: selectedTab - 1,
          offset: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
          keyword,
        },
      })
      .then((res) => {
        setTableItems(res.data.items);
        setTotalCount(res.data.total);
        const temp = new Array(res.data.length).fill(false);
        setCheckedList([...temp]);
        setSelectedItems([]);
        setCheckedAll(false);
        setLoading(false);
      })
      .catch((err) => {
        setTableItems([]);
        setLoading(false);
        console.log(err);
      });
  };

  const loadDLList = () => {
    api
      .get("/management/dl/", {
        params: {
          category: selectedTab - 1,
          offset: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
          keyword,
        },
      })
      .then((res) => {
        setTableItems(res.data.items);
        setTotalCount(res.data.total);
        const temp = new Array(res.data.length).fill(false);
        setCheckedList([...temp]);
        setSelectedItems([]);
        setCheckedAll(false);
        setLoading(false);
      })
      .catch((err) => {
        setTableItems([]);
        setLoading(false);
        console.log(err);
      });
  };

  const handleCheckedAll = () => {
    setCheckedAll(!checkedAll);
    const temp = new Array(tableItems.length).fill(!checkedAll);
    setCheckedList([...temp]);
  };

  const handleChecked = (index) => {
    return () => {
      const temp = [...checkedList];
      temp[index] = !temp[index];
      setCheckedList([...temp]);
      setCheckedAll(temp.indexOf(false) === -1);
    };
  };

  const getJapanDate = (timestamp, offset_month = 0, offset_year = 0) => {
    const date = new Date(timestamp);
    date.setMonth(date.getMonth() + offset_month);
    date.setFullYear(date.getFullYear() + offset_year);
    return date.toLocaleString("ja-JP", {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "Asia/Tokyo",
    });
  };

  const handleUpdateUser = (n) => {
    if (checkedList.indexOf(true) === -1) {
      Notiflix.Notify.warning("ユーザーが選択されていません。");
      return;
    }
    const temp = [];
    if (n == 4) {
      for (let i = 0; i < selectedItems.length; i++) {
        temp.push(selectedItems[i].id);
      }
    } else {
      for (let i = 0; i < selectedItems.length; i++) {
        if (!selectedItems[i].corporate) temp.push(selectedItems[i].id);
      }
    }
    api
      .put("/management/user/", {
        type: n,
        id: [...temp],
      })
      .then(() => {
        loadUsers();
      })
      .catch((err) => {
        Notiflix.Notify.warning("操作が失敗しました。");
        console.log(err);
      });
  };

  const handleDeleteUser = () => {
    if (checkedList.indexOf(true) === -1) {
      Notiflix.Notify.warning("ユーザーが選択されていません。");
      return;
    }
    setConfirmDeleteUserModalOpen(true);
  };

  const deleteUser = () => {
    const temp = [];
    for (let i = 0; i < selectedItems.length; i++) {
      if (!selectedItems[i].corporate) temp.push(selectedItems[i].id);
    }
    const formData = new FormData();
    formData.append("id", [...temp]);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    api
      .delete("/management/user/", config)
      .then(() => {
        setConfirmDeleteUserModalOpen(false);
        loadUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteEnterprise = () => {
    if (checkedList.indexOf(true) === -1) {
      Notiflix.Notify.warning("ユーザーが選択されていません。");
      return;
    }
    setConfirmDeleteEnterpriseModalOpen(true);
  };

  const deleteEnterprise = () => {
    const temp = [];
    for (let i = 0; i < selectedItems.length; i++) {
      temp.push(selectedItems[i].email);
    }
    const formData = new FormData();
    formData.append("email", [...temp]);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    api
      .delete("/management/corporate/", config)
      .then(() => {
        setConfirmDeleteEnterpriseModalOpen(false);
        loadUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteAgent = () => {
    if (checkedList.indexOf(true) === -1) {
      Notiflix.Notify.warning("ユーザーが選択されていません。");
      return;
    }
    setConfirmDeleteAgentModalOpen(true);
  };

  const deleteAgent = () => {
    const temp = [];
    for (let i = 0; i < selectedItems.length; i++) {
      temp.push(selectedItems[i].id);
    }
    const formData = new FormData();
    formData.append("id", [...temp]);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    api
      .delete("/management/agent/", config)
      .then(() => {
        setConfirmDeleteAgentModalOpen(false);
        loadEnterpriseAgent();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEditAgent = () => {
    if (selectedItems.length === 0) {
      Notiflix.Notify.warning("ユーザーが選択されていません。");
      return;
    }
    if (selectedItems.length > 1) {
      Notiflix.Notify.warning(
        "ユーザーを編集するには、1人のユーザーのみを選択する必要があります。"
      );
      return;
    }
    setUpdateAgentModalOpen(true);
  };

  const updateAgentModal = () => {
    setUpdateAgentModalOpen(false);
    loadEnterpriseAgent();
  };

  const handleDeleteContact = () => {
    if (checkedList.indexOf(true) === -1) {
      Notiflix.Notify.warning("ユーザーが選択されていません。");
      return;
    }
    setConfirmDeleteContactModalOpen(true);
  };

  const deleteContact = () => {
    const temp = [];
    for (let i = 0; i < selectedItems.length; i++) {
      temp.push(selectedItems[i].id);
    }
    const formData = new FormData();
    formData.append("id", [...temp]);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    api
      .delete("/management/contact/", config)
      .then(() => {
        setConfirmDeleteContactModalOpen(false);
        loadContactList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postSeverStatus = () => {
    api
      .post("/management/maintenance/", {
        is_under_maintenance: serverStatus,
        shutdown_time: shutDownTime,
      })
      .then((res) => {
        console.log({ res });
        if (res.data.is_under_maintenance) {
          Notiflix.Notify.info(
            "サーバーがメンテナンス方式に切り替えられました。"
          );
        } else {
          Notiflix.Notify.info("サーバーが通常の方法で動作しています。");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleDeleteDl = () => {
    if (checkedList.indexOf(true) === -1) {
      Notiflix.Notify.warning("ユーザーが選択されていません。");
      return;
    }
    setConfirmDeleteDlModalOpen(true);
  };

  const deleteDl = () => {
    const temp = [];
    for (let i = 0; i < selectedItems.length; i++) {
      temp.push(selectedItems[i].id);
    }
    const formData = new FormData();
    formData.append("id", [...temp]);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    api
      .delete("/management/dl/", config)
      .then(() => {
        setConfirmDeleteDlModalOpen(false);
        loadDLList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const moveFirst = () => {
    setCurrentPage(1);
  };

  const movePrev = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const moveNext = () => {
    if (currentPage < Math.ceil(totalCount / itemsPerPage))
      setCurrentPage(currentPage + 1);
  };

  const moveLast = () => {
    setCurrentPage(Math.ceil(totalCount / itemsPerPage));
  };

  const loadData = () => {
    if (!loading) {
      setTableItems([]);
      setLoading(true);
      setCheckedList([]);
      setCheckedAll(false);
      if (selectedTab < 3) {
        loadUsers();
      } else if (selectedTab === 3) {
        loadEnterpriseAgent();
      } else if (selectedTab === 4) {
        loadContactList();
      } else if (selectedTab === 5) {
        loadDLList();
      } else if (selectedTab === 6) {
        dispatch(loadSeverStatus());
        setLoading(false);
      }
    }
  };

  const formatDateToYYYYMMDD = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is 0-indexed, so +1
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0"); // Hours (00-23)
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Minutes (00-59)
    const seconds = String(date.getSeconds()).padStart(2, "0"); // Seconds (00-59)
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    dispatch(getPlans());
    dispatch(getEnterprisePlans());
  }, [dispatch]);

  useEffect(() => {
    const checkedItems = [];
    for (let i = 0; i < checkedList.length; i++) {
      if (checkedList[i]) checkedItems.push(tableItems[i]);
    }
    setSelectedItems(checkedItems);
  }, [checkedList, tableItems]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, itemsPerPage, selectedTab, currentPage]);

  useEffect(() => {
    setSeverStatus(!isUnderMaintenance ? "default" : "maintenance");
  }, [isUnderMaintenance]);

  useEffect(() => {
    api
      .get("/management/maintenance/")
      .then((response) => {
        if (response.data.shutdown_time) {
          const date = new Date(response.data.shutdown_time);
          setShutDownTime(formatDateToYYYYMMDD(date));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className="flex flex-col w-full flex-grow">
      {addAgentModalOpen && (
        <AddEnterpriseAgentModal
          openModal={addAgentModalOpen}
          refresh={loadData}
          onClose={() => setAddAgentModalOpen(false)}
        />
      )}
      {updateAgentModalOpen && (
        <UpdateEnterpriseAgentPlanModal
          openModal={updateAgentModalOpen}
          onClose={updateAgentModal}
          payload={selectedItems[0]}
        />
      )}
      <div className="w-full sm:h-[104px] h-[80px] bg-[#4B5090]">
        <div className="sm:flex flex-row md:max-w-[1130px] max-w-[95%] w-full mx-auto justify-between sm:mt-0 mt-[-16px]">
          <div className="relative 2xl:right-[42px] right-0 flex gap-2 md:mt-[22px] mt-[38px] w-full">
            <div
              onClick={() => {
                if (!loading) {
                  setSelectedTab(1);
                  setItemsPerPage(10);
                  setCurrentPage(1);
                }
              }}
              className={`max-w-[230px] w-full ${
                selectedTab === 1
                  ? "sm:h-[82px] h-[60px] !rounded-b-none bg-white"
                  : "sm:h-[73px] h-[55px] bg-[#EAEAEA]"
              } sm:rounded-[20px] rounded-[10px]
              ${!userInfo.admin ? "flex" : "hidden"}
               justify-center items-center text-[#333876] sm:text-[17px] text-[13px] font-sans font-[600] cursor-pointer`}
            >
              ユーザー管理
            </div>
            <div
              onClick={() => {
                if (!loading) {
                  setSelectedTab(2);
                  setItemsPerPage(10);
                  setCurrentPage(1);
                }
              }}
              className={`max-w-[230px] w-full ${
                selectedTab === 2
                  ? "sm:h-[82px] h-[60px] !rounded-b-none bg-white"
                  : "sm:h-[73px] h-[55px] bg-[#EAEAEA]"
              } sm:rounded-[20px] rounded-[10px]
              ${!userInfo.admin ? "flex" : "hidden"}
               justify-center items-center text-[#333876] sm:text-[17px] text-[13px] font-sans font-[600] cursor-pointer`}
            >
              Enterprise管理
            </div>
            <div
              onClick={() => {
                if (!loading) {
                  setSelectedTab(3);
                  setItemsPerPage(10);
                  setCurrentPage(1);
                }
              }}
              className={`max-w-[230px] w-full ${
                selectedTab === 3
                  ? "sm:h-[82px] h-[60px] !rounded-b-none bg-white"
                  : "sm:h-[73px] h-[55px] bg-[#EAEAEA]"
              } sm:rounded-[20px] rounded-[10px]
              ${!userInfo.admin ? "flex" : "hidden"}
               justify-center items-center text-[#333876] sm:text-[17px] text-[13px] font-sans font-[600] cursor-pointer`}
            >
              Enterprise Agent
            </div>
            <div
              onClick={() => {
                if (!loading) {
                  setSelectedTab(4);
                  setItemsPerPage(10);
                  setCurrentPage(1);
                }
              }}
              className={`max-w-[230px] w-full ${
                selectedTab === 4
                  ? "sm:h-[82px] h-[60px] !rounded-b-none bg-white"
                  : "sm:h-[73px] h-[55px] bg-[#EAEAEA]"
              } sm:rounded-[20px] rounded-[10px]
              ${!userInfo.admin ? "flex" : "hidden"}
               justify-center items-center text-[#333876] sm:text-[17px] text-[13px] font-sans font-[600] cursor-pointer`}
            >
              お問い合わせ内容
            </div>
            <div
              onClick={() => {
                if (!loading) {
                  setSelectedTab(5);
                  setItemsPerPage(10);
                  setCurrentPage(1);
                }
              }}
              className={`max-w-[230px] w-full ${
                selectedTab === 5
                  ? "sm:h-[82px] h-[60px] !rounded-b-none bg-white"
                  : "sm:h-[73px] h-[55px] bg-[#EAEAEA]"
              } sm:rounded-[20px] rounded-[10px]
              ${!userInfo.admin ? "flex" : "hidden"}
               justify-center items-center text-[#333876] sm:text-[17px] text-[13px] font-sans font-[600] cursor-pointer`}
            >
              資料DL
            </div>
            <div
              onClick={() => {
                if (!loading) {
                  setSelectedTab(6);
                }
              }}
              className={`max-w-[230px] w-full ${
                selectedTab === 6
                  ? "sm:h-[82px] h-[60px] !rounded-b-none bg-white"
                  : "sm:h-[73px] h-[55px] bg-[#EAEAEA]"
              } sm:rounded-[20px] rounded-[10px]
              ${!userInfo.admin ? "flex" : "hidden"}
               justify-center items-center text-[#333876] sm:text-[17px] text-[13px] font-sans font-[600] cursor-pointer`}
            >
              サーバー管理
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full flex flex-col flex-grow">
        {selectedTab === 1 && (
          <>
            <div className="max-w-[1096px] w-full mx-auto pt-6 flex justify-between sm:px-0 px-5 gap-6">
              <div className="flex gap-[15px] items-center">
                <button
                  title="無料ユーザーとして設定/解除"
                  onClick={() => handleUpdateUser(0)}
                  className="flex w-9 h-9 items-center justify-center border border-[#4B5090] rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    viewBox="0 0 20.091 24.864"
                  >
                    <g transform="translate(-1641.407 -29)">
                      <circle
                        cx="7"
                        cy="7"
                        r="7"
                        transform="translate(1644.5 29)"
                        fill="#333876"
                      />
                      <path
                        d="M-15284.517-164.881c-.105,0-.457-.039-.564-.546a14.622,14.622,0,0,1,.652-4.189,12.358,12.358,0,0,1,3.913-6.334,7.96,7.96,0,0,0,5.516,2.206,7.963,7.963,0,0,0,5.5-2.19,12.352,12.352,0,0,1,3.829,6.355,17.894,17.894,0,0,1,.67,4.174.526.526,0,0,1-.563.515h-.023l-18.9.008Z"
                        transform="translate(16926.5 218.746)"
                        fill="#333876"
                      />
                    </g>
                    <g
                      transform="translate(5.407 19)"
                      fill="none"
                      stroke="#fff"
                      strokeWidth="1"
                    >
                      <circle cx="3" cy="2" r="3" stroke="none" />
                      <circle cx="3" cy="2" r="2.5" fill="none" />
                    </g>
                    <g
                      transform="translate(9.407 19)"
                      fill="none"
                      stroke="#fff"
                      strokeWidth="1"
                    >
                      <circle cx="3" cy="2" r="3" stroke="none" />
                      <circle cx="3" cy="2" r="2.5" fill="none" />
                    </g>
                  </svg>
                </button>
                <button
                  title="無料(300)ユーザーとして設定/解除"
                  onClick={() => handleUpdateUser(2)}
                  className="flex w-9 h-9 items-center justify-center border border-[#4B5090] rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    viewBox="0 0 20.091 25.182"
                  >
                    <g transform="translate(-205.909 -454.818)">
                      <circle
                        cx="7"
                        cy="7"
                        r="7"
                        transform="translate(209.002 454.818)"
                        fill="#333876"
                      />
                      <path
                        d="M-15284.517-164.881c-.105,0-.457-.039-.564-.546a14.622,14.622,0,0,1,.652-4.189,12.358,12.358,0,0,1,3.913-6.334,7.96,7.96,0,0,0,5.516,2.206,7.963,7.963,0,0,0,5.5-2.19,12.352,12.352,0,0,1,3.829,6.355,17.894,17.894,0,0,1,.67,4.174.526.526,0,0,1-.563.515h-.023l-18.9.008Z"
                        transform="translate(15491.002 644.564)"
                        fill="#333876"
                      />
                      <text
                        transform="translate(211 478)"
                        fill="#fff"
                        fontSize="8"
                        fontFamily="SegoeUI, Segoe UI"
                      >
                        <tspan x="-2" y="0">
                          300
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </button>
                <button
                  title="Enterpriseトライアル"
                  onClick={() => handleUpdateUser(4)}
                  className="flex w-9 h-9 items-center justify-center border border-[#4B5090] rounded-md"
                >
                  <svg
                    width="20"
                    height="25"
                    viewBox="0 0 20 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0473 14.0609C13.8958 14.0609 17.0156 10.9411 17.0156 7.0926C17.0156 3.24412 13.8958 0.12431 10.0473 0.12431C6.19879 0.12431 3.07898 3.24412 3.07898 7.0926C3.07898 10.9411 6.19879 14.0609 10.0473 14.0609Z"
                      fill="#333876"
                    />
                    <path
                      d="M0.573859 24.8767C0.469335 24.8767 0.118478 24.8379 0.0119629 24.3332C0.0245866 22.919 0.243575 21.5142 0.661352 20.1631C1.28074 17.7109 2.64099 15.5092 4.55671 13.8578C6.03508 15.271 8.00221 16.0579 10.0474 16.0538C12.0847 16.0577 14.0454 15.277 15.5224 13.8737C17.4113 15.5421 18.742 17.7505 19.3342 20.2C19.7199 21.5538 19.9437 22.9485 20.0011 24.3551C19.9998 24.4261 19.9837 24.4961 19.9544 24.5609C19.9252 24.6257 19.8834 24.6838 19.831 24.7318C19.7785 24.7797 19.7173 24.8165 19.6501 24.8399C19.583 24.8633 19.511 24.8727 19.4402 24.8677H19.4178L0.603024 24.8757L0.573859 24.8767Z"
                      fill="#333876"
                    />
                    <path
                      d="M7.81352 24V17.4545H12.224V18.5955H9.1974V20.1552H11.9971V21.2962H9.1974V22.859H12.2368V24H7.81352Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <button
                  title="アカウントの利用/停止"
                  onClick={() => handleUpdateUser(1)}
                  className="flex w-9 h-9 items-center justify-center border border-[#4B5090] rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#4b5090"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <rect
                      x="3"
                      y="11"
                      width="18"
                      height="11"
                      rx="2"
                      ry="2"
                      fill="#4B5090"
                    ></rect>
                    <path d="M7 11V7a5 5 0 0 1 9.9-1"></path>
                  </svg>
                </button>
                <button
                  title="選択したユーザーを削除"
                  onClick={handleDeleteUser}
                  className="flex w-9 h-9 items-center justify-center border border-[#4B5090] rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="24"
                    viewBox="0 0 22.897 31.649"
                  >
                    <g transform="translate(-1454.49 -3077)">
                      <path
                        d="M1.393,0H20.48a1.393,1.393,0,0,1,1.393,1.393L18.962,25.076a1.393,1.393,0,0,1-1.393,1.393H4.294A1.393,1.393,0,0,1,2.9,25.076L0,1.393A1.393,1.393,0,0,1,1.393,0Z"
                        transform="translate(1455 3082.179)"
                        fill="#4b5090"
                      />
                      <line
                        x2="1.393"
                        y2="11.145"
                        transform="translate(1460.258 3089.85)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        y2="11"
                        transform="translate(1465.999 3089.833)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x1="1.393"
                        y2="11.145"
                        transform="translate(1470.223 3089.85)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x2="20.897"
                        transform="translate(1455.49 3080.09)"
                        fill="none"
                        stroke="#4b5090"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x2="5.572"
                        transform="translate(1463.152 3078)"
                        fill="none"
                        stroke="#4b5090"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                    </g>
                  </svg>
                </button>
              </div>
              <SearchControl
                value={tempKeyword}
                onChange={(e) => setTempKeyword(e.target.value)}
                onClick={() => setKeyword(tempKeyword)}
              />
            </div>
            <div className="max-w-[1130px] w-full h-full mx-auto sm:flex flex-row items-start py-5 overflow-auto">
              <div className="w-full min-w-[1130px] border md:rounded-[20px] rounded-[10px] overflow-hidden">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-[#E9E9F0] h-[60px]">
                      <th className="w-12 border-r border-[#CCCCCC]">
                        <input
                          className="w-[16px] h-[16px]"
                          type="checkbox"
                          checked={checkedAll}
                          onChange={handleCheckedAll}
                        />
                      </th>
                      <th className="border-r text-[#6E6E6E] text-[16px] font-sans border-[#CCCCCC]">
                        ユーザー名
                      </th>
                      <th className="border-r text-[#6E6E6E] text-[16px] font-sans border-[#CCCCCC]">
                        メールアドレス
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        プラン
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        利用/停止
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        Enterprise ID
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        入会日
                      </th>
                      <th className="text-[#6E6E6E] text-[16px] font-sans">
                        次回の支払い日
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <tr className="bg-white h-[46px]">
                        <td
                          className="text-[#6E6E6E] text-[16px] font-sans"
                          colSpan={100}
                        >
                          項目をロード中です...
                        </td>
                      </tr>
                    )}
                    {!loading && tableItems.length === 0 && (
                      <tr className="bg-white h-[46px]">
                        <td
                          className="text-[#6E6E6E] text-[16px] font-sans"
                          colSpan={100}
                        >
                          データがありません。
                        </td>
                      </tr>
                    )}
                    {tableItems.map((item, index) => (
                      <tr
                        className={`${
                          item.is_test
                            ? "bg-[#eaffe5]"
                            : item.is_test_300
                            ? "bg-[#e5eaff]"
                            : item.is_active
                            ? ""
                            : "bg-[#ffeeee]"
                        } h-[46px]`}
                        key={item.id}
                      >
                        <td className="max-w-[60px] border-r border-[#CCCCCC]">
                          <input
                            className="w-[16px] h-[16px]"
                            type="checkbox"
                            checked={checkedList[index] || false}
                            onChange={handleChecked(index)}
                          />
                        </td>
                        <td className="border-r text-[#6E6E6E] text-[16px] font-sans border-[#CCCCCC]">
                          {item.name}
                        </td>
                        <td className="border-r text-[#6E6E6E] text-[16px] font-sans border-[#CCCCCC]">
                          {item.email}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.corporate_admin
                            ? "管理者"
                            : item.corporate
                            ? "Enterprise"
                            : item.is_test
                            ? "Test"
                            : item.is_test_300
                            ? "Test_300"
                            : item.plan_name}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.is_active ? "利用" : "停止"}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.corporate}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.period_end > 0 &&
                            getJapanDate(
                              item.period_end * 1000,
                              item.period_interval === "year" ? 0 : -1,
                              item.period_interval === "year" ? -1 : 0
                            )}
                        </td>
                        <td className="border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.period_end > 0 &&
                            getJapanDate(item.period_end * 1000)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-[#E9E9F0] h-[24px] md:rounded-br-[20px] md:rounded-bl-[20px] rounded-br-[10px] rounded-bl-[10px]">
                      <td className="max-w-[60px] justify-center border-r border-[#CCCCCC]"></td>
                      <td className="border-r text-[#6E6E6E] text-[16px] font-sans border-[#CCCCCC]"></td>
                      <td className="border-r text-[#6E6E6E] text-[16px] font-sans border-[#CCCCCC]"></td>
                      <td className="border-r text-[#6E6E6E] text-[16px] font-sans border-[#CCCCCC]"></td>
                      <td className="border-r text-[#6E6E6E] text-[16px] font-sans border-[#CCCCCC]"></td>
                      <td className="border-r text-[#6E6E6E] text-[16px] font-sans border-[#CCCCCC]"></td>
                      <td className="border-r text-[#6E6E6E] text-[16px] font-sans border-[#CCCCCC]"></td>
                      <td className="text-[#6E6E6E] text-[16px] font-sans"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        {selectedTab === 2 && (
          <>
            <div className="max-w-[1096px] w-full mx-auto pt-6 flex justify-between sm:px-0 px-5 gap-6">
              <div className="flex gap-[15px] items-center">
                <button
                  title="選択したEnterpriseの削除"
                  onClick={handleDeleteEnterprise}
                  className="flex w-9 h-9 items-center justify-center border border-[#4B5090] rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="24"
                    viewBox="0 0 22.897 31.649"
                  >
                    <g transform="translate(-1454.49 -3077)">
                      <path
                        d="M1.393,0H20.48a1.393,1.393,0,0,1,1.393,1.393L18.962,25.076a1.393,1.393,0,0,1-1.393,1.393H4.294A1.393,1.393,0,0,1,2.9,25.076L0,1.393A1.393,1.393,0,0,1,1.393,0Z"
                        transform="translate(1455 3082.179)"
                        fill="#4b5090"
                      />
                      <line
                        x2="1.393"
                        y2="11.145"
                        transform="translate(1460.258 3089.85)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        y2="11"
                        transform="translate(1465.999 3089.833)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x1="1.393"
                        y2="11.145"
                        transform="translate(1470.223 3089.85)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x2="20.897"
                        transform="translate(1455.49 3080.09)"
                        fill="none"
                        stroke="#4b5090"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x2="5.572"
                        transform="translate(1463.152 3078)"
                        fill="none"
                        stroke="#4b5090"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                    </g>
                  </svg>
                </button>
              </div>
              <SearchControl
                value={tempKeyword}
                onChange={(e) => setTempKeyword(e.target.value)}
                onClick={() => setKeyword(tempKeyword)}
              />
            </div>
            <div className="max-w-[1130px] w-full h-full mx-auto sm:flex flex-row items-start py-5 overflow-auto">
              <div className="w-full min-w-[1130px] border md:rounded-[20px] rounded-[10px] overflow-hidden">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-[#E9E9F0] h-[60px]">
                      <th className="w-12 border-r border-[#CCCCCC]">
                        <input
                          className="w-[16px] h-[16px]"
                          type="checkbox"
                          checked={checkedAll || false}
                          onChange={handleCheckedAll}
                        />
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        管理者名
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        メールアドレス
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        プラン
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        入会日
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        次回の支払い日
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        会社名
                      </th>
                      <th className="text-[#6E6E6E] text-[16px] font-sans">
                        Enterprise ID
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <tr className="bg-white h-[46px]">
                        <td
                          className="text-[#6E6E6E] text-[16px] font-sans"
                          colSpan={100}
                        >
                          項目をロード中です...
                        </td>
                      </tr>
                    )}
                    {!loading && tableItems.length === 0 && (
                      <tr className="bg-white h-[46px]">
                        <td
                          className="text-[#6E6E6E] text-[16px] font-sans"
                          colSpan={100}
                        >
                          データがありません。
                        </td>
                      </tr>
                    )}
                    {tableItems.map((item, index) => (
                      <tr className="bg-white h-[46px]" key={item.id}>
                        <td className="max-w-[60px] border-r border-[#CCCCCC]">
                          <input
                            className="w-[16px] h-[16px]"
                            type="checkbox"
                            checked={checkedList[index] || false}
                            onChange={handleChecked(index)}
                          />
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.admin}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.email}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.plan
                            ? "Enterprise_" + item.plan
                            : "Enterprise trial"}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.creation_date &&
                            getJapanDate(item.creation_date)}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.period_end > 0 &&
                            getJapanDate(item.period_end * 1000)}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.company_name}
                        </td>
                        <td className="text-[#6E6E6E] text-[16px] font-sans">
                          {item.company_id}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-[#E9E9F0] h-[24px] md:rounded-br-[20px] md:rounded-bl-[20px] rounded-br-[10px] rounded-bl-[10px]">
                      <td className="max-w-[60px] justify-center border-r border-[#CCCCCC]"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="text-[#6E6E6E] text-[16px] font-sans"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        {selectedTab === 3 && (
          <>
            <div className="max-w-[1096px] w-full mx-auto pt-6 flex justify-between sm:px-0 px-5 gap-6">
              <div className="flex gap-[15px] items-center">
                <button
                  title="Agentの登録"
                  onClick={() => setAddAgentModalOpen(true)}
                  className="flex w-9 h-9 items-center justify-center border border-[#4B5090] rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 27 27"
                    className="w-4 h-4"
                  >
                    <g transform="translate(-1246.5 -2912.5)">
                      <line
                        y2="22"
                        transform="translate(1260.5 2915)"
                        fill="none"
                        stroke="#4b5090"
                        strokeLinecap="round"
                        strokeWidth="5"
                      />
                      <line
                        x2="22"
                        transform="translate(1249 2926)"
                        fill="none"
                        stroke="#4b5090"
                        strokeLinecap="round"
                        strokeWidth="5"
                      />
                    </g>
                  </svg>
                </button>
                <button
                  title="選択したAgentの編集"
                  onClick={handleEditAgent}
                  className="flex w-9 h-9 items-center justify-center border border-[#4B5090] rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 36.156 36.156"
                    className="w-5 h-5"
                  >
                    <g transform="translate(-971.405 3130.456) rotate(-135)">
                      <g transform="translate(1496 2880)" fill="none">
                        <path d="M5.113,0l5.113,8.948H0Z" stroke="none" />
                        <path
                          d="M 5.113253116607666 4.03113842010498 L 3.446364879608154 6.948196887969971 L 6.78014087677002 6.948196887969971 L 5.113253116607666 4.03113842010498 M 5.113253116607666 -2.86102294921875e-06 L 10.22650337219238 8.948196411132812 L 2.86102294921875e-06 8.948196411132812 L 5.113253116607666 -2.86102294921875e-06 Z"
                          stroke="none"
                          fill="#4b5090"
                        />
                      </g>
                      <path
                        d="M10.227,26.205H8.767V3.2H7.305V26.2H0V0H10.227V26.2Z"
                        transform="translate(1496 2888.948)"
                        fill="#4b5090"
                      />
                      <path
                        d="M0,0H10.227a0,0,0,0,1,0,0V1.474a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V0A0,0,0,0,1,0,0Z"
                        transform="translate(1496 2916.432)"
                        fill="#4b5090"
                      />
                    </g>
                  </svg>
                </button>
                <button
                  title="選択したAgentの削除"
                  onClick={handleDeleteAgent}
                  className="flex w-9 h-9 items-center justify-center border border-[#4B5090] rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="24"
                    viewBox="0 0 22.897 31.649"
                  >
                    <g transform="translate(-1454.49 -3077)">
                      <path
                        d="M1.393,0H20.48a1.393,1.393,0,0,1,1.393,1.393L18.962,25.076a1.393,1.393,0,0,1-1.393,1.393H4.294A1.393,1.393,0,0,1,2.9,25.076L0,1.393A1.393,1.393,0,0,1,1.393,0Z"
                        transform="translate(1455 3082.179)"
                        fill="#4b5090"
                      />
                      <line
                        x2="1.393"
                        y2="11.145"
                        transform="translate(1460.258 3089.85)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        y2="11"
                        transform="translate(1465.999 3089.833)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x1="1.393"
                        y2="11.145"
                        transform="translate(1470.223 3089.85)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x2="20.897"
                        transform="translate(1455.49 3080.09)"
                        fill="none"
                        stroke="#4b5090"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x2="5.572"
                        transform="translate(1463.152 3078)"
                        fill="none"
                        stroke="#4b5090"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                    </g>
                  </svg>
                </button>
              </div>
              <SearchControl
                value={tempKeyword}
                onChange={(e) => setTempKeyword(e.target.value)}
                onClick={() => setKeyword(tempKeyword)}
              />
            </div>
            <div className="max-w-[1130px] w-full h-full mx-auto sm:flex flex-row items-start py-5 overflow-auto">
              <div className="w-full min-w-[1130px] border md:rounded-[20px] rounded-[10px] overflow-hidden">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-[#E9E9F0] h-[60px]">
                      <th className="w-12 border-r border-[#CCCCCC]">
                        <input
                          className="w-[16px] h-[16px]"
                          type="checkbox"
                          checked={checkedAll || false}
                          onChange={handleCheckedAll}
                        />
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        管理者名
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        メールアドレス
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        プラン
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        入会日
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        次回の支払い日
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        会社名
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        Enterprise ID
                      </th>
                      <th className="text-[#6E6E6E] text-[16px] font-sans">
                        Agent ID
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <tr className="bg-white h-[46px]">
                        <td
                          className="text-[#6E6E6E] text-[16px] font-sans"
                          colSpan={100}
                        >
                          項目をロード中です...
                        </td>
                      </tr>
                    )}
                    {!loading && tableItems.length === 0 && (
                      <tr className="bg-white h-[46px]">
                        <td
                          className="text-[#6E6E6E] text-[16px] font-sans"
                          colSpan={100}
                        >
                          データがありません。
                        </td>
                      </tr>
                    )}
                    {tableItems.map((item, index) => (
                      <tr className="bg-white h-[46px]" key={item.id}>
                        <td className="max-w-[60px] border-r border-[#CCCCCC]">
                          <input
                            className="w-[16px] h-[16px]"
                            type="checkbox"
                            checked={checkedList[index] || false}
                            onChange={handleChecked(index)}
                          />
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.admin}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.email}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {"Enterprise_" + item.plan}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {getJapanDate(item.creation_date)}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.period_end > 0 &&
                            getJapanDate(item.period_end * 1000)}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.company_name}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.company_id}
                        </td>
                        <td className="text-[#6E6E6E] text-[16px] font-sans">
                          {item.agent_id}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-[#E9E9F0] h-[24px] md:rounded-br-[20px] md:rounded-bl-[20px] rounded-br-[10px] rounded-bl-[10px]">
                      <td className="max-w-[60px] justify-center border-r border-[#CCCCCC]"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="text-[#6E6E6E] text-[16px] font-sans"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        {selectedTab === 4 && (
          <>
            <div className="max-w-[1096px] w-full mx-auto pt-6 flex justify-between sm:px-0 px-5 gap-6">
              <div className="flex gap-[15px] items-center">
                <button
                  title="選択したEnterpriseの削除"
                  onClick={handleDeleteContact}
                  className="flex w-9 h-9 items-center justify-center border border-[#4B5090] rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="24"
                    viewBox="0 0 22.897 31.649"
                  >
                    <g transform="translate(-1454.49 -3077)">
                      <path
                        d="M1.393,0H20.48a1.393,1.393,0,0,1,1.393,1.393L18.962,25.076a1.393,1.393,0,0,1-1.393,1.393H4.294A1.393,1.393,0,0,1,2.9,25.076L0,1.393A1.393,1.393,0,0,1,1.393,0Z"
                        transform="translate(1455 3082.179)"
                        fill="#4b5090"
                      />
                      <line
                        x2="1.393"
                        y2="11.145"
                        transform="translate(1460.258 3089.85)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        y2="11"
                        transform="translate(1465.999 3089.833)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x1="1.393"
                        y2="11.145"
                        transform="translate(1470.223 3089.85)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x2="20.897"
                        transform="translate(1455.49 3080.09)"
                        fill="none"
                        stroke="#4b5090"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x2="5.572"
                        transform="translate(1463.152 3078)"
                        fill="none"
                        stroke="#4b5090"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                    </g>
                  </svg>
                </button>
              </div>
              <SearchControl
                value={tempKeyword}
                onChange={(e) => setTempKeyword(e.target.value)}
                onClick={() => setKeyword(tempKeyword)}
              />
            </div>
            <div className="max-w-[1130px] w-full h-full mx-auto sm:flex flex-row items-start py-5 overflow-auto">
              <div className="w-full min-w-[1130px] border md:rounded-[20px] rounded-[10px] overflow-hidden">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-[#E9E9F0] h-[60px]">
                      <th className="w-12 border-r border-[#CCCCCC]">
                        <input
                          className="w-[16px] h-[16px]"
                          type="checkbox"
                          checked={checkedAll || false}
                          onChange={handleCheckedAll}
                        />
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        日付
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        お名前
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        メールアドレス
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        電話番号
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        会社名
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        部署名
                      </th>
                      <th className="text-[#6E6E6E] text-[16px] font-sans">
                        お問い合せ内容
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <tr className="bg-white h-[46px]">
                        <td
                          className="text-[#6E6E6E] text-[16px] font-sans"
                          colSpan={100}
                        >
                          項目をロード中です...
                        </td>
                      </tr>
                    )}
                    {!loading && tableItems.length === 0 && (
                      <tr className="bg-white h-[46px]">
                        <td
                          className="text-[#6E6E6E] text-[16px] font-sans"
                          colSpan={100}
                        >
                          データがありません。
                        </td>
                      </tr>
                    )}
                    {tableItems.map((item, index) => (
                      <tr className="bg-white h-[46px]" key={item.id}>
                        <td className="max-w-[60px] border-r border-[#CCCCCC]">
                          <input
                            className="w-[16px] h-[16px]"
                            type="checkbox"
                            checked={checkedList[index] || false}
                            onChange={handleChecked(index)}
                          />
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {getJapanDate(item.creation_date)}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.name}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.email}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.phone}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.company}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.part}
                        </td>
                        <td className="text-[#6E6E6E] text-[16px] font-sans px-4 max-w-[200px]">
                          <p
                            className="truncate w-full text-start underline cursor-pointer hover:text-[#4356ff]"
                            onClick={() => {
                              setDetailText(item.content);
                              setDetailInfo({
                                email: item.email,
                                name: item.name,
                              });
                            }}
                          >
                            {item.content}
                          </p>
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-[#E9E9F0] h-[24px] md:rounded-br-[20px] md:rounded-bl-[20px] rounded-br-[10px] rounded-bl-[10px]">
                      <td className="max-w-[60px] justify-center border-r border-[#CCCCCC]"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="text-[#6E6E6E] text-[16px] font-sans"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        {selectedTab === 5 && (
          <>
            <div className="max-w-[1096px] w-full mx-auto pt-6 flex justify-between sm:px-0 px-5 gap-6">
              <div className="flex gap-[15px] items-center">
                <button
                  title="選択したEnterpriseの削除"
                  onClick={handleDeleteDl}
                  className="flex w-9 h-9 items-center justify-center border border-[#4B5090] rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="24"
                    viewBox="0 0 22.897 31.649"
                  >
                    <g transform="translate(-1454.49 -3077)">
                      <path
                        d="M1.393,0H20.48a1.393,1.393,0,0,1,1.393,1.393L18.962,25.076a1.393,1.393,0,0,1-1.393,1.393H4.294A1.393,1.393,0,0,1,2.9,25.076L0,1.393A1.393,1.393,0,0,1,1.393,0Z"
                        transform="translate(1455 3082.179)"
                        fill="#4b5090"
                      />
                      <line
                        x2="1.393"
                        y2="11.145"
                        transform="translate(1460.258 3089.85)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        y2="11"
                        transform="translate(1465.999 3089.833)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x1="1.393"
                        y2="11.145"
                        transform="translate(1470.223 3089.85)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x2="20.897"
                        transform="translate(1455.49 3080.09)"
                        fill="none"
                        stroke="#4b5090"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x2="5.572"
                        transform="translate(1463.152 3078)"
                        fill="none"
                        stroke="#4b5090"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                    </g>
                  </svg>
                </button>
              </div>
              <SearchControl
                value={tempKeyword}
                onChange={(e) => setTempKeyword(e.target.value)}
                onClick={() => setKeyword(tempKeyword)}
              />
            </div>
            <div className="max-w-[1130px] w-full h-full mx-auto sm:flex flex-row items-start py-5 overflow-auto">
              <div className="w-full min-w-[1130px] border md:rounded-[20px] rounded-[10px] overflow-hidden">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-[#E9E9F0] h-[60px]">
                      <th className="w-12 border-r border-[#CCCCCC]">
                        <input
                          className="w-[16px] h-[16px]"
                          type="checkbox"
                          checked={checkedAll || false}
                          onChange={handleCheckedAll}
                        />
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        日付
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        お名前
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        メールアドレス
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        電話番号
                      </th>
                      <th className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                        会社名
                      </th>
                      <th className="text-[#6E6E6E] text-[16px] font-sans">
                        部署名
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <tr className="bg-white h-[46px]">
                        <td
                          className="text-[#6E6E6E] text-[16px] font-sans"
                          colSpan={100}
                        >
                          項目をロード中です...
                        </td>
                      </tr>
                    )}
                    {!loading && tableItems.length === 0 && (
                      <tr className="bg-white h-[46px]">
                        <td
                          className="text-[#6E6E6E] text-[16px] font-sans"
                          colSpan={100}
                        >
                          データがありません。
                        </td>
                      </tr>
                    )}
                    {tableItems.map((item, index) => (
                      <tr className="bg-white h-[46px]" key={item.id}>
                        <td className="max-w-[60px] border-r border-[#CCCCCC]">
                          <input
                            className="w-[16px] h-[16px]"
                            type="checkbox"
                            checked={checkedList[index] || false}
                            onChange={handleChecked(index)}
                          />
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {getJapanDate(item.creation_date)}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.name}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.email}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.phone}
                        </td>
                        <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                          {item.company}
                        </td>
                        <td className="text-[#6E6E6E] text-[16px] font-sans px-4 max-w-[200px]">
                          {item.part}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-[#E9E9F0] h-[24px] md:rounded-br-[20px] md:rounded-bl-[20px] rounded-br-[10px] rounded-bl-[10px]">
                      <td className="max-w-[60px] justify-center border-r border-[#CCCCCC]"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                      <td className="text-[#6E6E6E] text-[16px] font-sans"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        {selectedTab === 6 && (
          <>
            <div className="max-w-[1096px] w-full mx-auto pt-6 px-8 gap-6">
              <div className="flex flex-wrap justify-center lg:justify-start gap-[15px] items-center">
                <div
                  className={`whitespace-nowrap text-[#333876] sm:text-[17px] text-[13px] font-sans font-bold`}
                >
                  サーバー状態の変更
                </div>
                <select
                  onChange={(e) => setSeverStatus(e.target.value)}
                  className="max-w-[554px] border border-[#8B8B8B] h-[40px] px-[20px] rounded-[10px] bg-transparent font-sans focus:outline-none"
                >
                  <option
                    className="bg-background-main font-[18px] font-sans"
                    value="default"
                    selected={serverStatus === "default"}
                  >
                    一般
                  </option>
                  <option
                    className="bg-background-main font-[18px] font-sans"
                    value="maintenance"
                    selected={serverStatus === "maintenance"}
                  >
                    メンテナンス
                  </option>
                </select>
                <input
                  type="datetime-local"
                  className="border border-[#8B8B8B] h-[40px] px-[20px] rounded-[10px]"
                  value={shutDownTime}
                  onChange={(e) => setShutDownTime(e.target.value)}
                />
                <button
                  title="変更"
                  onClick={() => postSeverStatus()}
                  className="flex items-center justify-center border min-w-[70px] border-[#4B5090] rounded-md sm:h-[40px] h-[35px] py-1 px-4 hover:bg-[#4B5090] hover:text-white"
                >
                  変更
                </button>
              </div>
            </div>
          </>
        )}
        {selectedTab !== 6 && (
          <div className="max-w-[1130px] w-full flex flex-row justify-end mx-auto text-[#6E6E6E] sm:px-0 px-5 gap-5">
            <div className="grid sm:grid-cols-3 grid-cols-1 sm:max-w-[600px] items-center w-full">
              <div className="flex col-span-2 items-center justify-center gap-4">
                <span>ページごとの項目</span>
                <select
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(e.target.value)}
                  className="w-20 border h-10 rounded-md outline-none px-2"
                >
                  <option>10</option>
                  <option>20</option>
                  <option>50</option>
                  <option>すべて</option>
                </select>
                <span>
                  {(currentPage - 1) * itemsPerPage + 1}-
                  {currentPage * itemsPerPage < totalCount
                    ? currentPage * itemsPerPage
                    : totalCount}{" "}
                  件目 / {totalCount}件
                </span>
              </div>
              <div className="flex flex-row sm:justify-end justify-center gap-5">
                <button onClick={moveFirst} className="w-8 h-8 hover:border">
                  &lt;&lt;
                </button>
                <button onClick={movePrev} className="w-8 h-8 hover:border">
                  &lt;
                </button>
                <button onClick={moveNext} className="w-8 h-8 hover:border">
                  &gt;
                </button>
                <button onClick={moveLast} className="w-8 h-8 hover:border">
                  &gt;&gt;
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {confirmDeleteUserModalOpen && (
        <ConfirmModal
          isOpen={confirmDeleteUserModalOpen}
          onAgree={deleteUser}
          onCancel={() => setConfirmDeleteUserModalOpen(false)}
          text={
            <div>
              <p>選択したユーザーアカウントを削除しますか？</p>
              <p className="text-[#505050] text-[16px] mt-4">
                選択中のEnterpriseユーザーは削除されません。
              </p>
            </div>
          }
          buttonText="削除する"
        />
      )}
      {confirmDeleteEnterpriseModalOpen && (
        <ConfirmModal
          isOpen={confirmDeleteEnterpriseModalOpen}
          onAgree={deleteEnterprise}
          onCancel={() => setConfirmDeleteEnterpriseModalOpen(false)}
          text="選択したEnterpriseアカウントを削除しますか？"
          buttonText="削除する"
        />
      )}
      {confirmDeleteAgentModalOpen && (
        <ConfirmModal
          isOpen={confirmDeleteAgentModalOpen}
          onAgree={deleteAgent}
          onCancel={() => setConfirmDeleteAgentModalOpen(false)}
          text="選択したEnterprise Agentアカウントを削除しますか？"
          buttonText="削除する"
        />
      )}
      {confirmDeleteContactModalOpen && (
        <ConfirmModal
          isOpen={confirmDeleteContactModalOpen}
          onAgree={deleteContact}
          onCancel={() => setConfirmDeleteContactModalOpen(false)}
          text="選択したアイテムを削除しますか？"
          buttonText="削除する"
        />
      )}
      {confirmDeleteDlModalOpen && (
        <ConfirmModal
          isOpen={confirmDeleteDlModalOpen}
          onAgree={deleteDl}
          onCancel={() => setConfirmDeleteDlModalOpen(false)}
          text="選択したアイテムを削除しますか？"
          buttonText="削除する"
        />
      )}
      {detailText !== "" && (
        <div className="flex bg-black/40 w-full h-screen z-[9999] fixed top-0 left-0 sm:px-0 px-5">
          <div className="flex flex-col max-w-[830px] w-full mx-auto my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] rounded-[10px]">
            <div className="flex justify-end">
              <img
                onClick={() => setDetailText("")}
                className="w-[20px] h-[20px] cursor-pointer"
                src="/assets/cancel-modal.svg"
                alt="Cancel"
              />
            </div>
            <div className="text-[#4B5090] font-sans sm:text-[20px] text-[14px] text-start items-start px-10">
              <div className="flex flex-row gap-10">
                <p>お名前: {detailInfo.name}</p>
                <p>メールアドレス: {detailInfo.email}</p>
              </div>
              <textarea
                readOnly
                className="border text-[#505050] text-[16px] w-full h-[400px] min-h-[200px] max-h-[400px] my-4 p-4 overflow-auto outline-none"
              >
                {detailText}
              </textarea>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
