import React, { useEffect, useState } from "react";
import Notiflix from "notiflix";

import api from "../../../services/api";
import { useSelector } from "react-redux";

const getDateString = (timestamp) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export default function UpdateEnterpriseAgentPlanModal({
  openModal,
  onClose,
  payload,
}) {
  const permissions = useSelector(
    (state) => state.LanguiseReducer.enterprisePlans
  );
  const [permissionId, setPermissionId] = useState();
  const [companyName, setCompanyName] = useState(payload.company_name);
  const [email, setEmail] = useState(payload.email);
  const [paymentDate, setPaymentDate] = useState();

  useEffect(() => {
    if (permissions.length > 0) setPermissionId(permissions[0].id);
  }, [permissions]);

  useEffect(() => {
    if (payload.period_end)
      setPaymentDate(getDateString(payload.period_end * 1000));
  }, [payload.period_end]);

  const updatePlan = (e) => {
    e.preventDefault();

    api
      .put("/management/agent/", {
        id: payload.id,
        plan: permissionId,
        company_name: companyName,
        email: email,
        payment_date: paymentDate,
      })
      .then((rst) => {
        onClose();
      })
      .catch((error) => {
        if (error.response.status === 400) {
          Notiflix.Notify.failure("メールアドレスが存在しません。");
        }
        if (error.response.status === 406) {
          Notiflix.Notify.failure(
            "メールアドレスは、現在のエンタープライズエージェントのユーザーではありません。"
          );
        }
        console.log(error);
      });
  };

  return (
    <div
      className={`${
        openModal
          ? "flex !opacity-100 !translate-y-0"
          : "hidden !opacity-0 !translate-y-10"
      } fixed inset-0 bg-black/40 z-30 !transition-all !ease-in-out !duration-300`}
    >
      <div className="flex flex-col max-w-[830px] w-full mx-auto my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] rounded-lg">
        <div className="flex justify-end">
          <img
            onClick={onClose}
            className="w-[20px] h-[20px] cursor-pointer"
            src="/assets/cancel-modal.svg"
            alt="Cancel"
          />
        </div>
        <p className="text-[#4B5090] font-sans text-[20px] text-center">
          Enterpriseプランの変更
        </p>
        <div className="flex flex-col max-w-[703px] w-full mx-auto gap-[10px] mt-[30px]">
          <div className="flex sm:flex-row flex-col sm:gap-8 sm:items-center items-start">
            <div className="flex flex-1 justify-end text-[#505050] text-[16px] font-sans">
              会社名
            </div>
            <input
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className="max-w-[554px] w-full border border-[#8B8B8B] h-[40px] px-[20px] rounded-[10px] bg-transparent font-sans focus:outline-none"
            />
          </div>
          <div className="flex sm:flex-row flex-col sm:gap-8 sm:items-center items-start">
            <div className="flex flex-1 justify-end text-[#505050] text-[16px] font-sans">
              メールアドレス
            </div>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="max-w-[554px] w-full border border-[#8B8B8B] h-[40px] px-[20px] rounded-[10px] bg-transparent font-sans focus:outline-none"
            />
          </div>
          <div className="flex sm:flex-row flex-col sm:gap-8 sm:items-center items-start">
            <div className="flex flex-1 justify-end text-[#505050] text-[16px] font-sans">
              次回の支払日
            </div>
            <input
              type="date"
              value={paymentDate}
              onChange={(e) => setPaymentDate(e.target.value)}
              className="max-w-[554px] w-full border border-[#8B8B8B] h-[40px] px-[20px] rounded-[10px] bg-transparent font-sans focus:outline-none"
            />
          </div>
          <div className="flex sm:flex-row flex-col sm:gap-8 sm:items-center items-start">
            <div className="flex flex-1 justify-end text-[#505050] text-[16px] font-sans">
              プランを選択
            </div>
            <select
              onChange={(e) => setPermissionId(e.target.value)}
              className="max-w-[554px] w-full border border-[#8B8B8B] h-[40px] px-[20px] rounded-[10px] bg-transparent font-sans focus:outline-none"
            >
              {permissions.map((item, index) => (
                <option
                  key={index}
                  className="bg-background-main font-[18px] font-sans"
                  value={item.id}
                >
                  Enterprise_{item.count}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className=" max-w-[703px] w-full mx-auto flex justify-end my-5">
          <button
            onClick={updatePlan}
            className="max-w-[105px] w-full bg-[#4B5090] hover:bg-[#6267b2] h-[40px] text-white rounded-[10px]"
          >
            更新する
          </button>
        </div>
      </div>
    </div>
  );
}
