import React, { useCallback, useState, useEffect } from "react";
import TextField from "../TextField";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import {
  LoginSocialGoogle,
  // LoginSocialFacebook,
  // LoginSocialApple,
} from "reactjs-social-login";
import { noTokenApi } from "../../services/api";
import Notiflix from "notiflix";
import { setCookie } from "../../services/utils";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, getUserInfo } from "../../redux/actions/languiseAction";
import ConfirmModal from "./ConfirmModal";
import api from "../../services/api";
import { useTranslation } from "react-i18next";

export default function LoginModalContent({
  goToNextStep,
  getAnimationClass,
  transitionDirection,
  openModal,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [textColor, setTextColor] = useState("#8B8B8B");
  const [showClicked, setShowClicked] = useState(false);
  const [isForgotPassword, setForgotPassword] = useState(false);
  const [isSendMail, setSendMail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [stayLogin, setStayLogin] = useState(false);

  const prices = useSelector((state) => state.LanguiseReducer.prices);
  const userInfo = useSelector((state) => state.LanguiseReducer.userInfo);
  const [plans, setPlans] = useState([]);

  const rootUrl = window.location.origin;

  useEffect(() => {
    if (prices.monthly) setPlans(prices.monthly);
  }, [prices]);

  useEffect(() => {
    if (password.length > 0 && email.length > 0) {
      setBgColor("bg-[#4B5090]");
      setTextColor("text-[#eee] ");
    } else {
      setBgColor("");
      setTextColor("text-[#8B8B8B]");
    }
  }, [password, email]);

  const responseSocial = async ({ provider, data }) => {
    if (!data || !provider) return;
    const { access_token } = data;
    noTokenApi
      .post("/user/social-login/", {
        token: access_token,
        provider: provider,
      })
      .then((res) => {
        setCookie(res);
        dispatch(loginUser(true));
        openModal();
      })
      .catch((err) => {
        if (err.response && err.response.status === 406) {
          Notiflix.Notify.failure(t("socialLoginFailure"));
        } else {
          Notiflix.Notify.failure(t("loginFailure"));
        }
      });
  };

  const handleForgotPassword = () => {
    if (email === null || email === "") return;
    noTokenApi
      .post("/user/resetpassword/", { email })
      .then((res) => {
        setSendMail(true);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          Notiflix.Notify.failure(t("noEmail"));
        } else {
          Notiflix.Notify.failure(t("requestFailure"));
        }
      });
  };
  useEffect(() => {
    if (!userInfo.plan && userInfo.plan != 0) return;
    openModal();
    
  }, [userInfo]);

  const validateForm = () => {
    const temp = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    if (!emailRegex.test(email)) temp.email = t("requireEmail");
    if (!password) temp.password = t("requirePassword");
    return temp;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    setErrorMessage({ ...validationErrors });
    if (Object.keys(validationErrors).length > 0) return;

    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("stayLogin", stayLogin);
    setIsLoading(true);
    noTokenApi
      .post("/user/login/", formData)
      .then((res) => {
        setIsLoading(false);
        setCookie(res);
        dispatch(loginUser(true));
        dispatch(getUserInfo());
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response && err.response.status === 401) {
          Notiflix.Notify.failure(t("unregistered"));
        } else if (err.response && err.response.status === 406) {
          Notiflix.Notify.failure(t("socialLoginFailure"));
        } else {
          Notiflix.Notify.failure(t("wrongPassword"));
        }
      });
  };

  return (
    <div className="flex bg-black/40 w-full h-screen z-[9999] fixed top-0 left-0 sm:px-0 px-5">
      {isLoading && (
        <div className="flex fixed top-0 z-[9999] left-0 w-full items-center justify-center h-full">
          <PuffLoader
            css={css`
              display: block;
              margin: 0 auto;
              border-color: red;
            `}
            size={150}
            color={"#11135C"}
            loading={isLoading}
          />
        </div>
      )}
      {!isForgotPassword ? (
        <div className="flex flex-col max-w-[830px] w-full mx-auto my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] pb-10 rounded-[10px]">
          <div className="flex justify-end">
            <img
              onClick={openModal}
              className="w-[20px] h-[20px] cursor-pointer"
              src="/assets/cancel-modal.svg"
              alt="Cancel"
            />
          </div>

          <p className="text-[#4B5090] text-[20px] font-sans text-center font-[600]">
            {t("login")}
          </p>

          <div className="max-w-[420px] w-full flex flex-col gap-5 mx-auto sm:mt-[43px] mt-5">
            {/* <LoginSocialGoogle
              client_id={process.env.REACT_APP_CLIENT_ID}
              cookiePolicy={"single_host_origin"}
              onLogoutFailure={onLogoutFailure}
              onLogoutSuccess={onLogoutSuccess}
              onResolve={responseSocial}
              onReject={(err) => {
                console.log("Error: ", err);
              }}
            >
              <div className="relative text-[16px] text-[#505050] font-sans border-[#8B8B8B] border rounded-[10px] w-full h-[40px] flex items-center justify-center active:bg-[#e6e6e6] cursor-pointer">
                <img
                  className="absolute left-[30px]"
                  src="/assets/google-log.png"
                  alt="Google Log"
                />
                Google で続ける
              </div>
            </LoginSocialGoogle> */}
            {/* <div className="w-full flex justify-between gap-5">
              <LoginSocialApple
                className="max-w-[200px] w-full"
                client_id="123"
                scope={"name email"}
                onResolve={responseSocial}
              >
                <div className="relative text-[16px] text-[#505050] font-sans border-[#8B8B8B] border rounded-[10px] h-[40px] flex flex-1 items-center justify-center active:bg-[#e6e6e6] cursor-pointer">
                  <img
                    className="absolute sm:left-[40px] left-[20px]"
                    src="/assets/apple-log.png"
                    alt="Apple Log"
                  />
                  Apple
                </div>
              </LoginSocialApple>
              <LoginSocialFacebook
                className="max-w-[200px] w-full"
                appId="123"
                fieldsProfile={"id, first_name, last_name, email"}
                onResolve={responseSocial}
              >
                <div className="relative text-[16px] text-[#505050] font-sans border-[#8B8B8B] border rounded-[10px] h-[40px] flex flex-1 items-center justify-center active:bg-[#e6e6e6] cursor-pointer">
                  <img
                    className="absolute sm:left-[30px] left-[20px]"
                    src="/assets/facebook-log.png"
                    alt="Facebook Log"
                  />
                  Facebook
                </div>
              </LoginSocialFacebook>
            </div> */}
          </div>

          {/* <div className="max-w-[420px] w-full mx-auto flex justify-between gap-[26px] sm:mt-[33px] mt-5">
            <hr className=" flex flex-1 border-[#CCCCCC] mt-[10px]" />
            <p className="text-[16px] text-[#8B8B8B] font-sans">または</p>
            <hr className=" flex flex-1 border-[#CCCCCC] mt-[10px]" />
          </div> */}

          <form onSubmit={handleLogin}>
            <div className="flex flex-col max-w-[420px] w-full mx-auto gap-4 sm:mt-[26px] mt-3">
              <div className="flex flex-col gap-1">
                <p className="text-[#505050] text-[12px] font-sans text-start">
                  {t("email")}
                </p>
                <TextField
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="name@email.com"
                />
                <span className="ml-4 text-start text-red-600 text-sm">
                  {errorMessage.email}
                </span>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-[#505050] text-[12px] font-sans text-start">
                  {t("password")}
                </p>
                <TextField
                  type={`${showClicked ? "text" : "password"}`}
                  value={password}
                  showClicked={showClicked}
                  onClick={() => setShowClicked(!showClicked)}
                  onChange={(event) => setPassword(event.target.value)}
                  show_password={true}
                  placeholder={t("password")}
                />
                <span className="ml-4 text-start text-red-600 text-sm">
                  {errorMessage.password}
                </span>
                <span
                  onClick={() => setForgotPassword(true)}
                  className="text-[#3D80BA] text-[14px] font-sans text-start cursor-pointer"
                >
                  {t("forgotPassword")}
                </span>
              </div>
            <div className="flex justify-center sm:text-[14px] text-[12px] items-center">
              <input
                type="checkbox"
                checked={stayLogin}
                onChange={(e) => setStayLogin(e.target.checked)}
                className="cursor-pointer"
              ></input>
              <span className="ml-2 text-[#505050]">
                {t("keepLogin")}
              </span>
            </div>
              <button
                type="submit"
                onClick={handleLogin}
                className={`ease-in duration-100 border border-[#707070] ${textColor} text-[16px] font-sans h-[40px] ${bgColor} rounded-[10px] hover:bg-[#5d63af] hover:text-white`}
              >
                {t("next")}
              </button>
            </div>
          </form>

          <div className="flex max-w-[381.5px] w-full mx-auto justify-between mt-6">
            <p className="text-[#505050] sm:text-[16px] text-[13px] font-sans text-start">
              {t("signUpText")}
            </p>
            <button
              onClick={goToNextStep}
              className="text-[#3D80BA] sm:text-[16px] text-[13px] font-sans underline"
            >
              {t("signUp")}
            </button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col max-w-[830px] w-full mx-auto my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] pb-10 rounded-[10px]">
          <div className="flex justify-end">
            <img
              onClick={() => {
                setForgotPassword(false);
                setSendMail(false);
              }}
              className="w-[20px] h-[20px] cursor-pointer"
              src="/assets/cancel-modal.svg"
              alt="Cancel"
            />
          </div>

          <p className="text-[#4B5090] text-[20px] font-sans text-center font-[600]">
            {t("resetPassword")}
          </p>
          {isSendMail ? (
            <div className="max-w-[420px] w-full flex flex-col gap-10 mx-auto mt-12">
              <p className="text-xl text-[#505050]">
                {t("resetMessage")}({email})
              </p>
            </div>
          ) : (
            <div className="max-w-[420px] w-full flex flex-col gap-10 mx-auto mt-12">
              <TextField
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="name@email.com"
              />
              <button
                onClick={handleForgotPassword}
                className={`ease-in duration-100 border w-full border-[#707070] ${textColor} text-[16px] font-sans h-[40px] ${bgColor} rounded-[10px] hover:bg-[#5d63af] hover:text-white`}
              >
                {t("reset")}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
