import React from "react";

export default function Card({
  width,
  smWidth,
  height,
  smHeight,
  thumbnail,
  alternative,
  title,
  content,
  thumbnailSize,
}) {
  return (
    <div className="flex flex-col md:flex-row max-w-[320px] items-center md:max-w-[600px] lg:gap-[38px] gap-[17.5px]">
      <div
        className={`flex items-center flex-grow min-w-0 md:min-w-max max-w-[${smWidth}] m-auto sm:m-0  min-w-none md:min-w-[190px] lg:min-w-none`}
      >
        <img
          className={`mx-auto max-h-[128px] md:max-h-[174px]`}
          src={`/assets/${thumbnail}`}
          alt={alternative}
          style={{
            width: thumbnailSize.width,
          }}
        />
      </div>
      <div>
        <h3 className="text-[#222222] text-center md:text-left xl:text-[20px] md:text-[18px] text-[16px] font-sans font-[600]">
          {title}
        </h3>
        <p className="text-[#222222] xl:text-[16px] md:text-[15px] text-[14px] xl:leading-[32px] md:leading-[28px] leading-[25px] font-sans text-start mt-4">
          {content}
        </p>
      </div>
    </div>
  );
}
