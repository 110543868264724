import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../services/api";
import { useDispatch, useSelector } from "react-redux";
import { getA8ID } from "../services/utils";
import { getUserInfo } from "../redux/actions/languiseAction";
import { useTranslation } from "react-i18next";
import Storage from "../translations/storage";

export default function CorporateSuccess() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const info = queryParams.get("info");
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [first, setFirst] = useState(true);
  const [text, setText] = useState(t("corporateSuccessText1"));
  const userInfo = useSelector((state) => state.LanguiseReducer.userInfo);
  const uiLang = useSelector((state) => state.LanguiseReducer.uiLang);
  const [lang, setLang] = useState("jp");

  useEffect(() => {
    setLang(uiLang);
  }, [uiLang]);

  const verify = async () => {
    try {
      const res = await api.post("/user/enterpriseplan/", { id, info });
      dispatch(getUserInfo());
      setSuccess(true);
      const { so, si } = res.data;
      const a8 = getA8ID();
      if (a8) {
        fetch(
          `https://px.a8.net/a8fly/earnings?a8=${a8}&pid=s00000026066001&so=${so}&si=${si}&currency=JPY`,
          {
            method: "GET",
            mode: "no-cors",
          }
        ).catch((err) => {
          console.log(err);
        });
      }
    } catch (error) {
      setText(t("corporateSuccessText2"));
      console.log(error);
    }
  };

  useEffect(() => {
    setFirst(false);
    if (first) verify();
  }, [first]);

  return (
    <div className="flex flex-col flex-grow bg-black/40 md:mx-0 mx-5">
      <div className="flex flex-col max-w-[830px] w-full mx-auto my-auto z-50 bg-[#F0F1F8] px-[12px] py-[12px] rounded-lg">
        {success ? (
          <>
            <div className="text-[#4B5090] font-semibold font-sans md:text-[30px] text-[20px] text-center md:mt-12 mt-8">
              {t("corporateSuccessText3")}
            </div>
            {lang !== "jp" && (
              <div className="text-[#6E6E6E] md:text-[16px] text-[12px]">
                {"Enjoy Languise with "}
              </div>
            )}
            {!!userInfo.plan && userInfo.plan + 1 && (
              <div className="flex flex-row text-[#4B5090] font-sans text-center items-end mx-auto sm:mt-10 mt-6">
                <div className="md:text-[56px] text-[36px] text-center font-semibold">
                  Enterprise {(userInfo.plan + 1) * 100}
                </div>
                <div className="md:text-[26px] text-[20px] text-center md:pb-3 pb-2 pl-2 font-semibold">
                  {t("plan")}
                </div>
              </div>
            )}
            {lang === "jp" && (
              <div className="text-[#6E6E6E] md:text-[16px] text-[12px]">
                でLanguiseをお楽しみください。
              </div>
            )}
            <button
              onClick={() => navigate("/")}
              className="bg-[#4B5090] text-white md:text-[16px] text-[14px] md:w-[170px] w-[120px] h-[40px] rounded-[10px] mx-auto mt-10 md:mb-10 mb-6"
            >
              {t("goHome")}
            </button>
          </>
        ) : (
          <div className="text-[#4B5090] font-semibold font-sans md:text-[30px] text-[20px] text-center md:mt-12 mt-8 mb-20">
            {text}
          </div>
        )}
      </div>
    </div>
  );
}
