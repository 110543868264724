import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import api from "../../../services/api";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";

export function ToogleSwitch({ value, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`flex flex-row justify-center items-center gap-2 w-20 h-8 rounded-full ${
        value ? "bg-white" : "bg-gray-300"
      } cursor-pointer`}
    >
      {!value && <p className="text-[#4B5090] text-[16px]">OFF</p>}
      <div className="w-5 h-5 rounded-full bg-[#4B5090]">&nbsp;</div>
      {value && <p className="text-[#4B5090] text-[16px]">ON</p>}
    </div>
  );
}

export default function ShareDictionaryModal({
  openModal,
  onClose,
  dictionaryId,
}) {
  const { t } = useTranslation();
  const corporateUsers = useSelector(
    (state) => state.LanguiseReducer.corporateUsers
  );

  const [isUseable, setUseable] = useState(false);
  const [isEditable, setEditable] = useState(false);
  const [sharedUsers, setSharedUsers] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    if (openModal && corporateUsers && dictionaryId) {
      const params = new URLSearchParams();
      params.append("dictionary_id", dictionaryId);
      api
        .get("/dictionary/share/", { params })
        .then((rst) => {
          const data = rst.data;
          if (data.length > 0) {
            setUseable(data[0].useable);
            setEditable(data[0].editable);
            const users = data[0].shared_users.split(",");
            setSelectedUsers(
              users.map((id) => {
                return {
                  value: Number(id),
                  label: corporateUsers.filter(
                    (item) => Number(item.id) === Number(id)
                  )[0].user.email,
                };
              })
            );
          } else {
            setUseable(false);
            setEditable(false);
            setSelectedUsers([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setSelectedUsers([])
    }
  }, [corporateUsers, dictionaryId, openModal]);

  useEffect(() => {
    if (corporateUsers.length > 0) {
      setSelectOptions(
        corporateUsers.map((item) => {
          return {
            value: item.id,
            label: item.user.email,
          };
        })
      );
    }
  }, [corporateUsers]);

  const selectUsers = (values) => {
    console.log(values);
    setSelectedUsers(values);
    const temp = values.map((item) => {
      return item.value;
    });
    setSharedUsers(temp);
  };

  const shareDictionary = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("dictionary_id", dictionaryId);
    formData.append("shared_users", String(sharedUsers));
    formData.append("useable", isUseable);
    formData.append("editable", isEditable);

    api
      .post("/dictionary/share/", formData)
      .then((res) => {
        onClose();
      })
      .catch((err) => {
        Notiflix.Notify.failure(t("operationFailure"));
        console.log(err);
      });
  };
  if (!openModal || !dictionaryId) return null;
  return (
    <div className="flex bg-black/40 w-full h-screen z-[9999] fixed top-0 left-0 sm:px-0 px-5">
      <div className="flex flex-col max-w-[830px] w-full min-h-[278px] mx-auto my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] rounded-[10px]">
        <div className="flex justify-end">
          <img
            onClick={onClose}
            className="w-[20px] h-[20px] cursor-pointer"
            src="/assets/cancel-modal.svg"
            alt="Cancel"
          />
        </div>
        <p className="text-[#4B5090] font-sans text-[20px] text-center">
          {t("shareDicModalText1")}
        </p>
        <div className="flex flex-col w-full sm:px-[70px] px-2 mx-auto gap-[27px] mt-[37px]">
          <div className="flex justify-between gap-5">
            <Select
              options={selectOptions}
              isMulti
              onChange={selectUsers}
              className="w-full text-left"
              closeMenuOnSelect={false}
              value={selectedUsers}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderRadius: "10px",
                }),
              }}
              placeholder={t("shareDicModalText2")}
            />
            <button
              onClick={shareDictionary}
              className="max-w-[76px] w-full bg-[#4B5090] h-[40px] text-white rounded-[10px]"
            >
              {t("shareDicModalText3")}
            </button>
          </div>
          <div className="flex sm:flex-row flex-col justify-center items-center gap-2 text-[#4B5090] text-[14px]">
            <div className="flex flex-row items-center gap-2">
              <p>{t("shareDicModalText4")}</p>
              <ToogleSwitch
                value={isEditable}
                onClick={() => setEditable(!isEditable)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
