import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  useStripe,
  useElements,
  CardElement,
  Elements,
} from "@stripe/react-stripe-js";
import api from "../../../services/api";
import { useDispatch } from "react-redux";
import { getUserInfo } from "../../../redux/actions/languiseAction";
import { useTranslation } from "react-i18next";

const getStripeInstance = async () => {
  try {
    const res = await api.get("/payment/config/");
    const publishableKey = res.data.publishableKey;
    return loadStripe(publishableKey);
  } catch (err) {
    console.log();
    return null;
  }
};

const StripeElemens = ({ closeModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (error) {
      console.error(error);
      setLoading(false);
      return;
    }
    api
      .put("/payment/config/", { payment_method: paymentMethod })
      .then(() => {
        setLoading(false);
        closeModal();
        dispatch(getUserInfo());
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <form className="w-full" onSubmit={handleSubmit}>
      <CardElement
        className="border border-[#B1B1B1B1] rounded-lg p-6 text-lg"
        options={{
          hidePostalCode: true,
          disableLink: true,
        }}
      />
      <div className="flex justify-center mt-16">
        <button
          type="submit"
          disabled={!stripe || loading}
          className="max-w-[200px] w-full bg-[#4B5090] h-[40px] text-white rounded-[10px]"
        >
          {loading
            ? t("updatePaymentModalText1")
            : t("updatePaymentModalText2")}
        </button>
      </div>
    </form>
  );
};

const UpdatePaymentMethodModal = ({ openModal, closeModal }) => {
  const stripeInstance = getStripeInstance();

  return (
    <div>
      {openModal && (
        <div className="flex flex-gr bg-black/40 w-full h-screen z-[9999] fixed top-0 left-0 sm:px-0 px-5">
          <div className="flex flex-col max-w-[830px] w-full min-h-[278px] mx-auto my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] rounded-[10px]">
            <div className="flex justify-end">
              <img
                onClick={closeModal}
                className="w-[20px] h-[20px] cursor-pointer"
                src="/assets/cancel-modal.svg"
                alt="Cancel"
              />
            </div>
            <div className="flex w-full max-w-[500px] mx-auto mt-10 justify-between">
              <Elements stripe={stripeInstance}>
                <StripeElemens closeModal={closeModal} />
              </Elements>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdatePaymentMethodModal;
