import React from "react";
import { Helmet } from "react-helmet";

export default function SecurityEn() {
  return (
    <div className="flex flex-col">
      <Helmet>
        <title>Languise - Security</title>
        <meta
          name="description"
          content="お客様の全てのデータは最大限のセキュリティ管理下で取り扱います"
        />
        <link rel="canonical" href="https://languise.com/security" />
      </Helmet>
      <div
        className="flex items-center bg-cover bg-center w-full sm:h-[360px] h-[220px]"
        style={{ backgroundImage: "url('/assets/5-bg.png')" }}
      >
        <div className="flex flex-col xl:gap-[23px] sm:gap-4 gap-2 2xl:max-w-[1306px] max-w-[95%] w-full mx-auto">
          <h1 className="sm:text-[45px] text-[22px] text-[#333876] font-sans font-semibold text-start">
            Languise Security
          </h1>
          <p className="sm:text-[20px] text-[10px] text-[#505050] font-sans text-start">
            All your data will be handled with the utmost security
          </p>
        </div>
      </div>
      <div className="2xl:max-w-[1306px] max-w-[95%] w-full mx-auto flex flex-col">
        <h2 className="text-start sm:mt-[81px] mt-[20px] text-[#11135C] sm:text-[26px] text-[12px] font-sans font-semibold">
          All data will be protected by encryption
        </h2>

        <p className="text-start sm:text-[20px] text-[12px] sm:mt-[46px] mt-2 text-[#222222] sm:leading-[38px] leading-[24px] font-sans ">
          Languise uses the state-of-the-art TLS (Transport Layer Security)
          encryption technology to encrypt communications, protecting them from
          being read or altered by third parties.
          <br />
          This technology operates automatically when you access our website or
          exchange information on Languise, providing a complex encryption
          process, so you can use the service with peace of mind without having
          to take any special steps. In addition, all data handled by the system
          is encrypted and stored.
        </p>
        <h2 className="text-start sm:mt-[62px] mt-[20px] text-[#11135C] sm:text-[26px] text-[12px] font-sans font-semibold">
          All data used in processing will be deleted and will not be used for
          secondary purposes.
        </h2>
        <p className="text-start sm:text-[20px] text-[12px] sm:mt-[46px] mt-2 text-[#222222] sm:leading-[38px] leading-[24px] font-sans">
          The data used by the customer for processing will be sent to a server
          managed by Language, then processed via OpenAI's server and returned
          to the customer.
          <br />
          Regardless of whether it is a paid or free version, data stored on the
          Language server will be deleted immediately after the process is
          completed and will not be used for secondary purposes.
          <br />
          Data sent to OpenAI's servers will be temporarily stored for the
          purpose of monitoring for misuse, but will be completely deleted
          within 30 days and will not be used for AI training.
          <br />
          Click here for detailed information on OpenAI's data policy. (
          <a
            className="text-[#11135C] underline"
            href="https://openai.com/enterprise-privacy"
          >
            https://openai.com/enterprise-privacy
          </a>
          )
        </p>
        <h2 className="text-start sm:mt-[62px] mt-[20px] text-[#11135C] sm:text-[26px] text-[12px] font-sans font-semibold">
          Data storage will be kept to a minimum
        </h2>
        <p className="text-start sm:text-[20px] text-[12px] sm:mt-[46px] mt-2 text-[#222222] sm:leading-[38px] leading-[24px] font-sans">
          Regardless of whether you use the paid or free version, the text being
          processed is only temporarily stored in random access memory (RAM).
          Document files are only temporarily stored in storage to carry out the
          processing specified by the customer, and are completely deleted once
          all processing is complete.
          <br />
          The processed text is encrypted using a symmetric key cryptography
          method, and this symmetric key is only stored in volatile memory in
          the subscriber's system.
        </p>
        <h2 className="text-start sm:mt-[62px] mt-[20px] text-[#11135C] sm:text-[26px] text-[12px] font-sans font-semibold">
          We will continue to update our security measures
        </h2>
        <p className="text-start sm:text-[20px] text-[12px] sm:mt-[46px] mt-2 text-[#222222] sm:leading-[38px] leading-[24px] font-sans">
          Languise is committed to constantly updating its security measures to
          keep pace with technological advances.
          <br />
          We will continue to do our best to ensure that our customers can use
          our services safely and with confidence.
        </p>
        <h2 className="text-start sm:mt-[62px] mt-[20px] text-[#11135C] sm:text-[26px] text-[12px] font-sans font-semibold">
          High security of data centers
        </h2>
        <p className="text-start sm:text-[20px] text-[12px] sm:mt-[46px] mt-2 text-[#222222] sm:leading-[38px] leading-[24px] font-sans">
          Languise runs on AWS. AWS data centers have a high level of security
          and are strongly protected from unauthorized access to physical data.
          <br />
          In addition, all operations are recorded as logs, so in the unlikely
          event of any unauthorized activity, thorough tracing can be performed.
          <br />
          For more information on AWS data center security, please click here.
          <a
            className="text-[#11135C] underline"
            href="https://aws.amazon.com/jp/compliance/data-center/controls/"
            target="blank"
          >
            (https://aws.amazon.com/jp/compliance/data-center/controls/)
          </a>
        </p>
        <h2 className="text-start sm:mt-[62px] mt-[20px] text-[#11135C] sm:text-[26px] text-[12px] font-sans font-semibold">
          Compliance with the European General Data Protection Regulation (GDPR)
        </h2>
        <p className="text-start sm:text-[20px] text-[12px] sm:mt-[46px] mt-2 text-[#222222] sm:leading-[38px] leading-[24px] font-sans sm:mb-[160px] mb-[40px]">
          We respect your privacy and make every effort to protect your personal
          information. We have set strict standards for handling personal data
          in accordance with the EU General Data Protection Regulation (GDPR),
          and in addition to being transparent about the purpose of use and
          minimizing the data collected, we respect your right to correct or
          delete your data at your own will.
          <br />
          For specific details regarding customer data protection, please refer
          to{" "}
          <a
            className="text-[#11135C] underline"
            href="/privacy"
            target="blank"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
}
