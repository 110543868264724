import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthModal from "../components/Modal/AuthModal";
import { useTranslation } from "react-i18next";

import {
  getEnterprisePlans,
  getPrices,
  getUserInfo,
  loadSeverStatus,
  loginUser,
  showLoginModal,
} from "../redux/actions/languiseAction";
import { getAccessToken, setA8ID, removeAllCookies } from "../services/utils";

export default function Header() {
  const isUnderMaintenance = useSelector(
    (state) => state.LanguiseReducer.isUnderMaintenance
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const a8 = queryParams.get("a8");

  const [firstLoad, setFirstLoad] = useState(true);
  const [dropDown, setDropDown] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loginClicked, setLoginClicked] = useState(false);

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const sideMenuRef = useRef();
  const [currentStep, setCurrentStep] = useState(1);
  const [transitionDirection, setTransitionDirection] = useState("right");

  const isAuthenticated = getAccessToken();
  const userInfo = useSelector((state) => state.LanguiseReducer.userInfo);
  const showLogin = useSelector(
    (state) => state.LanguiseReducer.showLoginModal
  );

  const handleLogin = () => {
    dispatch(showLoginModal(!showLogin));
    setCurrentStep(1);
  };

  const toggleNavbar = () => {
    if (!menuOpen) {
      setMenuOpen(true);
    } else {
      setMenuOpen(false);
    }
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setDropDown((prevDropDown) => !prevDropDown);
  };

  const goToNextStep = () => {
    setCurrentStep(currentStep + 1);
    setTransitionDirection("right");
  };

  const goToPreviousStep = () => {
    setCurrentStep(currentStep - 1);
    setTransitionDirection("left");
  };

  const handleLogout = (e) => {
    e.stopPropagation();
    removeAllCookies();
    dispatch(loginUser(false));
    setDropDown(false);
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (isAuthenticated) dispatch(getUserInfo());
    dispatch(getPrices());
    dispatch(getEnterprisePlans());
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (userInfo.plan && firstLoad) {
      setFirstLoad(false);
    }
  }, [firstLoad, navigate, userInfo]);

  useEffect(() => {
    if (a8) {
      setA8ID(a8);
      navigate("/");
    }
  }, [a8, navigate]);

  useEffect(() => {
    dispatch(loadSeverStatus());
  }, []);

  useEffect(() => {
    if (
      isUnderMaintenance &&
      location.pathname !== "/manage" &&
      location.pathname !== "/maintenance"
    ) {
      navigate("/maintenance");
    }
  }, [isUnderMaintenance, location, navigate]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        if (buttonRef.current && buttonRef.current.contains(event.target)) {
          setDropDown(true);
        } else {
          setDropDown(false);
        }
      }
      if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="sticky top-0 z-50 !w-full flex justify-center box-border shadow-custom bg-white items-center sm:h-[80px] h-[60px] md:px-[40px] !px-[20px]">
      <div className="flex flex-1 lg:ml-12">
        <button onClick={toggleNavbar}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 27"
            className="sm:w-8 sm:h-7 w-5 h-5"
          >
            <g id="button" transform="translate(-41.5 -18.5)">
              <line
                x2="30"
                transform="translate(41.5 19.5)"
                fill="none"
                stroke="#11135c"
                strokeWidth="2"
              />
              <line
                x2="30"
                transform="translate(41.5 32)"
                fill="none"
                stroke="#11135c"
                strokeWidth="2"
              />
              <line
                x2="30"
                transform="translate(41.5 44.5)"
                fill="none"
                stroke="#11135c"
                strokeWidth="2"
              />
            </g>
          </svg>
        </button>
        {/* Navbar content */}
        {menuOpen && (
          <div className="fixed top-0 left-0 w-full h-screen bg-black/30 z-[200]">
            <div
              ref={sideMenuRef}
              className={`absolute left-0 max-w-[272px] w-full bg-white z-50  top-0 h-screen py-[14px]
              ${
                menuOpen
                  ? "translate-x-0 ease-in-out transform transition-transform duration-300"
                  : "-translate-x-full ease-in-out transform transition-transform duration-300"
              }
              transform transition-transform duration-300 ease-in-out`}
            >
              <div className="flex justify-end px-[14px]">
                <img
                  onClick={toggleNavbar}
                  className="w-[20px] h-[20px] cursor-pointer"
                  src="/assets/cancel-modal.svg"
                  alt="Cancel"
                />
              </div>
              <div
                onClick={() => {
                  navigate("/");
                  setMenuOpen(false);
                }}
                className="flex justify-center"
              >
                <img
                  className="w-[150px] mr-[40px] mt-[-10px]"
                  src="/assets/logo-nav.png"
                  alt="Logo"
                />
              </div>
              <div
                onClick={() => {
                  navigate("/");
                  setMenuOpen(false);
                }}
                className={`w-full flex justify-start ${
                  location.pathname === "/" ? "bg-[#F0F1F8]" : "bg-white"
                } items-center h-[55px] px-[18px] text-[16px] font-sans text-[#333876] hover:bg-[#f9f9f9] rounded-[6px] cursor-pointer mt-[21px] border-t border-[#F0F1F8]`}
              >
                {t("home")}
              </div>
              <div
                onClick={() => {
                  navigate("/about");
                  setMenuOpen(false);
                }}
                className={`w-full flex justify-start ${
                  location.pathname === "/about" ? "bg-[#F0F1F8]" : "bg-white"
                } items-center !h-[55px] px-[18px] text-[16px] font-sans text-[#333876] hover:bg-[#f9f9f9] rounded-[6px] cursor-pointer border-t border-[#F0F1F8]`}
              >
                {t("about")}
              </div>
              <div
                onClick={() => {
                  navigate("/enterprise");
                  setMenuOpen(false);
                }}
                className={`w-full flex justify-start ${
                  location.pathname === "/enterprise"
                    ? "bg-[#F0F1F8]"
                    : "bg-white"
                } items-center !h-[55px] px-[18px] text-[16px] font-sans text-[#333876] hover:bg-[#f9f9f9] rounded-[6px] cursor-pointer border-t border-[#F0F1F8]`}
              >
                {t("multipleAccounts")}
              </div>
              <div
                onClick={() => {
                  navigate("/priceplan");
                  setMenuOpen(false);
                }}
                className={`w-full flex justify-start ${
                  location.pathname === "/priceplan"
                    ? "bg-[#F0F1F8]"
                    : "bg-white"
                } items-center !h-[55px] px-[18px] text-[16px] font-sans text-[#333876] hover:bg-[#f9f9f9] rounded-[6px] cursor-pointer border-t border-[#F0F1F8]`}
              >
                {t("prisePlan")}
              </div>
              <div
                onClick={() => {
                  navigate("/security");
                  setMenuOpen(false);
                }}
                className={`w-full flex justify-start ${
                  location.pathname === "/security"
                    ? "bg-[#F0F1F8]"
                    : "bg-white"
                } items-center !h-[55px] px-[18px] text-[16px] font-sans text-[#333876] hover:bg-[#f9f9f9] rounded-[6px] cursor-pointer border-t border-[#F0F1F8]`}
              >
                {t("languiseSecurity")}
              </div>
              <div
                onClick={() => {
                  navigate("/terms");
                  setMenuOpen(false);
                }}
                className={`w-full flex justify-start ${
                  location.pathname === "/terms" ? "bg-[#F0F1F8]" : "bg-white"
                } items-center !h-[55px] px-[18px] text-[16px] font-sans text-[#333876] hover:bg-[#f9f9f9] rounded-[6px] cursor-pointer border-t border-[#F0F1F8]`}
              >
                {t("terms")}
              </div>
              <div
                onClick={() => {
                  navigate("/privacy");
                  setMenuOpen(false);
                }}
                className={`w-full flex justify-start ${
                  location.pathname === "/privacy" ? "bg-[#F0F1F8]" : "bg-white"
                } items-center !h-[55px] px-[18px] text-[16px] font-sans text-[#333876] hover:bg-[#f9f9f9] rounded-[6px] cursor-pointer border-t border-[#F0F1F8]`}
              >
                {t("privacy")}
              </div>
              <div
                onClick={() => {
                  navigate("/imprint");
                  setMenuOpen(false);
                }}
                className={`w-full flex justify-start ${
                  location.pathname === "/imprint" ? "bg-[#F0F1F8]" : "bg-white"
                } items-center !h-[55px] px-[18px] text-[16px] font-sans text-[#333876] hover:bg-[#f9f9f9] rounded-[6px] cursor-pointer border-t border-[#F0F1F8]`}
              >
                {t("imprint")}
              </div>
              <div
                onClick={() => {
                  navigate("/contactus");
                  setMenuOpen(false);
                }}
                className={`w-full flex justify-start ${
                  location.pathname === "/contactus"
                    ? "bg-[#F0F1F8]"
                    : "bg-white"
                } items-center !h-[55px] px-[18px] text-[16px] font-sans text-[#333876] hover:bg-[#f9f9f9] rounded-[6px] cursor-pointer border-t border-[#F0F1F8]`}
              >
                {t("qa")}
              </div>
            </div>
          </div>
        )}

        <div className="w-full xl:flex justify-start items-center">
          <Link
            className={`xl:block hidden text-[#11135C] sm:text-[20px] text-[14px] lg:mx-12 lg:px-8 px-1 lg:py-[22px] font-sans font-normal lg:border-b-[6px] border-b ${
              location.pathname === "/"
                ? "border-[#4B5090]"
                : "border-white hover:border-[#c9ccff]"
            }`}
            to="/"
          >
            {t("home")}
          </Link>
          <Link
            to="/about"
            className={`xl:flex text-[#11135C] text-[20px] py-[22px] px-4 font-sans font-normal border-b-[6px] hidden ${
              location.pathname === "/about"
                ? "border-[#4B5090]"
                : "border-white hover:border-[#c9ccff]"
            }`}
          >
            {t("about")}
          </Link>
        </div>
      </div>
      <Link to="/">
        <img
          className="flex justify-center items-center sm:w-[250px] w-[130px]"
          src="/assets/logo.png"
          alt="Logo"
        />
      </Link>
      <div className="flex flex-1 justify-end items-center relative">
        {isAuthenticated ? (
          <>
            <div className="flex gap-5 mr-5 items-center">
              <p className="text-[#4B5090] text-[14px] font-sans 2xl:flex hidden">
                {t("limitCount")}
              </p>
              <p className="text-[#333876] text-base sm:text-[20px] flex font-sans text-nowrap pl-4">
                {userInfo.process_count} / {userInfo.credit}
              </p>
            </div>
            <div className="border border-[#333876] font-semibold rounded-[10px] hidden h-[32px] bg-[#F0F1F8] lg:flex px-3 mr-3 items-center text-[14px] font-sans text-[#333876]">
              {userInfo.enterprise_user ? "Enterprise" : userInfo.plan}
            </div>
            <div
              onClick={toggleDropdown}
              ref={buttonRef}
              className="flex lg:justify-center justify-end items-center h-[28px] sm:gap-3 gap-2 cursor-pointer lg:mr-12"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20.091 24.864"
                className="sm:w-5 sm:h-6 w-4 h-4"
              >
                <g transform="translate(-1641.407 -29)">
                  <circle
                    cx="7"
                    cy="7"
                    r="7"
                    transform="translate(1644.5 29)"
                    fill="#333876"
                  />
                  <path
                    d="M-15284.517-164.881c-.105,0-.457-.039-.564-.546a14.622,14.622,0,0,1,.652-4.189,12.358,12.358,0,0,1,3.913-6.334,7.96,7.96,0,0,0,5.516,2.206,7.963,7.963,0,0,0,5.5-2.19,12.352,12.352,0,0,1,3.829,6.355,17.894,17.894,0,0,1,.67,4.174.526.526,0,0,1-.563.515h-.023l-18.9.008Z"
                    transform="translate(16926.5 218.746)"
                    fill="#333876"
                  />
                </g>
              </svg>
              <p className="sm:flex hidden text-[18px] text-[#333876] leading-[32px] font-sans font-semibold">
                {userInfo.name}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11.706"
                height="6.56"
                viewBox="0 0 11.706 6.56"
                className="sm:w-[12px] sm:h-[7px] w-2"
              >
                <path
                  d="M1744,37l5.5,5.5L1755,37"
                  transform="translate(-1743.646 -36.646)"
                  fill="none"
                  stroke="#333876"
                  strokeWidth="1"
                />
              </svg>
            </div>
          </>
        ) : (
          <button
            onClick={() => {
              handleLogin();
              setLoginClicked(true);
            }}
            className="sm:text-[18px] text-[10px] text-white bg-background-other lg:mr-12 mr-0 sm:px-[18px] px-[12px] sm:py-[9px] py-2 rounded-[5px]"
          >
            {t("login")}
          </button>
        )}
        {dropDown ? (
          <div
            ref={dropdownRef}
            className="fixed sm:top-[60px] top-[45px] z-[100] ease-in duration-150 flex flex-col w-[272px] lg:mr-12 border border-[#6E6E6E] bg-white"
          >
            <button className="sm:hidden flex flex-col justify-start items-start gap-[11px] pl-6 pr-4 py-[13px] text-[#333876] text-[16px] font-sans h-[90px] border-b border-[#CCCCCC] cursor-pointer hover:bg-[#F0F1F8]">
              <div className="text-[16px] font-semibold text-[#333876]">
                {userInfo.name}
              </div>
              <div className="flex flex-row w-full items-center justify-between gap-2">
                <div className="flex flex-col text-[9px]">
                  <p className="text-[#4B5090]">{t("limitCount")}</p>
                  <p>
                    {userInfo.process_count} / {userInfo.credit}
                  </p>
                </div>
                <div className="border border-[#333876] rounded-[10px] px-3 h-[32px] bg-[#F0F1F8] flex items-center justify-center text-[14px] font-sans text-[#333876]">
                  {userInfo.enterprise_user ? "Enterprise" : userInfo.plan}
                </div>
              </div>
            </button>
            <button
              onClick={() => {
                setDropDown(false);
                navigate("/mypage");
                setMenuOpen(false);
              }}
              className="flex items-center pl-6 pr-4 text-[#505050] text-[16px] font-sans h-[50px] border-b border-[#CCCCCC] cursor-pointer hover:bg-[#F0F1F8]"
            >
              {t("myPage")}
            </button>
            <button
              onClick={() => {
                setDropDown(false);
                navigate("/myinvoice");
                setMenuOpen(false);
              }}
              className="flex items-center pl-6 pr-4 text-[#505050] text-[16px] font-sans h-[50px] border-b border-[#CCCCCC] cursor-pointer hover:bg-[#F0F1F8]"
            >
              {t("invoice")}
            </button>
            <button
              onClick={handleLogout}
              className="flex items-center pl-6 pr-4 text-[#505050] text-[16px] font-sans h-[50px] cursor-pointer hover:bg-[#F0F1F8]"
            >
              {t("logout")}
            </button>
          </div>
        ) : null}
      </div>
      {showLogin && loginClicked && (
        <AuthModal
          openModal={handleLogin}
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
          currentStep={currentStep}
          transitionDirection={transitionDirection}
        />
      )}
    </div>
  );
}
