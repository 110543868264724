import React, { useEffect, useState } from "react";
import api from "../services/api";
import Notiflix from "notiflix";
import SubscriptionSuccessModal from "./Modal/SubscriptionSuccessModal";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../redux/actions/languiseAction";
import ConfirmModal from "./Modal/ConfirmModal";
import { useTranslation } from "react-i18next";

function ConfirmFreeTrial({ onAgree, onClose, trial }) {
  const { t } = useTranslation();
  return (
    <div className="flex bg-black/40 w-full h-screen z-[9999] fixed top-0 left-0 sm:px-0 px-5">
      <div className="flex flex-col max-w-[830px] w-full mx-auto my-auto z-[50] bg-[#F0F1F8] px-10 py-3 rounded-[10px]">
        <p className="text-[#4B5090] font-sans text-[20px] text-center md:mt-9 mt-3">
          {t("SubscriptionCardText1")}
        </p>
        {trial ? (
          <p className="text-[#505050] font-sans md:text-[16px] text-[14px] text-start md:px-10 mt-[34px]">
            {t("SubscriptionCardText2")}
            <br />
            {t("SubscriptionCardText3")}
          </p>
        ) : (
          <p className="text-[#505050] font-sans md:text-[16px] text-[14px] text-start md:px-10 mt-[34px]">
            {t("SubscriptionCardText4")}
          </p>
        )}
        <div className="flex flex-row justify-center mt-4 gap-4">
          <button
            onClick={onClose}
            className="max-w-[230px] w-full border border-[#8B8B8B] text-[#8B8B8B] rounded-[10px] hover:bg-[#8B8B8B] hover:text-white h-[40px] text-[16px]"
          >
            {t("SubscriptionCardText5")}
          </button>
          <button
            onClick={onAgree}
            className="max-w-[230px] w-full bg-[#4B5090] hover:bg-[#5c63be] h-[40px] text-[16px] text-white rounded-[10px] mb-10"
          >
            {t("SubscriptionCardText6")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default function SubscriptionCard({
  data,
  button,
  description,
  plan,
  payment_cycle,
  currency,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.LanguiseReducer.userInfo);
  const [showConfirmTrial, setShowConfirmTrial] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [availableTrial, setAvailableTrial] = useState(true);
  const rootUrl = window.location.origin;

  const currencyCharactor = {
    jpy: "¥",
    usd: "$",
    eur: "€",
  };

  const plans = ["Free", "Basic", "Pro", "Pro Max"];

  const updateSubscription = async () => {
    if (loading) return;
    setLoading(true);
    api
      .put("/payment/subscription/", {
        success_url: `${rootUrl}/payment/success`,
        cancel_url: window.location.href,
        payment_type: plan,
        payment_cycle,
      })
      .then(async (rst) => {
        dispatch(getUserInfo());
        setShowConfirm(false);
        setShowConfirmTrial(false);
        setShowSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        Notiflix.Notify.failure(t("operationFailure"));
        setLoading(false);
      });
  };

  const handleSubscription = async () => {
    try {
      const { data } = await api.post("/payment/subscription/", {
        success_url: `${rootUrl}/payment/success`,
        cancel_url: window.location.href,
        payment_type: plan,
        currency: currency,
        payment_cycle,
      });
      if (data.url) {
        setShowConfirmTrial(false);
        setShowConfirm(false);
        const newTab = window.open(data.url, "_blank");
        if (!newTab) {
          window.location = data.url;
        }
      } else Notiflix.Notify.failure(t("paymentFailure"));
    } catch (err) {
      if (err.response.status === 406) {
        Notiflix.Notify.warning(t("unavailableEnterprise"));
        return;
      }
      if (err.response.status === 409) {
        Notiflix.Notify.warning(t("existPlan"));
        return;
      }
      if (err.response.status === 402) {
        handleUpdate();
        return;
      }
    }
  };

  const handleUpdate = () => {
    let text = "";
    if (plans.indexOf(userInfo.plan) < plans.indexOf(plan.name)) {
      // Upgrade plan
      if (userInfo.period_interval === "year" && payment_cycle) {
        text = t("SubscriptionCardText7");
      } else {
        text = t("SubscriptionCardText8");
      }
    } else if (plans.indexOf(userInfo.plan) > plans.indexOf(plan.name)) {
      // Downgrade plan
      if (userInfo.period_interval === "month" && payment_cycle) {
        text = t("SubscriptionCardText8");
      }
      if (userInfo.period_interval === "year" && !payment_cycle) {
        text = t("SubscriptionCardText8");
      } else {
        text = t("SubscriptionCardText12");
      }
    } else {
      // Change billing cycle
      text = t("SubscriptionCardText8");
    }

    const temp = (
      <div className="px-10">
        <p className="mb-4">
          {userInfo.plan}/
          {userInfo.period_interval === "year" ? t("year") : t("month")}→
          {plan.name}/{payment_cycle ? t("year") : t("month")}：
          {t("SubscriptionCardText9")}
        </p>
        <p className="text-[#505050] text-[16px]">{text}</p>
      </div>
    );
    setConfirmText(temp);
    setShowConfirm(true);
    setShowConfirmTrial(false);
  };

  const handleConfirmTrial = () => {
    if (userInfo.plan === plan.name) return;
    api
      .get("/payment/subscription/")
      .then((res) => {
        if (res.data.subscriptions === 0) {
          setAvailableTrial(true);
        } else {
          setAvailableTrial(false);
        }
        setShowConfirmTrial(true);
      })
      .catch((err) => {
        if (err.response.status === 406) {
          Notiflix.Notify.warning(t("unavailableEnterprise"));
          return;
        }
        console.log({ err });
      });
  };

  useEffect(() => {
    if (showConfirmTrial) document.body.style.overflowY = "hidden";
    else document.body.style.overflowY = "scroll";
  }, [showConfirmTrial]);

  return (
    <div className="flex w-full justify-center">
      <div className="min-w-[280px] max-w-[400px] w-full flex flex-col rounded-[10px] h-[850px] border border-[#707070]">
        <div className="bg-[#333876] justify-center rounded-tr-[10px] rounded-tl-[10px]">
          <p className="text-white text-[24px] font-sans font-[600] mt-[35px]">
            {plan.name}
          </p>
          <div className="text-white text-[50px] font-sans font-[500] mt-4 mb-10">
            {currencyCharactor[currency]}
            {plan[currency] && plan[currency].toLocaleString()}
            <span className="text-white text-[20px] font-sans font-[300]">
              /{t("month")}
            </span>
          </div>
        </div>
        <div className="flex flex-col py-4 px-5 h-full bg-white rounded-br-[10px] rounded-bl-[10px]">
          <button
            onClick={
              button === t("SubscriptionCardText10")
                ? handleConfirmTrial
                : handleSubscription
            }
            className="bg-[#3D80BA] text-white text-[20px] font-sans rounded-[10px] max-w-[95%] w-full h-[55px]"
          >
            {button}
          </button>
          {description ? (
            <p className="text-[#3D80BA] text-[12px] leading-[30px] font-sans font-[600]">
              {description}
            </p>
          ) : null}
          <div
            className={`flex flex-col gap-1 ${
              description ? "mt-[5px]" : "mt-[40px]"
            } justify-start`}
          >
            {data.map((item) =>
              item?.bold ? (
                <div className="flex gap-3 items-center" key={item.title}>
                  <img
                    className="w-[15px] h-[11px]"
                    src="/assets/sm-check.png"
                    alt="Check"
                  />
                  <div className="flex w-full justify-between items-center">
                    <span className="text-[#222222] text-[12px] text-start leading-[22px] font-sans">
                      {item.title}
                    </span>
                    <span className="text-[#222222] text-[12px] font-sans font-semibold relative left-[-10px]">
                      {item.bold}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="flex gap-3 items-center" key={item.title}>
                  <img
                    className="w-[15px] h-[11px]"
                    src="/assets/sm-check.png"
                    alt="Check"
                  />
                  <span className="text-[#222222] text-start text-[12px] leading-[22px] font-sans">
                    {item.title}
                  </span>
                </div>
              )
            )}
          </div>
        </div>
        {showConfirmTrial && (
          <ConfirmFreeTrial
            onAgree={handleSubscription}
            onClose={() => setShowConfirmTrial(false)}
            trial={availableTrial}
          />
        )}
        {showConfirm && (
          <ConfirmModal
            isOpen={showConfirm}
            onAgree={updateSubscription}
            onCancel={() => setShowConfirm(false)}
            text={confirmText}
            buttonText={t("SubscriptionCardText11")}
          />
        )}
        {showSuccess && (
          <SubscriptionSuccessModal success={true} message={""} />
        )}
      </div>
    </div>
  );
}
