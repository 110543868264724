import React, { useState } from "react";
import CommonSuccessModal from "../components/Modal/CommonSuccessModal";
import api from "../services/api";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function ContactUs() {
  const { t } = useTranslation();
  const [confirmPage, setConfirmPage] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [part, setPart] = useState("");
  const [agree, setAgree] = useState(false);
  const [success, setSuccess] = useState(false);
  const [urlToken, setUrlToken] = useState("");
  const [errorMessage, setErrorMessage] = useState({});

  const handleConfirm = () => {
    const validationErrors = validateForm();
    setErrorMessage({ ...validationErrors });
    if (Object.keys(validationErrors).length > 0 || !agree) return;
    window.scrollTo(0, 0);
    setConfirmPage(true);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone.replace(/\D/g, ""));
    formData.append("company", company);
    formData.append("part", part);

    api
      .post("/user/dl/", formData)
      .then((res) => {
        setUrlToken(res.data.token);
        setSuccess(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateForm = () => {
    const temp = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{0,15}?$/;
    if (!name) temp.name = t("requireUsername");
    if (!emailRegex.test(email)) temp.email = t("requireEmail");
    if (!phoneRegex.test(phone)) temp.phone = t("requirePhone");
    return temp;
  };

  return (
    <>
      <Helmet>
        <title>Languise - DL</title>
        <meta name="description" content="Enterprise 資料ダウンロード" />
        <link rel="canonical" href="https://languise.com/dl" />
      </Helmet>
      {!confirmPage ? (
        <div className="flex flex-col flex-grow">
          <div
            className="flex items-center bg-cover bg-center w-full sm:h-[360px] h-[200px]"
            style={{ backgroundImage: "url('/assets/5-bg.png')" }}
          >
            <div className="flex flex-col max-w-[1400px] sm:px-10 px-8 w-full mx-auto">
              <p className="sm:text-[45px] text-[18px] pt-0 mt-0 text-[#333876] font-semibold font-sans text-start">
                {t("downloadPageText1")}
              </p>
              <p className="sm:text-[20px] text-[12px] text-[#505050] font-normal mt-6 font-sans text-start">
                {t("downloadPageText2")}
              </p>
            </div>
          </div>
          <div className="flex flex-col max-w-[800px] sm:px-0 px-5 w-full mx-auto gap-3 sm:mt-14 mt-6">
            <div className="flex flex-col items-center gap-2">
              <div className="flex w-full items-center gap-2 text-start text-[#505050] font-semibold sm:text-[16px] text-[14px]">
                {t("name")}
                <span className="text-start text-red-600 text-sm font-normal">
                  {errorMessage.name}
                </span>
              </div>
              <div className="w-full">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value.substring(0, 50))}
                  placeholder={t("contactUsText3")}
                  className={`${
                    name ? "bg-white" : "bg-transparent"
                  } w-full h-[38px] sm:h-[40px] border border-[#8B8B8B] rounded-[10px] px-3 sm:px-5 outline-none`}
                />
              </div>
            </div>
            <div className="flex flex-col items-center gap-1">
              <div className="flex w-full items-center gap-2 text-start text-[#505050] font-semibold sm:text-[16px] text-[14px]">
                {t("email")}
                <span className="text-start text-red-600 text-sm font-normal">
                  {errorMessage.email}
                </span>
              </div>
              <div className="w-full">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="name@email.com"
                  className={`${
                    email ? "bg-white" : "bg-transparent"
                  } w-full h-[38px] sm:h-[40px] border border-[#8B8B8B] rounded-[10px] px-3 sm:px-5 outline-none`}
                />
              </div>
            </div>
            <div className="flex flex-col items-center gap-1">
              <div className="flex w-full items-center gap-2 text-start text-[#505050] font-semibold sm:text-[16px] text-[14px]">
                {t("phoneNumber")}
                <span className="text-start text-red-600 text-sm font-normal">
                  {errorMessage.phone}
                </span>
              </div>
              <div className="w-full">
                <input
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className={`${
                    phone ? "bg-white" : "bg-transparent"
                  } w-full h-[35px] sm:h-[40px] border border-[#8B8B8B] rounded-[10px] px-3 sm:px-5 outline-none`}
                />
              </div>
            </div>
            <div className="flex flex-col items-center gap-1">
              <div className="flex w-full items-center gap-2 text-start text-[#505050] font-semibold sm:text-[16px] text-[14px]">
                {t("companyName")}
                {t("optional")}
              </div>
              <div className="w-full">
                <input
                  type="text"
                  value={company}
                  onChange={(e) => setCompany(e.target.value.substring(0, 50))}
                  placeholder={t("companyName")}
                  className={`${
                    company ? "bg-white" : "bg-transparent"
                  } w-full h-[38px] sm:h-[40px] border border-[#8B8B8B] rounded-[10px] px-3 sm:px-5 outline-none`}
                />
              </div>
            </div>
            <div className="flex flex-col items-center gap-1">
              <div className="flex w-full items-center gap-2 text-start text-[#505050] font-semibold sm:text-[16px] text-[14px]">
                {t("departmentName")}
                {t("optional")}
              </div>
              <div className="w-full">
                <input
                  type="text"
                  value={part}
                  onChange={(e) => setPart(e.target.value.substring(0, 50))}
                  placeholder={t("departmentName")}
                  className={`${
                    part ? "bg-white" : "bg-transparent"
                  } w-full h-[35px] sm:h-[40px] border border-[#8B8B8B] rounded-[10px] px-3 sm:px-5 outline-none`}
                />
              </div>
            </div>
          </div>
          <div className="flex my-6">
            <div className="flex mx-auto gap-4 items-start">
              <input
                type="checkbox"
                checked={agree}
                onChange={(e) => setAgree(e.target.checked)}
                className="w-5 h-5 border border-[#8B8B8B]"
              />
              <p
                onClick={() => window.open("/privacy")}
                className="sm:text-[16px] font-semibold text-[#3D80BA] underline cursor-pointer"
              >
                {t("agreementPolicy")}
              </p>
            </div>
          </div>
          <div className="max-w-[360px] w-full mx-auto flex justify-end mb-14 px-5 sm:px-0">
            <button
              onClick={handleConfirm}
              className="flex max-w-[360px] justify-center items-center w-full bg-[#4b5090] hover:bg-[#6267b2] h-12 text-white rounded-[20px] text-[16px]"
            >
              {t("downloadPageText3")}
            </button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col flex-grow bg-[#F0F1F8]">
          <div className="flex mx-auto sm:my-16 mt-6 mb-10">
            <p className="sm:text-[30px] text-[20px] pt-0 mt-0 text-[#505050] font-semibold font-sans text-start">
              {t("downloadPageText3")}
            </p>
          </div>
          <div className="flex flex-col max-w-[580px] sm:px-0 px-5 w-full mx-auto gap-3">
            <div className="flex flex-col items-center gap-2">
              <div className="flex w-full text-start text-[#4B5090] font-semibold sm:text-[16px] px-2 font-sans">
                {t("name")}
              </div>
              <div className="w-full pb-4 border-b border-[#CCCCCC] px-2 text-start">
                <p className="text-[16px] text-[#505050]">{name}</p>
              </div>
            </div>
            <div className="flex flex-col items-center gap-2">
              <div className="flex w-full text-start text-[#4B5090] font-semibold sm:text-[16px] px-2 font-sans">
                {t("email")}
              </div>
              <div className="w-full pb-4 border-b border-[#CCCCCC] px-2 text-start">
                <p className="text-[16px] text-[#505050]">{email}</p>
              </div>
            </div>
            <div className="flex flex-col items-center gap-2">
              <div className="flex w-full text-start text-[#4B5090] font-semibold sm:text-[16px] px-2 font-sans">
                {t("phoneNumber")}
              </div>
              <div className="w-full pb-4 border-b border-[#CCCCCC] px-2 text-start">
                <p className="text-[16px] text-[#505050]">{phone}</p>
              </div>
            </div>
            <div className="flex flex-col items-center gap-2">
              <div className="flex w-full text-start text-[#4B5090] font-semibold sm:text-[16px] px-2 font-sans">
                {t("companyName")}
                {t("optional")}
              </div>
              <div className="w-full pb-4 border-b border-[#CCCCCC] px-2 text-start">
                <p className="text-[16px] text-[#505050]">{company}</p>
              </div>
            </div>
            <div className="flex flex-col items-center gap-2">
              <div className="flex w-full text-start text-[#4B5090] font-semibold sm:text-[16px] px-2 font-sans">
                {t("departmentName")}
                {t("optional")}
              </div>
              <div className="w-full pb-4 border-b border-[#CCCCCC] px-2 text-start">
                <p className="text-[16px] text-[#505050]">{part}</p>
              </div>
            </div>
          </div>
          <div className="flex justify-center gap-10">
            <button
              onClick={() => {
                window.scrollTo(0, 0);
                setConfirmPage(false);
              }}
              className="flex max-w-[200px] justify-center items-center w-full sm:my-14 mt-8 mb-10 bg-[#4b5090] hover:bg-[#6267b2] h-12 text-white rounded-[20px] text-[16px]"
            >
              {t("downloadPageText4")}
            </button>
            <button
              onClick={handleSubmit}
              className="flex max-w-[200px] justify-center items-center w-full sm:my-14 mt-8 mb-10 bg-[#4b5090] hover:bg-[#6267b2] h-12 text-white rounded-[20px] text-[16px]"
            >
              {t("downloadPageText5")}
            </button>
          </div>
        </div>
      )}
      {success && (
        <CommonSuccessModal
          title={t("downloadPageText6")}
          text={
            <div>
              <p className="text-[18px] text-[#4B5090]">
                {t("downloadPageText7")}
              </p>
              <p className="text-[18px] text-[#4B5090]">
                {t("downloadPageText8")}
              </p>
              <Link
                className="text-[18px] text-[#4B5090] underline underline-offset-8"
                to={`${process.env.REACT_APP_API_BASE_URL}/assistance/dl/${urlToken}`}
                target="_blank"
              >
                Languise_Enterprise_DL_contents.pdf
              </Link>
            </div>
          }
        />
      )}
    </>
  );
}
