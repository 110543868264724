import React from "react";
import Plans from "../Plans";

export default function UpdatePlanModal({ open, onClose }) {

  return (
    <div className="flex bg-black/40 w-full h-screen z-[9999] fixed top-0 left-0 sm:px-0 px-5">
      <div className="flex flex-col max-w-[1300px] w-full max-h-[90vh] mx-auto my-auto z-50 bg-[#F0F1F8] rounded-lg">
        <div className="flex justify-end p-4">
          <img
            onClick={onClose}
            className="w-[20px] h-[20px] cursor-pointer"
            src="/assets/cancel-modal.svg"
            alt="Cancel"
          />
        </div>
        <div className="flex flex-col w-full mx-auto gap-4 overflow-hidden overflow-y-auto">
          <Plans modal={true} />
        </div>
      </div>
    </div>
  );
}
