import React from "react";

export default function SwitchButton({
  value,
  setClickedSwitch,
  text1,
  text2,
  readonly = false,
}) {
  return (
    <div className="relative flex max-w-[137px] w-full border border-[#F0F1F8] bg-white rounded-[20px] h-[30px] gap-[7px] justify-between items-center px-[6px]">
      <button
        onClick={() => !readonly && setClickedSwitch(0)}
        className={`ease-in duration-200 transition transform sm:text-[14px] text-[12px] sm:min-w-[60px] px-2 ${
          value === 0
            ? "bg-background-footer text-white"
            : "bg-white text-[#11135C]"
        } h-[23px] flex justify-center items-center flex-1 rounded-full whitespace-nowrap`}
      >
        {text1}
      </button>
      <button
        onClick={() => !readonly && setClickedSwitch(1)}
        className={`ease-in duration-200 transition transform sm:text-[14px] text-[12px] sm:min-w-[60px] px-2 ${
          value === 1
            ? "bg-background-footer text-white"
            : "bg-white text-[#11135C]"
        } h-[23px] flex justify-center items-center flex-1 rounded-full whitespace-nowrap`}
      >
        {text2}
      </button>
      {readonly && (
        <div className="absolute top-0 left-0 w-full h-full bg-gray-100 bg-opacity-50 rounded-full"></div>
      )}
    </div>
  );
}
