import React, { useState, useEffect } from "react";
import { getAccessToken } from "../services/utils";
import { useTranslation } from "react-i18next";

export default function DictionaryTable({
  data,
  type,
  onHandleSelect,
  pageSelected,
  onCheckedDicList,
}) {
  const { t } = useTranslation();
  const isAuthenticated = getAccessToken();
  const [pageNumber, setPageNumber] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const [checkedState, setCheckedState] = useState(
    new Array(data.length).fill(false)
  );

  const pageCount = Math.ceil(data.length / pageNumber); //  pageCount is total page
  const firstItemIndex = (currentPage - 1) * pageNumber; //  firstItemIndex is previous page index
  const lastItemIndex = firstItemIndex + pageNumber; //  lastItemIndex is previous page + item_count
  const currentItems = data.slice(firstItemIndex, lastItemIndex);

  useEffect(() => {
    setCheckedState(new Array(data.length).fill(false));
  }, [data]);

  const handleNext = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleMainCheckboxChange = (event) => {
    const newState = new Array(data.length).fill(event.target.checked);
    setCheckedState(newState);

    if (event.target.checked) {
      currentItems.forEach((item) => onCheckedDicList(item, true));
    } else {
      currentItems.forEach((item) => onCheckedDicList(item, false));
    }
  };

  const handleCheckboxChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
    onCheckedDicList(data[position], updatedCheckedState[position]);
  };

  return (
    <div className="flex flex-col sm:px-0 px-5">
      {type === "readOnly" && (
        <div className="flex justify-between items-center w-full sm:pl-[37px] pl-[15.17px] sm:pr-[27px] pr-[15.17px] sm:h-[64px] h-[47px] border-b border-[#E9E9F0] bg-white rounded-tr-[10px] rounded-tl-[10px]">
          <p className="md:text-[20px] text-[14px] leading-[24px] text-[#11135C] font-sans">
            {t("DictionaryTableText1")}
          </p>
        </div>
      )}
      <table className="w-full">
        <thead>
          {!(type === "readOnly" && data.length === 0) && (
            <tr
              className={`bg-[#E9E9F0] flex items-center ${
                type === "readOnly"
                  ? "h-[40px]"
                  : "rounded-tr-[20px] rounded-tl-[20px] h-[60px]"
              }`}
            >
              {type === "readOnly" ? null : (
                <th className="max-w-[57px] w-full h-full flex items-center justify-center border-r border-[#CCCCCC]">
                  <input
                    className="w-[16px] h-[16px]"
                    type="checkbox"
                    onChange={handleMainCheckboxChange}
                    checked={
                      (checkedState.length > 0 &&
                        checkedState.every(Boolean)) ||
                      false
                    }
                  />
                </th>
              )}
              <th className="max-w-[217.4px] w-full h-full border-r text-start flex items-center text-[#6E6E6E] text-[16px] font-sans border-[#CCCCCC] pl-[25.5px]">
                {t("DictionaryTableText2")}
              </th>
              <th className="w-full h-full pl-[25.5px] text-start flex items-center text-[#6E6E6E] text-[16px] font-sans">
                {t("DictionaryTableText3")}
              </th>
            </tr>
          )}
        </thead>
        <tbody>
          {data.length > 0 ? (
            currentItems.map((item, index) => {
              const isLastItem = index === currentItems.length - 1;

              const baseClass =
                type === "readOnly"
                  ? "bg-white"
                  : index % 2 === 0
                  ? "bg-white"
                  : "bg-[#F5F5F5]";

              return (
                <tr
                  key={index}
                  className={`flex items-center h-[46px] ${baseClass} ${
                    isLastItem
                      ? "rounded-br-[20px] rounded-bl-[20px]"
                      : "border-b border-[#F0F1F8]"
                  }`}
                >
                  {type !== "readOnly" && (
                    <td className="max-w-[57px] w-full h-full flex items-center justify-center border-r border-[#CCCCCC]">
                      <input
                        className="w-[16px] h-[16px]"
                        type="checkbox"
                        onChange={() => handleCheckboxChange(index)}
                        checked={checkedState[index] || false}
                      />
                    </td>
                  )}
                  <td className="max-w-[217.4px] w-full h-full border-r text-start flex items-center text-[#6E6E6E] text-[16px] font-sans border-[#CCCCCC] pl-[25.5px]">
                    {item.reading}
                  </td>
                  <td className="w-full h-full pl-[25.5px] text-start flex items-center text-[#6E6E6E] text-[16px] font-sans">
                    {item.alternative_reading}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr
              className={`bg-white flex items-center rounded-br-[20px] rounded-bl-[20px]`}
            >
              <td
                className={`w-full h-full flex sm:items-center items-start sm:justify-center justify-start sm:px-0 px-[18px] sm:py-0 py-[19.5px] ${
                  type === "readOnly"
                    ? "text-[#CCCCCC] md:text-[20px] text-[13px] tracking-tighter sm:h-[150px] !h-[172px]"
                    : "text-[#6E6E6E] text-[16px] !h-[120px]"
                } font-sans`}
              >
                {type === "readOnly"
                  ? !isAuthenticated && t("DictionaryTableText4")
                  : t("DictionaryTableText5")}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {currentItems.length > 0 && (
        <div className="flex w-full justify-end items-center px-[17px] h-[21px] mt-2">
          <div className="relative flex w-20 px-2 ml-[35px] border border-gray-600 bg-white rounded-lg">
            <div
              onClick={onHandleSelect}
              className="flex flex-1 justify-between items-center h-full rounded-[20px] bg-transparent cursor-pointer text-white"
            >
              <p
                className={`text-[16px] ${
                  type === "readOnly" ? "text-[#505050]" : "text-white"
                } font-sans`}
              >
                {pageNumber}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="8"
                viewBox="0 0 13 8"
              >
                <path
                  d="M6.5,0,13,8H0Z"
                  transform="translate(13 8) rotate(180)"
                  fill="#CCCCCC"
                />
              </svg>
            </div>
            {pageSelected ? (
              <div className="z-[100] absolute left-0 w-full mt-[25px] border border-[#707070] rounded-[7px] bg-white">
                <div
                  onClick={() => {
                    setPageNumber(5);
                    onHandleSelect(false);
                    setCurrentPage(1);
                  }}
                  className=" cursor-pointer hover:bg-[#ececec] rounded-[7px] font-sans"
                >
                  5
                </div>
                <div
                  onClick={() => {
                    setPageNumber(10);
                    onHandleSelect(false);
                    setCurrentPage(1);
                  }}
                  className="cursor-pointer hover:bg-[#ececec] rounded-[7px] font-sans"
                >
                  10
                </div>
                <div
                  onClick={() => {
                    setPageNumber(20);
                    onHandleSelect(false);
                    setCurrentPage(1);
                  }}
                  className="cursor-pointer hover:bg-[#ececec] rounded-[7px] font-sans"
                >
                  20
                </div>
                <div
                  onClick={() => {
                    setPageNumber(50);
                    onHandleSelect(false);
                    setCurrentPage(1);
                  }}
                  className="cursor-pointer hover:bg-[#ececec] rounded-[7px] font-sans"
                >
                  50
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex gap-6 ml-6 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              onClick={handlePrevious}
              width="9.855"
              height="14.305"
              viewBox="0 0 9.855 14.305"
              className="cursor-pointer"
              style={{ pointerEvents: currentPage === 1 ? "none" : "auto" }}
            >
              <path
                d="M16686.107,1265.494l-6.885,5.568,6.885,5.92"
                transform="translate(-16677.662 -1264.087)"
                fill="none"
                stroke={currentPage > 1 ? "#505050" : "#CCCCCC"}
                strokeLinecap="round"
                strokeWidth="2"
              />
            </svg>
            <div
              className={`flex sm:text-[16px] text-[15px] ${
                type === "readOnly" ? "text-[#505050]" : "text-white"
              } font-sans border border-gray-600 rounded-lg w-10 justify-center`}
            >
              <span>{pageCount}</span>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9.855"
              height="14.305"
              viewBox="0 0 9.855 14.305"
              onClick={handleNext}
              className="cursor-pointer"
              style={{
                pointerEvents: currentPage === pageCount ? "none" : "auto",
              }}
            >
              <path
                d="M16679.221,1265.494l6.885,5.568-6.885,5.92"
                transform="translate(-16677.811 -1264.087)"
                fill="none"
                stroke={currentPage !== pageCount ? "#505050" : "#CCCCCC"}
                strokeLinecap="round"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      )}
    </div>
  );
}
