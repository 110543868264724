import React from "react";
import { useTranslation } from "react-i18next";

export default function ConfirmProcessChangeModal({ onAgree, onCancel }) {
  const { t } = useTranslation();
  const handleChecked = (checked) => {
    window.localStorage.setItem("ProcessChangeModal", !checked);
  };
  return (
    <>
      <div className="flex bg-black/40 w-full h-screen z-[9999] fixed top-0 left-0 sm:px-0 px-5">
        <div className="flex flex-col max-w-[830px] w-full mx-auto my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] rounded-[10px]">
          <div className="flex justify-end">
            <img
              onClick={() => onCancel(false)}
              className="w-[20px] h-[20px] cursor-pointer"
              src="/assets/cancel-modal.svg"
              alt="Cancel"
            />
          </div>
          <div className="text-[#4B5090] font-sans sm:text-[20px] text-[14px] text-center mt-[34px]">
            {t("confirmProcessChangeModalText1")}
          </div>
          <div className="flex justify-center mt-8">
            <button
              onClick={onAgree}
              className="flex items-center px-4 bg-[#4B5090] h-[40px] text-white rounded-[10px]"
            >
              {t("confirmProcessChangeModalText2")}
            </button>
          </div>
          <div className="flex justify-center text-[12px] items-center my-8">
            <input
              type="checkbox"
              onChange={(e) => handleChecked(e.target.checked)}
            ></input>
            <span className="ml-2">{t("confirmProcessChangeModalText3")}</span>
          </div>
        </div>
      </div>
    </>
  );
}
