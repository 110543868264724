import React, { useEffect, useState } from "react";
import TextField from "../../TextField";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../services/api";
import Notiflix from "notiflix";
import { removeAllCookies } from "../../../services/utils";
import { loginUser } from "../../../redux/actions/languiseAction";
import { useTranslation } from "react-i18next";

export default function ChangeLoginMethodModal({ openModal, closeModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [changeEmail, setChangeEmail] = useState(false);
  const userInfo = useSelector((state) => state.LanguiseReducer.userInfo);
  const [emailAddress, setEmailAddress] = useState("");

  useEffect(() => {
    if (userInfo.email) setEmailAddress(userInfo.email);
  }, [userInfo]);

  const toggleModal = () => {
    setChangeEmail(!changeEmail);
  };

  const setGoogleLogin = () => {
    api
      .post("/user/changelogin/")
      .then((res) => {
        removeAllCookies();
        dispatch(loginUser(false));
        Notiflix.Notify.success(t("changeLoginMethodModalText1"));
      })
      .catch((err) => {
        Notiflix.Notify.failure(t("changeLoginMethodModalText1"));
      });
  };

  const setEmailLogin = () => {
    api
      .post("/user/changeemail/", { email: emailAddress })
      .then((res) => {
        removeAllCookies();
        dispatch(loginUser(false));
        Notiflix.Notify.success(
          `${t("changeLoginMethodModalText3")}${emailAddress}${t(
            "changeLoginMethodModalText4"
          )}`
        );
      })
      .catch((err) => {
        Notiflix.Notify.failure(t("changeLoginMethodModalText5"));
      });
  };

  return (
    <div
      className={`${
        openModal
          ? "flex !opacity-100 !translate-y-0"
          : "hidden !opacity-0 !translate-y-10"
      } fixed inset-0 bg-black/40 z-30 !transition-all !ease-in-out !duration-300`}
    >
      <div className="flex flex-col max-w-[830px] w-full sm:mx-auto mx-5 my-auto z-[50] bg-[#F0F1F8] px-3 pt-3 pb-10 rounded-lg">
        {changeEmail && (
          <>
            <div className="flex justify-between">
              <div
                onClick={toggleModal}
                className="flex gap-[8.5px] items-center cursor-pointer"
              >
                <img
                  className="w-[16px] h-[11px]"
                  src="/assets/previous.png"
                  alt="Previous"
                />
                <p className="text-[#8B8B8B] text-[16px] font-sans">
                  {t("back")}
                </p>
              </div>
              <img
                onClick={closeModal}
                className="w-[20px] h-[20px] cursor-pointer"
                src="/assets/cancel-modal.svg"
                alt="Cancel"
              />
            </div>
            <p className="text-[#4B5090] font-sans text-[20px] text-center">
              {t("changeLoginMethodModalText6")}
            </p>
            <div className="flex flex-col max-w-[420px] w-full mx-auto gap-4 mt-[37px]">
              <div className="flex flex-col gap-1">
                <p className="text-start font-sans text-[#505050] text-[12px]">
                  {t("email")}
                </p>
                <TextField
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                  placeholder="name@email.com"
                />
              </div>
              <div className="flex justify-center mt-[14px]">
                <button
                  onClick={setEmailLogin}
                  className="w-full bg-transparent h-[40px] border border-[#8B8B8B] text-[#8B8B8B] rounded-[10px] hover:bg-[#4B5090] hover:text-white"
                >
                  {t("changeLoginMethodModalText7")}
                </button>
              </div>
            </div>
          </>
        )}
        {!changeEmail && (
          <>
            <div className="flex justify-end">
              <img
                onClick={closeModal}
                className="w-[20px] h-[20px] cursor-pointer"
                src="/assets/cancel-modal.svg"
                alt="Cancel"
              />
            </div>
            <p className="text-[#4B5090] font-sans text-[20px] text-center pt-5">
              {t("changeLoginMethodModalText6")}
            </p>
            <div className="flex flex-col max-w-[420px] w-full mx-auto gap-4 pb-5">
              <div className="max-w-[420px] w-full flex flex-col gap-5 mx-auto sm:mt-[43px] mt-5">
                <div
                  onClick={toggleModal}
                  className="relative text-[16px] text-[#505050] font-sans border-[#8B8B8B] border rounded-[10px] w-full h-[40px] flex items-center justify-center active:bg-[#e6e6e6] cursor-pointer"
                >
                  <img
                    className="absolute left-[30px]"
                    src="/assets/email-log.png"
                    alt="Email Logo"
                  />
                  {t("changeLoginMethodModalText7")}
                </div>
                {/* <div
                  onClick={setGoogleLogin}
                  className="relative text-[16px] text-[#505050] font-sans border-[#8B8B8B] border rounded-[10px] w-full h-[40px] flex items-center justify-center active:bg-[#e6e6e6] cursor-pointer"
                >
                  <img
                    className="absolute left-[30px]"
                    src="/assets/google-log.png"
                    alt="Google Log"
                  />
                  {t("changeLoginMethodModalText9")}
                </div> */}
                {/* <div className="w-full flex justify-between gap-5">
                  <div className="relative text-[16px] text-[#505050] font-sans border-[#8B8B8B] border rounded-[10px] h-[40px] flex flex-1 items-center justify-center active:bg-[#e6e6e6] cursor-pointer">
                    <img
                      className="absolute sm:left-[40px] left-[20px]"
                      src="/assets/apple-log.png"
                      alt="Apple Log"
                    />
                    Apple
                  </div>
                  <div className="relative text-[16px] text-[#505050] font-sans border-[#8B8B8B] border rounded-[10px] h-[40px] flex flex-1 items-center justify-center active:bg-[#e6e6e6] cursor-pointer">
                    <img
                      className="absolute sm:left-[30px] left-[20px]"
                      src="/assets/facebook-log.png"
                      alt="Facebook Log"
                    />
                    Facebook
                  </div>
                </div> */}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
