import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Storage from "../translations/storage";
import { changeUiLang } from "../redux/actions/languiseAction";
import { useDispatch } from "react-redux";

const lang = navigator.language || navigator.userLanguage;
const defaultLanguage = lang.startsWith("ja") ? "jp" : "en";
const savedLang = Storage.getData("language");

export default function Footer() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState("en");

  const saveLang = (lang) => {
    Storage.setData("language", { key: lang });
    i18n.changeLanguage(lang);
    dispatch(changeUiLang(lang));
  };

  useEffect(() => {
    const lang = savedLang ? savedLang.key : defaultLanguage
    saveLang(lang)
    setLang(lang);
    dispatch(changeUiLang(lang));
  }, []);

  return (
    <div>
      <div className="flex flex-col w-full bg-background-footer sm:h-[117px] h-[60px] justify-center items-center">
        <div className="w-full max-w-[1400px] flex justify-between items-end px-[20px] ">
          <div className="flex items-center justify-start sm:gap-2 gap-1">
            <img
              className="!text-white !fill-current w-3 h-3 sm:w-6 sm:h-6"
              src="/assets/world.svg"
              alt="World"
            />
            <select
              value={lang}
              onChange={(e) => {
                setLang(e.target.value);
                saveLang(e.target.value);
              }}
              className="bg-transparent text-white text-[12px] sm:text-[20px] w-auto outline-none"
            >
              <option value="jp" className="text-[#11135C]">
                日本語
              </option>
              <option value="en" className="text-[#11135C]">
                English
              </option>
            </select>
          </div>
          <p className="max-w-[412px] w-full sm:text-[14px] text-[12px] font-sans text-white">
            Copyright ©2024 BuilBridge, All rights reserved
          </p>
          <div className="xl:flex gap-5 hidden">
            <Link className="text-white font-[550] text-[20px]" to="/security">
              {t("security")}
            </Link>
            <Link className="text-white font-[550] text-[20px]" to="/terms">
              {t("terms")}
            </Link>
            <Link className="text-white font-[550] text-[20px]" to="/privacy">
              {t("privacy")}
            </Link>
            <Link className="text-white font-[550] text-[20px]" to="/imprint">
              {t("imprint")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
