import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import TextField from "../../TextField";
import { getDictionaryContentList } from "../../../redux/actions/languiseAction";
import api from "../../../services/api";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";

export default function EditDicDetailModal({
  openModal,
  onClose,
  currentDictionaryData,
  dictionaryId,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [newDicWord, setNewDicWord] = useState("");
  const [newDicResult, setNewDicResult] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (currentDictionaryData) {
      setNewDicWord(currentDictionaryData.reading);
      setNewDicResult(currentDictionaryData.alternative_reading);
      setTitle(t("editDicDetailModalText1"));
      setButtonText(t("edit"));
    } else {
      setTitle(t("editDicDetailModalText1"));
      setButtonText(t("add"));
    }
  }, [currentDictionaryData]);

  const handleEdit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (currentDictionaryData) {
      formData.append("dictionary_detail_id", currentDictionaryData.id);
      formData.append("reading", newDicWord);
      formData.append("alternative_reading", newDicResult);
      api
        .put("/dictionary/detail/", formData)
        .then((res) => {
          const formData = new FormData();
          formData.append("dictionary_id", Number(dictionaryId));

          dispatch(getDictionaryContentList(formData));
          onClose();
        })
        .catch((err) => {
          Notiflix.Notify.failure(t("operationFailure"));
          console.log(err);
        });
    } else {
      formData.append("dictionary_id", dictionaryId);
      formData.append("reading", newDicWord);
      formData.append("alternative_reading", newDicResult);
      api
        .post("/dictionary/detail/", formData)
        .then((res) => {
          const formData = new FormData();
          formData.append("dictionary_id", Number(dictionaryId));
          dispatch(getDictionaryContentList(formData));
          onClose();
        })
        .catch((err) => {
          Notiflix.Notify.failure(t("operationFailure"));
          console.log(err);
        });
    }
  };

  return (
    <div
      className={`${
        openModal ? "flex" : "hidden"
      } fixed inset-0 bg-black/40 z-[9999] transition-opacity duration-2000 ease-in-out sm:px-0 px-5`}
    >
      <div className="flex flex-col max-w-[830px] w-full min-h-[246px] mx-auto my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] rounded-lg">
        <div className="flex justify-end">
          <img
            onClick={onClose}
            className="w-[20px] h-[20px] cursor-pointer"
            src="/assets/cancel-modal.svg"
            alt="Cancel"
          />
        </div>
        <p className="text-[#4B5090] font-sans text-[20px] text-center">
          {title}
        </p>
        <div className="flex max-w-[692px] w-full justify-between mx-auto mt-[49px] gap-5">
          <TextField
            type="text"
            value={newDicWord}
            onChange={(e) => setNewDicWord(e.target.value)}
            className="flex flex-1 !max-w-[600px]"
            placeholder={t("editDicDetailModalText2")}
          />
          <TextField
            type="text"
            value={newDicResult}
            onChange={(e) => setNewDicResult(e.target.value)}
            className="flex flex-1 !max-w-[600px]"
            placeholder={t("editDicDetailModalText3")}
          />
          <button
            onClick={handleEdit}
            className="max-w-[76px] w-full bg-[#4B5090] text-white rounded-[10px]"
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}
