import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import api from "../../../services/api";
import LanguageSelect from "../../LanguageSelect";
import ConfirmModal from "../ConfirmModal";
import UpdatePlanModal from "../UpdatePlanModal";
import { getAccessToken } from "../../../services/utils";
import { showLoginModal } from "../../../redux/actions/languiseAction";
import { useTranslation } from "react-i18next";
import Notiflix from "notiflix";

export function TranslateModal({ permission, fromLanguage, toLanguage }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticated = getAccessToken();

  const [fromTranslate, setFromTranslate] = useState(fromLanguage);
  const [toTranslate, setToTranslate] = useState(toLanguage);
  const [isRotated, setIsRotated] = useState(false);

  const [showTranslateBox, setShowTranslateBox] = useState(false);
  const [originText, setOriginText] = useState("");
  const [translateResult, setTranslateResult] = useState([]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showUpdatePlanModal, setShowUpdatePlanModal] = useState(false);

  const [topPosition, setTopPosition] = useState(null);
  const componentRef = useRef(null);

  const handleSwapper = () => {
    if (fromTranslate === "Detect language") {
      return;
    }
    let temp;
    temp = fromTranslate;
    setIsRotated(!isRotated);
    setFromTranslate(toTranslate);
    setToTranslate(temp);
  };

  const handleTranslate = () => {
    // if (!permission) return;
    if (originText.length === 0) {
      setTranslateResult([]);
      return;
    }
    if (originText.length > 50) {
      setTranslateResult([t("translationModalText1")]);
      return;
    }
    const formData = new FormData();
    formData.append("lang1", fromTranslate);
    formData.append("lang2", toTranslate);
    formData.append("tone", "");
    formData.append("text", originText);
    formData.append("dictionary_id", -1);
    formData.append("category", "");
    const apiURL = "/translate/word/";
    api
      .post(apiURL, formData)
      .then((res) => {
        const temp = res.data.result.slice(0, 3);
        setTranslateResult(temp || []);
      })
      .catch((err) => {
        try {
          if (err.response) {
            if (err.response.status == 400) {
              Notiflix.Notify.failure(
                t("One of the specified language pair is not valid.")
              );
            }
          } else {
            console.log("Error:", err.message);
          }
        } catch (error) {
          console.log("Error:", err.message);
        }
      });
  };

  const handleAlert = useCallback(
    (e) => {
      e.preventDefault();
      if (!permission) {
        setShowConfirmModal(true);
      }
    },
    [permission, setShowConfirmModal]
  );

  const calculateContentHeight = (ta, scanAmount) => {
    var height = ta.offsetHeight,
      scrollHeight = ta.scrollHeight,
      overflow = ta.style.overflow;
    if (height >= scrollHeight) {
      ta.style.height = height + scanAmount + "px";
      ta.style.overflow = "hidden";
      if (scrollHeight < ta.scrollHeight) {
        while (ta.offsetHeight >= ta.scrollHeight) {
          ta.style.height = (height -= scanAmount) + "px";
        }
        while (ta.offsetHeight < ta.scrollHeight) {
          ta.style.height = height++ + "px";
        }
        ta.style.height = "";
        ta.style.overflow = overflow;
        return height;
      }
    } else {
      return scrollHeight;
    }
  };

  const onChangeInput = useCallback(
    (e) => {
      console.log("input: %s", e.target.value);
      setOriginText(e.target.value);
    },
    [setOriginText]
  );

  const calculateHeight = () => {
    var ta = document.getElementById("wordInput");
    if (!ta) return 1;
    var style = window.getComputedStyle
        ? window.getComputedStyle(ta)
        : ta.currentStyle,
      taLineHeight = parseInt(style.lineHeight, 10),
      taHeight = calculateContentHeight(ta, taLineHeight),
      numberOfLines = Math.round(taHeight / taLineHeight);

    return numberOfLines;
  };

  const numberOfLines = useMemo(() => {
    const count = calculateHeight();
    return count;
  }, [originText]);

  const wordInputCSS = useMemo(
    () =>
      `w-full ${
        numberOfLines > 1 ? "sm:h-16 h-14" : "sm:h-8 h-7"
      }  pl-2 border border-r-0 border-[#707070] outline-none`,
    [numberOfLines]
  );

  useEffect(() => {
    if (componentRef.current) {
      // Get the distance from the top of the container to the component when using bottom
      const rect = componentRef.current.getBoundingClientRect();
      const calculatedTop = window.innerHeight - rect.bottom; // Calculate top from bottom
      setTopPosition(calculatedTop); // Set the calculated top position
    }
  }, []);

  return (
    <>
      {showUpdatePlanModal && (
        <UpdatePlanModal
          open={showUpdatePlanModal}
          onClose={() => setShowUpdatePlanModal(false)}
        />
      )}
      <ConfirmModal
        isOpen={showConfirmModal}
        text={
          <div>
            <p className="sm:text-[32px] text-[24px] mb-4">
              {t("charBotModalText5")}
            </p>
            <p>{t("charBotModalText6")}</p>
          </div>
        }
        buttonText={!isAuthenticated ? t("login") : t("charBotModalText7")}
        cancelText={t("charBotModalText8")}
        onAgree={() => {
          if (isAuthenticated) {
            setShowConfirmModal(false);
            setShowUpdatePlanModal(true);
          } else {
            setShowConfirmModal(false);
            setShowUpdatePlanModal(false);
            dispatch(showLoginModal(true));
          }
        }}
        onCancel={() => setShowConfirmModal(false)}
      />
      <div
        ref={componentRef}
        style={{ top: topPosition !== null ? `${topPosition}px` : 'sm:bottom-[180px] bottom-[160px]' }}
        className={`absolute z-[101] flex flex-row right-0`}
      >
        <div
          onClick={() => setShowTranslateBox(!showTranslateBox)}
          className="flex sm:w-[46px] sm:h-[135px] w-[38px] h-[112px] border border-[#3D80BA] bg-[#F7F7F7] sm:text-[16px] text-[12px] text-[#3D80BA] font-semibold px-2 sm:leading-[18px] leading-[14px] items-center justify-center cursor-pointer"
        >
          {t("translationModalText2")}
        </div>
        <div className={`relative p-0 bg-[#EAEAEA] border border-l-0 border-[#707070] transition-width duration-300 ease-in-out ${showTranslateBox ? "w-[300px] sm:p-5 p-4 sm:w-[360px]" : "w-0 overflow-hidden"}`}>
          <div
            onClick={() => {
              setTranslateResult([]);
              setOriginText("");
              setShowTranslateBox(false);
            }}
            className="absolute flex top-0 right-0 sm:p-3 p-2 cursor-pointer hover:bg-red-600 hover:text-white hover:stroke-white stroke-[#707070]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 15.707 15.707"
              className="sm:w-4 sm:h-4 w-3 h-3"
            >
              <g transform="translate(-1894.146 -771.146)">
                <line
                  x1="15"
                  y2="15"
                  transform="translate(1894.5 771.5)"
                  fill="none"
                  strokeWidth="1"
                />
                <line
                  x2="15"
                  y2="15"
                  transform="translate(1894.5 771.5)"
                  fill="none"
                  strokeWidth="1"
                />
              </g>
            </svg>
          </div>
          <div className="flex flex-row pl-2 pr-6">
            <div className="w-5/12">
              <LanguageSelect
                value={fromTranslate}
                onChange={setFromTranslate}
                small={true}
                isDetectShow={true}
              />
            </div>
            <div
              onClick={handleSwapper}
              className="w-2/12 flex justify-center cursor-pointer mx-auto"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 23.411 32.411"
                className="h-4 sm:h-5"
              >
                <g transform="translate(-897.794 -365.794)">
                  <line
                    x2="22"
                    transform="translate(898.5 373.5)"
                    stroke="#4b5090"
                    strokeWidth="2"
                  ></line>
                  <line
                    x2="8"
                    y2="7"
                    transform="translate(912.5 366.5)"
                    stroke="#4b5090"
                    strokeWidth="2"
                  ></line>
                  <line
                    y1="7"
                    x2="8"
                    transform="translate(912.5 373.5)"
                    stroke="#4b5090"
                    strokeWidth="2"
                  ></line>
                </g>
                <g transform="translate(0.706 17.706)">
                  <line
                    x1="22"
                    transform="translate(0 7)"
                    stroke="#4b5090"
                    strokeWidth="2"
                  ></line>
                  <line x1="8" y2="7" stroke="#4b5090"></line>
                  <line
                    x1="8"
                    y1="7"
                    transform="translate(0 7)"
                    stroke="#4b5090"
                    strokeWidth="2"
                  ></line>
                </g>
              </svg>
            </div>
            <div className="w-5/12">
              <LanguageSelect
                value={toTranslate}
                onChange={setToTranslate}
                auto={false}
                small={true}
                className={"mx-auto"}
              />
            </div>
          </div>
          <div className="relative flex flex-row sm:mt-4 mt-3 justify-items-stretch">
            <textarea
              id="wordInput"
              value={originText}
              onClick={handleAlert}
              onInput={onChangeInput}
              readOnly={!permission}
              className={wordInputCSS}
            />
            <button
              onClick={handleTranslate}
              className={`sm:w-16 w-12 ${
                numberOfLines > 1 ? "sm:h-16 h-14" : "sm:h-8 h-7"
              } bg-[#4B5090] text-[14px] font-semibold text-white`}
            >
              {t("charBotModalText11")}
            </button>
            {!permission && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18.843 23.211"
                className="absolute left-2 sm:top-[6px] top-1 sm:w-[18px] sm:h-[24px] w-[16px] h-[22px]"
              >
                <g transform="translate(-142.153 4)">
                  <g
                    transform="translate(142.153 -4)"
                    clip-path="url(#clip-path)"
                  >
                    <path
                      d="M28.566,17.626V10.834A3.209,3.209,0,0,1,31.634,7.5h4.073a3.209,3.209,0,0,1,3.068,3.334v6.792Z"
                      transform="translate(-24.339 -6.39)"
                      fill="none"
                      stroke="#b1b1b1"
                      strokeMiterlimit="10"
                      strokeWidth="2"
                    />
                    <path
                      d="M17.068,62.846H1.775A1.781,1.781,0,0,0,0,64.621V74.983a1.781,1.781,0,0,0,1.775,1.775H17.068a1.781,1.781,0,0,0,1.775-1.775V64.621a1.781,1.781,0,0,0-1.775-1.775m-6.442,6.712v3.3a1.1,1.1,0,0,1-2.2,0v-3.3a1.981,1.981,0,1,1,2.2,0"
                      transform="translate(0 -53.547)"
                      fill="#b1b1b1"
                    />
                  </g>
                </g>
              </svg>
            )}
          </div>
          <div className="flex flex-row justify-between sm:text-[12px] text-[11px] text-[#707070]">
            <span>{t("translationModalText3")}</span>
            <span>{originText.length}/50</span>
          </div>
          {translateResult.length > 0 && (
            <div className="flex flex-col items-start mt-3 gap-1">
              {translateResult.map((item, index) => (
                <p key={index} className="text-[12px] text-[#505050]">
                  {item}
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
