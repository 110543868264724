import React, { useState } from "react";
import TextField from "../../TextField";
import { useDispatch } from "react-redux";
import api from "../../../services/api";
import { getDictionaryList } from "../../../redux/actions/languiseAction";
import { useTranslation } from "react-i18next";

export default function AddModal({ openModal, isOpen }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [dictionary, setDictionary] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const hanldeSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("dictionary_name", dictionary);
    api
      .post("/dictionary/folder/", formData)
      .then((res) => {
        dispatch(getDictionaryList());
        openModal();
      })
      .catch((err) => {
        if (err.response.status === 402) {
          setShowConfirmModal(true);
          return;
        }
        console.log(err);
      });
  };

  return (
    <div
      className={`fixed inset-0 bg-black/40 z-[9999] transition-opacity duration-2000 ease-in-out sm:px-0 px-5 flex opacity-100`}
    >
      <div className="flex flex-col max-w-[830px] w-full sm:min-h-[246px] min-h-[180px] mx-auto my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] rounded-lg">
        <div className="flex justify-end">
          <img
            onClick={openModal}
            className="w-[20px] h-[20px] cursor-pointer"
            src="/assets/cancel-modal.svg"
            alt="Cancel"
          />
        </div>
        <p className="text-[#4B5090] font-sans sm:text-[20px] text-[16px] text-center">
          {t("addModalText1")}
        </p>
        {showConfirmModal ? (
          <div className="">
            <p className="my-10">{t("addModalText2")}</p>
            <button
              onClick={() => (window.location.href = "/priceplan")}
              className="px-4 h-10 bg-[#4B5090] text-white rounded-[10px]"
            >
              {t("choosePlan")}
            </button>
          </div>
        ) : (
          <div className="flex max-w-[692px] w-full justify-between mx-auto mt-[49px] gap-4">
            <TextField
              type="text"
              value={dictionary}
              onChange={(e) => setDictionary(e.target.value)}
              className="flex flex-1 !max-w-[600px]"
              placeholder={t("addModalText3")}
            />
            <button
              onClick={hanldeSubmit}
              className="max-w-[76px] h-10 w-full bg-[#4B5090] text-white rounded-[10px]"
            >
              {t("add")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
