import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function PrivacyPolicyEn() {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = "CookieDeclaration";
    script.src = "https://consent.cookiebot.com/79fd8692-01be-4249-ba10-83d26285c018/cd.js";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="flex flex-col">
      <Helmet>
        <title>Languise - Privacy Policy</title>
        <meta name="description" content="Languiseのプライバシーポリシー" />
        <link rel="canonical" href="https://languise.com/privacy" />
      </Helmet>
      <div
        className="flex items-center bg-cover bg-center w-full sm:h-[360px] h-[220px]"
        style={{ backgroundImage: "url('/assets/5-bg.png')" }}
      >
        <div className="flex flex-col xl:gap-[23px] px-5 sm:px-0 sm:gap-4 gap-2 2xl:max-w-[1306px] max-w-[95%] w-full mx-auto">
          <h1 className="sm:text-[45px] text-[22px] text-[#333876] font-sans font-semibold text-start">
            Privacy Policy of Languise
          </h1>
        </div>
      </div>

      <div className="2xl:max-w-[1306px] max-w-[95%] w-full mx-auto flex flex-col px-5 sm:px-0 text-start sm:text-[20px] text-[12px] text-[#222222] font-sans">
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          General remarks
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          Takes the protection of your personal data very seriously. We treat
          your personal data confidentially and in accordance with the statutory
          data protection regulations, in particular the EU General Data
          Protection Regulation (GDPR) and the Federal Data Protection Act
          (BDSG).
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          The following gives an overview of what happens to your personal
          information when you use our website. Personal data is any data with
          which you could be personally identified (such as name, email address,
          phone number, etc.).{" "}
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          Who is responsible (Controller) for the data processing for this
          website?
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          The Controller for the data processing on this website is:
        </p>
        <p className="sm:leading-9 leading-6 font-semibold">
          BuilBridge UG (haftungsbeschränkt)
        </p>
        <p className="sm:leading-9 leading-6">Niederkasseler Kirchweg 57,</p>
        <p className="sm:leading-9 leading-6">40547 Düsseldorf Germany</p>
        <p className="sm:leading-9 leading-6">TEL：+49（0）173 9303239</p>
        <p className="sm:leading-9 leading-6">Email : info(at)languise.com</p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          Controller is the natural or legal person who alone or jointly with
          others decides on the purposes and means of processing personal data,
          Art. 7 fig.4 GDPR.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          How your data is collected, stored, and how it is used and for what
          purposes
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          a) When visiting the website
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          When you visit our website, the browser used on your device
          automatically sends information to the server of our website. This
          information is temporarily stored in a so-called log file. The
          following information is automatically collected and stored until it
          is deleted automatically:
        </p>
        <ul className="list-outside pl-10 sm:mt-10 mt-2 sm:leading-9 leading-6">
          <li className="list-disc indent-4">
            IP address of the requesting computer,
          </li>
          <li className="list-disc indent-4">date and time of access,</li>
          <li className="list-disc indent-4">
            name and URL of the retrieved file,
          </li>
          <li className="list-disc indent-4">
            Website from which access is made (so-called "Referrer URL"),
          </li>
          <li className="list-disc indent-4">
            the browser used and, if applicable, the operating system of your
            computer as well as the name of your access provider.
          </li>
        </ul>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          The data as described above will be processed by us for the following
          purposes:
        </p>
        <ul className="list-outside pl-10 sm:mt-10 mt-2 sm:leading-9 leading-6">
          <li className="list-disc indent-4">
            Ensuring a smooth connection of the website,
          </li>
          <li className="list-disc indent-4">
            Ensuring comfortable use of our website,
          </li>
          <li className="list-disc indent-4">
            Evaluation of system security and stability as well
          </li>
          <li className="list-disc indent-4">
            For further administrative purposes.
          </li>
        </ul>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          The legal basis for data processing is Art. 6 para. 1 sent. 1 lit. f
          GDPR. Our legitimate interest derives from the data collection
          purposes listed above. We will not use the collected data for the
          purpose of drawing conclusions about your identity.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          In addition, we use cookies and analysis services when visiting our
          website. For more details, please check below in this Privacy Policy.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          b) By using our contact form or inquiries by E-Mail
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          If you have any questions, we offer you to contact us via a form
          provided on the website or via our E-Mail address. In addition to your
          name, it is necessary to enter a valid E-Mail Address so that we know
          who the request came from and to answer it. Further information can be
          provided voluntarily. We use the information you provide to respond to
          your inquiries or to provide the products or services you have
          requested from us.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          In these cases, data processing takes place in accordance with Art. 6
          para. 1 S. 1 lit. a GDPR on the basis of your voluntarily granted
          consent or according to Art. 6 (1) S.1 lit. b GDPR (execution of
          contract or pre-contractual measures) or Art. 6 para. 1 lit. f GDPR
          (for the protection of our legitimate interest or the legitimate
          interest of a third party, unless your interest predominates).
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          Disclosure of your data to third parties
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          a) Transmission of data to other controllers
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          Your personal data will only be transmitted by us to other third
          parties if this is necessary for the fulfillment of the contract, if
          we or the third party have a legitimate interest in passing on your
          data or if you have given consent to it. Details of the legal grounds
          can be found in the subsection "Legal grounds for Transmission".{" "}
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          BUILBRIDGE does not collect or process any personal data that has been
          made available to us through our website in order to disclose or sell
          it to third parties for their marketing purposes or to use it for host
          mailings made on behalf of third parties.{" "}
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          b) Transmission to service providers
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          We engage external service providers with tasks such as programming or
          data hosting. We have carefully selected these service providers and
          monitor them regularly, in particular their careful handling and
          safeguarding of the data stored with them. All service providers are
          obliged by us to confidentiality and compliance with legal
          requirements.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          Legal grounds for Transmission
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          We will not transmit your personal data to third parties for purposes
          other than those listed below.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          We only share your personal information with third parties if:
        </p>
        <ul className="list-outside pl-10 sm:mt-10 mt-2 sm:leading-9 leading-6">
          <li className="list-disc indent-4">
            you have given express consent according to Art. 6 para. 1 S. 1 lit.
            a GDPR{" "}
          </li>
          <li className="list-disc indent-4">
            it is legally permissible according to Art. 6 para. 1 S.1 lit. b
            GDPR and it is required for the settlement of contractual
            relationships with you,
          </li>
          <li className="list-disc or indent-4">
            disclosure pursuant to Art. 6 para. 1 S. 1 lit. f GDPR is necessary
            for asserting, exercising or defending legal claims and there is no
            reason to assume that you have a predominantly legitimate interest
            in not disclosing your data,
          </li>
          <li className="list-disc indent-4">
            In the event that disclosure pursuant to Art. 6 para. 1 S. 1 lit. c
            GDPR is a legal obligation.
          </li>
        </ul>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          Transfer to recipients outside the EEA
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          BUILBRIDGE may also transfer personal information to recipients
          located outside the EEA in so-called Third countries. In this case, we
          ensure that the recipient has either an adequate level of data
          protection prior to distribution, e.g. due to a decision on adequacy
          of the EU Commission for the respective country under Article 45 par.
          3 GDPR (e.g. Japan) or based on the agreement of so-called EU Standard
          contractual clauses of the European Union (SCC) with the recipient
          according to Art. 46 para.2 lit. c GDPR or your consent to the
          disclosure or other exceptions according to Art. 49 GDPR.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          You can obtain from us an overview of the recipients in third
          countries and a copy of the specific arrangements agreed to ensure the
          appropriate level of data protection. Please use the information under
          "controller".
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          What rights do you have regarding your data?
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          You have the right:
        </p>
        <ul className="list-outside pl-10 sm:mt-10 mt-2 sm:leading-9 leading-6">
          <li className="list-disc indent-4">
            in accordance with Art.15 GDPR, to request information about your
            personal data processed by us. In particular, you will be provided
            with information on the processing purposes, the category of
            personal data, the categories of recipients to whom your data has
            been disclosed, the planned retention period, the right to
            rectification, deletion, limitation of processing or opposition, the
            existence of a The right to complain, the source of their data, if
            not collected from us, and the existence of automated
            decision-making including profiling and, where appropriate,
            significant information about their details;
          </li>
          <li className="list-disc indent-4">
            in accordance with Art. 16 GDPR, to immediately demand the
            correction of incorrect or to complete personal data stored with us;
          </li>
          <li className="list-disc indent-4">
            in accordance with Art. 17 GDPR, to demand the deletion of your
            personal data stored by us, unless the processing for the exercise
            of the right to freedom of speech and information, for the
            fulfillment of a legal obligation, for reasons of public interest or
            for the assertion, exercise or defense of Legal claims is required;
          </li>
          <li className="list-disc indent-4">
            to request the restriction of the processing of your personal data
            in accordance with Art. 18 GDPR, insofar as the accuracy of the data
            is disputed by you, the processing is unlawful, but you refuse to
            delete it and we no longer need the data, but you need it to assert,
            exercise or defend legal claims or you have objected to processing
            in accordance with Art. 21 GDPR;
          </li>
          <li className="list-disc indent-4">
            in accordance with Art. 20 GDPR, to receive your personal data
            provided to us in a structured, standard and machine-readable format
            or to request transmission to another person responsible;
          </li>
          <li className="list-disc indent-4">
            according to Art. 7 para. 3 GDPR, to revoke your once given consent
            to us at any time. As a result, we are not allowed to continue the
            data processing based on this consent for the future and
          </li>
          <li className="list-disc indent-4">
            to complain to a supervisory authority pursuant to Art. 77 GDPR. As
            a rule, you can contact the supervisory authority of your usual
            place of residence or work or our company headquarters.
          </li>
        </ul>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          Analytics and third-party tools
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          When visiting our website, statistical analyses may be made of your
          surfing behavior. This happens primarily using cookies and analytics.
          The analysis of your surfing behavior is usually anonymous, i.e. we
          will not be able to identify you from this data. You can object to
          this analysis or prevent it by not using certain tools. Detailed
          information can be found in the following privacy policy.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          You can object to this analysis. We will inform you below about how to
          exercise your options in this regard.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          Cookies
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          Some of our web pages use cookies. Cookies do not harm your computer
          and do not contain any viruses. Cookies help make our website more
          user-friendly, efficient, and secure. Cookies are small text files
          that are stored on your computer and saved by your browser.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          Most of the cookies we use are so-called "session cookies". They are
          automatically deleted after your visit. Other cookies remain in your
          device's memory until you delete them. These cookies make it possible
          to recognize your browser when you next visit the site.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          You can configure your browser to inform you about the use of cookies
          so that you can decide on a case-by-case basis whether to accept or
          reject a cookie. Alternatively, your browser can be configured to
          automatically accept cookies under certain conditions or to always
          reject them, or to automatically delete cookies when closing your
          browser. Disabling cookies may limit the functionality of this
          website.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          Cookies which are necessary to allow electronic communications or to
          provide certain functions you wish to use are stored pursuant to Art.
          6 paragraph 1, lit. f GDPR. The website operator has a legitimate
          interest in the storage of cookies to ensure an optimized service
          provided free of technical errors. If other cookies (such as those
          used to analyze your surfing behavior) are also stored, they will be
          treated separately in this privacy policy.
        </p>
        <div id="CookieDeclaration"></div>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          Plugins and tools
        </h2>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          Technical and organizational security measures
        </h2>
        We use appropriate technical and organizational security measures to
        protect your data against accidental or intentional manipulation,
        partial or total loss, destruction or against unauthorized access by
        third parties. Our security measures are continuously improved in line
        with technological developments.
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          SSL or TLS encryption
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          This site uses SSL or TLS encryption for security reasons and for the
          protection of the transmission of confidential content, such as the
          inquiries you send to us as the site operator. You can recognize an
          encrypted connection in your browser's address line when it changes
          from "http://" to "https://" and the lock icon is displayed in your
          browser's address
          bar. You will know it is an encrypted connection when it changes from “http://” to “https://” and the lock icon appears.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          If SSL or TLS encryption is activated, the data you transfer to us
          cannot be read by third parties.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          Please note that data transmission over the Internet (for example,
          when communicating via e-mail) may have security vulnerabilities. A
          complete protection of the data from access by third parties is not
          possible.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          Retention periods
        </h2>
        As a matter of principle, we store your data as long as necessary for
        the provision of our online service and associated services or we have a
        legitimate interest in the further storage. In all other cases, we will
        delete your personal information except for those we must retain in
        order to comply with legal obligations (for example, we may be required
        to maintain records for a certain period due to tax and commercial
        retention requirements, e.g. contracts and invoices).
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          Opposition to promotional emails
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          We hereby expressly prohibit the use of contact data published in the
          context of website legal notice requirements with regard to sending
          promotional and informational materials not expressly requested. The
          website operator reserves the right to take specific legal action if
          unsolicited advertising material, such as email spam, is received.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          BUILBRIDGE reserves the right to update this privacy policy from time
          to time.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6 mb-20">
          Last Update 19.01.2024
        </p>
      </div>
    </div>
  );
}
