import React from "react";

export default function Title({ marginTop, title }) {
  return (
    <div className={`flex flex-col mt-[${marginTop}]`}>
      <h2 className="text-[#222222] text-center xl:text-[40px] sm:text-[27px] text-[16px] font-sans xl:mt-[103px] sm:mt-[60px] mt-[37px] font-[600]">
        {title}
      </h2>
      <hr className="border-[#CCCCCC] xl:border-[5px] sm:border-[4px] border-[3px] xl:w-[198px] sm:w-[130px] w-[62px] mx-auto xl:mt-6 sm:mt-3 mt-[7px]"></hr>
    </div>
  );
}
