import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Storage from "./storage";
import jp from "./jp.json";
import en from "./en.json";

const languageDetector = {
  type: "languageDetector",
  async: true,
  // eslint-disable-next-line consistent-return
  detect: async (callback) => {
    const language = await Storage.getData("language");
    if (!language) {
      return callback("en");
    }

    callback(language.key);
  },
  init: () => {},
  cacheUserLanguage: (lang) => {
    // Storage.setData("language", { key: lang });
  },
};

i18next
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "jp",
    compatibilityJSON: "v3",
    resources: {
      jp,
      en,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18next;
