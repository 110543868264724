import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ChatBotModal } from "./Preview/ChatBotModal";
import { useTranslation } from "react-i18next";
import { s3_dir } from "../../utils/constants";
import { languageList } from "../Languages";
import { apiWithoutCred } from "../../services/api";

export function PreviewModal({
  file,
  onClose,
  type,
  permission,
  processType,
  fromTranslate,
  toTranslate,
}) {
  const { t } = useTranslation();
  const baseURL = process.env.REACT_APP_S3_FILE_DIR;

  const [originFile, setOriginFile] = useState("");
  const [resultFile, setResultFile] = useState("");
  const [isMaxWidth, setMaxWidth] = useState(false);
  const [isFullScreen, setFullScreen] = useState(0);
  const [currentTab, setCurrentTab] = useState(1);

  const [isOriginLoaded, setIsOriginLoaded] = useState(false);
  const [isResultLoaded, setIsResultLoaded] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const originViewRef = useRef(null);
  const resultViewRef = useRef(null);
  const originTimer = useRef();
  const resultTimer = useRef();

  const downloadName = useMemo(() => {
    let fullName = file;
    let underscoreIndex = fullName.indexOf("_");
    const originalName = fullName.substring(underscoreIndex + 1);

    const dotIndex = originalName.lastIndexOf(".");
    const baseName = originalName.substring(0, dotIndex);
    const ext = originalName.substring(dotIndex);
    const lang = processType === 2 ? fromTranslate : toTranslate;
    const item = languageList.find((e) => e.value === lang);
    let abbr = "auto";
    if (item) abbr = item.abbr;
    const downloadName = `${baseName}-${abbr}${ext}`;
    return downloadName;
  }, [file, processType, fromTranslate, toTranslate]);

  const onOriginLoadSuccess = () => {
    setIsOriginLoaded(true);
    if (originTimer.current) clearInterval(originTimer.current);
  };

  const onResultLoadSuccess = () => {
    setIsResultLoaded(true);
    console.log("Load result success");
    if (resultTimer.current) clearInterval(resultTimer.current);
  };

  const handleDownload = (e) => {
    e.preventDefault();
    if (isDownloading) return;
    setIsDownloading(true);
    const fileLink = `${s3_dir}/${file}`;
    apiWithoutCred
      .get(fileLink, {
        responseType: "blob",
      })
      .then((res) => {
        const file = new Blob([res.data], {
          type: "application/octet-stream",
        });

        const localFileURL = URL.createObjectURL(file);
        const downloadLink = document.createElement("a");
        downloadLink.href = localFileURL;
        downloadLink.setAttribute("download", downloadName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(localFileURL);
        setIsDownloading(false);
      })
      .catch((err) => {
        setIsDownloading(false);
        console.log(err);
      });
  };

  const handleFullScreen = (n) => {
    setMaxWidth(false);
    setFullScreen(n);
  };

  useEffect(() => {
    return () => {
      if (originTimer.current) clearInterval(originTimer.current);
      if (resultTimer.current) clearInterval(resultTimer.current);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (originFile && resultFile) {
      originTimer.current = setInterval(() => {
        try {
          if (
            originViewRef.current.contentWindow.document.body.innerHTML === ""
          )
            originViewRef.current.src = originFile;
        } catch (e) {
          console.log(e);
          onOriginLoadSuccess();
        }
      }, 4000);
      resultTimer.current = setInterval(() => {
        try {
          if (
            resultViewRef.current.contentWindow.document.body.innerHTML === ""
          ) {
            resultViewRef.current.src = resultFile;
          }
        } catch (e) {
          console.log(e);
          onResultLoadSuccess();
        }
      }, 4000);
    }
    return () => {
      if (originTimer.current) clearInterval(originTimer.current);
      if (resultTimer.current) clearInterval(resultTimer.current);
    };
  }, [originFile, resultFile]);

  useEffect(() => {
    if (file && file !== "") {
      const dotIndex = file.lastIndexOf(".");
      const originFile = `${baseURL}/${
        file.substring(0, dotIndex) + "_origin" + file.substring(dotIndex)
      }`;
      let resultFile = "";
      setOriginFile(
        `https://docs.google.com/gview?url=${encodeURIComponent(
          originFile
        )}&embedded=true&t=${Date.now()}`
      );
      if (type === 2) {
        const ext = file.substring(dotIndex);
        if (ext != ".pdf")
          resultFile = `${baseURL}/${
            file.substring(0, dotIndex) + "_pv" + ext
          }`;
        else resultFile = `${baseURL}/${file}`;
        setResultFile(
          `https://docs.google.com/gview?url=${encodeURIComponent(
            resultFile
          )}&embedded=true&t=${Date.now()}`
        );
      } else {
        resultFile = `${baseURL}/${file}`;
        setResultFile(
          `https://docs.google.com/gview?url=${encodeURIComponent(
            resultFile
          )}&embedded=true&t=${Date.now()}`
        );
      }
    }
  }, [baseURL, file, type]);

  const checkPreviewOrigin = useCallback(() => {
    if(!originFile) return
    setTimeout(() => {
      let iFrame = document.getElementById("preview_origin")
      if (iFrame && iFrame.contentDocument) {    
          iFrame.src = null
          iFrame.src = originFile
          checkPreviewOrigin()
      }
    }, 6000)
  }, [originFile])

  const checkPreviewResult = useCallback(() => {
    if(!resultFile) return
    setTimeout(() => {
      let iFrame = document.getElementById("preview_result")
      if (iFrame && iFrame.contentDocument) {    
          iFrame.src = null
          iFrame.src = resultFile
          checkPreviewResult()
      }
    }, 6000)
  }, [resultFile])

  useEffect(() => {
    checkPreviewOrigin()
  }, [originFile])

  useEffect(() => {
    checkPreviewResult()
  }, [resultFile])

  return (
    <div
      onSelect={(e) => e.preventDefault()}
      className="absolute flex justify-center top-0 left-0 w-full h-full z-[100] bg-[#4B5090] overflow-hidden"
    >
      <div
        className={`${
          isMaxWidth ? "md:max-w-[1810px]" : "md:max-w-[1400px]"
        } w-full md:pt-[20px]`}
      >
        <div className="flex flex-row justify-between md:h-[65px] h-[40px] md:px-0 px-4">
          <button
            className="flex items-center md:gap-4 gap-2"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 36.273 36.77"
              className="md:w-[36px] md:h-[36px] w-[18px] h-[18px]"
            >
              <g transform="translate(-272.727 -37.615)">
                <g transform="translate(-84.185 -1347.431) rotate(45)">
                  <line
                    x2="44"
                    transform="translate(1235.75 727)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="4"
                  />
                  <line
                    y1="44"
                    transform="translate(1257.75 705)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="4"
                  />
                </g>
              </g>
            </svg>
            <p className="md:text-base text-[8px] text-[#F8F8F8]">
              {t("previewModalText1")}
            </p>
          </button>
          <div className="flex items-center md:gap-10 gap-6">
            {!isMaxWidth && !isFullScreen && (
              <button
                className="md:flex hidden gap-0"
                onClick={() => setMaxWidth(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="21.874"
                  viewBox="0 0 56.59 21.874"
                >
                  <g transform="translate(17.226 1.538)">
                    <line
                      x2="43.321"
                      transform="translate(-14.136 9.462)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                    <line
                      x2="10.814"
                      y2="9.462"
                      transform="translate(18.372)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                    <line
                      x1="10.814"
                      y2="9.462"
                      transform="translate(-15.814)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                    <line
                      y1="9.462"
                      x2="10.814"
                      transform="translate(18.372 9.462)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                    <line
                      x1="10.814"
                      y1="9.462"
                      transform="translate(-15.814 9.462)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
                <p className="text-base text-[#F8F8F8]">横幅</p>
              </button>
            )}
            {isMaxWidth && !isFullScreen && (
              <button
                className="md:flex hidden gap-0"
                onClick={() => setMaxWidth(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="21.746"
                  viewBox="0 0 52.372 21.746"
                >
                  <g transform="translate(26 1.411)">
                    <g transform="translate(-13)">
                      <line
                        x2="20"
                        transform="translate(18.372 9.462)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x1="10.814"
                        y2="9.462"
                        transform="translate(18.372)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x1="10.814"
                        y1="9.462"
                        transform="translate(18.372 9.462)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                    </g>
                    <line
                      x2="20"
                      transform="translate(-25 9.462)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                    <line
                      x2="10.814"
                      y2="9.462"
                      transform="translate(-15.814)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                    <line
                      y1="9.462"
                      x2="10.814"
                      transform="translate(-15.814 9.462)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
                <p className="text-base text-[#F8F8F8]">横幅</p>
              </button>
            )}
            <a
              href={`${s3_dir}/${file}`}
              onClick={handleDownload}
              className="flex md:w-[230px] w-[106px] md:h-[50px] h-[23px] items-center justify-center gap-2 md:rounded-[10px] rounded-[5px] bg-[#F0F1F8] md:text-[18px] text-[8px] text-[#333876] font-bold"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="20"
                viewBox="0 0 16 20"
                className="md:w-[16px] md:h-[20px] w-[8px] h-[10px]"
              >
                <g transform="translate(-1314 -529)">
                  <g transform="translate(1314 529)">
                    <path
                      d="M0,0H5.619V11.6H0Z"
                      transform="translate(5.217)"
                      fill="#333876"
                    />
                    <path
                      d="M8,0l8,7H0Z"
                      transform="translate(16 18) rotate(180)"
                      fill="#333876"
                    />
                  </g>
                  <rect
                    width="16"
                    height="2"
                    transform="translate(1314 547)"
                    fill="#333876"
                  />
                </g>
              </svg>
              {t("previewModalText2")}
            </a>
          </div>
        </div>
        <div className="md:hidden flex flex-row w-full h-[38px] text-[12px] font-bold text-[#333876]">
          <div
            onClick={() => setCurrentTab(0)}
            className={`w-full pt-2 ${
              currentTab === 0 ? "bg-[#CCCCCC]" : "bg-[#F0F1F8]"
            }`}
          >
            {t("previewModalText3")}
          </div>
          <div
            onClick={() => setCurrentTab(1)}
            className={`w-full pt-2 ${
              currentTab === 1 ? "bg-[#CCCCCC]" : "bg-[#F0F1F8]"
            }`}
          >
            {t("previewModalText4")}
          </div>
        </div>
        <div
          className={`flex flex-row w-full h-full justify-between ${
            isMaxWidth ? "gap-[20px]" : "gap-[10px]"
          }`}
        >
          <div
            className={`w-full ${
              isFullScreen === 2 ? "-ml-[1400px] opacity-50" : ""
            } ${currentTab !== 0 ? "md:block hidden" : ""}`}
          >
            <div className="relative">
              <div
                className={`bg-white ${
                  isMaxWidth || isFullScreen
                    ? "md:h-[calc(100vh-85px)]"
                    : "md:h-[calc(100vh-110px)]"
                } h-[100vh] custom-scroll`}
              >
                {originFile !== "" && (
                  <iframe
                    title="origin file"
                    ref={originViewRef}
                    src={originFile}
                    className="w-full h-full"
                    onLoad={onOriginLoadSuccess}
                    onError={() => {
                      console.log("origin load error");
                    }}
                    id="preview_origin"
                  ></iframe>
                )}
              </div>
              {!isOriginLoaded && (
                <div className="absolute top-[100px] flex flex-col w-full justify-center z-10 gap-10">
                  <div className="flex mx-auto px-10 py-4 bg-[#4C494C] text-white rounded-xl shadow-xl">
                    {t("previewModalText5")}
                  </div>
                  <img
                    className="w-[100px] mx-auto"
                    src="/assets/loading-gif.gif"
                    alt="loading"
                  />
                </div>
              )}
              <div className="absolute top-[10px] right-[10px] z-10">
                {isFullScreen === 0 ? (
                  <button
                    className="w-11 h-11 pl-2 pt-1 md:flex hidden bg-[#0a0a0a]"
                    onClick={() => handleFullScreen(1)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="31.129"
                      height="31.129"
                      viewBox="0 0 31.129 31.129"
                    >
                      <g transform="translate(-5.277 23.024) rotate(-45)">
                        <line
                          x2="29.738"
                          transform="translate(0 9.462)"
                          fill="none"
                          stroke="#8b8b8b"
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                        <line
                          x2="10.814"
                          y2="9.462"
                          transform="translate(18.924 0)"
                          fill="none"
                          stroke="#8b8b8b"
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                        <line
                          y1="9.462"
                          x2="10.814"
                          transform="translate(18.924 9.462)"
                          fill="none"
                          stroke="#8b8b8b"
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                      </g>
                    </svg>
                  </button>
                ) : (
                  <button
                    className="w-16 h-16 pt-2 pr-2 md:flex hidden bg-[#0a0a0a]"
                    onClick={() => handleFullScreen(0)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="61.032"
                      height="61.032"
                      viewBox="0 0 61.032 61.032"
                    >
                      <g transform="translate(72.408 15.619) rotate(135)">
                        <line
                          x2="59.991"
                          transform="translate(0 19.088)"
                          fill="none"
                          stroke="#8b8b8b"
                          strokeLinecap="round"
                          strokeWidth="3"
                        />
                        <line
                          x2="21.815"
                          y2="19.088"
                          transform="translate(38.176 0)"
                          fill="none"
                          stroke="#8b8b8b"
                          strokeLinecap="round"
                          strokeWidth="3"
                        />
                        <line
                          y1="19.088"
                          x2="21.815"
                          transform="translate(38.176 19.088)"
                          fill="none"
                          stroke="#8b8b8b"
                          strokeLinecap="round"
                          strokeWidth="3"
                        />
                      </g>
                    </svg>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div
            className={`w-full ${
              isFullScreen === 1 ? "-mr-[1400px] opacity-50" : ""
            } ${currentTab !== 1 ? "md:block hidden" : ""}`}
          >
            <div className="relative">
              <div
                className={`bg-white ${
                  isMaxWidth || isFullScreen
                    ? "md:h-[calc(100vh-85px)]"
                    : "md:h-[calc(100vh-110px)]"
                } h-[100vh] custom-scroll`}
              >
                {resultFile !== "" && (
                  <iframe
                    title="result file"
                    ref={resultViewRef}
                    src={resultFile}
                    className="w-full h-full"
                    onLoad={onResultLoadSuccess}
                    onError={() => {
                      console.log("result load error");
                    }}
                    id="preview_result"
                  ></iframe>
                )}
              </div>
              {!isResultLoaded && (
                <div className="absolute top-[100px] flex flex-col w-full justify-center z-10 gap-10">
                  <div className="flex mx-auto px-10 py-4 bg-[#4C494C] text-white rounded-xl shadow-xl">
                    {t("previewModalText5")}
                  </div>
                  <img
                    className="w-[100px] mx-auto"
                    src="/assets/loading-gif.gif"
                    alt="loading"
                  />
                </div>
              )}
              <div className="absolute top-[10px] right-[10px] z-10">
                {isFullScreen === 0 ? (
                  <button
                    className="w-11 h-11 pl-2 pt-1 md:flex hidden bg-[#0a0a0a]"
                    onClick={() => handleFullScreen(2)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="31.129"
                      height="31.129"
                      viewBox="0 0 31.129 31.129"
                    >
                      <g transform="translate(-5.277 23.024) rotate(-45)">
                        <line
                          x2="29.738"
                          transform="translate(0 9.462)"
                          fill="none"
                          stroke="#8b8b8b"
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                        <line
                          x2="10.814"
                          y2="9.462"
                          transform="translate(18.924 0)"
                          fill="none"
                          stroke="#8b8b8b"
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                        <line
                          y1="9.462"
                          x2="10.814"
                          transform="translate(18.924 9.462)"
                          fill="none"
                          stroke="#8b8b8b"
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                      </g>
                    </svg>
                  </button>
                ) : (
                  <button
                    className="w-16 h-16 pt-2 pr-2 md:flex hidden bg-[#0a0a0a]"
                    onClick={() => handleFullScreen(0)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="61.032"
                      height="61.032"
                      viewBox="0 0 61.032 61.032"
                    >
                      <g transform="translate(72.408 15.619) rotate(135)">
                        <line
                          x2="59.991"
                          transform="translate(0 19.088)"
                          fill="none"
                          stroke="#8b8b8b"
                          strokeLinecap="round"
                          strokeWidth="3"
                        />
                        <line
                          x2="21.815"
                          y2="19.088"
                          transform="translate(38.176 0)"
                          fill="none"
                          stroke="#8b8b8b"
                          strokeLinecap="round"
                          strokeWidth="3"
                        />
                        <line
                          y1="19.088"
                          x2="21.815"
                          transform="translate(38.176 19.088)"
                          fill="none"
                          stroke="#8b8b8b"
                          strokeLinecap="round"
                          strokeWidth="3"
                        />
                      </g>
                    </svg>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChatBotModal file={file} permission={permission} />
    </div>
  );
}
