import React from "react";
import { Helmet } from "react-helmet";

export default function Imprint() {
  return (
    <div className="flex flex-col flex-grow">
      <Helmet>
        <title>Languise - Imprint</title>
        <meta name="description" content="BuilBridge UG (haftungsbeschränkt)" />
        <link rel="canonical" href="https://languise.com/imprint" />
      </Helmet>
      <div
        className="flex items-center bg-cover bg-center w-full sm:h-[360px] h-[220px]"
        style={{ backgroundImage: "url('/assets/5-bg.png')" }}
      >
        <div className="flex flex-col xl:gap-[23px] px-5 sm:px-0 sm:gap-4 gap-2 2xl:max-w-[1306px] max-w-[95%] w-full mx-auto">
          <p className="sm:text-[45px] text-[22px] text-[#333876] font-sans font-semibold text-start">
            Imprint
          </p>
        </div>
      </div>

      <div className="relative 2xl:max-w-[1306px] max-w-[95%] w-full mx-auto flex flex-col px-5 sm:px-0 text-start sm:text-[20px] text-[12px] text-[#222222] font-sans">
        <div className="flex flex-col mx-auto my-20 items-start">
          <h3 className="flex text-[#11135C] sm:text-[26px] text-[12px] font-semibold lin">
            BuilBridge UG (haftungsbeschränkt)
          </h3>
          <p className="mt-2 sm:leading-9 leading-6">
            Address: Niederkasseler Kirchweg 57, 40547 Düsseldorf Germany
          </p>
          <p className="mt-2 sm:leading-9 leading-6">
            Tel .: +49 (0) 173 9303239
          </p>
          <p className="mt-2 sm:leading-9 leading-6">
            Email: k.komiyama(at)builbridge.com
          </p>
          <p className="mt-2 sm:leading-9 leading-6">Owner: Kyogo Komiyama</p>
          <p className="mt-2 sm:leading-9 leading-6">
            Commercial register: HRB 104357
          </p>
          <p className="mt-2 sm:leading-9 leading-6">
            Registration court: Amtsgericht Düsseldorf
          </p>
          <p className="mt-2 sm:leading-9 leading-6">VAT ID: DE368827272</p>
        </div>
        <div className="absolute top-0 left-0 w-full h-full"></div>
      </div>
    </div>
  );
}
