import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { detectLanguage } from "../redux/actions/languiseAction";
import { languageList } from "./Languages";
import { useDispatch, useSelector } from "react-redux";
import { SameLang } from "../utils/constants";

export default function LanguageSelect({
  value,
  onChange,
  className,
  auto = true,
  small = false,
  isDetectShow = false,
  isSameShow = false,
  isEnteredText = false,
  initialLangs = [],
  onChangeLangs = (_) => {},
}) {
  const uiLang = useSelector((state) => state.LanguiseReducer.uiLang);
  const dispatch = useDispatch();
  const [isShow, setShow] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [langItems, setLangItems] = useState([]);
  const [langLabel, setLangLabel] = useState("");
  const languageRef = useRef();
  const [languages, setLanguages] = useState([...initialLangs]);
  const [isDetecting, setIsDetecting] = useState(isDetectShow);

  const isEn = useMemo(() => {
    return uiLang !== "jp";
  }, [uiLang]);

  const Languages = useMemo(() => {
    if (!languageList.length) return;
    const langs = languages
      .map((lang) => {
        let temp = languageList.find(
          (e) => e.value.startsWith(lang) || e.label.startsWith(lang)
        );
        if (!temp)
          temp = languageList.find((e) => e.value === lang || e.label === lang);
        return temp;
      })
      .filter((lang) => lang !== undefined);

    return (
      <>
        {langs.map((lang, i) => (
          <div
            key={i}
            onClick={() => onClickLang(lang.value)}
            className={`whitespace-nowrap pt-4 border-b-4 font-sans font-normal cursor-pointer text-[#4B5090] ${
              small
                ? "sm:text-[13px] text-[11px]"
                : "xl:text-[18px] text-[14px]"
            } ${lang.value === value ? "border-[#4B5090]" : "border-white"}`}
          >
            {isEn ? lang.value : lang.label}
          </div>
        ))}
      </>
    );
  }, [languages, isEn, languageList, value]);

  const handleSelect = (langVal) => {
    setShow(false);
    onChange(langVal);
    if (langVal === SameLang.value) return;

    setKeyword("");
    if (langVal === languageList[0].value) {
      setIsDetecting(true);
      dispatch(detectLanguage(""));
    } else {
      setIsDetecting(false);
      const temp = languages.find((e) => e === langVal);
      if (!temp) {
        const newLangs = [langVal, ...languages].slice(0, 3);
        setLanguages(newLangs);
        onChangeLangs([...newLangs]);
      } else {
        // const arr = languages.filter((e) => e !== langVal);
        // setLanguages([langVal, ...arr]);
      }
    }
  };

  useEffect(() => {
    let temp = languageList.find((item) => item.value === value);
    if (value == SameLang.value) temp = SameLang;
    if (!temp) temp = languageList.find((item) => item.value.startsWith(value));
    if (temp) setLangLabel(uiLang === "jp" ? temp.label : temp.value);
  }, [languageList, value, uiLang]);

  const onClickLang = (lang) => {
    handleSelect(lang);
  };

  useEffect(() => {
    let temp = [...languageList];
    if (!auto) temp.shift();
    temp = temp.filter(
      (item) =>
        item.label.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
        item.value.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
    );
    setLangItems([...temp]);
  }, [auto, keyword]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (languageRef.current && !languageRef.current.contains(event.target)) {
        if (languageRef.current && languageRef.current.contains(event.target)) {
          setShow(true);
        } else {
          // setShow(false);
        }
      }
    }

    function resizeLanguagesSelector(event) {
      const width = document.getElementById("mainArea")?.clientWidth;
      if (languageRef.current) {
        languageRef.current.style.width = width ? `${width}px` : "100%";
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("resize", resizeLanguagesSelector);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", resizeLanguagesSelector);
    };
  }, []);

  const detectedLanguage = useMemo(() => {
    const isSameLangShow = isSameShow && value === SameLang.value;

    if (isSameLangShow) {
      return uiLang === "jp" ? SameLang.label : SameLang.value;
    }
    const isLanguageShow = isDetecting && value !== "Detect language";
    const lang = languageList.find((e) => e.value === value);
    const isExist = languages.find((lang) => lang === value);

    if (!lang || !isEnteredText || isExist)
      return uiLang !== "jp" ? "Detect language" : "自動で検出する";

    const val = uiLang === "jp" ? lang.label : lang.value;
    return isLanguageShow
      ? uiLang !== "jp"
        ? `${val} - Detected`
        : `${val} - 自動検出`
      : uiLang !== "jp"
      ? "Detect language"
      : "自動で検出する";
  }, [
    isDetecting,
    value,
    languageList,
    uiLang,
    languages,
    SameLang,
    isSameShow,
  ]);

  useEffect(() => {
    if (value === SameLang.value) return;
    if (value === "Detect language" || !value) return;
    const isExist = languages.find((e) => e === value);
    if (!isExist) setLanguages([value, ...languages].slice(0, 3));
  }, [value]);

  return (
    <>
      <div
        className={`h-full flex gap-4 max-w-full relative pr-6 ${
          className || ""
        }`}
      >
        <div className="hidden lg:block max-w-full relative overflow-x-scroll no-scrollbar h-full ">
          <div className="flex gap-4 h-full">
            {small && (
              <div
                onClick={() => setShow(!isShow)}
                className={`whitespace-nowrap border-b-4 text-[#4B5090] font-sans font-normal cursor-pointer sm:text-[13px] text-[11px]`}
              >
                {langLabel}
              </div>
            )}
            {!small && (
              <>
                {isDetectShow && (
                  <div
                    onClick={() => handleSelect(languageList[0].value)}
                    className={`pt-4 whitespace-nowrap border-b-4 text-[#4B5090] font-sans font-normal cursor-pointer ${
                      small
                        ? "sm:text-[13px] text-[11px]"
                        : "xl:text-[18px] text-[14px]"
                    } ${
                      isDetecting && !languages.includes(value)
                        ? " border-[#4B5090]"
                        : " border-transparent"
                    }`}
                  >
                    {detectedLanguage}
                  </div>
                )}
                {isSameShow && (
                  <div
                    onClick={() => onClickLang(SameLang.value)}
                    className={`pt-4 whitespace-nowrap border-b-4 text-[#4B5090] font-sans font-normal cursor-pointer ${
                      small
                        ? "sm:text-[13px] text-[11px]"
                        : "xl:text-[18px] text-[14px]"
                    } ${
                      value === SameLang.value
                        ? " border-[#4B5090]"
                        : " border-white"
                    }`}
                  >
                    {uiLang === "jp" ? SameLang.label : SameLang.value}
                  </div>
                )}
                {Languages}
              </>
            )}
          </div>
        </div>
        <div
          onClick={() => setShow(!isShow)}
          className={`hidden lg:flex items-center whitespace-nowrap text-[#4B5090] absolute right-0 z-20 ${
            small ? "sm:text-[13px] text-[11px]" : "xl:text-[18px] text-[14px]"
          } font-sans font-normal cursor-pointer top-0 h-full`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15.707 8.561"
            className={`relative z-20 ${
              small ? "sm:w-[10px] sm:h-2 w-2 h-2" : "xl:w-4 xl:h-2 w-2 h-2"
            }`}
          >
            <path
              d="M516.717,361.555l7.5,7.5,7.5-7.5"
              transform="translate(-516.364 -361.201)"
              fill="none"
              stroke="#4b5090"
              strokeWidth="1"
            />
          </svg>
        </div>
        <div className="flex gap-4 lg:hidden">
          <div
            onClick={() => setShow(!isShow)}
            className={`pt-4 whitespace-nowrap text-[#4B5090] font-sans font-normal cursor-pointer ${
              small
                ? "sm:text-[13px] text-[11px] !pt-0"
                : "xl:text-[18px] text-[14px]"
            }`}
          >
            {langLabel}
          </div>
          <div
            onClick={() => setShow(!isShow)}
            className={`flex items-center whitespace-nowrap text-[#4B5090] ${
              small
                ? "sm:text-[13px] text-[11px]"
                : "xl:text-[18px] text-[14px]"
            } font-sans font-normal cursor-pointer top-0  h-full`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 15.707 8.561"
              className={
                small ? "sm:w-[10px] sm:h-2 w-2 h-2" : "xl:w-4 xl:h-2 w-2 h-2"
              }
            >
              <path
                d="M516.717,361.555l7.5,7.5,7.5-7.5"
                transform="translate(-516.364 -361.201)"
                fill="none"
                stroke="#4b5090"
                strokeWidth="1"
              />
            </svg>
          </div>
        </div>
      </div>

      {isShow && (
        <div
          className={`absolute ${!small ? "w-[100vw]": "w-full"} top-[48px] xl:max-w-[1300px] left-0 z-30 items-center ${
            isDetectShow ? "" : "w-full"
          }`}
        >
          <div
            ref={languageRef}
            className={`flex flex-col w-full md:max-w-[1300px] mx-auto bg-white border border-[#E9E9E9] rounded-md ${
              small ? "mt-2" : "mt-4"
            }`}
          >
            <div
              className={`${
                small ? "sm:h-10 h-8" : "sm:h-14 h-12"
              } flex flex-row border border-b-[#E9E9E9] border-t-0 border-x-0`}
            >
              <div onClick={() => setShow(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21.51"
                  height="16.803"
                  viewBox="0 0 21.51 16.803"
                  className={`cursor-pointer ml-4 ${
                    small
                      ? "sm:mt-4 mt-3 sm:w-[20px] sm:h-[12px] w-[16px] h-[10px]"
                      : "sm:mt-[22px] mt-5 sm:w-[22px] sm:h-[14px] w-[18px] h-[12px]"
                  }`}
                >
                  <g transform="translate(-276.99 -273.098)">
                    <path
                      d="M-15904.579-20693.449l-7.909-6.83,7.909-7.148"
                      transform="translate(16190.988 20981.938)"
                      fill="none"
                      stroke="#9B9B9B"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                    <line
                      x2="18"
                      transform="translate(279.5 281.5)"
                      fill="none"
                      stroke="#9B9B9B"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
              </div>
              <input
                type="text"
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                placeholder="Search languages"
                className={`w-full mt-1 outline-none ${
                  small
                    ? "ml-2 sm:text-[13px] text-[11px]"
                    : "ml-4 sm:text-[16px] text-[14px]"
                }`}
              />
            </div>
            <div
              className={`grid ${
                small
                  ? "grid-cols-2 sm:max-h-[200px] max-h-[160px] overflow-auto"
                  : "md:grid-cols-6 grid-cols-2"
              } w-max md:w-full gap-0 p-2`}
            >
              {isSameShow && (
                <p
                  onClick={() => handleSelect(SameLang.value)}
                  className={`py-1 text-left ${
                    small
                      ? "sm:pl-4 pl-2 text-[11px] sm:text-[13px]"
                      : "pl-10 text-[12px] sm:text-[14px]"
                  } ${
                    value === SameLang.value
                      ? "text-[#4B50FF] bg-slate-200 font-bold"
                      : "text-gray-800"
                  } hover:bg-gray-100 cursor-pointer`}
                >
                  {isEn ? SameLang.value : SameLang.label}
                </p>
              )}
              {langItems.map((item, index) => {
                let itemTemp = { ...item };
                let temp = langItems.find((item) => item.value === value);
                if (!temp)
                  temp = langItems.find((item) => item.value.startsWith(value));
                const isSelected = temp && item.label.startsWith(temp.label);
                if (item.value === "English (US)")
                  itemTemp.value = "English (United States)";
                return (
                  <p
                    key={item.value}
                    onClick={() => handleSelect(item.value)}
                    className={`py-1 text-left ${
                      small
                        ? "sm:pl-4 pl-2 text-[11px] sm:text-[13px]"
                        : "pl-10 text-[12px] sm:text-[14px]"
                    } ${
                      isSelected
                        ? "text-[#4B50FF] bg-slate-200 font-bold"
                        : "text-gray-800"
                    } hover:bg-gray-100 cursor-pointer`}
                  >
                    {isEn ? itemTemp.value : itemTemp.label}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
