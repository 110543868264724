import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PrivacyPolicyEn from "./PrivacyPolicyEn";
import PrivacyPolicyJp from "./PrivacyPolicyJp";

export default function PrivacyPolicy() {
  const uiLang = useSelector((state) => state.LanguiseReducer.uiLang);
  const [lang, setLang] = useState("jp");

  useEffect(() => {
    setLang(uiLang);
  }, [uiLang]);
  
  return lang === "jp" ? <PrivacyPolicyJp /> : <PrivacyPolicyEn />;
}
