import React, { useEffect, useRef, useState } from "react";
import api from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../ConfirmModal";
import UpdatePlanModal from "../UpdatePlanModal";
import { getAccessToken } from "../../../services/utils";
import { showLoginModal } from "../../../redux/actions/languiseAction";
import { useTranslation } from "react-i18next";

export function ChatBotModal({ file, permission }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticated = getAccessToken();
  const userInfo = useSelector((state) => state.LanguiseReducer.userInfo);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showUpdatePlanModal, setShowUpdatePlanModal] = useState(false);

  const chatBoxRef = useRef();
  const [showChatBox, setShowChatBox] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [question, setQuestion] = useState("");
  const [txtFile, setTxtFile] = useState("");
  const [askList, setAskList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (question.trim() === "") return;
    sendMessage(question);
  };

  const sendMessage = async (q) => {
    if (isLoading) return;
    if (userInfo.plan === "Free") {
      setShowConfirmModal(true);
      return;
    }
    setIsLoading(true);
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const response = await fetch(`${baseURL}/chatbot/`, {
      method: "POST",
      body: JSON.stringify({ filename: txtFile, question: q }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${isAuthenticated}`,
      },
    });

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let partial = "";

    if (!response.ok) {
      return;
    }

    const data = [...chatList, { text: q, type: "human" }];
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        setIsLoading(false);
        break;
      }

      // Decode and handle the stream
      const text = decoder.decode(value, { stream: true });
      partial += text.replace(/\n/g, "<br/>");
      setChatList([...data, { text: partial, type: "bot" }]);
    }
    setQuestion("");
  };

  const handleAlert = (e) => {
    e.preventDefault();
    if (!permission) {
      setShowConfirmModal(true);
    }
  };

  useEffect(() => {
    setChatList([{ text: t("charBotModalText1"), type: "bot" }]);
    setAskList([t("charBotModalText2")]);
  }, []);

  useEffect(() => {
    if (!txtFile) return;
    api
      .post("/chatbot/", { filename: txtFile, question: "#Keywords" })
      .then((res) => {
        const answer = res.data.result.split("\n");
        for (let i = 0; i < answer.length; i++) {
          if(!answer[i].trim()) continue;
          answer[i] = t("charBotModalText3")+answer[i]+t("charBotModalText4");
        }
        setAskList([t("charBotModalText2"), ...answer]);
      })
      .catch((err) => {
        setAskList([t("charBotModalText2")]);
      });
  }, [txtFile]);

  useEffect(() => {
    if (file) {
      const dotIndex = file.lastIndexOf(".");
      const originFile = file.substring(0, dotIndex) + ".txt";
      setTxtFile(originFile);
    }
  }, [file]);

  useEffect(() => {
    chatBoxRef.current.scrollTo({
      top: chatBoxRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [chatList]);

  return (
    <>
      {showUpdatePlanModal && (
        <UpdatePlanModal
          open={showUpdatePlanModal}
          onClose={() => setShowUpdatePlanModal(false)}
        />
      )}
      {showConfirmModal && (
        <ConfirmModal
          isOpen={showConfirmModal}
          text={
            <div>
              <p className="sm:text-[32px] text-[24px] mb-4">
                {t("charBotModalText5")}
              </p>
              <p>{t("charBotModalText6")}</p>
            </div>
          }
          buttonText={!isAuthenticated ? t("login") : t("charBotModalText7")}
          cancelText={t("charBotModalText8")}
          onAgree={() => {
            if (isAuthenticated) {
              setShowConfirmModal(false);
              setShowUpdatePlanModal(true);
            } else {
              setShowConfirmModal(false);
              setShowUpdatePlanModal(false);
              dispatch(showLoginModal(true));
            }
          }}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}
      <div className="absolute bottom-6 sm:right-8 right-5 flex flex-col items-center gap-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 97.741 41.583"
          className="sm:w-[98px] sm:h-[42px] w-[88px] h-[38px]"
        >
          <path
            d="M.674,30.092.5,3.279A2.762,2.762,0,0,1,3.262.5H95.479a2.762,2.762,0,0,1,2.762,2.761V30.09a2.762,2.762,0,0,1-2.762,2.762H17.864a2.761,2.761,0,0,0-2.634,3.592c.39,1.235,1.927,5.638,1.927,5.638l-8.57-5.3L1.843,32.737A2.777,2.777,0,0,1,.674,30.092"
            transform="translate(-0.5 -0.5)"
            fill="#fff"
          />
          <text
            transform="translate(21.502 22)"
            fill="#3d80ba"
            fontWeight={600}
            className="text-[13px] sm:text-[14px]"
          >
            <tspan x="0" y="0">
            {t("charBotModalText9")}
            </tspan>
          </text>
        </svg>
        <div onClick={() => setShowChatBox(true)} className="cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 80.014 69.276"
            className="sm:w-[80px] sm:h-[70px] w-[72px] h-[62px]"
          >
            <path
              d="M35.91,58.305A28.918,28.918,0,0,0,57.415,86.255c2.346,1.315,9.206,3.691,11.765,3.691-.846-.582-2.592-1.342-3.362-2.753A28.905,28.905,0,1,0,35.91,58.305Z"
              transform="translate(-25.249 -20.67)"
              fill="#409cd6"
            />
            <g>
              <path
                d="M45.673,2.824A34.208,34.208,0,0,1,79.882,37.033h2.824a37.033,37.033,0,1,0-74.066,0h2.824A34.208,34.208,0,0,1,45.673,2.824Z"
                transform="translate(-6.077)"
                fill="#def0ff"
              />
              <path
                d="M11.93,159.586a21.059,21.059,0,0,0,3.008,8.793,5.487,5.487,0,0,0,1.078-.588,23.641,23.641,0,0,1-2.812-8.532C12.8,159.06,11.978,159.307,11.93,159.586Z"
                transform="translate(-8.39 -111.916)"
                fill="#def0ff"
              />
              <rect
                width="6.04"
                height="10.738"
                rx="3.02"
                transform="translate(3.878 57.224) rotate(-30)"
                fill="#def0ff"
              />
              <g transform="translate(0 29.381)">
                <path
                  d="M7.363,117.893H2.977A2.75,2.75,0,0,1,.01,115.1V101.7A2.43,2.43,0,0,1,2.6,98.95H7.363A1.042,1.042,0,0,1,8.4,99.992v16.861A1.042,1.042,0,0,1,7.363,117.9Z"
                  transform="translate(-0.01 -98.947)"
                  fill="#409cd6"
                />
                <path
                  d="M21.566,117.886H20.23V98.94h1.336a1.049,1.049,0,0,1,1.045,1.045V116.84A1.049,1.049,0,0,1,21.566,117.886Z"
                  transform="translate(-14.226 -98.94)"
                  fill="#def0ff"
                />
              </g>
              <g transform="translate(71.628 28.326)">
                <path
                  d="M242.253,114.343h4.386a2.75,2.75,0,0,0,2.967-2.794v-13.4A2.43,2.43,0,0,0,247.02,95.4h-4.766a1.042,1.042,0,0,0-1.033,1.042V113.3a1.042,1.042,0,0,0,1.033,1.042Z"
                  transform="translate(-241.22 -95.397)"
                  fill="#409cd6"
                />
                <path
                  d="M242.265,114.336H243.6V95.39h-1.336a1.049,1.049,0,0,0-1.045,1.045V113.29A1.049,1.049,0,0,0,242.265,114.336Z"
                  transform="translate(-241.22 -95.39)"
                  fill="#def0ff"
                />
              </g>
            </g>
            <g transform="translate(23.13 33.817)">
              <circle cx="3.216" cy="3.216" r="3.216" fill="#fff" />
              <circle
                cx="3.216"
                cy="3.216"
                r="3.216"
                transform="translate(13.574)"
                fill="#fff"
              />
              <circle
                cx="3.216"
                cy="3.216"
                r="3.216"
                transform="translate(26.75)"
                fill="#fff"
              />
            </g>
          </svg>
        </div>
      </div>
      <div
        className={`absolute bottom-6 sm:right-8 right-5 w-[307px] transition-opacity duration-500 ${
          showChatBox ? "opacity-100" : "opacity-0 invisible"
        }`}
      >
        <div className="flex items-center justify-between px-3 h-[34px] bg-[#E9E9F0] border border-[#707070] rounded-t-[15px]">
          <p className="text-[12px] text-[#707070]">
            {t("charBotModalText10")}
          </p>
          <div
            onClick={() => setShowChatBox(false)}
            className="flex cursor-pointer w-6 h-6 items-center justify-center border-[#4b5090] hover:border"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="3"
              viewBox="0 0 16 3"
            >
              <rect width="16" height="3" rx="1.5" fill="#4b5090" />
            </svg>
          </div>
        </div>
        <div className="relative">
          <div
            ref={chatBoxRef}
            className="h-[385px] bg-white p-3 border border-b-0 border-t-0 border-[#707070] overflow-y-auto"
          >
            {chatList.map((item, index) =>
              item.type === "bot" ? (
                <>
                  <div className="flex flex-row mt-4 gap-2" key={index}>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="45.746"
                        height="39.607"
                        viewBox="0 0 45.746 39.607"
                      >
                        <g transform="translate(-0.01)">
                          <g transform="translate(0.01)">
                            <path
                              d="M35.91,45.926A16.533,16.533,0,0,0,48.205,61.9a22.1,22.1,0,0,0,6.726,2.11,5.256,5.256,0,0,1-1.922-1.574,16.526,16.526,0,1,0-17.1-16.516Z"
                              transform="translate(-29.815 -24.409)"
                              fill="#409cd6"
                            />
                            <g>
                              <path
                                d="M29.813,1.615A19.558,19.558,0,0,1,49.37,21.173h1.615a21.173,21.173,0,1,0-42.345,0h1.615A19.558,19.558,0,0,1,29.813,1.615Z"
                                transform="translate(-7.175)"
                                fill="#3d80ba"
                              />
                              <path
                                d="M11.93,159.415a12.04,12.04,0,0,0,1.72,5.027,3.136,3.136,0,0,0,.616-.336,13.516,13.516,0,0,1-1.608-4.878C12.429,159.114,11.957,159.255,11.93,159.415Z"
                                transform="translate(-9.906 -132.161)"
                                fill="#3d80ba"
                              />
                              <rect
                                width="3.453"
                                height="6.139"
                                rx="1.727"
                                transform="translate(2.217 32.716) rotate(-30)"
                                fill="#3d80ba"
                              />
                              <g transform="translate(0 16.797)">
                                <path
                                  d="M4.214,109.78H1.706a1.572,1.572,0,0,1-1.7-1.6v-7.659A1.389,1.389,0,0,1,1.489,98.95H4.214a.6.6,0,0,1,.591.6v9.64a.6.6,0,0,1-.591.6Z"
                                  transform="translate(-0.01 -98.948)"
                                  fill="#409cd6"
                                />
                                <path
                                  d="M20.994,109.772H20.23V98.94h.764a.6.6,0,0,1,.6.6v9.636A.6.6,0,0,1,20.994,109.772Z"
                                  transform="translate(-16.797 -98.94)"
                                  fill="#3d80ba"
                                />
                              </g>
                              <g transform="translate(40.951 16.195)">
                                <path
                                  d="M241.811,106.23h2.508a1.572,1.572,0,0,0,1.7-1.6V96.974a1.389,1.389,0,0,0-1.479-1.574h-2.725a.6.6,0,0,0-.591.6v9.64a.6.6,0,0,0,.591.6Z"
                                  transform="translate(-241.22 -95.398)"
                                  fill="#409cd6"
                                />
                                <path
                                  d="M241.818,106.222h.764V95.39h-.764a.6.6,0,0,0-.6.6v9.636A.6.6,0,0,0,241.818,106.222Z"
                                  transform="translate(-241.22 -95.39)"
                                  fill="#3d80ba"
                                />
                              </g>
                            </g>
                            <g transform="translate(13.224 19.334)">
                              <circle
                                cx="1.839"
                                cy="1.839"
                                r="1.839"
                                fill="#fff"
                              />
                              <circle
                                cx="1.839"
                                cy="1.839"
                                r="1.839"
                                transform="translate(7.76)"
                                fill="#fff"
                              />
                              <circle
                                cx="1.839"
                                cy="1.839"
                                r="1.839"
                                transform="translate(15.293)"
                                fill="#fff"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="w-full">
                      <div className="text-[12px] mt-1 font-semibold text-start text-[#3D80BA]">
                        Chatbot
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.text }}
                        className="flex w-full p-3 bg-[#EAEAEA] rounded-[10px] rounded-tl-none text-[#505050] text-[12px] text-start items-center"
                      ></div>
                    </div>
                  </div>
                  {index === 0 && (
                    <div className="flex flex-wrap my-2 gap-1">
                      {askList.map((item, i) => (
                        <div
                          key={i}
                          onClick={() => sendMessage(item)}
                          className="max-w-[300px] text-[12px] text-start px-2 py-1 bg-[#F0F1F8] rounded-full cursor-pointer"
                        >
                          {item}
                        </div>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <div className="flex flex-row gap-2" key={index}>
                  <div className="max-w-[45px] w-full"></div>
                  <div className="flex w-full p-3 mt-5 bg-[#4B5090] rounded-[10px] rounded-tr-none text-white text-[12px] text-start items-center">
                    {item.text}
                  </div>
                </div>
              )
            )}
          </div>
          <form onSubmit={handleSendMessage}>
            <div className="relative flex flex-row p-5 bg-white border border-t-0 border-[#707070] rounded-b-[15px]">
              <input
                type="text"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                onBlur={() => window.scrollTo(0, 0)}
                className="flex w-full h-8 px-2 text-[12px] border border-[#CCCCCC] bg-white rounded-l-md outline-none"
              />
              <button className="w-12 h-8 bg-[#4B5090] rounded-r-md text-[12px] text-white font-semibold">
                {t("charBotModalText11")}
              </button>
              {!permission && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 18.843 23.211"
                  className="absolute left-8 top-6 w-[18px] h-[24px]"
                >
                  <g transform="translate(-142.153 4)">
                    <g
                      transform="translate(142.153 -4)"
                      clip-path="url(#clip-path)"
                    >
                      <path
                        d="M28.566,17.626V10.834A3.209,3.209,0,0,1,31.634,7.5h4.073a3.209,3.209,0,0,1,3.068,3.334v6.792Z"
                        transform="translate(-24.339 -6.39)"
                        fill="none"
                        stroke="#b1b1b1"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      />
                      <path
                        d="M17.068,62.846H1.775A1.781,1.781,0,0,0,0,64.621V74.983a1.781,1.781,0,0,0,1.775,1.775H17.068a1.781,1.781,0,0,0,1.775-1.775V64.621a1.781,1.781,0,0,0-1.775-1.775m-6.442,6.712v3.3a1.1,1.1,0,0,1-2.2,0v-3.3a1.981,1.981,0,1,1,2.2,0"
                        transform="translate(0 -53.547)"
                        fill="#b1b1b1"
                      />
                    </g>
                  </g>
                </svg>
              )}
            </div>
          </form>
          {!permission && (
            <>
              <div
                onClick={handleAlert}
                className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-[#222222] bg-opacity-10 rounded-b-[15px]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 18.843 23.211"
                  className="w-[45px] h-[55px]"
                >
                  <g transform="translate(-142.153 4)">
                    <g
                      transform="translate(142.153 -4)"
                      clip-path="url(#clip-path)"
                    >
                      <path
                        d="M28.566,17.626V10.834A3.209,3.209,0,0,1,31.634,7.5h4.073a3.209,3.209,0,0,1,3.068,3.334v6.792Z"
                        transform="translate(-24.339 -6.39)"
                        fill="none"
                        stroke="#707070"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      />
                      <path
                        d="M17.068,62.846H1.775A1.781,1.781,0,0,0,0,64.621V74.983a1.781,1.781,0,0,0,1.775,1.775H17.068a1.781,1.781,0,0,0,1.775-1.775V64.621a1.781,1.781,0,0,0-1.775-1.775m-6.442,6.712v3.3a1.1,1.1,0,0,1-2.2,0v-3.3a1.981,1.981,0,1,1,2.2,0"
                        transform="translate(0 -53.547)"
                        fill="#707070"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
