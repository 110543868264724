import React, { useEffect, useState } from "react";
import api from "../../services/api";
import Notiflix from "notiflix";
import { CustomSelect } from "./CustomSelect";
import { countryList } from "../Languages";
import { CurrencySelect } from "./CurrencySelect";
import { useTranslation } from "react-i18next";

const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      viewBox="0 0 17.103 13.52"
    >
      <path
        d="M455.262,1187.6l4.469,4.391,10.531-10.348"
        transform="translate(-454.21 -1180.575)"
        fill="none"
        stroke="#3d80ba"
        strokeWidth="3"
      />
    </svg>
  );
};

export default function AddEnterpriseModal({ openModal, onClose }) {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState("");
  const [address, setStreet] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [planList, setPlanList] = useState([]);
  const [yearlyCost, setYearlyCost] = useState(0);
  const [monthlyCost, setMonthlyCost] = useState(0);
  const [currency, setCurrency] = useState("jpy");
  const [errorMessage, setErrorMessage] = useState({});

  const sign = { jpy: "¥", usd: "$", eur: "€" };

  useEffect(() => {
    api
      .get("/user/enterpriseplan/")
      .then((res) => {
        setPlanList(
          res.data.map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.count,
            };
          })
        );
        setCount(res.data[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (count > 0) {
      const temp = planList.find((item) => item.value === count).price[
        currency
      ];
      setYearlyCost(temp);
      setMonthlyCost(temp / 12);
    }
  }, [count, currency, planList]);

  const handleSubmit = async (e) => {
    const validationErrors = validateForm();
    setErrorMessage({ ...validationErrors });
    if (Object.keys(validationErrors).length > 0) return;

    const rootUrl = window.location.origin;

    try {
      const { data } = await api.post("/corporate/", {
        company_name: company,
        country,
        address,
        postal_code: postalCode,
        plan: count,
        currency,
        success_url: `${rootUrl}/corporate/success`,
        cancel_url: window.location.href,
      });
      if (data.url) {
        onClose();
        const newTab = window.open(data.url, "_blank");
        if (!newTab) {
          window.location = data.url;
        }
      }
    } catch (err) {
      if (err.response.status === 406) {
        Notiflix.Notify.failure(t("addEnterpriseModalText1"));
        console.log(err);
      }
    }
  };

  const validateForm = () => {
    const temp = {};
    if (!company) temp.company = t("requireField");
    if (!country) temp.country = t("requireField");
    if (!address) temp.address = t("requireField");
    if (!postalCode) temp.postalCode = t("requireField");
    return temp;
  };

  return (
    <div
      className={`${
        openModal
          ? "flex !opacity-100 !translate-y-0"
          : "hidden !opacity-0 !translate-y-10"
      } fixed inset-0 bg-black/40 z-[9999] !transition-all !ease-in-out !duration-300 lg:px-0 px-5`}
    >
      <div className="relative flex flex-col max-w-[830px] w-full max-h-[98vh] overflow-auto mx-auto my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] rounded-lg">
        <div className="flex justify-end">
          <img
            onClick={onClose}
            className="w-[20px] h-[20px] mt-2 mr-4 cursor-pointer"
            src="/assets/cancel-modal.svg"
            alt="Cancel"
          />
        </div>
        <CurrencySelect
          value={currency}
          onChange={(v) => setCurrency(v)}
          className="absolute top-20 right-20"
        />
        <p className="text-[#4B5090] font-sans sm:text-[30px] text-[20px] text-center">
          Enterprise
        </p>
        <p className="text-[#4B5090] font-sans sm:text-[20px] text-[16px] text-center">
          {t("addEnterpriseModalText2")}
        </p>
        <div className="flex flex-col max-w-[450px] w-full mx-auto gap-1 mt-4 sm:mt-6">
          <div className="flex flex-row text-start text-[10px] sm:text-[14px] text-[#4B5090] gap-2">
            <CheckIcon />
            {t("addEnterpriseModalText3")}
          </div>
          <div className="flex flex-row text-start text-[10px] sm:text-[14px] text-[#4B5090] gap-2">
            <CheckIcon />
            {t("addEnterpriseModalText4")}
          </div>
          <div className="flex flex-row text-start text-[10px] sm:text-[14px] text-[#4B5090] gap-2">
            <CheckIcon />
            {t("addEnterpriseModalText5")}
          </div>
          <div className="flex flex-row text-start text-[10px] sm:text-[14px] text-[#4B5090] gap-2">
            <CheckIcon />
            {t("addEnterpriseModalText6")}
          </div>
          <div className="flex flex-row text-start text-[10px] sm:text-[14px] text-[#4B5090] gap-2">
            <CheckIcon />
            {t("addEnterpriseModalText7")}
          </div>
          <div className="flex flex-row text-start text-[9px] sm:text-[12px] text-[#505050] ml-4 mt-2">
            {t("addEnterpriseModalText8")}
          </div>
        </div>
        <div className="flex flex-col max-w-[420px] w-full mx-auto sm:mt-[30px] mt-4 gap-4">
          <div className="flex flex-row text-[12px] sm:text-[16px] border-b sm:border-b-2 border-[#707070] justify-between">
            <p className="text-[#4B5090]">{t("updateEnterpriseModalText4")}</p>
            <CustomSelect value={count} onChange={setCount} items={planList} />
          </div>
          <div className="flex flex-row text-[12px] sm:text-[16px] border-b sm:border-b-2 border-[#707070] justify-between">
            <p className="text-[#4B5090]">{t("updateEnterpriseModalText5")}</p>
            <p className="text-[#505050]">
              {sign[currency]}
              {yearlyCost.toLocaleString()}
            </p>
          </div>
          <div className="flex flex-row text-[12px] sm:text-[16px] border-b sm:border-b-2 border-[#707070] justify-between">
            <p className="text-[#4B5090]">{t("updateEnterpriseModalText6")}</p>
            <p className="text-[#505050]">
              {sign[currency]}
              {monthlyCost.toLocaleString()}
            </p>
          </div>
          <div className="flex flex-col w-full text-start text-[#8B8B8B] text-[10px] gap-1">
            <p>{t("addEnterpriseModalText9")}</p>
            <p>{t("addEnterpriseModalText10")}</p>
            <p>{t("addEnterpriseModalText11")}</p>
          </div>
        </div>
        <div className="flex flex-col max-w-[420px] w-full mx-auto gap-2 mt-4">
          <div className="flex flex-col items-center gap-2">
            <div className="flex gap-2 items-center w-full text-start text-[#505050] sm:text-[16px] text-[13px] font-sans">
              {t("companyName")}
              <span className="text-start text-red-600 text-sm">
                {errorMessage.company}
              </span>
            </div>
            <div className="sm:max-w-[450px] max-w-full w-full">
              <input
                type="text"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                className={`${
                  company ? "bg-white" : "bg-transparent"
                } w-full h-[35px] sm:h-[40px] border border-[#8B8B8B] rounded-[10px] px-2 sm:px-5 outline-none`}
              />
            </div>
          </div>
          <div className="flex flex-col items-center gap-1">
            <div className="flex gap-2 items-center w-full text-start text-[#505050] sm:text-[16px] text-[13px] font-sans">
              {t("country")}
              <span className="text-start text-red-600 text-sm">
                {errorMessage.country}
              </span>
            </div>
            <div className="sm:max-w-[450px] max-w-full w-full">
              <CustomSelect
                value={country}
                onChange={setCountry}
                items={countryList}
                className="h-[35px] sm:h-[40px]"
              />
            </div>
          </div>
          <div className="flex flex-col items-center gap-1">
            <div className="flex gap-2 items-center w-full text-start text-[#505050] sm:text-[16px] text-[13px] font-sans">
              {t("address")}
              <span className="text-start text-red-600 text-sm">
                {errorMessage.address}
              </span>
            </div>
            <div className="sm:max-w-[450px] max-w-full w-full">
              <input
                type="text"
                value={address}
                onChange={(e) => setStreet(e.target.value)}
                placeholder={t("address")}
                className={`${
                  address ? "bg-white" : "bg-transparent"
                } w-full h-[35px] sm:h-[40px] border border-[#8B8B8B] rounded-[10px] px-2 sm:px-5 outline-none`}
              />
            </div>
          </div>
          <div className="flex flex-col items-center gap-1">
            <div className="flex gap-2 items-center w-full text-start text-[#505050] sm:text-[16px] text-[13px] font-sans">
              {t("postalCode")}
              <span className="text-start text-red-600 text-sm">
                {errorMessage.postalCode}
              </span>
            </div>
            <div className="sm:max-w-[450px] max-w-full w-full">
              <input
                type="text"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                placeholder={t("postalCode")}
                className={`${
                  postalCode ? "bg-white" : "bg-transparent"
                } w-full h-[35px] sm:h-[40px] border border-[#8B8B8B] rounded-[10px] px-2 sm:px-5 outline-none`}
              />
            </div>
          </div>
        </div>
        <div className=" max-w-[420px] w-full mx-auto flex justify-end my-6">
          <button
            onClick={handleSubmit}
            className="w-full border border-[#8B8B8B] bg-[#F0F1F8] hover:bg-[#6267b2] h-[35px] sm:h-[40px] text-[#8B8B8B] hover:text-white rounded-[10px] sm:text-[16px] text-[14px]"
          >
            {t("update")}
          </button>
        </div>
      </div>
    </div>
  );
}
