import React from "react";
import { useDispatch } from "react-redux";
import { getDictionaryContentList } from "../../../redux/actions/languiseAction";
import api from "../../../services/api";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";

export default function DeleteDicDetailModal({
  openModal,
  onClose,
  deleteDicDetailId,
  dictionaryId,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deleteDictionary = () => {
    const id = deleteDicDetailId();

    const formData = new FormData();
    formData.append("dictionary_detail_id", JSON.stringify(id));

    const url = "/dictionary/detail/";
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    api
      .delete(url, config)
      .then((res) => {
        const formData = new FormData();
        formData.append("dictionary_id", Number(dictionaryId));
        dispatch(getDictionaryContentList(formData));
        onClose();
      })
      .catch((err) => {
        Notiflix.Notify.failure(t("operationFailure"));
        console.log(err);
      });
  };

  return (
    <div
      className={`${
        openModal ? "flex" : "hidden"
      } bg-black/40 w-full h-screen z-[9999] fixed top-0 left-0 sm:px-0 px-5`}
    >
      <div className="flex flex-col max-w-[830px] w-full min-h-[278px] sm:mx-auto mx-5 my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] rounded-[10px]">
        <div className="flex justify-end">
          <img
            onClick={onClose}
            className="w-[20px] h-[20px] cursor-pointer"
            src="/assets/cancel-modal.svg"
            alt="Cancel"
          />
        </div>
        <p className="text-[#4B5090] font-sans text-[20px] text-center mt-14">
          {t("deleteDicDetailModalText2")}
        </p>
        <div className="flex justify-center mt-12">
          <button
            onClick={deleteDictionary}
            className="max-w-[128px] w-full bg-[#4B5090] h-[40px] text-white rounded-[10px]"
          >
            {t("delete")}
          </button>
        </div>
      </div>
    </div>
  );
}
