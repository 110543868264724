import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import VerifyUser from "./pages/VerifyUser";
import ResetPassword from "./pages/ResetPassword";
import ChangeEmail from "./pages/ChangeEmail";

import Header from "./layout/header";
import Footer from "./layout/footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Enterprise from "./pages/Enterprise";
import ContactUs from "./pages/ContactUs";
import DownloadPage from "./pages/DownloadPage";
import Security from "./pages/Security";
import PricePlan from "./pages/PricePlan";
import MyPage from "./pages/MyPage";
import MyInvoice from "./pages/MyInvoice";
import PaymentSuccess from "./pages/PaymentSuccess";
import AdminPage from "./pages/AdminPage";
import CorporateSuccess from "./pages/CorporateSuccess";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import Imprint from "./pages/Imprint";
import VerifyCorporateAdminUser from "./pages/VerifyCorporateAdminUser";

import { AxiosInterceptor } from "./services/api";
import Maintenance from "./pages/Maintenance";

function App() {
  return (
    <Provider store={store}>
      <div className="App flex flex-col min-h-screen">
        <BrowserRouter>
          <AxiosInterceptor>
            <Header />
            <Routes>
              <Route path="/" Component={Home} />
              <Route path="/about" Component={About} />
              <Route path="/enterprise" Component={Enterprise} />
              <Route path="/contactus" Component={ContactUs} />
              <Route path="/dl" Component={DownloadPage} />
              <Route path="/security" Component={Security} />
              <Route path="/privacy" Component={PrivacyPolicy} />
              <Route path="/terms" Component={Terms} />
              <Route path="/priceplan" Component={PricePlan} />
              <Route path="/imprint" Component={Imprint} />
              <Route path="/mypage" Component={MyPage} />
              <Route path="/myinvoice" Component={MyInvoice} />
              <Route path="/corporate/success" Component={CorporateSuccess} />
              <Route path="/payment/success" Component={PaymentSuccess} />
              <Route path="/manage" Component={AdminPage} />
              <Route path="/maintenance" Component={Maintenance} />
              <Route
                path="/corporate/change-admin/:token"
                Component={VerifyCorporateAdminUser}
              />
              <Route path="/user/verify/:token" Component={VerifyUser} />
              <Route
                path="/user/resetpassword/:token"
                Component={ResetPassword}
              />
              <Route path="/user/changemail/:token" Component={ChangeEmail} />
              <Route path="/*" Component={Home} />
            </Routes>
            <Footer />
          </AxiosInterceptor>
        </BrowserRouter>
      </div>
    </Provider>
  );
}

export default App;
