export const getA8ID = () => {
  const item = localStorage.getItem("a8");
  if (item) {
    const parsedItem = JSON.parse(item);
    if (Date.now() < parsedItem.expiration) {
      return parsedItem.id;
    } else {
      localStorage.removeItem("a8"); // Remove expired item from localStorage
    }
    return null;
  }
};

export const setA8ID = (id) => {
  const expiration = Date.now() + 90 * 24 * 60 * 60 * 1000;
  localStorage.setItem("a8", JSON.stringify({ id, expiration }));
};

export const removeA8ID = () => {
  localStorage.removeItem("a8");
};

export const setCookie = (res) => {
  const date = new Date();
  date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * 365 * 100);
  document.cookie = `access_token=${
    res.data.access_token
  }; expires=${date.toUTCString()}; path=/; secure: HttpOnly`;
  date.setTime(date.getTime() + 48 * 60 * 60 * 1000 * 365 * 100);
  document.cookie = `refresh_token=${
    res.data.refresh_token
  }; expires=${date.toUTCString()}; path=/; secure: HttpOnly`;
};

export const getAccessToken = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith("access_token=")) {
      return cookie.substring("access_token=".length);
    }
  }
  return null;
};

export const getRefreshToken = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith("refresh_token=")) {
      return cookie.substring("refresh_token=".length);
    }
  }
  return null;
};

export const removeAllCookies = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const cookieName = cookie.split("=")[0];
    // Set the cookie's expiration date to a past date (Thu, 01 Jan 1970 00:00:00 UTC)
    document.cookie =
      cookieName + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
  }
};
