import React, { useState } from "react";
import TextField from "../../TextField";
import { useDispatch } from "react-redux";
import { getUserInfo } from "../../../redux/actions/languiseAction";
import api from "../../../services/api";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";

export default function ChangeAccountPasswordModal({ openModal, closeModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState({});

  const changePassword = () => {
    const validationErrors = validateForm();
    setErrorMessage({ ...validationErrors });
    if (Object.keys(validationErrors).length > 0) return;
    api
      .put("/user/password/", {
        old_password: oldPassword,
        new_password: newPassword,
      })
      .then((rst) => {
        Notiflix.Notify.success(t("resetPasswordPageMessage1"));
        dispatch(getUserInfo());
        setOldPassword("");
        setNewPassword("");
        setVerifyPassword("");
        closeModal();
      })
      .catch((error) => {
        Notiflix.Notify.failure(t("operationFailure"));
        console.log(error);
      });
  };

  const validateForm = () => {
    const temp = {};
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    if (!passwordRegex.test(oldPassword)) temp.oldPassword = t("passwordRule");
    if (!passwordRegex.test(newPassword)) temp.newPassword = t("passwordRule");
    if (!passwordRegex.test(verifyPassword))
      temp.verifyPassword = t("passwordRule");
    if (newPassword !== verifyPassword)
      temp.verifyPassword = t("notMatchPassword");
    return temp;
  };

  return (
    <div
      className={`${
        openModal
          ? "flex !opacity-100 !translate-y-0"
          : "hidden !opacity-0 !translate-y-10"
      } fixed inset-0 bg-black/40 z-30 !transition-all !ease-in-out !duration-300`}
    >
      <div className="flex flex-col max-w-[830px] w-full sm:mx-auto mx-5 my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] rounded-lg">
        <div className="flex justify-end">
          <img
            onClick={closeModal}
            className="w-[20px] h-[20px] cursor-pointer"
            src="/assets/cancel-modal.svg"
            alt="Cancel"
          />
        </div>
        <p className="text-[#4B5090] font-sans text-[20px] text-center">
          {t("resetPasswordPageText5")}
        </p>
        <div className="flex flex-col max-w-[420px] w-full mx-auto gap-4 mt-[37px]">
          <div className="flex flex-col gap-1">
            <p className="text-start font-sans text-[#505050] text-[12px]">
            {t("resetPasswordPageText10")}
            </p>
            <TextField
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <span className="text-start text-red-600 text-sm">
              {errorMessage.oldPassword}
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-start font-sans text-[#505050] text-[12px]">
            {t("resetPasswordPageText6")}
            </p>
            <TextField
              type={`${showPassword ? "text" : "password"}`}
              value={newPassword}
              showClicked={showPassword}
              onClick={() => setShowPassword(!showPassword)}
              onChange={(e) => setNewPassword(e.target.value)}
              show_password={true}
            />
            <span className="text-start text-red-600 text-sm">
              {errorMessage.newPassword}
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-start font-sans text-[#505050] text-[12px]">
            {t("resetPasswordPageText7")}
            </p>
            <TextField
              type="password"
              value={verifyPassword}
              onChange={(e) => setVerifyPassword(e.target.value)}
            />
            <span className="text-start text-red-600 text-sm">
              {errorMessage.verifyPassword}
            </span>
          </div>
          <div className="flex justify-center mt-[14px]">
            <button
              onClick={changePassword}
              className="w-full bg-transparent h-[40px] border border-[#8B8B8B] text-[#8B8B8B] rounded-[10px] hover:bg-[#4B5090] hover:text-white mb-10"
            >
              {t("update")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
