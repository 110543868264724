import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PuffLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/react";
import {
  detectLanguage,
  getDictionaryList,
  getUserInfo,
  showLoginModal,
} from "../redux/actions/languiseAction";
import SwitchButton from "../components/SwitchButton";
import SummarySelect, {
  summaryListEn,
  summaryListJp,
} from "../components/SummarySelect";
import CheckBoxContent from "../components/CheckBoxContent";
import LanguageSelect from "../components/LanguageSelect";
import DictionaryTable from "../components/DictionaryTable";
import { PreviewModal } from "../components/Modal/PreviewModal";
import ConfirmProcessChangeModal from "../components/Modal/ConfirmProcessChangeModal";
import ConfirmModal from "../components/Modal/ConfirmModal";
import Notiflix from "notiflix";

import api, { apiWithoutCred } from "../services/api";
import { getAccessToken } from "../services/utils";
import UpdatePlanModal from "../components/Modal/UpdatePlanModal";
import mammoth from "mammoth";
import pptxParser from "pptx-parser";
import { pdfjs } from "react-pdf";
import { Helmet } from "react-helmet";
import { JSONStorage } from "../utils/jsonStorage";
import { SameLang } from "../utils/constants";
import { TranslateModal } from "../components/Modal/Preview/TranslateModal";
import { languageList } from "../components/Languages";
import { s3_dir } from "../utils/constants";
import EnterpriseRecommendModal from "../components/Modal/EnterpriseRecommendModal";
import AddEnterpriseModal from "../components/Modal/AddEnterpriseModal";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const InitialFromLanguages = [
  "English (US)",
  "Japanese",
  "Chinese (Traditional)",
];
const InitialToLanguages = [
  "Japanese",
  "English (US)",
  "Chinese (Traditional)",
];

export default function Home() {
  const { t } = useTranslation();
  const uiLang = useSelector((state) => state.LanguiseReducer.uiLang);
  const [summaryList, setSummaryList] = useState(summaryListJp);
  const dispatch = useDispatch();
  const isAuthenticated = getAccessToken();
  const userInfo = useSelector((state) => state.LanguiseReducer.userInfo);
  const detectedLanguage = useSelector(
    (state) => state.LanguiseReducer.detectedLanguage
  );

  const [isRotated, setIsRotated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSelected, setPageSelected] = useState(false);

  const [processType, setProcessType] = useState(1);
  const [oldTranslateText, setOldTranslateText] = useState("");
  const [translateText, setTranslateText] = useState("");
  const [oldToLanguage, setOldToLanguage] = useState("");
  const [translateGPT4, setTranslateGPT4] = useState(-1);
  const [translateFileResult, setTranslateFileResult] = useState("");
  const [translateTextResult, setTranslateTextResult] = useState("");
  const [titleCreatorResult, setTitleCreatorResult] = useState([]);

  const [clickedSwitch, setClickedSwitch] = useState(0);
  const [copy, setCopy] = useState(false);
  const [showProofToneDlg, setShowProofToneDlg] = useState(false);
  const [showToneDlg, setShowToneDlg] = useState(false);
  const [showDicDlg, setShowDicDlg] = useState(false);
  const [showEnterpriseRecommendModal, setShowEnterpriseRecommendModal] =
    useState(false);
  const [showEnterpriseModal, setShowEnterpriseModal] = useState(false);

  const [fileUploaded, setFileUploaded] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [uploadedFileType, setUploadedFileType] = useState("");
  const [timeOutId, setTimeOutId] = useState();
  const [originFileSize, setOriginFileSize] = useState(0);
  const [resultFileSize, setResultFileSize] = useState(0);
  const [pageOrWordCount, setPageOrWordCount] = useState(0);

  const [fromTranslate, setFromTranslate] = useState("Detect language");
  const [toTranslate, setToTranslate] = useState("English (US)");
  const [textCategory, setTextCategory] = useState("");
  const [tone, setTone] = useState("");
  const [proofTone, setProofTone] = useState(0);
  const [proofOtherTone, setProofOtherTone] = useState("");
  const [proofToneType, setProofToneType] = useState(0);

  const [currentDictionaryId, setCurrentDictionatyId] = useState(-1);
  const [dictionaryDetail, setDictionaryDetail] = useState([]);
  const [activeTone, setActiveTone] = useState("");
  const [summaryItems, setSummaryItems] = useState([]);
  const [titleCreatorParams, seTitleCreatorParams] = useState({});
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [newProcessType, setNewProcessType] = useState();
  const [showConfirmProcessChangeModal, setShowConfirmProcessChangeModal] =
    useState(false);
  const originalTextRef = useRef();
  const translationRef = useRef();
  const proofToneRef = useRef();
  const toneRef = useRef();
  const dicRef = useRef();
  const btnGroupRef = useRef();
  const documentRef = React.createRef();
  const dictionaryList = useSelector(
    (state) => state.LanguiseReducer.dictionaryList
  );
  const [confirmText, setConfirmText] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showUpdatePlanModal, setShowUpdatePlanModal] = useState(false);
  const [isSubTitleProcess, setSubTitleProcess] = useState(false);
  const [titleOfSubTitle, setTitleOfSubTitle] = useState("");
  const [subTitleKey, setSubTitleKey] = useState(-1);
  const [showToastr, setShowToastr] = useState(false);
  const [_, setAutoTranslation] = useState(false);
  const [isUpgrade, setIsUpgrade] = useState(false);
  const toastrTimer = useRef();
  const intervalRef = useRef(null);
  const localStorage = new JSONStorage(window.localStorage);
  const prices = useSelector((state) => state.LanguiseReducer.prices);

  const from = localStorage.get("InitialFromLanguages");
  const to = localStorage.get("InitialToLanguages");
  const [initialFromLanguages, setInitialFromLanguages] = useState(
    from || InitialFromLanguages
  );
  const [initialToLanguages, setInitialToLanguages] = useState(
    to || InitialToLanguages
  );
  const [plans, setPlans] = useState([]);
  const detectTimerId = useRef(0);

  const rootUrl = window.location.origin;

  useEffect(() => {
    if (prices.monthly) setPlans(prices.monthly);
  }, [prices]);

  const handleSubscription = async () => {
    try {
      const { data } = await api.post("/payment/subscription/", {
        success_url: `${rootUrl}/payment/success`,
        cancel_url: window.location.href,
        payment_type: plans[2],
        currency: "jpy",
        payment_cycle: false,
      });
      if (data.url) {
        const newTab = window.open(data.url, "_blank");
        if (!newTab) {
          window.location = data.url;
        }
      } else Notiflix.Notify.failure(t("paymentFailure"));
    } catch (err) {
      if (err.response.status === 406) {
        Notiflix.Notify.warning(t("unavailableEnterprise"));
        return;
      }
      if (err.response.status === 409) {
        Notiflix.Notify.warning(t("existPlan"));
        return;
      }
      if (err.response.status === 402) {
        return;
      }
    }
  };

  const onChangeFromLangs = (langs) => {
    let dupeArray = [...langs, ...initialFromLanguages];
    let uniqueArray = Array.from(new Set(dupeArray)).slice(0, 3);
    if (uniqueArray && uniqueArray?.length === 3) {
      localStorage.set("InitialFromLanguages", uniqueArray);
    }
    setInitialFromLanguages(uniqueArray);
  };

  const onChangeToLangs = (langs) => {
    let dupeArray = [...langs, ...initialToLanguages];
    let uniqueArray = Array.from(new Set(dupeArray)).slice(0, 3);
    if (uniqueArray && uniqueArray?.length === 3) {
      localStorage.set("InitialToLanguages", uniqueArray);
    }
    setInitialToLanguages(uniqueArray);
  };

  const placeholderText = [
    t("translationDescription"),
    t("proofreadingDescription"),
    t("summaryDescription"),
  ];
  const proofToneList = [
    t("tone1"),
    t("tone2"),
    t("tone3"),
    t("tone4"),
    t("tone5"),
    t("tone6"),
    t("other"),
  ];

  const resetState = (newProcess) => {
    if (translateFileResult)
      api
        .post("/assistance/clear/", { filename: translateFileResult })
        .catch((error) => {
          console.log(error);
        });
    dispatch(detectLanguage(""));
    if (newProcess !== 3) setToTranslate("English (US)");
    else setToTranslate(SameLang.value);
    setUploadedFile(null);
    setUploadedFileName(null);
    setOldTranslateText("");
    setTranslateText("");
    setTranslateFileResult("");
    setTranslateTextResult("");
    setTitleCreatorResult([]);
    setFileUploaded(false);
    setTranslateGPT4(-1);
  };

  const resetResult = () => {
    if (translateText !== "") setTranslateText(translateText.trimEnd() + " ");
    setTranslateFileResult("");
    setTranslateTextResult("");
    setTranslateText("");
    setTitleCreatorResult([]);
  };

  const handleClick = (tone) => {
    setActiveTone(tone);
  };

  const handleProcessType = (type) => {
    setNewProcessType(type);
    if (processType === type) return;
    if (
      !window.localStorage.getItem("ProcessChangeModal") &&
      (translateText.length > 0 || fileUploaded)
    )
      setShowConfirmProcessChangeModal(true);
    else {
      setProcessType(type);
      resetState(type);
    }
  };

  const getClassName = (tone) => {
    return `flex items-center px-6 text-[#333876] text-[16px] leading-[32px] font-sans h-[50px] border-t border-[#CCCCCC] cursor-pointer ${
      activeTone === tone ? "bg-[#F0F2E8]" : "hover:bg-[#F0F1F8]"
    }`;
  };

  const handleDictionaryOpen = () => {
    setShowDicDlg(true);
    dispatch(getDictionaryList());
  };

  const handleGetDictionaryDetailById = async (id) => {
    const formData = new FormData();
    formData.append("dictionary_id", id);
    setCurrentDictionatyId(id);

    const params = new URLSearchParams();

    for (const [key, value] of formData) {
      params.append(key, value);
    }

    api
      .get("/dictionary/detail/", {
        params,
      })
      .then((res) => {
        setDictionaryDetail(res.data);
      })
      .catch((err) => {
        setDictionaryDetail([]);
        console.log(err);
      });
  };

  const handlePageSelected = () => {
    setPageSelected(!pageSelected);
  };

  const handleSummarySelected = (i, value) => {
    const temp = [...summaryItems];
    temp[i] = value;
    setSummaryItems(temp);
  };

  const replaceSummary = (temp) => {
    for (let i = 0; i < temp.length; i++) {
      temp[i] = temp[i].trim();
      if (!temp[i].startsWith("[")) {
        if (
          temp[i].startsWith("-") ||
          temp[i].startsWith("・") ||
          temp[i].startsWith(".")
        )
          temp[i] = `<li>${temp[i].substring(1)}</li>`;
        else temp[i] = `<p class="ml-[-10px]">${temp[i]}</p>`;
        continue;
      }
      const item = summaryList.find((item) => `[${item.value}]` === temp[i]);
      if (item) {
        temp[i] = `<h4 class="text-[19px] mb-[10px] font-semibold">
            ${item.title}
          </h4>`;
      } else {
        temp[i] = `<h4 class="text-[19px] font-semibold">${t("other")}</h4>`;
      }
      if (i === 0) temp[i] += "<ul class='list-outside pl-4 list-disc mb-4'>";
      else
        temp[i] =
          "</ul>" + temp[i] + "<ul class='list-outside pl-4 list-disc mb-4'>";
    }
    setTranslateTextResult(temp.join(""));
  };

  const checkProcessProgress = (id) => {
    if (id === "") return;
    api
      .get("/assistance/progress/", {
        params: {
          id,
        },
      })
      .then((res) => {
        const progress = Number(res.data.progress);
        if (progress === 0) return;
        clearInterval(intervalRef.current);
        setIsLoading(false);
        clearTimeout(toastrTimer.current);
        setShowToastr(false);
        if (progress === 400) {
          Notiflix.Notify.failure(t("serverError"));
        }
        if (progress !== 200) {
          return;
        }
        document.body.style.overflowY = "auto";
        if (isAuthenticated) dispatch(getUserInfo());
        if (processType === 3 && clickedSwitch === 0) {
          if (summaryItems.length > 0) {
            const temp = res.data.result.split("\n");
            replaceSummary(temp);
          } else {
            const temp = res.data.result.split("\n");
            temp.shift();
            setTranslateTextResult(temp.join("<br/>"));
          }
          originalTextRef.current.scrollTop = 0;
        } else if (processType === 3 && clickedSwitch === 1) {
          if (!isSubTitleProcess) {
            const temp = res.data.result.split("\n");
            setTitleCreatorResult(
              temp.map((item) => ({ title: item, level: 0 }))
            );
          } else {
            const titles = [...titleCreatorResult];
            const temp = res.data.result.split("\n");
            const childTitle = temp.map((item) => ({
              title: item,
              level: titles[subTitleKey].level + 1,
            }));
            Array.prototype.splice.apply(
              titles,
              [subTitleKey + 1, 0].concat(childTitle)
            );
            setTitleCreatorResult(titles);
            setSubTitleProcess(false);
            setTitleOfSubTitle("");
            setSubTitleKey(-1);
          }
        } else if (uploadedFileType === "txt") {
          const reader = new FileReader();
          reader.onload = function (e) {
            setOldTranslateText(translateText);
            setTranslateText(e.target.result);
          };
          reader.readAsText(uploadedFile);
          apiWithoutCred
            .get(`${s3_dir}/${res.data.filename}`)
            .then((rst) => {
              const temp = rst.data.replace(/\r\n/g, "\n");
              setTranslateTextResult(temp.replace(/\n/g, "<br/>"));
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (processType !== 3) {
          setResultFileSize(parseFloat(res.data.size / 1024 / 1024).toFixed(2));
          setTranslateFileResult(res.data.filename);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        document.body.style.overflowY = "auto";
      });
  };

  const handle_translate_stream = async (formData) => {
    if (toastrTimer.current) clearTimeout(toastrTimer.current);
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const data = {};
    formData.forEach(function (value, key) {
      data[key] = value;
    });
    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };
    if (isAuthenticated) {
      options.headers = {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${isAuthenticated}`,
      };
    }
    const response = await fetch(`${baseURL}/translate/text/`, options);
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let partial = "";

    if (!response.ok) {
      const { value } = await reader.read();
      let errorString = decoder.decode(value, { stream: true });
      if (response.status === 402) {
        if (errorString.indexOf("Too many free request count") !== -1) {
          setConfirmText(
            <div>
              <p className="sm:text-[32px] text-[24px] mb-4">
                {t("freeLimitMessage1")}
              </p>
              {!isAuthenticated ? (
                <p>{t("freeLimitMessage2")}</p>
              ) : (
                <p>{t("freeLimitMessage3")}</p>
              )}
            </div>
          );
        } else {
          setConfirmText(
            <div>
              <p className="sm:text-[32px] text-[24px] mb-4">
                {t("creditLimitMessage1")}
              </p>
              {!isAuthenticated ? (
                <p>{t("creditLimitMessage2")}</p>
              ) : (
                <p>{t("creditLimitMessage3")}</p>
              )}
            </div>
          );
        }
        setShowConfirmModal(true);
      }
      return;
    }

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        setIsLoading(false);
        if (isAuthenticated) dispatch(getUserInfo());
        break;
      }

      // Decode and handle the stream
      partial += decoder.decode(value, { stream: true });
      const lines = partial.split("\n");
      partial = lines.pop(); // Save incomplete line for next chunk

      for (const line of lines) {
        setTranslateTextResult((prev) => prev + line + "<br/>");
      }
    }
  };

  const handleProcess = async () => {
    if (isLoading) return;
    if (translateText.length === 0 && !fileUploaded) {
      return;
    }
    try {
      if (translateFileResult)
        api.post("/assistance/clear/", { filename: translateFileResult });
    } catch (error) {
      console.log(error);
    }
    if (
      (userInfo.character_limit !== -1 &&
        translateText.length > userInfo.character_limit) ||
      (!isAuthenticated && translateText.length > 1000)
    ) {
      setConfirmText(
        <div>
          <p className="sm:text-[32px] text-[24px] mb-4">
            {t("characterLimitMessage1")}
          </p>
          <p>{t("characterLimitMessage2")}</p>
        </div>
      );
      setShowConfirmModal(true);
      return;
    }

    setTranslateFileResult("");
    setTranslateTextResult("");

    window.scrollTo(0, 0);
    setIsLoading(true);
    toastrTimer.current = setTimeout(() => {
      setShowToastr(true);
    }, 20000);
    // document.body.style.overflowY = "hidden";
    if (userInfo.enterprise_trial) {
      if (userInfo.credit <= userInfo.process_count) {
        setShowEnterpriseRecommendModal(true);
      }
    }

    if (fileUploaded) {
      const formData = new FormData();
      let apiURL = "";
      if (processType === 1) {
        formData.append("lang1", fromTranslate);
        formData.append("lang2", toTranslate);
        formData.append("tone", tone);
        formData.append("file", uploadedFile);
        formData.append("dictionary_id", currentDictionaryId);
        formData.append("category", textCategory);
        formData.append("gpt4", translateGPT4 ? "1" : "0");
        apiURL = "/translate/file/";
      }
      if (processType === 2) {
        formData.append("lang", fromTranslate);
        formData.append("file", uploadedFile);
        formData.append("dictionary_id", currentDictionaryId);
        apiURL = "/proofreading/file/";
      }
      if (processType === 3) {
        if (clickedSwitch === 0) {
          const temp = summaryItems.filter((element) => element !== undefined);
          const toLang =
            toTranslate === SameLang.value ? fromTranslate : toTranslate;
          formData.append("lang1", toLang);
          formData.append("lang2", toTranslate);
          // formData.append("type", summaryType);
          formData.append("length", 0);
          formData.append("target", temp.join(":~:"));
          formData.append("file", uploadedFile);
          apiURL = "/summarize/file/";
        } else if (clickedSwitch === 1) {
          const temp = ["", "For business", "Catchy"];
          formData.append(
            "atmosphere",
            titleCreatorParams.atmosphere === 3
              ? titleCreatorParams.atmosphereText
              : temp[titleCreatorParams.atmosphere]
          );
          formData.append("gender", titleCreatorParams.gender);
          formData.append(
            "age",
            titleCreatorParams.startAge + "~" + titleCreatorParams.endAge
          );
          formData.append("country", titleCreatorParams.country);
          formData.append("purpose", titleCreatorParams.purpose);
          formData.append("title", titleOfSubTitle);
          formData.append("file", uploadedFile);
          apiURL = "/title/file/";
        }
      }

      api
        .post(apiURL, formData)
        .then((res) => {
          const id = res.data.id;
          intervalRef.current = setInterval(checkProcessProgress, 5000, id);
        })
        .catch((err) => {
          clearInterval(intervalRef.current);
          setIsLoading(false);
          setShowToastr(false);
          clearTimeout(toastrTimer.current);
          document.body.style.overflowY = "auto";
          if (err.response) {
            if (err.response.status === 402) {
              setConfirmText(
                <div>
                  <p className="sm:text-[32px] text-[24px] mb-4">
                    {t("creditLimitMessage1")}
                  </p>
                  {!isAuthenticated ? (
                    <p>{t("creditLimitMessage2")}</p>
                  ) : (
                    <p>{t("creditLimitMessage3")}</p>
                  )}
                </div>
              );
              setShowConfirmModal(true);
            } else if (err.response.status === 413) {
              setConfirmText(
                <div>
                  <p className="sm:text-[32px] text-[24px] mb-4">
                    {t("fileSizeLimitMessage1")}
                  </p>
                  <p>
                    {t("fileSizeLimitMessage2")}
                    <br />
                    {t("fileSizeLimitMessage3")}
                  </p>
                </div>
              );
              setShowConfirmModal(true);
            } else {
              console.log("Other error", err.response.status);
            }
          }
        });
    } else {
      const formData = new FormData();
      let apiURL = "";
      if (processType === 1 && translateText.trim()) {
        formData.append("lang1", fromTranslate);
        formData.append("lang2", toTranslate);
        formData.append("tone", tone);
        formData.append("text", translateText);
        formData.append("dictionary_id", currentDictionaryId);
        formData.append("category", textCategory);
        formData.append("gpt4", translateGPT4 ? "1" : "0");
        if (
          // autoTranslation &&
          translateText.length > 0 &&
          translateText.length <= 50
        ) {
          apiURL = "/translate/free/";
        } else {
          handle_translate_stream(formData);
          return;
          // apiURL = "/translate/text/";
        }
      }
      if (processType === 2) {
        formData.append("lang", fromTranslate);
        formData.append("text", translateText);
        formData.append("type", proofToneType);
        formData.append("tone", proofTone);
        formData.append("custom_tone", proofOtherTone);
        formData.append("dictionary_id", currentDictionaryId);
        apiURL = "/proofreading/text/";
      }
      if (processType === 3) {
        if (clickedSwitch === 0) {
          const temp = summaryItems.filter((element) => element !== undefined);
          formData.append("lang1", fromTranslate);
          const toLang =
            toTranslate === SameLang.value ? fromTranslate : toTranslate;
          formData.append("lang2", toLang);
          // formData.append("type", summaryType);
          formData.append("length", 0);
          formData.append("target", temp.join(":~:"));
          formData.append("text", translateText);
          apiURL = "/summarize/text/";
        } else if (clickedSwitch === 1) {
          const temp = ["", "For business", "Catchy"];
          formData.append(
            "atmosphere",
            titleCreatorParams.atmosphere === 3
              ? titleCreatorParams.atmosphereText
              : temp[titleCreatorParams.atmosphere]
          );
          formData.append("gender", titleCreatorParams.gender);
          formData.append(
            "age",
            titleCreatorParams.startAge + "~" + titleCreatorParams.endAge
          );
          formData.append("country", titleCreatorParams.country);
          formData.append("purpose", titleCreatorParams.purpose);
          formData.append("title", titleOfSubTitle);
          formData.append("text", translateText);
          apiURL = "/title/text/";
        }
      }
      api
        .post(apiURL, formData)
        .then((res) => {
          clearInterval(intervalRef.current);
          setIsLoading(false);
          setShowToastr(false);
          clearTimeout(toastrTimer.current);
          document.body.style.overflowY = "auto";
          if (isAuthenticated) dispatch(getUserInfo());
          if (processType === 3 && clickedSwitch === 0) {
            if (summaryItems.length > 0) {
              const temp = res.data.result.split("\n");
              replaceSummary(temp);
            } else {
              const temp = res.data.result.split("\n");
              temp.shift();
              setTranslateTextResult(temp.join("<br/>"));
            }
          } else if (processType === 3 && clickedSwitch === 1) {
            if (!isSubTitleProcess) {
              const temp = res.data.result.split("\n");
              setTitleCreatorResult(
                temp.map((item) => ({ title: item, level: 0 }))
              );
            } else {
              const titles = [...titleCreatorResult];
              const temp = res.data.result.split("\n");
              const childTitle = temp.map((item) => ({
                title: item,
                level: titles[subTitleKey].level + 1,
              }));
              Array.prototype.splice.apply(
                titles,
                [subTitleKey + 1, 0].concat(childTitle)
              );
              setTitleCreatorResult(titles);
              setSubTitleProcess(false);
              setTitleOfSubTitle("");
            }
          } else if (processType === 1 && apiURL.endsWith("/free/")) {
            let result = res.data.result;
            for (let i = 0; i < result.length; i++) {
              let temp = result[i];
              if (temp.startsWith("-") || temp.startsWith("・"))
                temp = temp.substring(1);
              if (i === 0)
                result[i] = `<p class='text-[18px] leading-6 mb-2'>${temp}</p>`;
              else
                result[
                  i
                ] = `<li class='text-[18px] leading-6 mb-2'>${temp}</li>`;
            }
            if (result.length > 1) {
              result = [
                result[0],
                `<p class='text-[18px] leading-6 mb-2'>${t(
                  "otherTranslation"
                )}</p>`,
                "<ul class='list-outside pl-10 list-disc'>",
                ...result.slice(1),
                "</ul>",
              ];
            }
            setTranslateTextResult(result.join(""));
          } else {
            const temp = res.data.result.replace(/\n\n/g, "<br/>");
            setTranslateTextResult(temp.replace(/\n/g, "<br/>"));
          }
          originalTextRef.current.scrollTop = 0;
        })
        .catch((err) => {
          clearInterval(intervalRef.current);
          setIsLoading(false);
          setShowToastr(false);
          clearTimeout(toastrTimer.current);
          document.body.style.overflowY = "auto";

          if (err.response) {
            if (err.response.status === 402) {
              if (err.response.data === "Too many free request count") {
                setConfirmText(
                  <div>
                    <p className="sm:text-[32px] text-[24px] mb-4">
                      {t("freeLimitMessage1")}
                    </p>
                    {!isAuthenticated ? (
                      <p>{t("freeLimitMessage2")}</p>
                    ) : (
                      <p>{t("freeLimitMessage3")}</p>
                    )}
                  </div>
                );
              } else {
                setConfirmText(
                  <div>
                    <p className="sm:text-[32px] text-[24px] mb-4">
                      {t("creditLimitMessage1")}
                    </p>
                    {!isAuthenticated ? (
                      <p>{t("creditLimitMessage2")}</p>
                    ) : (
                      <p>{t("creditLimitMessage3")}</p>
                    )}
                  </div>
                );
              }
              setShowConfirmModal(true);
            } else {
              console.log("Other error", err.response.status);
            }
          }
        });
    }
  };

  const handlePreview = () => {
    setShowPreviewModal(!showPreviewModal);
    window.scrollTo(0, 0);
    if (!showPreviewModal) document.body.style.overflowY = "hidden";
    else document.body.style.overflowY = "scroll";
  };

  const downloadTitles = () => {
    if (titleCreatorResult.length === 0) return;
    let data = titleCreatorResult.map((item) => {
      return "\t".repeat(item.level) + item.title;
    });
    let blob = new Blob([data.join("\r\n")], { type: "text/plain" });
    let a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = "titles.txt";
    a.click();
  };

  const handleChange = (e) => {
    setOldTranslateText(translateText);
    setTranslateText(e.target.value);
  };

  const handleCopyClick = (e) => {
    const text = translationRef.current.innerText;
    navigator.clipboard.writeText(text);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };

  const handlePasteClick = (e) => {
    navigator.clipboard.readText().then((text) => {
      dispatch(detectLanguage(text)).then(() => {
        setOldTranslateText(translateText);
        setTranslateText(text);
      });
    });
  };

  const handleSwapper = () => {
    if (fromTranslate === "Detect language") {
      return;
    }
    if (toTranslate === SameLang.value) {
      return;
    }
    setIsRotated(!isRotated);
    const temp = fromTranslate;
    setFromTranslate(toTranslate);
    setToTranslate(temp);
  };

  const handleDropDownFile = async (e) => {
    e.preventDefault();
    let file;
    setFileUploaded(false);
    if (e.dataTransfer.items) {
      const item = e.dataTransfer.items[0];
      if (item && item.kind === "file") {
        file = item.getAsFile();
      } else {
        return;
      }
    } else {
      file = e.dataTransfer.files[0];
    }
    const filename = file.name.toLowerCase();
    const extention = filename.split(".").pop();
    if (
      extention !== "docx" &&
      extention !== "pptx" &&
      extention !== "xlsx" &&
      extention !== "pdf" &&
      extention !== "txt"
    )
      return;
    if (file.size > userInfo.file_limit * 1024 * 1024) {
      setConfirmText(
        <div>
          <p className="sm:text-[32px] text-[24px] mb-4">
            {t("fileSizeLimitMessage1")}
          </p>
          <p>
            {t("fileSizeLimitMessage2")}
            <br />
            {t("fileSizeLimitMessage3")}
          </p>
        </div>
      );
      setShowConfirmModal(true);
      return;
    }
    if (extention === "pdf") getPdfPageCount(file);
    else if (extention === "docx") getDocxWordCount(file);
    else if (extention === "pptx") getPptxPageCount(file);
    setUploadedFile(file);
    setOriginFileSize(file.size / 1024 / 1024);
    setUploadedFileName(filename);
    setUploadedFileType(extention);
    setFileUploaded(true);
    setProofOtherTone("");
    setProofToneType(0);
  };

  const handleDoucmentUpload = () => {
    const inputElement = documentRef.current;
    setOriginFileSize(0);
    setPageOrWordCount(0);
    if (inputElement) {
      inputElement.click();
    }
  };

  const handleDocChange = async (event) => {
    const file = event.target.files.item(0);

    const filename = file.name.toLowerCase();
    const extention = filename.split(".").pop();
    if (
      extention !== "docx" &&
      extention !== "pptx" &&
      extention !== "xlsx" &&
      extention !== "pdf" &&
      extention !== "txt"
    )
      return;
    if (file.size > userInfo.file_limit * 1024 * 1024) {
      event.target.value = null;
      setConfirmText(
        <div>
          <p className="sm:text-[32px] text-[24px] mb-4">
            {t("fileSizeLimitMessage1")}
          </p>
          <p>
            {t("fileSizeLimitMessage2")}
            <br />
            {t("fileSizeLimitMessage3")}
          </p>
        </div>
      );
      setShowConfirmModal(true);
      return;
    }
    if (extention === "pdf") getPdfPageCount(file);
    else if (extention === "docx") getDocxWordCount(file);
    else if (extention === "pptx") getPptxPageCount(file);
    setUploadedFile(file);
    setOriginFileSize(file.size / 1024 / 1024);
    setUploadedFileName(filename);
    setUploadedFileType(extention);
    setFileUploaded(true);
    setProofOtherTone("");
    setProofToneType(0);
  };

  const handleDownload = (e) => {
    e.preventDefault();

    if (translateTextResult) {
      const text = translationRef.current.innerText;
      const textFile = new Blob([text], {
        type: "text/plain;charset=utf-8",
      });

      const textFileURL = URL.createObjectURL(textFile);
      const textDownloadLink = document.createElement("a");
      textDownloadLink.href = textFileURL;
      textDownloadLink.setAttribute("download", "result.txt");
      document.body.appendChild(textDownloadLink);
      textDownloadLink.click();
      document.body.removeChild(textDownloadLink);
      URL.revokeObjectURL(textFileURL);
    }

    if (translateFileResult) {
      apiWithoutCred
        .get(`${s3_dir}/${translateFileResult}`, {
          responseType: "blob",
        })
        .then((res) => {
          const file = new Blob([res.data], {
            type: "application/octet-stream",
          });
          const fileURL = URL.createObjectURL(file);
          const downloadLink = document.createElement("a");

          let fullName = translateFileResult;
          let underscoreIndex = fullName.indexOf("_");
          const originalName = fullName.substring(underscoreIndex + 1);
          downloadLink.href = fileURL;
          //Change the file name
          const dotIndex = originalName.lastIndexOf(".");
          const baseName = originalName.substring(0, dotIndex);
          const ext = originalName.substring(dotIndex);
          const lang = processType === 2 ? fromTranslate : toTranslate;
          const item = languageList.find((e) => e.value === lang);
          let abbr = "auto";
          if (item) abbr = item.abbr;
          const downloadName = `${baseName}-${abbr}${ext}`;

          downloadLink.setAttribute("download", downloadName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          URL.revokeObjectURL(fileURL);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getDocxWordCount = (file) => {
    if (
      file &&
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      try {
        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
          const arrayBuffer = e.target.result;
          const result = await mammoth.extractRawText({
            arrayBuffer,
          });
          const text = result.value;
          setPageOrWordCount(text.length);
        };
        fileReader.readAsArrayBuffer(file);
      } catch (err) {
        setPageOrWordCount(0);
        console.error(err);
      }
    } else {
      setPageOrWordCount(0);
    }
  };

  const getPptxPageCount = async (file) => {
    if (
      file &&
      file.type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      try {
        const pptx = await pptxParser(file);
        setPageOrWordCount(pptx.slides.length);
      } catch (err) {
        setPageOrWordCount(0);
        console.error(err);
      }
    } else {
      setPageOrWordCount(0);
    }
  };

  const getPdfPageCount = (file) => {
    if (file && file.type === "application/pdf") {
      try {
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          const typedArray = new Uint8Array(fileReader.result);
          const pdf = await pdfjs.getDocument({ data: typedArray }).promise;
          setPageOrWordCount(pdf.numPages);
        };
        fileReader.readAsArrayBuffer(file);
      } catch (err) {
        setPageOrWordCount(0);
        console.error(err);
      }
    } else {
      setPageOrWordCount(0);
    }
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  useEffect(() => {
    setSummaryList(uiLang === "jp" ? summaryListJp : summaryListEn);
  }, [uiLang]);

  useEffect(() => {
    if (originalTextRef.current && translationRef.current) {
      originalTextRef.current.style.height = "auto";
      const h = originalTextRef.current.scrollHeight + "px";
      originalTextRef.current.style.height = h;
      translationRef.current.height = h;
    }
    const text = translateText.trim();
    if (fromTranslate === "Detect language" && text.length > 2) {
      if (detectTimerId.current) {
        clearTimeout(detectTimerId.current);
      }
      const timer = setTimeout(() => {
        dispatch(detectLanguage(text));
        detectTimerId.current = 0;
      }, 500);
      detectTimerId.current = timer;
    }
    if (timeOutId) clearTimeout(timeOutId);
    if (!text.length) {
      setTranslateTextResult("");
      return;
    }

    if (text === oldTranslateText && toTranslate === oldToLanguage) {
      return;
    }

    const id = setTimeout(() => {
      if (userInfo.enterprise_trial) {
        if (userInfo.credit <= userInfo.process_count) {
          setShowEnterpriseRecommendModal(true);
        }
      }
      setOldTranslateText(text);
      setOldToLanguage(toTranslate);
      if (
        // autoTranslation &&
        translateText.length > 0 &&
        translateText.length <= 50 &&
        processType === 1
      ) {
        const formData = new FormData();
        formData.append("lang1", fromTranslate);
        formData.append("lang2", toTranslate);
        formData.append("tone", tone);
        formData.append("text", text);
        formData.append("dictionary_id", currentDictionaryId);
        formData.append("category", textCategory);
        const apiURL = "/translate/free/";
        setIsLoading(true);
        api
          .post(apiURL, formData)
          .then((res) => {
            setIsLoading(false);
            if (text.length > 0) {
              let result = res.data.result;
              for (let i = 0; i < result.length; i++) {
                let temp = result[i];
                if (temp.startsWith("-") || temp.startsWith("・"))
                  temp = temp.substring(1);
                if (i === 0)
                  result[
                    i
                  ] = `<p class='text-[18px] leading-6 mb-2'>${temp}</p>`;
                else
                  result[
                    i
                  ] = `<li class='text-[18px] leading-6 mb-2'>${temp}</li>`;
              }
              if (result.length > 1) {
                result = [
                  result[0],
                  `<p class='text-[18px] leading-6 mb-2'>${t(
                    "otherTranslation"
                  )}</p>`,
                  "<ul class='list-outside pl-10 list-disc'>",
                  ...result.slice(1),
                  "</ul>",
                ];
              }
              setTranslateTextResult(result.join(""));
            } else {
              setTranslateTextResult("");
            }
          })
          .catch((err) => {
            setIsLoading(false);
            if (err.response) {
              if (err.response.status === 402) {
                if (err.response.data === "Too many free request count") {
                  setConfirmText(
                    <div>
                      <p className="sm:text-[32px] text-[24px] mb-4">
                        {t("freeLimitMessage1")}
                      </p>
                      {!isAuthenticated ? (
                        <p>{t("freeLimitMessage2")}</p>
                      ) : (
                        <p>{t("freeLimitMessage3")}</p>
                      )}
                    </div>
                  );
                } else {
                  setConfirmText(
                    <div>
                      <p className="sm:text-[32px] text-[24px] mb-4">
                        {t("creditLimitMessage1")}
                      </p>
                      {!isAuthenticated ? (
                        <p>{t("creditLimitMessage2")}</p>
                      ) : (
                        <p>{t("creditLimitMessage3")}</p>
                      )}
                    </div>
                  );
                }
                setShowConfirmModal(true);
              } else {
                console.log("Other error", err.response.status);
              }
            }
          });
      }
    }, 500);

    setTimeOutId(id);

    return () => {
      clearTimeout(id);
    };
  }, [translateText, fromTranslate, toTranslate]);

  useEffect(() => {
    if (!userInfo.plan) return;
    if (userInfo.plan === "Free") {
      const lastDate = localStorage.get("popup_date");
      if (
        !lastDate ||
        parseInt((Date.now() - lastDate) / 1000 / 60 / 60 / 24) > 7
      ) {
        setIsUpgrade(true);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    const plan = String(userInfo.plan);
    if (userInfo.enterprise_user || plan.startsWith("Pro") || plan === "Test")
      setAutoTranslation(true);
    else setAutoTranslation(false);
    if (translateGPT4 === -1) {
      if (!plan || plan.startsWith("Free")) setTranslateGPT4(0);
      else setTranslateGPT4(1);
    }
  }, [userInfo, fileUploaded]);

  useEffect(() => {
    if (
      (userInfo.character_limit !== -1 &&
        translateText.length > userInfo.character_limit) ||
      (!isAuthenticated && translateText.length > 1000)
    ) {
      setConfirmText(
        <div>
          <p className="sm:text-[32px] text-[24px] mb-4">
            {t("characterLimitMessage1")}
          </p>
          <p>{t("characterLimitMessage2")}</p>
        </div>
      );
      setShowConfirmModal(true);
    }
  }, [translateText.length, userInfo.character_limit]);

  useEffect(() => {
    if (translateText.length === 0) {
      setTranslateTextResult("");
    } else {
      setTranslateGPT4(false);
    }
    if (!fileUploaded) {
      setTranslateFileResult("");
    } else {
      setTranslateGPT4(true);
    }
  }, [translateText, fileUploaded]);

  useEffect(() => {
    setFromTranslate(detectedLanguage);
  }, [detectedLanguage]);

  useEffect(() => {
    if (fromTranslate === toTranslate) {
      if (processType === 3) {
        return;
      }
      const newTranslate =
        fromTranslate.indexOf("English") !== -1 ? "Japanese" : "English (US)";
      setToTranslate(newTranslate);
    }
  }, [fromTranslate, toTranslate, processType]);

  useEffect(() => {
    if (isSubTitleProcess && subTitleKey > -1 && titleOfSubTitle !== "") {
      handleProcess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubTitleProcess, subTitleKey, titleOfSubTitle]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (processType === 2 && event.target.tagName === "INPUT") {
        setShowToneDlg(true);
        return;
      }
      if (!(toneRef.current && toneRef.current.contains(event.target))) {
        setShowToneDlg(false);
      }
      if (!(dicRef.current && dicRef.current.contains(event.target))) {
        setShowDicDlg(false);
      }
      if (proofToneRef.current && proofToneRef.current.contains(event.target)) {
        setShowProofToneDlg(true);
      } else {
        setShowProofToneDlg(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="bg-background-main w-full flex-grow">
      <Helmet>
        <title>Languise - Home</title>
        <meta name="description" content="翻訳、校正、要約機能を備えたアプリ" />
        <link rel="canonical" href="https://languise.com/" />
      </Helmet>
      {showConfirmProcessChangeModal && (
        <ConfirmProcessChangeModal
          onAgree={() => {
            resetState();
            setProcessType(newProcessType);
            setShowConfirmProcessChangeModal(false);
            setTone("");
            setActiveTone(null);
            setTextCategory("");
            setShowToneDlg(false);
            setShowDicDlg(false);
            setCurrentDictionatyId(-1);
            setDictionaryDetail([]);
          }}
          onCancel={() => setShowConfirmProcessChangeModal(false)}
        />
      )}
      {showConfirmModal && (
        <ConfirmModal
          isOpen={showConfirmModal}
          text={confirmText}
          buttonText={
            !isAuthenticated
              ? t("login")
              : userInfo.enterprise_user
              ? t("close")
              : t("choosePlan")
          }
          onAgree={() => {
            if (isAuthenticated) {
              setShowConfirmModal(false);
              if (!userInfo.enterprise_user) {
                setShowUpdatePlanModal(true);
              }
            } else {
              setShowConfirmModal(false);
              setShowUpdatePlanModal(false);
              dispatch(showLoginModal(true));
            }
          }}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}
      {showEnterpriseRecommendModal && (
        <EnterpriseRecommendModal
          isOpen={showEnterpriseRecommendModal && userInfo.enterprise_trial}
          onAgree={() => {
            setShowEnterpriseRecommendModal(false);
            setShowEnterpriseModal(true);
          }}
          onClose={() => setShowEnterpriseRecommendModal(false)}
        />
      )}
      {showEnterpriseModal && (
        <AddEnterpriseModal
          openModal={showEnterpriseModal}
          onClose={() => setShowEnterpriseModal(false)}
        />
      )}
      {showUpdatePlanModal && (
        <UpdatePlanModal
          open={showUpdatePlanModal}
          onClose={() => setShowUpdatePlanModal(false)}
        />
      )}
      {isLoading && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
          <PuffLoader
            css={override}
            size={150}
            color={"#11135C"}
            loading={isLoading}
          />
        </div>
      )}
      {showPreviewModal ? (
        <>
          <PreviewModal
            file={translateFileResult}
            onClose={handlePreview}
            type={processType}
            permission={translateGPT4}
            processType={processType}
            fromTranslate={fromTranslate}
            toTranslate={toTranslate}
          />
          <TranslateModal
            permission={translateGPT4}
            fromLanguage={fromTranslate}
            toLanguage={toTranslate}
          />
        </>
      ) : (
        <>
          <div
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDrop={handleDropDownFile}
            className="w-full flex flex-col sm:gap-[45px] gap-5"
          >
            <div
              className={`relative flex ${
                processType === 1 ? "md:flex-row" : "lg:flex-row"
              } flex-col md:max-w-[1300px] w-full mx-auto sm:gap-[35px] gap-3 md:justify-between  sm:mt-[45px] mt-5 xl:px-0 px-5`}
            >
              <div className="flex sm:max-w-[550px] sm:mx-0 mx-auto w-full sm:justify-start justify-between items-center sm:gap-[14px] py-2 gap-3">
                <button
                  onClick={() => {
                    !isLoading && handleProcessType(1);
                  }}
                  className={`flex justify-center items-center gap-1 ${
                    processType === 1
                      ? "bg-background-clicked"
                      : "bg-white hover:bg-[#e9ebfa]"
                  } sm:h-[48px] h-[35px] sm:min-w-[140px] sm:px-2 px-1 min-w-[100px] font-sans border border-[#CCCCCC] rounded-[10px] xl:text-[20px] sm:text-[16px] text-[13px] leading-[24px] text-[#11135C]`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 36.955 22.838"
                    className="sm:w-8 sm:h-6 w-5 h-3"
                  >
                    <g transform="translate(-81.682 -268.305)">
                      <g transform="translate(83.096 273.924)">
                        <path
                          d="M1048.632,1365.009l6.811-6.7,6.955,6.7"
                          transform="translate(-1048.632 -1358.305)"
                          fill="none"
                          stroke="#11135c"
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                        <path
                          d="M820.5,496.734v13.945h14.411"
                          transform="translate(-813.622 -494.46)"
                          fill="none"
                          stroke="#11135c"
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(95.928 269.305)">
                        <path
                          d="M1062.4,1358.3l-6.811,6.7-6.955-6.7"
                          transform="translate(-1041.104 -1348.791)"
                          fill="none"
                          stroke="#11135c"
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                        <path
                          d="M834.915,510.679V496.734H820.5"
                          transform="translate(-820.504 -496.734)"
                          fill="none"
                          stroke="#11135c"
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                      </g>
                    </g>
                  </svg>
                  {t("translation")}
                </button>
                <button
                  onClick={() => {
                    !isLoading && handleProcessType(2);
                  }}
                  className={`flex justify-center items-center sm:gap-2 gap-1 ${
                    processType === 2
                      ? "bg-background-clicked"
                      : "bg-white hover:bg-[#e9ebfa]"
                  } sm:h-[48px] h-[35px] sm:min-w-[140px] sm:px-2 px-1 min-w-[100px] font-sans border border-[#CCCCCC] rounded-[10px] xl:text-[20px] sm:text-[16px] text-[13px] leading-[24px] text-[#11135C]`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                    className="sm:w-[30px] sm:h-[30px] w-4 h-4"
                  >
                    <g transform="translate(-473 -134)">
                      <g
                        transform="translate(478 139)"
                        fill="none"
                        stroke="#11135c"
                        strokeWidth="2"
                      >
                        <circle cx="10" cy="10" r="10" stroke="none" />
                        <circle cx="10" cy="10" r="9" fill="none" />
                      </g>
                      <line
                        y2="28"
                        transform="translate(488 135)"
                        fill="none"
                        stroke="#11135c"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x2="28"
                        transform="translate(474 149)"
                        fill="none"
                        stroke="#11135c"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <circle
                        cx="4"
                        cy="4"
                        r="4"
                        transform="translate(484 145)"
                        fill="#11135c"
                      />
                      <line
                        x2="4"
                        transform="translate(486 149)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="1"
                      />
                      <line
                        y2="4"
                        transform="translate(488 147)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="1"
                      />
                    </g>
                  </svg>
                  {t("proofreading")}
                </button>
                <button
                  onClick={() => {
                    !isLoading && handleProcessType(3);
                  }}
                  className={`flex justify-center items-center sm:gap-2 gap-1 ${
                    processType === 3
                      ? "bg-background-clicked"
                      : "bg-white hover:bg-[#e9ebfa]"
                  } sm:h-[48px] h-[35px] sm:min-w-[140px] sm:px-2 px-1 min-w-[100px] font-sans border border-[#CCCCCC] rounded-[10px] xl:text-[20px] sm:text-[16px] text-[13px] leading-[24px] text-[#11135C]`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24.082 26.041"
                    className="sm:w-5 sm:h-6 w-3 h-4"
                  >
                    <g transform="translate(-657.078 -136.959)">
                      <path
                        d="M-22363.922-20832v-24.041h22.082v18.137l-5.549,5.9Z"
                        transform="translate(23022 20994)"
                        fill="none"
                        stroke="#11135c"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                      <path
                        d="M-22341.84-20838.363h-6.121v6.352"
                        transform="translate(23022 20994)"
                        fill="none"
                        stroke="#11135c"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                      <line
                        x2="12"
                        transform="translate(663.119 144.5)"
                        fill="none"
                        stroke="#11135c"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x2="12"
                        transform="translate(663.119 149.5)"
                        fill="none"
                        stroke="#11135c"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x2="6"
                        transform="translate(663.119 154.5)"
                        fill="none"
                        stroke="#11135c"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                    </g>
                  </svg>
                  {t("summary")}
                </button>
              </div>
              {processType === 1 && (
                <div className="flex flex-col sm:px-0 px-5 items-end justify-end">
                  <div className="sm:gap-4 px-4 flex sm:flex-row flex-col w-full sm:items-center items-start">
                    <p className="md:text-[16px] text-[14px] text-[#333876]">
                      {t("textType1")}
                    </p>
                    <p className="md:text-[12px] text-[10px] text-[#333876]">
                      {t("textType2")}
                    </p>
                  </div>
                  <div className="gap-4 flex flex-row w-full md:text-[16px] text-[13px] text-[#333876] items-center">
                    <input
                      value={textCategory}
                      onChange={(e) => setTextCategory(e.target.value)}
                      type="text"
                      placeholder={t("textType3")}
                      className={`sm:w-[450px] w-full sm:h-[40px] h-[33px] px-2 border border-[#8B8B8B] rounded-[10px] placeholder:text-[#CCCCCC] outline-none`}
                    />
                  </div>
                </div>
              )}
              {processType === 2 && (
                <div className="relative flex sm:flex-row flex-col sm:items-center sm:gap-5 gap-2">
                  <div
                    onClick={() => {
                      setProofToneType(0);
                      setProofTone(0);
                      setProofOtherTone("");
                    }}
                    className="gap-4 flex cursor-pointer sm:px-0 px-5 items-center"
                  >
                    <input
                      type="checkbox"
                      checked={proofToneType === 0}
                      className="md:w-5 md:h-5 w-4 h-4"
                    />
                    <p className="md:text-[16px] text-[14px] text-[#333876]">
                      {t("proofText1")}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      if (!fileUploaded) {
                        setProofToneType(1);
                        setProofTone(0);
                        setProofOtherTone("");
                      }
                    }}
                    className={`gap-4 flex sm:px-0 px-5 items-center ${
                      fileUploaded ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                  >
                    <input
                      type="checkbox"
                      checked={proofToneType === 1}
                      readOnly={fileUploaded}
                      className="md:w-5 md:h-5 w-4 h-4"
                    />
                    <p
                      className={`md:text-[16px] text-[14px] ${
                        fileUploaded ? "text-[#707070]" : "text-[#333876]"
                      }`}
                    >
                      {t("proofText2")}
                    </p>
                  </div>
                  <div className="relative gap-4 flex flex-col items-center justify-center">
                    <div
                      className={`sm:text-[16px] sm:w-auto w-full text-[13px] justify-center items-center ${
                        fileUploaded ? "text-[#707070]" : "text-[#333876]"
                      }`}
                    >
                      <button
                        onClick={() => {
                          !fileUploaded &&
                            setShowProofToneDlg(!showProofToneDlg);
                        }}
                        className={`sm:h-[40px] sm:w-[200px] w-full h-[33px] border border-[#8B8B8B] rounded-[10px] bg-white flex flex-row items-center justify-center gap-2 ${
                          fileUploaded
                            ? "cursor-not-allowed bg-[#DADADA]"
                            : "cursor-pointer"
                        }`}
                      >
                        <div className="flex justify-center w-full">
                          {proofToneList[proofTone]}
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 15 12"
                          className="md:w-[15px] md:h-[12px] w-[12px] h-[10px] mr-4"
                        >
                          <path
                            d="M7.5,0,15,12H0Z"
                            transform="translate(15 12) rotate(180)"
                            fill={`${fileUploaded ? "#707070" : "#4b5090"}`}
                          />
                        </svg>
                      </button>
                    </div>
                    {showProofToneDlg && (
                      <div
                        ref={proofToneRef}
                        className="absolute font-sans sm:w-[320px] w-full sm:top-10 top-8 sm:-left-24 sm:px-5 z-10"
                      >
                        <div className="w-full flex flex-col border border-[#6E6E6E] bg-white">
                          {proofToneList.map((item, index) => (
                            <div
                              key={index}
                              onClick={(e) => {
                                setProofTone(index);
                                if (e.target.tagName !== "INPUT")
                                  setShowProofToneDlg(false);
                                if (index > 0) setProofToneType(2);
                                else setProofToneType(0);
                                if (index !== 6) setProofOtherTone("");
                              }}
                              className="flex items-center px-4 text-[#333876] text-[16px] leading-[32px] h-[50px] border-t border-[#CCCCCC] cursor-pointer hover:bg-[#F0F1F8]"
                            >
                              {item}
                              {index === 6 && (
                                <input
                                  type="text"
                                  value={proofOtherTone}
                                  onChange={(e) =>
                                    setProofOtherTone(e.target.value)
                                  }
                                  className="ml-5 pl-2 w-44 border outline-none"
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {processType === 3 && (
                <div
                  className={`sm:flex w-full justify-between items-end sm:px-0 px-5  ${
                    clickedSwitch === 0
                      ? "2xl:gap-[50px] xl:gap-[25px] md:gap-[10px]"
                      : "gap-2"
                  }`}
                >
                  <div className="flex max-w-[150px] w-full">
                    <SwitchButton
                      setClickedSwitch={setClickedSwitch}
                      value={clickedSwitch}
                      text1={t("summary")}
                      text2={t("title")}
                    />
                  </div>
                  {clickedSwitch === 0 && (
                    <div className="flex flex-col w-full gap-2 sm:-mb-4">
                      <div className="sm:text-[20px] text-[14px] font-semibold text-[#505050] text-start">
                        {t("summaryText1")}
                      </div>
                      <div className="flex sm:flex-row flex-col justify-between sm:gap-0 gap-2">
                        <SummarySelect
                          value={summaryItems[0]}
                          number={0}
                          onChange={handleSummarySelected}
                        />
                        <SummarySelect
                          value={summaryItems[1]}
                          number={1}
                          onChange={handleSummarySelected}
                        />
                        <SummarySelect
                          value={summaryItems[2]}
                          number={2}
                          onChange={handleSummarySelected}
                        />
                        <SummarySelect
                          value={summaryItems[3]}
                          number={3}
                          onChange={handleSummarySelected}
                        />
                        <SummarySelect
                          value={summaryItems[4]}
                          number={4}
                          onChange={handleSummarySelected}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            {clickedSwitch === 1 && processType === 3 && (
              <CheckBoxContent onChange={seTitleCreatorParams} />
            )}

            {clickedSwitch === 1 && processType === 3 ? (
              <>
                <div className="sm:flex flex-row md:max-w-[1300px] w-full 2xl:px-0 gap-[28px] mx-auto sm:mt-0 mt-[-35px]">
                  <div className="flex flex-col flex-1 w-full h-[250px] bg-white sm:rounded-[10px] rounded-none">
                    <div className="flex flex-1 w-full md:px-[33.75px] px-[20px] md:pt-[29.5px] md:pb-[29.5px] py-[21px] pb-2 relative justify-center">
                      <textarea
                        value={translateText}
                        onChange={handleChange}
                        disabled={fileUploaded}
                        ref={originalTextRef}
                        placeholder={`${
                          fileUploaded ? "" : t("textareaPlaceholder1")
                        }`}
                        className={`border-none w-full focus:outline-none focus:border-transparent ${
                          translateText.length > 50
                            ? "text-[14px]"
                            : "text-[20px]"
                        } text-start font-sans resize-none placeholder:text-center md:placeholder:text-[16px] placeholder:text-[12px] ${
                          fileUploaded ? "!bg-transparent" : ""
                        }`}
                      />
                      {!translateText.length > 0 && !fileUploaded ? (
                        <>
                          <div className="absolute top-[80px] sm:flex flex-col justify-center items-center hidden">
                            <button
                              onClick={handleDoucmentUpload}
                              className="xl:w-[280px] w-[230px] xl:h-[60px] h-[52px] flex justify-center items-center font-sans text-white bg-[#CCCCCC] hover:bg-[#b7b7b7] xl:text-[20px] text-[16px] rounded-[10px]"
                            >
                              {t("uploadFile")}
                              <input
                                type="file"
                                onChange={(event) => {
                                  handleDocChange(event);
                                }}
                                style={{ display: "none" }}
                                accept=".txt, .pdf, .docx, .xlsx, .pptx"
                                ref={documentRef}
                              />
                            </button>
                            <p className="text-[#CCCCCC] xl:text-[20px] text-[16px] mt-[30px] font-sans">
                              {t("dragAndDrop")}
                            </p>
                          </div>
                          <div className="absolute top-[90px] sm:hidden flex-col justify-center items-center flex">
                            <button onClick={handleDoucmentUpload}>
                              <img
                                className="w-[65px] h-[65px]"
                                src="/assets/upload.png"
                                alt="Upload"
                              />
                              <input
                                type="file"
                                onChange={(event) => {
                                  handleDocChange(event);
                                }}
                                style={{ display: "none" }}
                                accept=".txt, .pdf, .docx, .xlsx, .pptx"
                                ref={documentRef}
                              />
                            </button>
                            <p className="text-[#CCCCCC] text-[12px] mt-[10px] font-sans">
                              {t("upload")}
                            </p>
                          </div>
                        </>
                      ) : null}
                      {fileUploaded && translateText.length === 0 ? (
                        <div className="absolute top-[80px] max-w-[300px] w-full h-[130px] flex flex-col justify-center items-center bg-[#f1f1f1] rounded-[10px]">
                          <img
                            onClick={() => {
                              setFileUploaded(false);
                              resetState();
                            }}
                            className="absolute top-[6px] right-[6px] w-[18px] h-[18px] cursor-pointer"
                            src="/assets/cancel-circle.png"
                            alt="Cancel"
                          />
                          <img
                            className="w-[60x] h-[80px]"
                            src={`/assets/${
                              (uploadedFileType === "pdf" && "pdf.svg") ||
                              (uploadedFileType === "docx" && "word.svg") ||
                              (uploadedFileType === "pptx" &&
                                "powerpoint.svg") ||
                              (uploadedFileType === "xlsx" && "excel.svg") ||
                              (uploadedFileType === "txt" && "text.svg")
                            }`}
                            alt={uploadedFileType}
                          />
                          <p className="text-[#7e7e7e] text-[18px] font-sans">
                            {uploadedFileName}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="flex w-full justify-end md:pl-[24px] pl-[20.3px] pr-[17.5px]">
                      <p className="flex text-[#CCCCCC] xl:text-[20px] lg:text-[18px] text-[15px] leading-[30px] sm:mb-[18px] mb-4">
                        {translateText.length}
                      </p>
                    </div>
                  </div>

                  <div className="flex max-w-full justify-start items-end sm:px-0 px-5 sm:mt-0 mt-4">
                    <button
                      onClick={handleProcess}
                      disabled={translateText.length === 0 && !fileUploaded}
                      className={`ease-in duration-150 lg:w-[141px] md:w-[110px] sm:w-[100px] w-full ${
                        translateText.length > 0 || fileUploaded
                          ? "bg-background-other hover:bg-[#11145cdc]"
                          : "bg-[#B9B9B9]"
                      } rounded-[10px] font-sans flex justify-center items-center lg:h-[50px] md:h-[43px] h-[35px] lg:text-[20px] md:text-[17px] text-[13px] text-white`}
                    >
                      {t("create")}
                    </button>
                  </div>
                </div>
                <div className="m-auto w-full max-w-[1300px] sm:mb-8">
                  <div className="flex justify-between pl-[20px] text-[#4B5090] font-sans text-start">
                    <p className="w-full md:mb-[22px] mb-2 sm:text-[20px] text-[18px] font-semibold">
                      {t("titleResults")}
                    </p>
                    <div className="w-full md:flex flex-row justify-end mt-3 hidden">
                      <button
                        onClick={downloadTitles}
                        className={`flex flex-row justify-center w-[160px] h-[30px] rounded-[10px] ${
                          titleCreatorResult.length === 0
                            ? "bg-[#B9B9B9]"
                            : "bg-background-other hover:bg-[#11145cdc]"
                        } text-center text-white text-[10px] font-sans items-center`}
                      >
                        <svg
                          className="mt-1 mr-1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="8.559"
                          height="11.222"
                          viewBox="0 0 8.559 11.222"
                        >
                          <g id="Group_105" data-name="Group 105">
                            <path
                              d="M0,0H3.435V6H0Z"
                              transform="translate(2.575)"
                              fill="#fff"
                            />
                            <path
                              d="M4.279,0,8.559,4.279H0Z"
                              transform="translate(8.559 10) rotate(180)"
                              fill="#fff"
                            />
                          </g>
                          <rect
                            width="8.559"
                            height="1.223"
                            transform="translate(0 10)"
                            fill="#fff"
                          />
                        </svg>
                        <p>{t("downloadResults")}</p>
                      </button>
                    </div>
                  </div>
                  <div className="relative w-full min-h-[150px] bg-white sm:rounded-[10px] rounded-none md:mb-[15px] px-5 py-[25px]">
                    {titleCreatorResult.map((item, index) => (
                      <div
                        key={index}
                        className={`flex w-full text-start items-center sm:gap-5 gap-2 h-10`}
                        style={{ paddingLeft: `${item.level * 20}px` }}
                      >
                        <button
                          onClick={() => {
                            setTitleOfSubTitle(item.title);
                            setSubTitleKey(index);
                            setSubTitleProcess(true);
                          }}
                          className="sm:max-w-[60px] max-w-[40px] w-full h-[26px] bg-[#EAEAEA] hover:bg-[#CCCCCC] border border-[#707070] rounded-[5px] sm:text-[12px] text-[10px] text-[#6E6E6E]"
                        >
                          {t("recreate")}
                        </button>
                        <div className="sm:text-[16px] text-[12px] text-[#505050]">
                          {item.title}
                        </div>
                      </div>
                    ))}
                    {titleCreatorResult.length > 0 && (
                      <div className="absolute right-5 top-5">
                        <button className="flex gap-3" onClick={resetResult}>
                          <p className="md:text-[16px] text-[12px] text-[#333876]">
                            {t("closeResult")}
                          </p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24.875"
                            height="24.875"
                            viewBox="0 0 24.875 24.875"
                            className="md:w-[24px] md:h-[24px] w-[20px] h-[20px]"
                          >
                            <g transform="translate(-362.859 -1379.098) rotate(45)">
                              <line
                                x2="27.179"
                                transform="translate(1235.75 718.589)"
                                fill="none"
                                stroke="#4b5090"
                                strokeLinecap="round"
                                strokeWidth="2"
                              />
                              <line
                                y1="27.179"
                                transform="translate(1249.339 705)"
                                fill="none"
                                stroke="#4b5090"
                                strokeLinecap="round"
                                strokeWidth="2"
                              />
                            </g>
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="w-full md:hidden flex flex-row justify-end px-[20px] my-[15px]">
                    <button
                      onClick={downloadTitles}
                      className={`flex flex-row justify-center w-[160px] h-[30px] rounded-[10px] ${
                        titleCreatorResult.length === 0
                          ? "bg-[#B9B9B9]"
                          : "bg-background-other hover:bg-[#11145cdc]"
                      } text-center text-white text-[10px] font-sans items-center`}
                    >
                      <svg
                        className="mt-1 mr-1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.559"
                        height="11.222"
                        viewBox="0 0 8.559 11.222"
                      >
                        <g id="Group_105" data-name="Group 105">
                          <path
                            d="M0,0H3.435V6H0Z"
                            transform="translate(2.575)"
                            fill="#fff"
                          />
                          <path
                            d="M4.279,0,8.559,4.279H0Z"
                            transform="translate(8.559 10) rotate(180)"
                            fill="#fff"
                          />
                        </g>
                        <rect
                          width="8.559"
                          height="1.223"
                          transform="translate(0 10)"
                          fill="#fff"
                        />
                      </svg>
                      <p>{t("downloadResults")}</p>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div
                className={`relative md:grid grid-cols-2 flex-row gap-[19px] md:max-w-[1300px] md:mt-0 sm:-mt-[22px] mt-0 w-full mx-auto ${
                  processType === 3 ? "mb-[50px]" : "mb-0"
                }`}
                id="mainArea"
              >
                <div
                  className={`flex flex-col flex-1 w-full sm:min-h-[500px] min-h-[350px] bg-white sm:rounded-[10px] rounded-none relative`}
                >
                  <div className="grid grid-cols-3 md:flex w-full justify-between max-w-full sm:pl-10 pl-7 sm:pr-12 pr-7 h-[61.5px] border-b border-[#E9E9F0] items-center">
                    <LanguageSelect
                      value={fromTranslate}
                      onChange={setFromTranslate}
                      isDetectShow={true}
                      initialLangs={initialFromLanguages}
                      onChangeLangs={onChangeFromLangs}
                      isEnteredText={!!translateText.length}
                    />
                    <div className="flex flex-row items-center md:absolute right-4">
                      <div className="sm:hidden relative z-20">
                        {translateText.length > 0 && (
                          <div className="flex justify-end pr-4">
                            <button className="flex gap-3" onClick={resetState}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24.875"
                                height="24.875"
                                viewBox="0 0 24.875 24.875"
                                className="md:w-[24px] md:h-[24px] w-[20px] h-[20px]"
                              >
                                <g transform="translate(-362.859 -1379.098) rotate(45)">
                                  <line
                                    x2="27.179"
                                    transform="translate(1235.75 718.589)"
                                    fill="none"
                                    stroke="#4b5090"
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                  />
                                  <line
                                    y1="27.179"
                                    transform="translate(1249.339 705)"
                                    fill="none"
                                    stroke="#4b5090"
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                  />
                                </g>
                              </svg>
                            </button>
                          </div>
                        )}
                      </div>
                      {processType !== 2 && (
                        <div
                          onClick={handleSwapper}
                          className="flex items-center cursor-pointer relative z-20 mx-auto md:mx-0"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 23.411 32.411"
                            className="w-4 md:w-6"
                          >
                            <g transform="translate(-897.794 -365.794)">
                              <line
                                x2="22"
                                transform="translate(898.5 373.5)"
                                fill="none"
                                stroke="#4b5090"
                                strokeLinecap="round"
                                strokeWidth="1"
                              />
                              <line
                                x2="8"
                                y2="7"
                                transform="translate(912.5 366.5)"
                                fill="none"
                                stroke="#4b5090"
                                strokeLinecap="round"
                                strokeWidth="1"
                              />
                              <line
                                y1="7"
                                x2="8"
                                transform="translate(912.5 373.5)"
                                fill="none"
                                stroke="#4b5090"
                                strokeLinecap="round"
                                strokeWidth="1"
                              />
                            </g>
                            <g transform="translate(0.706 17.706)">
                              <line
                                x1="22"
                                transform="translate(0 7)"
                                fill="none"
                                stroke="#4b5090"
                                strokeLinecap="round"
                                strokeWidth="1"
                              />
                              <line
                                x1="8"
                                y2="7"
                                fill="none"
                                stroke="#4b5090"
                                strokeLinecap="round"
                                strokeWidth="1"
                              />
                              <line
                                x1="8"
                                y1="7"
                                transform="translate(0 7)"
                                fill="none"
                                stroke="#4b5090"
                                strokeLinecap="round"
                                strokeWidth="1"
                              />
                            </g>
                          </svg>
                        </div>
                      )}
                    </div>
                    {processType !== 2 && (
                      <LanguageSelect
                        value={toTranslate}
                        onChange={setToTranslate}
                        className="md:hidden pr-0 ml-auto md:ml-0"
                        isSameShow={true}
                        auto={false}
                      />
                    )}
                  </div>
                  <div className="flex flex-1 w-full md:px-[33.75px] px-[20px] md:pt-[29.5px] md:pb-[29.5px] py-[21px] pb-2 relative justify-center">
                    {!(fileUploaded && translateFileResult.length > 0) && (
                      <textarea
                        value={translateText}
                        onChange={handleChange}
                        disabled={fileUploaded}
                        ref={originalTextRef}
                        className={`border-none w-full pr-4 focus:outline-none focus:border-transparent h-auto overflow-hidden text-[18px] font-sans resize-none placeholder:max-w-full placeholder:w-full lg:placeholder:text-[16px] placeholder:text-[12px] ${
                          fileUploaded ? "!bg-transparent" : ""
                        }`}
                        placeholder={`${
                          fileUploaded ? "" : placeholderText[processType - 1]
                        }`}
                      />
                    )}
                    {!translateText.length > 0 && !fileUploaded ? (
                      <div className="absolute top-[150px] sm:flex flex-col justify-center items-center hidden">
                        {processType === 2 && (
                          <div className="relative -top-10 lg:text-[16px] text-[12px] text-[#333876]">
                            {t("proofText3")}
                            <br />
                            {t("proofText4")}
                          </div>
                        )}
                        <button
                          onClick={handleDoucmentUpload}
                          className="xl:w-[280px] w-[230px] xl:h-[60px] h-[52px] flex justify-center items-center font-sans text-white bg-[#CCCCCC] hover:bg-[#b7b7b7] xl:text-[20px] text-[16px] rounded-[10px]"
                        >
                          {t("uploadFile")}
                          <input
                            type="file"
                            onChange={(event) => {
                              handleDocChange(event);
                            }}
                            style={{ display: "none" }}
                            accept=".txt, .pdf, .docx, .xlsx, .pptx"
                            ref={documentRef}
                          />
                        </button>
                        <p className="text-[#CCCCCC] xl:text-[20px] text-[16px] mt-[30px] font-sans">
                          {t("dragAndDrop")}
                        </p>
                      </div>
                    ) : null}
                    {!translateText.length > 0 && !fileUploaded ? (
                      <div className="absolute top-[90px] sm:hidden flex-col justify-center items-center flex">
                        {processType === 2 && (
                          <div className="relative -top-5 text-[12px] text-[#333876]">
                            {t("proofText3")}
                            <br />
                            {t("proofText4")}
                          </div>
                        )}
                        <button onClick={handleDoucmentUpload}>
                          <img
                            className="w-[65px] h-[65px]"
                            src="/assets/upload.png"
                            alt="Upload"
                          />
                          <input
                            type="file"
                            onChange={(event) => {
                              handleDocChange(event);
                            }}
                            style={{ display: "none" }}
                            accept=".txt, .pdf, .docx, .xlsx, .pptx"
                            ref={documentRef}
                          />
                        </button>
                        <p className="text-[#CCCCCC] text-[12px] mt-[10px] font-sans">
                          {t("upload")}
                        </p>
                      </div>
                    ) : null}
                    {fileUploaded && translateText.length === 0 && (
                      <>
                        <div className="absolute md:top-[85px] top-[30px] max-w-[300px] w-full md:h-[130px] h-[100px] flex flex-col justify-center items-center bg-[#f1f1f1] rounded-[10px]">
                          <img
                            onClick={() => {
                              setFileUploaded(false);
                              resetState();
                            }}
                            className="absolute top-[6px] right-[6px] w-[18px] h-[18px] cursor-pointer"
                            src="/assets/cancel-circle.png"
                            alt="Cancel"
                          />
                          <img
                            className="md:w-[60px] md:h-[80px] w-[40px] h-[60px]"
                            src={`/assets/${
                              (uploadedFileType === "pdf" && "pdf.svg") ||
                              (uploadedFileType === "docx" && "word.svg") ||
                              (uploadedFileType === "pptx" &&
                                "powerpoint.svg") ||
                              (uploadedFileType === "xlsx" && "excel.svg") ||
                              (uploadedFileType === "txt" && "text.svg")
                            }`}
                            alt={uploadedFileType}
                          />
                          <p className="text-[#7e7e7e] text-[18px] max-w-[280px] w-full font-sans truncate">
                            {uploadedFileName}
                          </p>
                        </div>
                        <div className="absolute md:top-[230px] top-[140px] sm:max-w-[440px] max-w-[320px] w-full flex flex-col sm:text-[14px] text-[10px] text-[#333876] text-start items-start">
                          {processType === 1 && uploadedFileType === "pdf" && (
                            <p>{t("uploadWarning1")}</p>
                          )}
                          {processType === 2 && uploadedFileType === "pdf" && (
                            <p>{t("uploadWarning2")}</p>
                          )}
                          {processType === 1 &&
                          translateGPT4 &&
                          userInfo.plan &&
                          userInfo.plan !== "Free" ? (
                            <p>
                              {(uploadedFileType === "pdf" ||
                                uploadedFileType === "pptx") &&
                                pageOrWordCount > 10 &&
                                t("uploadWarning3")}
                              {uploadedFileType === "docx" &&
                                pageOrWordCount > 10000 &&
                                t("uploadWarning4")}
                            </p>
                          ) : null}
                        </div>
                        <div className="absolute flex flex-row gap-5 sm:bottom-5 bottom-2 mx-auto sm:text-[16px] text-[12px] text-[#333876] font-semibold">
                          <span>{parseFloat(originFileSize).toFixed(2)}MB</span>
                          {(uploadedFileType === "pdf" ||
                            uploadedFileType === "pptx") && (
                            <span>
                              {pageOrWordCount}
                              {t("page")}
                            </span>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="flex mb-3 items-center justify-between md:pl-[24px] pl-[20.3px] pr-[17.5px]">
                    <div ref={btnGroupRef} className="flex gap-[14px]">
                      <button
                        onClick={() => {
                          setShowToneDlg(true);
                        }}
                        className={`flex flex-col gap-[4.5px] p-1 justify-center items-center ${
                          processType === 1 ? "cursor-pointer" : "hidden"
                        } ${tone !== "" ? "bg-[#555a96] rounded-md" : ""}`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 32 28"
                          className="h-6 md:h-8"
                        >
                          <g transform="translate(1 1)">
                            <path
                              d="M36.432,20.086c0,6.952-6.477,12.586-14.466,12.586a16.172,16.172,0,0,1-6.457-1.321L7.5,33.68l3.487-5.4a11.633,11.633,0,0,1-3.487-8.2C7.5,13.134,13.977,7.5,21.966,7.5S36.432,13.134,36.432,20.086Z"
                              transform="translate(-7.5 -7.5)"
                              fill="none"
                              stroke={tone === "" ? "#4b5090" : "#FFFFFF"}
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                          </g>
                        </svg>
                        <p
                          className={`md:text-[9px] w-full text-[7px] ${
                            tone === "" ? "text-[#8B8B8B]" : "text-white"
                          } font-sans`}
                        >
                          {t("toneSelection")}
                        </p>
                      </button>
                      <button
                        onClick={handleDictionaryOpen}
                        className={`flex flex-col gap-[4.5px] p-1 justify-center items-center relative ${
                          processType === 3 ? "hidden" : "cursor-pointer"
                        } ${
                          currentDictionaryId !== -1
                            ? "bg-[#555a96] rounded-md"
                            : ""
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 31 29"
                          className="h-6 md:h-8"
                        >
                          <g
                            fill="none"
                            stroke={
                              currentDictionaryId === -1 ? "#4b5090" : "#FFFFFF"
                            }
                            strokeWidth="2"
                          >
                            <circle cx="14" cy="14" r="14" stroke="none" />
                            <circle cx="14" cy="14" r="13" fill="none" />
                          </g>
                          <g transform="translate(9 9)">
                            <line
                              x2="10"
                              transform="translate(0 5)"
                              fill="none"
                              stroke={
                                currentDictionaryId === -1
                                  ? "#4b5090"
                                  : "#FFFFFF"
                              }
                              strokeLinecap="round"
                              strokeWidth="2"
                            />
                            <line
                              y1="10"
                              transform="translate(5)"
                              fill="none"
                              stroke={
                                currentDictionaryId === -1
                                  ? "#4b5090"
                                  : "#FFFFFF"
                              }
                              strokeLinecap="round"
                              strokeWidth="2"
                            />
                          </g>
                        </svg>
                        <p
                          className={`md:text-[9px] w-full text-[7px] ${
                            currentDictionaryId === -1
                              ? "text-[#8B8B8B]"
                              : "text-white"
                          } font-sans`}
                        >
                          {t("dictSelection")}
                        </p>
                      </button>
                      {!fileUploaded && (
                        <button
                          onClick={handlePasteClick}
                          className="flex flex-col gap-[4.5px] justify-center items-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 22.537 27.782"
                            className="h-6 md:h-8"
                          >
                            <g transform="translate(1)">
                              <g
                                transform="translate(3.537)"
                                fill="#fff"
                                stroke="#4b5090"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              >
                                <rect width="18" height="23" stroke="none" />
                                <rect
                                  x="1"
                                  y="1"
                                  width="16"
                                  height="21"
                                  fill="none"
                                />
                              </g>
                              <path
                                d="M1055.234,704.341v16.637H1071.9"
                                transform="translate(-1055.234 -694.196)"
                                fill="none"
                                stroke="#4b5090"
                                strokeLinecap="round"
                                strokeWidth="2"
                              />
                            </g>
                          </svg>
                          <p className="md:text-[9px] text-[7px] text-[#8B8B8B]">
                            {t("paste")}
                          </p>
                        </button>
                      )}
                    </div>
                    <div className="relative flex items-start md:gap-[23.5px] gap-[10.5px]">
                      <p className="text-[#CCCCCC] xl:text-[20px] lg:text-[18px] text-[15px] leading-[30px] mt-2 md:mt-4">
                        {fileUploaded && uploadedFileType === "docx"
                          ? pageOrWordCount
                          : translateText.length}
                      </p>
                      <button
                        onClick={handleProcess}
                        disabled={translateText.length === 0 && !fileUploaded}
                        className={`ease-in duration-150 lg:w-[141px] md:w-[110px] w-[100px] ${
                          translateText.length > 0 || fileUploaded
                            ? "bg-background-other hover:bg-[#11145cdf]"
                            : "bg-[#6E6E6E]"
                        } rounded-[10px] font-sans flex justify-center items-center lg:h-[50px] md:h-[43px] h-[35px] lg:text-[20px] md:text-[17px] text-[13px] text-white`}
                      >
                        {processType === 1
                          ? t("translate")
                          : processType === 2
                          ? t("proofread")
                          : t("summarize")}
                      </button>
                      {processType === 1 &&
                      translateText.length > 0 &&
                      translateText.length <= 50 ? (
                        <div className="absolute text-[#11135C] sm:text-[12px] text-[10px] right-0 sm:-top-8 -top-5">
                          {t("notConsume")}
                        </div>
                      ) : null}
                      {processType === 1 &&
                        (translateText.length > 50 ||
                          (fileUploaded &&
                            uploadedFileType !== "xlsx" &&
                            uploadedFileType !== "txt")) && (
                          <>
                            {translateGPT4 && translateText.length > 50 ? (
                              <div className="absolute -top-14 right-0 text-[12px] text-[#11135C]">
                                {t("useConsume")}
                              </div>
                            ) : null}
                            <div className="absolute -top-10 right-0">
                              <SwitchButton
                                setClickedSwitch={setTranslateGPT4}
                                value={translateGPT4 ? 1 : 0}
                                text1={t("normal")}
                                text2={t("high")}
                                readonly={
                                  fileUploaded &&
                                  ((uploadedFileType === "docx" &&
                                    pageOrWordCount <= 10000) ||
                                    pageOrWordCount <= 11)
                                }
                              />
                              {(!userInfo.plan || userInfo.plan === "Free") && (
                                <div
                                  onClick={() => {
                                    setConfirmText(
                                      <div>
                                        <p className="sm:text-[32px] text-[24px] mb-4">
                                          {t("translationMessage")}
                                        </p>
                                        <p>{t("upgradePlan")}</p>
                                      </div>
                                    );
                                    setShowConfirmModal(true);
                                  }}
                                  className="absolute top-0 left-0 w-full h-full bg-gray-100 bg-opacity-50 rounded-full"
                                ></div>
                              )}
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </div>
                <div
                  className={`sm:flex flex-col flex-1 w-full sm:min-h-[500px] min-h-[350px] bg-white sm:rounded-[10px] rounded-none`}
                >
                  <div className="sm:flex sm:flex-row justify-between pl-10 pr-[22px] h-[61.5px] border-b border-[#E9E9F0] items-center hidden">
                    <div className="w-full flex flex-row justify-between items-end h-full">
                      {processType !== 2 && (
                        <LanguageSelect
                          value={toTranslate}
                          onChange={setToTranslate}
                          auto={false}
                          initialLangs={initialToLanguages}
                          onChangeLangs={onChangeToLangs}
                          isSameShow={processType === 3}
                        />
                      )}
                      {translateTextResult.length > 0 && (
                        <div className="flex w-full justify-end">
                          <button className="flex gap-3" onClick={resetResult}>
                            <p className="md:text-[16px] text-[12px] text-[#333876]">
                              {t("closeResult")}
                            </p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24.875"
                              height="24.875"
                              viewBox="0 0 24.875 24.875"
                              className="md:w-[24px] md:h-[24px] w-[20px] h-[20px]"
                            >
                              <g transform="translate(-362.859 -1379.098) rotate(45)">
                                <line
                                  x2="27.179"
                                  transform="translate(1235.75 718.589)"
                                  fill="none"
                                  stroke="#4b5090"
                                  strokeLinecap="round"
                                  strokeWidth="2"
                                />
                                <line
                                  y1="27.179"
                                  transform="translate(1249.339 705)"
                                  fill="none"
                                  stroke="#4b5090"
                                  strokeLinecap="round"
                                  strokeWidth="2"
                                />
                              </g>
                            </svg>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="relative flex flex-1 w-full sm:h-full min-h-[300px] md:px-[33.75px] px-[20px] md:py-[29.5px] py-[21px] sm:border-none border-t border-[#E9E9F0]">
                    {showToastr && (
                      <div className="absolute flex h-full m-auto sm:px-20 px-8">
                        <div className="flex flex-col sm:mt-20 mt-10 gap-4 text-[#333876]">
                          <h4 className="text-[32px]">{t("processing")}</h4>
                          <p>{t("processWarning")}</p>
                        </div>
                      </div>
                    )}
                    {/* Translate Text Result */}
                    {!isLoading &&
                    translateFileResult.length > 0 &&
                    uploadedFileType !== "xlsx" ? (
                      <div className="flex flex-col w-full h-full">
                        <div className="flex flex-row justify-end">
                          <button className="flex gap-3" onClick={resetState}>
                            <p className="sm:flex hidden md:text-[16px] text-[12px] text-[#333876]">
                              {t("closeResult")}
                            </p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24.875"
                              height="24.875"
                              viewBox="0 0 24.875 24.875"
                              className="md:w-[24px] md:h-[24px] w-[20px] h-[20px]"
                            >
                              <g transform="translate(-362.859 -1379.098) rotate(45)">
                                <line
                                  x2="27.179"
                                  transform="translate(1235.75 718.589)"
                                  fill="none"
                                  stroke="#4b5090"
                                  strokeLinecap="round"
                                  strokeWidth="4"
                                />
                                <line
                                  y1="27.179"
                                  transform="translate(1249.339 705)"
                                  fill="none"
                                  stroke="#4b5090"
                                  strokeLinecap="round"
                                  strokeWidth="4"
                                />
                              </g>
                            </svg>
                          </button>
                        </div>
                        <div className="flex flex-col w-full sm:min-h-full min-h-[200px] justify-center items-center">
                          {resultFileSize < 20 ? (
                            <button onClick={handlePreview}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 120 126.267"
                                className="md:w-[120px] md:h-[126px] w-[90px] h-[95px]"
                              >
                                <defs>
                                  <clipPath id="clipPath">
                                    <rect
                                      id="Rectangle_143"
                                      data-name="Rectangle 143"
                                      width="120"
                                      height="126.267"
                                      transform="translate(0 0)"
                                      fill="none"
                                    />
                                  </clipPath>
                                </defs>
                                <g clipPath="url(#clipPath)">
                                  <path
                                    d="M98.979,51.533A47.49,47.49,0,1,1,51.49,4a47.511,47.511,0,0,1,47.49,47.533"
                                    transform="translate(1.393 1.398)"
                                    fill="#fff"
                                    opacity="0.485"
                                  />
                                  <ellipse
                                    cx="47.49"
                                    cy="47.533"
                                    rx="47.49"
                                    ry="47.533"
                                    transform="translate(5.393 5.398)"
                                    fill="none"
                                    stroke="#32324c"
                                    strokeMiterlimit="10"
                                    strokeWidth="6"
                                  />
                                  <path
                                    d="M27.326,28.985,1.829,2.135"
                                    transform="translate(85.933 90.534)"
                                    fill="none"
                                    stroke="#32324c"
                                    strokeLinecap="round"
                                    strokeWidth="8"
                                  />
                                  <line
                                    x2="40.778"
                                    transform="translate(31.767 54.468)"
                                    fill="none"
                                    stroke="#32324c"
                                    strokeLinecap="round"
                                    strokeMiterlimit="10"
                                    strokeWidth="6"
                                  />
                                  <line
                                    y1="40.815"
                                    transform="translate(52.883 34.06)"
                                    fill="none"
                                    stroke="#32324c"
                                    strokeLinecap="round"
                                    strokeMiterlimit="10"
                                    strokeWidth="6"
                                  />
                                </g>
                              </svg>
                              <p className="text-[15px] font-bold">
                                {t("preview")}
                              </p>
                            </button>
                          ) : (
                            <div className="sm:text-[16px] text-[12px] text-[#333876]">
                              {t("previewWarning")}
                            </div>
                          )}
                          <div className="absolute flex sm:bottom-5 bottom-2 mx-auto sm:text-[16px] text-[12px] text-[#333876] font-semibold">
                            {resultFileSize}MB
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="w-full break-all pr-4 font-sans text-[18px] text-start overflow-hidden"
                        ref={translationRef}
                        dangerouslySetInnerHTML={{
                          __html: translateTextResult,
                        }}
                      />
                    )}
                  </div>
                  <div className="flex lg:h-[68px] sm:h-[68px] h-[35px] justify-between md:pl-[24px] pl-[20.3px] relative pr-[17.5px]">
                    {translateTextResult || translateFileResult ? (
                      <div className="flex gap-[14px]">
                        {copy ? (
                          <div
                            className={`ease-in sticky mt-[-33px] drop-shadow-xl z-[9999] duration-150 bg-[#f8f8f8] font-sans text-[#616161] text-[14px] rounded-[5px] flex justify-center items-center h-[26px] max-w-[110px] px-[6px] w-full`}
                          >
                            <img
                              className="w-[15px] h-[15px] mr-[6px]"
                              src="/assets/copy-check.png"
                              alt="Copied"
                            />
                            {t("copied")}
                          </div>
                        ) : null}
                        {(processType === 3 || !fileUploaded) && (
                          <button
                            onClick={handleCopyClick}
                            className="flex flex-col gap-[4.5px] justify-center items-center absolute lg:mt-0 sm:mt-4 mt-1"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 22.537 27.782"
                              className="h-6 md:h-8"
                            >
                              <g
                                id="Group_151"
                                data-name="Group 151"
                                transform="translate(-1054.234 -696)"
                              >
                                <g
                                  id="Rectangle_115"
                                  data-name="Rectangle 115"
                                  transform="translate(1058.771 696)"
                                  fill="#fff"
                                  stroke="#4b5090"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                >
                                  <rect width="18" height="23" stroke="none" />
                                  <rect
                                    x="1"
                                    y="1"
                                    width="16"
                                    height="21"
                                    fill="none"
                                  />
                                </g>
                                <path
                                  d="M1055.234,704.341v16.637H1071.9"
                                  transform="translate(0 1.804)"
                                  fill="none"
                                  stroke="#4b5090"
                                  strokeLinecap="round"
                                  strokeWidth="2"
                                />
                              </g>
                              <line
                                x2="7"
                                transform="translate(10.272 11.444)"
                                fill="none"
                                stroke="#4b5090"
                                strokeLinecap="round"
                                strokeWidth="2"
                              />
                              <line
                                y2="7"
                                transform="translate(13.772 7.944)"
                                fill="none"
                                stroke="#4b5090"
                                strokeLinecap="round"
                                strokeWidth="2"
                              />
                            </svg>
                            <p className="md:text-[9px] text-[7px] text-[#8B8B8B]">
                              {t("copy")}
                            </p>
                          </button>
                        )}
                      </div>
                    ) : null}
                    {!isLoading &&
                    (translateTextResult || translateFileResult) ? (
                      <div className="flex justify-start lg:items-start items-center">
                        <button
                          onClick={handleDownload}
                          className={`ease-in duration-150 lg:w-[235px] md:w-[200px] w-[160px] font-sans ${
                            translateText.length > 0 || translateFileResult
                              ? "bg-background-other hover:bg-[#11145ce4]"
                              : "bg-[#6E6E6E]"
                          } rounded-[10px] flex justify-center items-center lg:h-[50px] md:h-[43px] h-[35px] lg:text-[20px] md:text-[17px] text-[13px] text-white`}
                        >
                          {t("download")}
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
            {processType !== 3 && (
              <div className="flex-col w-full md:max-w-[1300px] mx-auto sm:mt-[-10px] mt-[10px] sm:mb-[186px] mb-[35px]">
                <DictionaryTable
                  type="readOnly"
                  data={dictionaryDetail}
                  pageSelected={pageSelected}
                  onHandleSelect={handlePageSelected}
                />
              </div>
            )}
          </div>
          <div className="fixed top-[200px] left-5">
            {showToneDlg && processType !== 2 ? (
              <div
                ref={toneRef}
                className="flex flex-col px-[10px] justify-center w-[270px] border border-[#6E6E6E] bg-white"
              >
                <div className="flex justify-between items-center h-[50px] px-2">
                  <p className="text-[#333876] text-[16px] leading-[32px] font-sans">
                    {t("toneSelection")}
                  </p>
                  <img
                    onClick={() => {
                      setShowToneDlg(false);
                    }}
                    className="w-[14px] h-[24px] relative top-[-4px] cursor-pointer"
                    src="/assets/×.png"
                    alt="Cancel"
                  />
                </div>
                <div
                  onClick={() => {
                    setTone("");
                    handleClick("");
                    setShowProofToneDlg(false);
                  }}
                  className="flex items-center px-6 text-[#333876] text-[16px] leading-[32px] h-[50px] border-t border-[#CCCCCC] cursor-pointer hover:bg-[#F0F1F8]"
                >
                  {t("tone1")}
                </div>
                <div
                  onClick={() => {
                    handleClick("casual");
                    setTone("casual");
                  }}
                  className={getClassName("casual")}
                >
                  {t("tone2")}
                </div>
                <div
                  onClick={() => {
                    handleClick("official");
                    setTone("official");
                  }}
                  className={getClassName("official")}
                >
                  {t("tone3")}
                </div>
              </div>
            ) : null}
            {showDicDlg ? (
              <div
                ref={dicRef}
                className="ease-linear duration-150 flex flex-col px-[10px] justify-start w-[270px] border min-h-[200px] border-[#6E6E6E] bg-white"
              >
                <div className="flex justify-between items-center h-[50px] px-2">
                  <p className="text-[#333876] text-[16px] leading-[32px] font-sans">
                    {t("dictSelection")}
                  </p>
                  <img
                    onClick={() => {
                      setShowDicDlg(false);
                    }}
                    className="w-[14px] h-[24px] relative top-[-4px] cursor-pointer"
                    src="/assets/×.png"
                    alt="Cancel"
                  />
                </div>
                {dictionaryList.length > 0 ? (
                  <div
                    onClick={() => {
                      setCurrentDictionatyId(-1);
                      setDictionaryDetail([]);
                    }}
                    className="flex items-center px-6 text-[#333876] text-[16px] leading-[32px] font-sans h-[50px] border-t border-[#CCCCCC] cursor-pointer hover:bg-[#F0F1F8]"
                  >
                    {t("unselectDic")}
                  </div>
                ) : (
                  <div>{t("noDic")}</div>
                )}
                {dictionaryList.length > 0 &&
                  dictionaryList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => handleGetDictionaryDetailById(item.id)}
                        className={`flex items-center px-6 text-[#333876] text-[16px] leading-[32px] font-sans h-[50px] border-t border-[#CCCCCC] cursor-pointer hover:bg-[#F0F1F8] ${
                          currentDictionaryId === item.id ? "bg-[#F0F2E8]" : ""
                        }`}
                      >
                        {item.dictionary_name}
                      </div>
                    );
                  })}
              </div>
            ) : null}
          </div>
        </>
      )}
      {isUpgrade && (
        <ConfirmModal
          isOpen={isUpgrade}
          text={
            <div>
              <p className="sm:text-[32px] text-[24px]">
                {t("loginModalConfirmText1")}
              </p>
              <p className="sm:mb-6 mb-4">{t("loginModalConfirmText2")}</p>
              <ul className="text-start list-disc list-outside">
                <li>
                  {t("loginModalConfirmText3")}
                  <strong>{t("loginModalConfirmText4")}</strong>
                  {t("loginModalConfirmText5")}
                </li>
                <li>
                  {t("loginModalConfirmText6")}
                  <strong>{t("loginModalConfirmText7")}</strong>
                </li>
                <li>
                  {t("loginModalConfirmText8")}
                  <strong>{t("loginModalConfirmText9")}</strong>
                  {t("loginModalConfirmText10")}
                </li>
                <li>
                  <strong>{t("loginModalConfirmText11")}</strong>
                  {t("loginModalConfirmText12")}
                </li>
                <li>
                  {t("loginModalConfirmText13")}
                  <strong>{t("loginModalConfirmText14")}</strong>
                  {t("loginModalConfirmText15")}
                </li>
                <li>
                  <strong>{t("loginModalConfirmText16")}</strong>
                  {t("loginModalConfirmText17")}
                </li>
                <li>
                  <strong>{t("loginModalConfirmText18")}</strong>
                  {t("loginModalConfirmText19")}
                </li>
                <li>
                  <strong>{t("loginModalConfirmText20")}</strong>
                  {t("loginModalConfirmText21")}
                </li>
                <li>
                  {t("loginModalConfirmText22")}
                  <strong>{t("loginModalConfirmText23")}</strong>
                  {t("loginModalConfirmText24")}
                </li>
              </ul>
              <p className="mt-4 sm:text-[18px] text-[11px]">
                {t("loginModalConfirmText25")}
              </p>
            </div>
          }
          buttonText={t("loginModalConfirmText26")}
          cancelText={t("close")}
          onAgree={() => {
            localStorage.set("popup_date", Date.now());
            handleSubscription();
            setIsUpgrade(false);
          }}
          onCancel={() => {
            localStorage.set("popup_date", Date.now());
            setIsUpgrade(false);
          }}
          swap={true}
        />
      )}
    </div>
  );
}
