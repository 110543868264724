import React, {useState, useEffect, useRef} from "react";

export const CustomSelect = ({ value, onChange, items, className = "" }) => {
   const [dropDown, setDropDown] = useState(false);
   const [selectedLabel, setSelectedLabel] = useState();
   const controlRef = useRef();
 
   useEffect(() => {
     function handleClickOutside(event) {
       if (!controlRef.current || !controlRef.current.contains(event.target)) {
         setDropDown(false);
       }
     }
 
     document.addEventListener("mousedown", handleClickOutside);
     return () => {
       document.removeEventListener("mousedown", handleClickOutside);
     };
   }, []);
 
   useEffect(() => {
     const temp = items.find((item) => item.value === value);
     if (temp) setSelectedLabel(temp.label);
   }, [items, value]);
 
   return (
     <div ref={controlRef} className="text-[#505050] relative">
       <div
         onClick={() => setDropDown(!dropDown)}
         className={`${className} flex min-w-[100px] w-full min-h-[30px] h-full ${
           (dropDown || value) && "bg-white"
         } border border-[#8B8B8B] rounded-[10px] pl-2 sm:pl-5 pr-2 justify-between items-center gap-4 cursor-pointer`}
       >
         <p>{selectedLabel}</p>
         <svg
           xmlns="http://www.w3.org/2000/svg"
           width="13"
           height="8"
           viewBox="0 0 13 8"
         >
           <path
             d="M6.5,0,13,8H0Z"
             transform="translate(13 8) rotate(180)"
             fill="#6e6e6e"
           />
         </svg>
       </div>
       <div
         className={`${
           dropDown ? "" : "hidden"
         } flex w-full h-full cursor-pointer`}
       >
         <div className="absolute flex flex-col w-full min-h-10 max-h-40 border border-[#707070] bg-white overflow-auto z-10">
           {items.map((item, index) => (
             <div
               onClick={() => {
                 onChange(item.value);
                 setDropDown(false);
               }}
               key={index}
               className="flex w-full h-[35px] sm:h-[40px] hover:bg-[#f2f2f8] justify-center items-center"
             >
               {item.label}
             </div>
           ))}
         </div>
       </div>
     </div>
   );
 };
 