import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function CommonSuccessModal({ title, text }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex !opacity-100 !translate-y-0 fixed inset-0 bg-black/40 z-30 !transition-all !ease-in-out !duration-300 sm:px-0 px-5">
      <div className="flex flex-col max-w-[830px] w-full mx-auto my-auto z-50 bg-[#F0F1F8] px-4 py-4 rounded-lg">
        <div className="text-[#4B5090] font-semibold font-sans sm:text-[30px] text-[15px] text-center sm:mt-14 sm:mb-10 mt-6 mb-6">
          {title}
        </div>
        <div className="text-[#6E6E6E] sm:text-[16px] text-[12px]">{text}</div>
        <button
          onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' }); navigate("/")}}
          className="bg-[#4B5090] text-white sm:text-[16px] text-[14px] sm:px-6 px-4 sm:h-10 h-8  rounded-[10px] mx-auto sm:mt-14 sm:mb-10 my-4"
        >
          {t("goHome")}
        </button>
      </div>
    </div>
  );
}
