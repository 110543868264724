import React from "react";
import { Helmet } from "react-helmet";

export default function SecurityJp() {
  return (
    <div className="flex flex-col">
      <Helmet>
        <title>Languise - Security</title>
        <meta
          name="description"
          content="お客様の全てのデータは最大限のセキュリティ管理下で取り扱います"
        />
        <link rel="canonical" href="https://languise.com/security" />
      </Helmet>
      <div
        className="flex items-center bg-cover bg-center w-full sm:h-[360px] h-[220px]"
        style={{ backgroundImage: "url('/assets/5-bg.png')" }}
      >
        <div className="flex flex-col xl:gap-[23px] sm:gap-4 gap-2 2xl:max-w-[1306px] max-w-[95%] w-full mx-auto">
          <h1 className="sm:text-[45px] text-[22px]  text-[#333876] font-sans font-semibold text-start">
            Languiseのセキュリティ
          </h1>
          <p className="sm:text-[20px] text-[10px] text-[#505050] font-sans text-start">
            お客様の全てのデータは最大限のセキュリティ管理下で取り扱います
          </p>
        </div>
      </div>

      <div className="2xl:max-w-[1306px] max-w-[95%] w-full mx-auto flex flex-col">
        <h2 className="text-start sm:mt-[81px] mt-[20px] text-[#11135C] sm:text-[26px] text-[12px] font-sans font-semibold">
          すべてのデータは暗号化により保護されます
        </h2>
        <p className="text-start sm:text-[20px] text-[12px] sm:mt-[46px] mt-2 text-[#222222] sm:leading-[38px] leading-[24px] font-sans ">
          Languiseは通信の暗号化に最先端のTLS（Transport Layer
          Security）暗号化技術に対応しており、第三者による読取りや改ざんから保護されます。
          <br />
          この技術はお客様が当社のウェブサイトにアクセスする際、またはLanguise上で情報をやり取りする際に自動的に作動して複雑な暗号化プロセスを提供しますので、お客様には何か特別な操作をして頂く必要はなく、安心してサービスをご利用頂けます。
          また、システムが取り扱うデータはすべて暗号化して格納されます。
        </p>
        <h2 className="text-start sm:mt-[62px] mt-[20px] text-[#11135C] sm:text-[26px] text-[12px] font-sans font-semibold">
          全ての処理に用いたデータは削除され、二次利用もされません
        </h2>
        <p className="text-start sm:text-[20px] text-[12px] sm:mt-[46px] mt-2 text-[#222222] sm:leading-[38px] leading-[24px] font-sans">
          お客様が処理に用いたデータはLanguiseが管理するサーバーに送られた後、OpenAIのサーバーを経由して処理されてお客様のお手元に戻ります。
          <br />
          有料・無料版を問わず、Languiseのサーバーに保存されたデータはプロセスが終わった時点で即座に消去し、二次利用は致しません。
          <br />
          OpenAIのサーバーに送られたデータは不正利用の監視目的で一時的に保存されますが、30日以内に完全に消去され、AIの学習に利用される事もありません。
          <br />
          OpenAIのデータに対する詳しい方針はこちらをご覧ください。（
          <a
            className="text-[#11135C] underline"
            href="https://openai.com/enterprise-privacy"
          >
            https://openai.com/enterprise-privacy
          </a>
          ）
        </p>
        <h2 className="text-start sm:mt-[62px] mt-[20px] text-[#11135C] sm:text-[26px] text-[12px] font-sans font-semibold">
          データの保存は必要最小限に留めます
        </h2>
        <p className="text-start sm:text-[20px] text-[12px] sm:mt-[46px] mt-2 text-[#222222] sm:leading-[38px] leading-[24px] font-sans">
          有料・無料版を問わず、処理されるテキストはランダムアクセスメモリ（RAM）のみに一時的に保存されます。文書ファイルはお客様が指定した処理を行うためにのみストレージに一時的に保存されますが、全ての処理が終わった時点で完全に消去されます。
          <br />
          処理されたテキストは共通鍵暗号方式で暗号化され、この共通鍵は登録者のシステム内の揮発性メモリにのみ保存されます。
        </p>
        <h2 className="text-start sm:mt-[62px] mt-[20px] text-[#11135C] sm:text-[26px] text-[12px] font-sans font-semibold">
          セキュリティ対策は常に更新し続けます
        </h2>
        <p className="text-start sm:text-[20px] text-[12px] sm:mt-[46px] mt-2 text-[#222222] sm:leading-[38px] leading-[24px] font-sans">
          Languiseは技術の進化に合わせてセキュリティ対策を常に更新し続けることをお約束します。
          <br />
          お客様が当社のサービスを安全に、信頼してご利用いただけるよう、引き続き最善を尽くしてまいります。
        </p>
        <h2 className="text-start sm:mt-[62px] mt-[20px] text-[#11135C] sm:text-[26px] text-[12px] font-sans font-semibold">
          データセンターの高いセキュリティ
        </h2>
        <p className="text-start sm:text-[20px] text-[12px] sm:mt-[46px] mt-2 text-[#222222] sm:leading-[38px] leading-[24px] font-sans">
          LanguiseはAWS上で動作します。AWSのデータセンターは高いセキュリティレベルを有しており、物理的なデータへの不正アクセスから強く守られています。
          <br />
          また、あらゆる操作はログとして記録されるため、万が一不正行為が発生した場合にも徹底的な追跡調査を行うことができます。
          <br />
          AWSのデータセンターセキュリティに関する詳しい情報はこちらをご覧ください。
          <a
            className="text-[#11135C] underline"
            href="https://aws.amazon.com/jp/compliance/data-center/controls/"
            target="blank"
          >
            (https://aws.amazon.com/jp/compliance/data-center/controls/)
          </a>
        </p>
        <h2 className="text-start sm:mt-[62px] mt-[20px] text-[#11135C] sm:text-[26px] text-[12px] font-sans font-semibold">
          欧州の一般データ保護規則（GDPR)の遵守
        </h2>
        <p className="text-start sm:text-[20px] text-[12px] sm:mt-[46px] mt-2 text-[#222222] sm:leading-[38px] leading-[24px] font-sans sm:mb-[160px] mb-[40px]">
          お客様のプライバシーを尊重し、個人情報を保護するために最大限の努力をします。個人データの取り扱いはEU一般データ保護規則（GDPR）に準拠した厳格な基準を設けており、利用目的の透明性や収集するデータの最小化に加えて、お客様ご自身の意思でデータの修正や削除を行う権利を尊重しています。
          <br />
          お客様のデータ保護に関する具体的な内容は
          <a
            className="text-[#11135C] underline"
            href="/privacy"
            target="blank"
          >
            プライバシーポリシー
          </a>
          をご参照ください。
        </p>
      </div>
    </div>
  );
}
