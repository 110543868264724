import React, { useState } from "react";
import Plans from "../components/Plans";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AddEnterpriseModal from "../components/Modal/AddEnterpriseModal";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function PricePlan() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [openAddEnterpriseModal, setOpenAddEnterpriseModal] = useState(false);

  const userInfo = useSelector((state) => state.LanguiseReducer.userInfo);

  const handleCloseAddEnterpriseModal = () => {
    setOpenAddEnterpriseModal(!openAddEnterpriseModal);
    if (!openAddEnterpriseModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  };

  const naviagterEnterprise = () => {
    if ((!userInfo.enterprise_user && !userInfo.enterprise_admin) || userInfo.enterprise_trial) {
      handleCloseAddEnterpriseModal();
    } else {
      navigate("/mypage");
    }
  };

  return (
    <div className="flex flex-col">
      <AddEnterpriseModal
        openModal={openAddEnterpriseModal}
        onClose={handleCloseAddEnterpriseModal}
      />
      <Helmet>
        <title>Languise - Plans</title>
        <meta
          name="description"
          content="文章業務の正確性と効率UPをLanguiseで"
        />
        <link rel="canonical" href="https://languise.com/priceplan" />
      </Helmet>
      <div className="bg-cover bg-center w-full lg:h-[360px] md:h-[260px] h-[180px] sm:pt-10 pt-6">
        <h1 className="lg:text-[60px] md:text-[40px] text-[22px] text-[#11135C] font-sans font-semibold text-center">
          {t("pricePlanPageText1")}
          <br />
          {t("pricePlanPageText2")}
        </h1>
        <p className="lg:text-[30px] dm:text-[24px] text-[16px] text-[#6E6E6E] font-sans font-normal text-center mt-[20px] px-16">
          {t("pricePlanPageText3")}
        </p>
      </div>
      <div className="bg-[#F0F1F8] xl:pt-[120px] sm:pt-[60px] pt-[27px]">
        <Plans />
      </div>
      <div className="flex flex-col bg-cover bg-center w-full md:mt-16 mt-10 px-5">
        <h1 className="2xl:block hidden w-full xl:text-[60px] lg:text-[40px] text-[32px] mx-auto text-[#11135C] font-sans font-semibold text-center px-5">
          {t("pricePlanPageText4")}
          <br />
          {t("pricePlanPageText5")}
        </h1>
        <h1 className="2xl:hidden w-full lg:text-[36px] text-[24px] mx-auto text-[#11135C] font-sans font-semibold text-center px-5">
          {t("pricePlanPageText6")}
          <br />
          {t("pricePlanPageText7")}
          <br />
          {t("pricePlanPageText8")}
        </h1>
        <p className="sm:text-[30px] text-[16px] text-[#11135C] font-sans font-semibold text-center sm:mt-8 mt-4">
          Languise Enterprise
        </p>
        <div className="flex flex-col mx-auto text-start">
          <p className="sm:text-[20px] text-[11px] text-[#6E6E6E] font-sans mt-[18px]">
            {t("pricePlanPageText9")}
          </p>
          <p className="sm:text-[20px] text-[11px] text-[#6E6E6E] font-sans">
            {t("pricePlanPageText9_1")}
          </p>
          <p className="sm:text-[20px] text-[11px] text-[#6E6E6E] font-sans">
            {t("pricePlanPageText10")}
          </p>
          <p className="sm:text-[20px] text-[11px] text-[#6E6E6E] font-sans">
            {t("pricePlanPageText11")}
          </p>
          <p className="sm:text-[20px] text-[11px] text-[#6E6E6E] font-sans">
            {t("pricePlanPageText12")}
          </p>
        </div>
      </div>
      <div className="flex sm:flex-row flex-col gap-2 sm:gap-6 w-full justify-center items-center sm:items-end sm:my-[50px] my-[70px] xl:px-0 px-5">
        <div className="flex flex-col gap-[16px] sm:max-w-[250px] max-w-full w-full mb-[7px] items-center order-2 sm:order-1">
          <p className="text-[#6E6E6E] text-[20px] text-center leading-[24px] font-sans font-semibold sm:mt-0 mt-[26px]">
            {t("enterprisePageText18")}
          </p>
          <button
            onClick={() => navigate("/dl")}
            className="max-w-[250px] w-full h-[65px] rounded-[10px] bg-[#6E6E6E] font-sans font-semibold text-white text-[20px] text-center sm:mt-0 mt-2"
          >
            {t("downloadDocument")}
          </button>
        </div>
        <div
          onClick={naviagterEnterprise}
          className="flex flex-col sm:max-w-[330px] w-full h-[120px] gap-[6px] justify-center items-center bg-[#11135C] rounded-[20px] order-1 sm:order-2 cursor-pointer"
        >
          <p className="text-white text-[16px] font-sans font-semibold">
            Languise Enterprise
          </p>
          <p className="text-white text-[30px] font-sans font-semibold">
            {t("apply")}
          </p>
        </div>
        <button
          onClick={() => navigate("/contactus")}
          className="max-w-[250px] w-full h-[65px] rounded-[10px] bg-[#6E6E6E] text-white text-[20px] text-center mb-[7px] sm:mt-0 mt-[26px] order-3"
        >
          {t("qa")}
        </button>
      </div>
    </div>
  );
}
