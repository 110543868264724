export const languageList = [
  { label: "自動で検出する", value: "Detect language", abbr: "auto" },
  { label: "アイスランド語", value: "Icelandic", abbr: "is" },
  { label: "アイルランド語", value: "Irish", abbr: "ga" },
  { label: "アゼルバイジャン語", value: "Azerbaijani", abbr: "az" },
  { label: "アッサム語", value: "Assamese", abbr: "as" },
  { label: "アフリカーンス語", value: "Afrikaans", abbr: "af" },
  { label: "アムハラ語", value: "Amharic", abbr: "am" },
  { label: "アラビア語", value: "Arabic", abbr: "ar" },
  { label: "アルバニア語", value: "Albanian", abbr: "sq" },
  { label: "アルメニア語", value: "Armenian", abbr: "hy" },
  { label: "イタリア語", value: "Italian", abbr: "it" },
  { label: "イボ語", value: "Igbo", abbr: "ig" },
  { label: "インドネシア語", value: "Indonesian", abbr: "id" },
  { label: "ウイグル語", value: "Uyghur", abbr: "ug" },
  { label: "ウェールズ語", value: "Welsh", abbr: "cy" },
  { label: "ウクライナ語", value: "Ukrainian", abbr: "uk" },
  { label: "ウズベグ語", value: "Uzbek", abbr: "uz" },
  { label: "ウルドゥー語", value: "Urdu", abbr: "ur" },
  { label: "英語(英国)", value: "English (UK)", abbr: "en" },
  { label: "英語(米国)", value: "English (US)", abbr: "en" },
  { label: "エストニア語", value: "Estonian", abbr: "et" },
  { label: "オランダ語", value: "Dutch", abbr: "nl" },
  { label: "オリヤー語", value: "Odia (Oriya)", abbr: "or" },
  { label: "カザフ語", value: "Kazakh", abbr: "kk" },
  { label: "カタルーニャ語", value: "Catalan", abbr: "ca" },
  { label: "ガリシア語", value: "Galician", abbr: "gl" },
  { label: "韓国語", value: "Korean", abbr: "ko" },
  { label: "広東語", value: "Cantonese", abbr: "yue" },
  { label: "カンナダ語", value: "Kannada", abbr: "kn" },
  { label: "キニヤルワンダ語", value: "Kinyarwanda", abbr: "rw" },
  { label: "ギリシャ語", value: "Greek", abbr: "el" },
  { label: "キルギス語", value: "Kyrgyz", abbr: "ky" },
  { label: "クジャラート語", value: "Gujarati", abbr: "gu" },
  { label: "クメール語", value: "Khmer", abbr: "km" },
  { label: "グルジア語", value: "Georgian", abbr: "ka" },
  { label: "クロアチア語", value: "Croatian", abbr: "hr" },
  { label: "コーサ語", value: "Xhosa", abbr: "xh" },
  { label: "サモア語", value: "Samoan", abbr: "sm" },
  { label: "シンド語", value: "Sindhi", abbr: "sd" },
  { label: "シンハラ語", value: "Sinhala", abbr: "si" },
  { label: "スウェーデン語", value: "Swedish", abbr: "sv" },
  { label: "ズールー語", value: "Zulu", abbr: "zu" },
  { label: "スペイン語", value: "Spanish", abbr: "es" },
  { label: "スロバキア語", value: "Slovak", abbr: "sk" },
  { label: "スロベニア語", value: "Slovenian", abbr: "sl" },
  { label: "スワヒリ語", value: "Swahili", abbr: "sw" },
  { label: "セルビア語", value: "Serbian", abbr: "sr" },
  { label: "ソマリ語", value: "Somali", abbr: "so" },
  { label: "タイ語", value: "Thai", abbr: "th" },
  { label: "タタール語", value: "Tatar", abbr: "tt" },
  { label: "タミル語", value: "Tamil", abbr: "ta" },
  { label: "チェコ語", value: "Czech", abbr: "cs" },
  { label: "中国語(簡体字)", value: "Chinese (Simplified)", abbr: "zh" },
  { label: "中国語(繁体字)", value: "Chinese (Traditional)", abbr: "zh" },
  { label: "ディベヒ語", value: "Dhivehi", abbr: "dv" },
  { label: "テルグ語", value: "Telugu", abbr: "te" },
  { label: "デンマーク語", value: "Danish", abbr: "da" },
  { label: "ドイツ語", value: "German", abbr: "de" },
  { label: "トルクメン語", value: "Turkmen", abbr: "tk" },
  { label: "トルコ語", value: "Turkish", abbr: "tr" },
  { label: "日本語", value: "Japanese", abbr: "ja" },
  { label: "ネパール語", value: "Nepali", abbr: "ne" },
  { label: "ノルウェー語", value: "Norwegian", abbr: "no" },
  { label: "ハイチ語", value: "Haitian", abbr: "ht" },
  { label: "ハウサ語", value: "Hausa", abbr: "ha" },
  { label: "バシキール語", value: "Bashkir", abbr: "ba" },
  { label: "パシュトゥ語", value: "Pashto", abbr: "ps" },
  { label: "バスク語", value: "Basque", abbr: "eu" },
  { label: "ハンガリー語", value: "Hungarian", abbr: "hu" },
  { label: "パンジャーブ語", value: "Punjabi", abbr: "pa" },
  { label: "ビルマ語", value: "Burmese", abbr: "my" },
  { label: "ヒンディー語", value: "Hindi", abbr: "hi" },
  { label: "フィジー語", value: "Fijian", abbr: "fj" },
  { label: "フィンランド語", value: "Finnish", abbr: "fi" },
  { label: "フェロー語", value: "Faroese", abbr: "fo" },
  { label: "フランス語", value: "French", abbr: "fr" },
  { label: "ブルガリア語", value: "Bulgarian", abbr: "bg" },
  { label: "ベトナム語", value: "Vietnamese", abbr: "vi" },
  { label: "ヘブライ語", value: "Hebrew", abbr: "he" },
  { label: "ペルシア語", value: "Persian", abbr: "fa" },
  { label: "ポーランド語", value: "Polish", abbr: "pl" },
  { label: "ボスニア語", value: "Bosnian", abbr: "bs" },
  { label: "ポルトガル語", value: "Portuguese", abbr: "pt" },
  { label: "マイティリー語", value: "Maithili", abbr: "mai" },
  { label: "マオリ語", value: "Maori", abbr: "mi" },
  { label: "マケドニア語", value: "Macedonian", abbr: "mk" },
  { label: "マダガスカル語", value: "Malagasy", abbr: "mg" },
  { label: "マラーティー語", value: "Marathi", abbr: "mr" },
  { label: "マラヤーラム語", value: "Malayalam", abbr: "ml" },
  { label: "マルタ語", value: "Maltese", abbr: "mt" },
  { label: "マレー語", value: "Malay", abbr: "ms" },
  { label: "モンゴル語", value: "Mongolian", abbr: "mn" },
  { label: "ヨルバ語", value: "Yoruba", abbr: "yo" },
  { label: "ラオス語", value: "Lao", abbr: "lo" },
  { label: "ラトビア語", value: "Latvian", abbr: "lv" },
  { label: "リトアニア語", value: "Lithuanian", abbr: "lt" },
  { label: "リンガラ語", value: "Lingala", abbr: "ln" },
  { label: "ルーマニア語", value: "Romanian", abbr: "ro" },
  { label: "ルガンダ語", value: "Luganda", abbr: "lg" },
  { label: "ロシア語", value: "Russian", abbr: "ru" },
];

export const countryList = [
  { label: "アフガニスタン", value: "Afghanistan" },
  { label: "アイスランド", value: "Iceland" },
  { label: "アイルランド", value: "Ireland" },
  { label: "アゼルバイジャン", value: "Azerbaijan" },
  { label: "アラブ首長国連邦", value: "United Arab Emirates" },
  { label: "アルジェリア", value: "Algeria" },
  { label: "アルゼンチン", value: "Argentina" },
  { label: "アルバニア", value: "Albania" },
  { label: "アルメニア", value: "Armenia" },
  { label: "アンゴラ", value: "Angola" },
  { label: "アンティグア・バーブーダ", value: "Antigua and Barbuda" },
  { label: "アンドラ", value: "Andorra" },
  { label: "イエメン", value: "Yemen" },
  { label: "イスラエル", value: "Israel" },
  { label: "イタリア", value: "Italy" },
  { label: "イラク", value: "Iraq" },
  { label: "イラン", value: "Iran" },
  { label: "インド", value: "India" },
  { label: "インドネシア", value: "Indonesia" },
  { label: "ウガンダ", value: "Uganda" },
  { label: "ウクライナ", value: "Ukraine" },
  { label: "ウズベキスタン", value: "Uzbekistan" },
  { label: "ウルグアイ", value: "Uruguay" },
  { label: "エクアドル", value: "Ecuador" },
  { label: "エジプト", value: "Egypt" },
  { label: "エストニア", value: "Estonia" },
  { label: "エスワティニ", value: "Eswatini" },
  { label: "エチオピア", value: "Ethiopia" },
  { label: "エリトリア", value: "Eritrea" },
  { label: "エルサルバドル", value: "El Salvador" },
  { label: "オーストラリア", value: "Australia" },
  { label: "オーストリア", value: "Austria" },
  { label: "オマーン", value: "Oman" },
  { label: "オランダ", value: "Netherlands" },
  { label: "ガーナ", value: "Ghana" },
  { label: "カーボベルデ", value: "Cabo Verde" },
  { label: "ガイアナ", value: "Guyana" },
  { label: "カザフスタン", value: "Kazakhstan" },
  { label: "カタール", value: "Qatar" },
  { label: "カナダ", value: "Canada" },
  { label: "ガボン", value: "Gabon" },
  { label: "カメルーン", value: "Cameroon" },
  { label: "ガンビア", value: "Gambia" },
  { label: "カンボジア", value: "Cambodia" },
  { label: "ギニア", value: "Guinea" },
  { label: "ギニアビサウ", value: "Guinea-Bissau" },
  { label: "キプロス", value: "Cyprus" },
  { label: "キューバ", value: "Cuba" },
  { label: "ギリシャ", value: "Greece" },
  { label: "キリバス", value: "Kiribati" },
  { label: "キルギスタン", value: "Kyrgyzstan" },
  { label: "グアテマラ", value: "Guatemala" },
  { label: "クウェート", value: "Kuwait" },
  { label: "グレナダ", value: "Grenada" },
  { label: "クロアチア", value: "Croatia" },
  { label: "ケニア", value: "Kenya" },
  { label: "コートジボアール", value: "Côte d'Ivoire (Ivory Coast)" },
  { label: "コスタリカ", value: "Costa Rica" },
  { label: "コモロ", value: "Comoros" },
  { label: "コロンビア", value: "Colombia" },
  { label: "コンゴ", value: "Congo" },
  { label: "コンゴ民主共和国", value: "Democratic Republic of the Congo" },
  { label: "サウジアラビア", value: "Saudi Arabia" },
  { label: "サモア", value: "Samoa" },
  { label: "サントメ・プリンシペ", value: "São Tomé and Príncipe" },
  { label: "ザンビア", value: "Zambia" },
  { label: "サンマリノ", value: "San Marino" },
  { label: "シエラレオネ", value: "Sierra Leone" },
  { label: "ジブチ", value: "Djibouti" },
  { label: "ジャマイカ", value: "Jamaica" },
  { label: "ジョージア", value: "Georgia" },
  { label: "シンガポール", value: "Singapore" },
  { label: "ジンバブエ", value: "Zimbabwe" },
  { label: "スイス", value: "Switzerland" },
  { label: "スウェーデン", value: "Sweden" },
  { label: "スーダン", value: "Sudan" },
  { label: "スペイン", value: "Spain" },
  { label: "スリナム", value: "Suriname" },
  { label: "スリランカ", value: "Sri Lanka" },
  { label: "スロバキア", value: "Slovakia" },
  { label: "スロベニア", value: "Slovenia" },
  { label: "セイシェル", value: "Seychelles" },
  { label: "セネガル", value: "Senegal" },
  { label: "セルビア", value: "Serbia" },
  { label: "セントクリストファー・ネイビス", value: "Saint Kitts and Nevis" },
  {
    label: "セントビンセントおよびグレナディーン諸島",
    value: "Saint Vincent and the Grenadines",
  },
  { label: "セントルシア", value: "Saint Lucia" },
  { label: "ソマリア", value: "Somalia" },
  { label: "ソロモン諸島", value: "Solomon Islands" },
  { label: "タイ", value: "Thailand" },
  { label: "タジキスタン", value: "Tajikistan" },
  { label: "タンザニア", value: "Tanzania" },
  { label: "チェコ", value: "Czech Republic" },
  { label: "チャド", value: "Chad" },
  { label: "チュニジア", value: "Tunisia" },
  { label: "チリ", value: "Chile" },
  { label: "ツバル", value: "Tuvalu" },
  { label: "デンマーク", value: "Denmark" },
  { label: "ドイツ", value: "Germany" },
  { label: "トーゴ", value: "Togo" },
  { label: "ドミニカ", value: "Dominica" },
  { label: "ドミニカ共和国", value: "Dominican Republic" },
  { label: "トリニダード・トバゴ", value: "Trinidad and Tobago" },
  { label: "トルクメニスタン", value: "Turkmenistan" },
  { label: "トルコ", value: "Turkey" },
  { label: "トンガ", value: "Tonga" },
  { label: "ナイジェリア", value: "Nigeria" },
  { label: "ナウル", value: "Nauru" },
  { label: "ナミビア", value: "Namibia" },
  { label: "ニカラグア", value: "Nicaragua" },
  { label: "ニジェール", value: "Niger" },
  { label: "ニュージーランド", value: "New Zealand" },
  { label: "ネパール", value: "Nepal" },
  { label: "ノルウェー", value: "Norway" },
  { label: "バーレーン", value: "Bahrain" },
  { label: "ハイチ", value: "Haiti" },
  { label: "パキスタン", value: "Pakistan" },
  { label: "パナマ", value: "Panama" },
  { label: "バヌアツ", value: "Vanuatu" },
  { label: "バハマ", value: "Bahamas" },
  { label: "パプア・ニューギニア", value: "Papua New Guinea" },
  { label: "パラオ", value: "Palau" },
  { label: "パラグアイ", value: "Paraguay" },
  { label: "バルバドス", value: "Barbados" },
  { label: "ハンガリ－", value: "Hungary" },
  { label: "バングラデシュ", value: "Bangladesh" },
  { label: "フィジ－", value: "Fiji" },
  { label: "フィリピン", value: "Philippines" },
  { label: "フィンランド", value: "Finland" },
  { label: "ブータン", value: "Bhutan" },
  { label: "ブラジル", value: "Brazil" },
  { label: "フランス", value: "France" },
  { label: "ブルガリア", value: "Bulgaria" },
  { label: "ブルキナファソ", value: "Burkina Faso" },
  { label: "ブルネイ", value: "Brunei" },
  { label: "ブルンジ", value: "Burundi" },
  { label: "ベトナム", value: "Vietnam" },
  { label: "ベナン", value: "Benin" },
  { label: "ベネズエラ", value: "Venezuela" },
  { label: "ベリーズ", value: "Belize" },
  { label: "ペルー", value: "Peru" },
  { label: "ベルギー", value: "Belgium" },
  { label: "ポーランド", value: "Poland" },
  { label: "ボスニア・ヘルツェゴビナ", value: "Bosnia and Herzegovina" },
  { label: "ボツワナ", value: "Botswana" },
  { label: "ボリビア", value: "Bolivia" },
  { label: "ポルトガル", value: "Portugal" },
  { label: "ホンジュラス", value: "Honduras" },
  { label: "マーシャル諸島", value: "Marshall Islands" },
  { label: "マダガスカル", value: "Madagascar" },
  { label: "マラウィ", value: "Malawi" },
  { label: "マリ", value: "Mali" },
  { label: "マルタ", value: "Malta" },
  { label: "マレーシア", value: "Malaysia" },
  { label: "ミクロネシア連邦", value: "Federated States of Micronesia" },
  { label: "ミャンマー", value: "Myanmar" },
  { label: "メキシコ", value: "Mexico" },
  { label: "モーリシャス", value: "Mauritius" },
  { label: "モーリタニア", value: "Mauritania" },
  { label: "モザンビーク", value: "Mozambique" },
  { label: "モナコ", value: "Monaco" },
  { label: "モルディブ", value: "Maldives" },
  { label: "モルドバ", value: "Moldova" },
  { label: "モロッコ", value: "Morocco" },
  { label: "モンゴル", value: "Mongolia" },
  { label: "モンテネグロ", value: "Montenegro" },
  { label: "ヨルダン", value: "Jordan" },
  {
    label: "ラオス人民民主共和国",
    value: "Lao People's Democratic Republic (Laos)",
  },
  { label: "ラトビア", value: "Latvia" },
  { label: "リトアニア", value: "Lithuania" },
  { label: "リビア", value: "Libya" },
  { label: "リヒテンシュタイン", value: "Liechtenstein" },
  { label: "リベリア", value: "Liberia" },
  { label: "ルーマニア", value: "Romania" },
  { label: "ルクセンブルク", value: "Luxembourg" },
  { label: "ルワンダ", value: "Rwanda" },
  { label: "レソト", value: "Lesotho" },
  { label: "レバノン", value: "Lebanon" },
  { label: "英国", value: "United Kingdom" },
  { label: "韓国", value: "South Korea" },
  { label: "赤道ギニア", value: "Equatorial Guinea" },
  { label: "中央アフリカ共和国", value: "Central African Republic" },
  { label: "中国", value: "China" },
  { label: "東ティモール", value: "Timor-Leste (East Timor)" },
  { label: "南アフリカ", value: "South Africa" },
  { label: "南スーダン", value: "South Sudan" },
  { label: "日本", value: "Japan" },
  { label: "米国", value: "United States" },
  { label: "北マケドニア", value: "North Macedonia" },
];

export const openAICountryList = [
  "AL",
  "DZ",
  "AF",
  "AD",
  "AO",
  "AG",
  "AR",
  "AM",
  "AU",
  "AT",
  "AZ",
  "BS",
  "BH",
  "BD",
  "BB",
  "BE",
  "BZ",
  "BJ",
  "BT",
  "BO",
  "BA",
  "BW",
  "BR",
  "BN",
  "BG",
  "BF",
  "BI",
  "CV",
  "KH",
  "CM",
  "CA",
  "CF",
  "TD",
  "CL",
  "CO",
  "KM",
  "CG",
  "CD",
  "CR",
  "CI",
  "HR",
  "CY",
  "CZ",
  "DK",
  "DJ",
  "DM",
  "DO",
  "EC",
  "EG",
  "SV",
  "GQ",
  "ER",
  "EE",
  "SZ",
  "ET",
  "FJ",
  "FI",
  "FR",
  "GA",
  "GM",
  "GE",
  "DE",
  "GH",
  "GR",
  "GD",
  "GT",
  "GN",
  "GW",
  "GY",
  "HT",
  "VA",
  "HN",
  "HU",
  "IS",
  "IN",
  "ID",
  "IQ",
  "IE",
  "IL",
  "IT",
  "JM",
  "JP",
  "JO",
  "KZ",
  "KE",
  "KI",
  "KW",
  "KG",
  "LA",
  "LV",
  "LB",
  "LS",
  "LR",
  "LY",
  "LI",
  "LT",
  "LU",
  "MG",
  "MW",
  "MY",
  "MV",
  "ML",
  "MT",
  "MH",
  "MR",
  "MU",
  "MX",
  "FM",
  "MD",
  "MC",
  "MN",
  "ME",
  "MA",
  "MZ",
  "MM",
  "NA",
  "NR",
  "NP",
  "NL",
  "NZ",
  "NI",
  "NE",
  "NG",
  "MK",
  "NO",
  "OM",
  "PK",
  "PW",
  "PS",
  "PA",
  "PG",
  "PY",
  "PE",
  "PH",
  "PL",
  "PT",
  "QA",
  "RO",
  "RW",
  "KN",
  "LC",
  "VC",
  "WS",
  "SM",
  "ST",
  "SA",
  "SN",
  "RS",
  "SC",
  "SL",
  "SG",
  "SK",
  "SI",
  "SB",
  "SO",
  "ZA",
  "KR",
  "SS",
  "ES",
  "LK",
  "SR",
  "SE",
  "CH",
  "SD",
  "TJ",
  "TZ",
  "TH",
  "TL",
  "TG",
  "TO",
  "TT",
  "TN",
  "TR",
  "TM",
  "TV",
  "UG",
  "UA",
  "AE",
  "GB",
  "US",
  "UY",
  "UZ",
  "VU",
  "VN",
  "YE",
  "ZM",
  "ZW",
];
