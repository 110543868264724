import React from "react";
import { Helmet } from "react-helmet";

export default function TermsEn() {
  return (
    <div className="flex flex-col">
      <Helmet>
        <title>Languise - Terms of service</title>
        <meta
          name="description"
          content="LANGUISEの使用に関する一般的な利用条件"
        />
        <link rel="canonical" href="https://languise.com/terms" />
      </Helmet>
      <div
        className="flex items-center bg-cover bg-center w-full sm:h-[360px] h-[220px]"
        style={{ backgroundImage: "url('/assets/5-bg.png')" }}
      >
        <div className="flex flex-col xl:gap-[23px] px-5 sm:px-0 sm:gap-4 gap-2 2xl:max-w-[1306px] max-w-[95%] w-full mx-auto">
          <h1 className="sm:text-[45px] text-[22px] text-[#333876] font-sans font-semibold text-start">
            General Terms and Conditions for the use of “LANGUISE”
          </h1>
        </div>
      </div>

      <div className="2xl:max-w-[1306px] max-w-[95%] w-full mx-auto flex flex-col px-5 sm:px-0 text-start sm:text-[20px] text-[12px] text-[#222222] font-sans">
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          1 Subject of the contract
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          1.1 These General Terms and Conditions (GTC) apply to the use of the
          AI-based Software (hereinafter referred to as “LANGUISE” or
          “Software”) provided by BUILBRIDGE, Niederkasseler Kirchweg 57, 40547
          Düsseldorf, Germany, provided on the website "www.languise.com", or as
          "Languise App".
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          1.2 LANGUISE is operated by BUILBRIDGE as a web-based SaaS solution.
          You are allowed to use LANGUISE which is stored and running on
          exclusively EU-based servers of BUILBRIDGE or of a service provider
          commissioned by BUILBRIDGE, such as Amazon Web Service, via an
          Internet connection and browser access for your own purposes during
          the term contract and to save and process your data using the
          Software.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          1.3 These GTC apply both to entrepreneurs in accordance with Section
          14 of the German Civil Code (BGB) and to consumers in accordance with
          Section 13 of the German Civil Code. A consumer is any natural person
          who concludes a legal transaction for purposes that cannot primarily
          be attributed to their commercial or independent professional
          activity. The “additional conditions for consumers” apply to
          consumers, additionally. Minors can only use our Software with the
          consent of their legal guardians.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          1.4 These GTC apply exclusively to the contractual relationship.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          1.5 You declare your agreement to the validity of these GTC when
          placing orders and registering as a user on the website or app by
          clicking on a corresponding button.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          2 Registration, assurances upon registration, conclusion of contract
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.1 In order to use our services, you must first register on the
          website. You are only allowed to register once and create only one
          user profile. The registration is free. Limited, free use without
          registration is also (currently) provided.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.2 You can create an Account via manual registration, or by using
          your Google Account login details. If you create an LANGUISE Account
          using those Third-Party platform account details, you authorize us to
          access and use information from these for authentication purposes.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.3 By completing your registration, you are making an offer to
          conclude a license agreement and guaranteeing that all the data you
          provided during registration is true and complete. You are obliged to
          report any changes to your registration data immediately. The license
          agreement concluded through registration includes the use of the “free
          30-day version” of the Software within the meaning of Section 3.1.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.4 BUILBRIDGE accepts this offer by activating the respective user
          account for the services. With this acceptance, the contract is
          concluded.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.5 If you do not use the Software yourself as a natural person, only
          those people who are in a service, employment or training relationship
          with you at the time the contract is concluded can be authorized to
          use it. Direct or indirect use by persons other than you or as
          described in sentence 1 is not permitted. You warrant that you have
          obliged every user to comply with these GTC and in this respect you
          release BUILBRIDGE from all claims in accordance with the regulation
          in section 6.7.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.6 You are obliged to keep the password you chose during registration
          and the other access data secret and to ensure that authorized persons
          to whom you provide access data also do the same. BUILBRIDGE will
          never ask you for to share your password at any time outside of the
          access to the Software.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.7 By presenting and advertising products and services on our
          website, we do not make a binding offer to sell specific products or
          services. If you would like to purchase paid versions of the Software
          (i.e. “paid versions” within the meaning of Section 3.1), you can
          initiate the initially non-binding ordering process by clicking the
          “Buy now” button. If provided for the respective paid version, in the
          next step you can specify how many users a license for the respective
          paid version should be purchased for. By clicking on the “Complete
          order / Order with obligation to pay” button, you place a binding
          order for the previously selected Software version. Confirmation of
          receipt of your order will be sent by email immediately after you
          submit the order. The point in time at which the contract with us
          comes into effect depends on the payment method you choose.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          3 Variants of use, prices, price changes and payment conditions
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          3.1 BUILBRIDGE offers registered users two usage options on its
          websites:
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          a) a free but functionally limited version (“Free Version”) and
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          b) several paid versions with additional functions (“paid version”).
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          3.2 Details about the fees for the individual paid versions are
          regulated on the websites in the “Prices” section. The prices stated
          there are binding and do not include the applicable statutory tax.
          Every fee is due for payment upon conclusion of the contract for the
          entire term.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          3.3 Payment may be made by credit card or Google Pay. If a fee cannot
          be collected, you will be responsible for all costs incurred, to the
          extent you are responsible for the cause of the occurrence, including
          any bank fees or equivalent fees associated with the refund.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          3.4 For the fees to be paid, you can download invoice in LANGUISE.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          4 Type and scope of the service
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          4.1 BUILBRIDGE makes the Software available for you to use in its
          current version at the router exit of the data center in which the
          server with the Software is located (“transfer point”). The Software,
          the computing power required to operate the Software in the data
          center and the storage and data processing space required for this are
          provided by BUILBRIDGE. However, BUILBRIDGE is not responsible for
          establishing and maintaining the data connection between your IT
          systems and the described transfer point.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          4.2 BUILBRIDGE grants you, for the term of the contract, the
          non-exclusive, non-transferable right, limited to the duration of the
          usage contract, to use the Software for your own purposes, to store
          and process your data using the Software, and to use the Software's
          user interface to be loaded into the RAM of the end devices used for
          this purpose in accordance with the contract for display on the screen
          and to make the resulting copies of the user interface.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          4.3 The contractually agreed service may not be made available to
          third parties unless this has been expressly agreed by the contract
          parties.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          5 Availability and changes to the Software
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          5.1 BUILBRIDGE will enable the use of the Software within the
          framework of the proven state of the art and strives to ensure the
          greatest possible availability. BUILBRIDGE reserves the right to
          temporarily restrict access to the Software or its use in whole or in
          part if this is necessary with regard to capacity limits, the security
          or integrity of the servers or to implement technical measures and if
          this serves to ensure the proper or improved provision of the services
          (e.g. during maintenance work, updates, upgrades). During the term of
          the Agreement, BUILBRIDGE will provide you with updates necessary to
          maintain the Software's compliance with the Agreement and will provide
          you with appropriate notice of these updates. Furthermore, BUILBRIDGE
          is not obliged to provide updates or upgrades for the Software or to
          adapt the Software in any other way to any changes in hardware and/or
          Software (in particular operating systems or web browsers).
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          5.2 BUILBRIDGE points out that restrictions or impairments to the
          services provided may arise that are beyond BUILBRIDGE's control. This
          includes, in particular, actions by third parties who do not act on
          behalf of BUILBRIDGE, technical conditions of the Internet that cannot
          be influenced by BUILBRIDGE, and force majeure. The hardware, Software
          and technical infrastructure you use can also have an impact on the
          services. To the extent that such circumstances influence the
          availability or functionality of the service provided by BUILBRIDGE,
          this has no effect on the contractual conformity of the services
          provided.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          5.3 You are obliged to report any functional failures, malfunctions or
          impairments of the Software to BUILBRIDGE immediately and as precisely
          as possible. If you fail to cooperate, Section 536c of the German
          Civil Code (BGB) applies accordingly.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          5.4 BUILBRIDGE is entitled to further develop and change the Software
          if such further development or change (i) is necessary for BUILBRIDGE
          to comply with mandatory legal requirements, (ii) merely means an
          adaptation to the proven or current state of the art or (iii ) does
          not go beyond what is customary for comparable Software and what is
          reasonable for you, i.e. does not disappoint your legitimate
          expectation of continuing to use essential functions available to the
          Software.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          6 Obligations of the user
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          6.1 In order to use the Software, you must meet the system
          requirements stated in the product description; You are responsible
          for this yourself. Using the Software requires a sufficient internet
          connection, which you must ensure yourself. Offline use of the
          Software is not possible.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          6.2 You are solely responsible for properly and regularly backing up
          your data. This also applies to documents provided by BUILBRIDGE in
          the course of contract processing (e.g. invoices).
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          6.3 If you provide BUILBRIDGE with protected content (e.g. graphics,
          brands, texts and other content protected by copyright or trademark
          law as part of the use of the Software), you grant BUILBRIDGE all the
          rights necessary to carry out the contract. This includes in
          particular the right to make the relevant content accessible to other
          users, if necessary, as part of the execution of the contract. In this
          respect, you assure that you have all necessary rights to the
          materials provided in order to grant BUILBRIDGE the corresponding
          rights.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          6.4 You are obliged to comply with applicable laws and the rights of
          third parties when using the content and services on the websites. In
          particular, you are prohibited from
        </p>
        <ul className="list-outside pl-10 sm:mt-10 mt-2 sm:leading-9 leading-6">
          <li className="list-disc indent-4">
            use offensive or defamatory content, regardless of whether this
            content concerns other users, BUILBRIDGE employees or other people
            or companies,
          </li>
          <li className="list-disc indent-4">
            to use pornographic content or content that violates youth
            protection laws or to advertise, offer or distribute pornographic
            products or products that violate youth protection laws,
          </li>
          <li className="list-disc indent-4">
            to harass other users unreasonably (particularly through spam) (e.g.
            in accordance with Section 7 UWG),
          </li>
          <li className="list-disc indent-4">
            to use content protected by law (e.g. by copyright, trademark,
            patent, design or utility model law) without being authorized to do
            so, or to advertise, offer or distribute legally protected goods or
            services and
          </li>
          <li className="list-disc indent-4">
            engage in or promote anti-competitive conduct, including progressive
            solicitation (such as chain, pyramid or pyramid schemes).
          </li>
        </ul>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          6.5 You are also prohibited from doing the following:
        </p>
        <ul className="list-outside pl-10 sm:mt-10 mt-2 sm:leading-9 leading-6">
          <li className="list-disc indent-4">
            Use of any mechanisms, software or scripts in connection with the
            use of the Website. However, you may use the interfaces, in
            particular APIs or software, that are made available to you as part
            of the services offered on our website or app.
          </li>
          <li className="list-disc indent-4">
            Dissemination and public reproduction of content from the websites
            or from other users.
          </li>
          <li className="list-disc indent-4">
            Any action that is likely to impair the functionality of the
            LANGUISE infrastructure, in particular to place an excessive load on
            it.
          </li>
        </ul>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          6.6 If the content you have posted or your use of the services
          violates the rights of third parties or legal requirements, you will
          immediately stop the non-contractual and/or illegal use.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          6.7 You release BUILBRIDGE from all claims, including claims for
          damages, that other users or other third parties assert against
          BUILBRIDGE due to an infringement of their rights by (i) the content
          you have posted, or (ii) your use of the Software. You shall bear all
          reasonable costs incurred by us as a result of any infringement of
          third-party rights, including reasonable costs incurred for legal
          defense. The above obligation from this section 6.7 does not apply if
          BUILBRIDGE is responsible for the infringement. All further rights and
          claims for damages of BUILBRIDGE remain unaffected.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          7 Warranty
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          7.1 You must report any defects in the Software to BUILBRIDGE
          immediately and in writing. The written notice of defects must
          describe the defect and the corresponding data processing environment
          as precisely as possible.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          7.2 The legal regulations regarding warranties in rental agreements
          generally apply. Sections 536b BGB (knowledge of the tenant about the
          defect upon conclusion of the contract or acceptance), 536c BGB
          (defects occurring during the rental period; notification of defects
          by the tenant) applies. No-fault liability for defects existing at the
          time of conclusion of the contract in accordance with Section 536a
          Paragraph 1 of the German Civil Code (the landlord's obligation to pay
          damages) is excluded.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          7.3 Warranty rights do not apply if the defect in the Software arose
          due to inadmissible, unsuitable or improper handling or use of the
          Software by you or due to a change to the Software not authorized by
          BUILBRIDGE.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          8 Liability of BUILBRIDGE
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          8.1 BUILBRIDGE is liable to you in all cases of contractual and
          non-contractual liability in the event of intent and gross negligence,
          in the event of culpable injury to life, body or health, in the
          assumption of a guarantee and in the event of liability under the
          Product Liability Act.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          8.2 In other cases, BUILBRIDGE is only liable in the event of a simple
          negligent breach of an essential contractual obligation, i.e. a
          contractual obligation whose fulfillment is essential for the proper
          execution of the contract and on whose compliance you as a customer
          can regularly rely, and this is limited to the replacement of the
          foreseeable and typical damage. In all other cases, BUILBRIDGE's
          liability is excluded.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          8.3 If you suffer damages resulting from the loss of data, BUILBRIDGE
          is not liable for this if the damages could have been avoided if you
          had regularly and completely backed up all relevant data. You will
          carry out regular and complete data backups yourself or have them
          carried out by a third party and are solely responsible for this.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          9 Termination of the contract
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          9.1 The contract for the registered free version of the Software runs
          for 30 days. You can terminate this contract earlier at any time and
          without giving reasons or notice. Please send us your cancellation
          notice using the contact form available on every page of the websites
          and provide your username and email address registered on our website
          or App. Alternatively, you can terminate your contract under
          “Subscription” in the account area. After the expiry of 30 days of
          registration for the free version, you will continue the usage as paid
          version of LANGUISE.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          9.2 The contract for the paid versions of LANGUISE is concluded as a
          subscription contract with a fixed minimum usage period of, for
          example, one or twelve months. The length of the minimum usage period
          is determined in the ordering process. Subject to the contrary
          regulation for consumers in Section 11 of these contractual
          conditions, the subscription contract for the paid version will be
          extended after the expiry of the minimum period of use by a period
          corresponding to the minimum period of use, unless the contract is
          terminated beforehand in a timely manner. The subscription contract
          for the paid version can be canceled by you or by BUILBRIDGE without
          giving reasons with thirty (30) days' notice to the end of the minimum
          usage period booked in the ordering process or thereafter to the end
          of each extension period. Termination can be declared to BUILBRIDGE by
          using the contact form accessible from any page on the LANGUISES
          website or by email. When canceling, you must provide the username and
          email address registered on the LANGUISE websites.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          9.3 The right of both parties to terminate the usage contract for the
          Software without notice for good cause remains unaffected.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          9.4 If there is good cause, BUILBRIDGE can also impose the following
          sanctions against you, regardless of termination:
        </p>
        <ul className="list-outside pl-10 sm:mt-10 mt-2 sm:leading-9 leading-6">
          <li className="list-disc indent-4">
            Delete any infringing content you have posted;
          </li>
          <li className="list-disc indent-4">Issuance of a warning;</li>
          <li className="list-disc indent-4">
            (temporary) blocking of access to the services offered by BUILBRIDGE
            until you have remedied an identified legal violation.
          </li>
        </ul>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          10 Final provisions
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          10.1 Changes and additions to these contractual conditions must be
          made in writing. This applies in particular to a waiver of this
          written form clause.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          10.2 If individual provisions of these contractual terms and
          conditions are or become invalid, the effectiveness of the remaining
          provisions will not be affected. The parties undertake to replace the
          invalid provision with one that comes closest to the economic intent
          of the invalid provision in a legally permissible manner. The same
          applies to gaps in the agreement.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          10.3 The law of the Federal Republic of Germany applies, excluding the
          UN Convention on Contracts for the International Sale of Goods. If you
          are a consumer and have your habitual residence in another country at
          the time the contract is concluded, the application of the mandatory
          legal provisions of that country remains unaffected by the choice of
          law made in sentence 1.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          10.4 If you are a merchant, the exclusive place of jurisdiction is
          Düsseldorf. Otherwise, the applicable legal provisions apply to local
          and international jurisdiction.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] text-center font-semibold">
          Additional conditions for consumers
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          The following conditions only apply to consumers. In the event of a
          conflict with the remaining contractual conditions, the provisions of
          this section take precedence.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          11 Term and payment conditions for consumer transactions
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          If you conclude the usage contract via the paid version as a consumer,
          this contract will be extended for an indefinite period of time after
          the minimum usage period has expired, contrary to Section 9.2. In this
          case, you can cancel the paid version at any time with one (1) months’
          notice after the minimum usage period booked in the ordering process
          has expired.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          12 Cancellation policy (only for CONSUMERS)
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          12.1 Right of withdrawal: As a consumer, you have the right to
          withdraw from this contract within 14 days without giving reasons. The
          period for revocation begins on the day the contract is concluded; We
          expressly point out the regulations on the exclusion of the right of
          withdrawal for digital content – see section 14.3 below. To meet the
          cancellation deadline, it is sufficient to send a clear statement of
          your decision to withdraw this contract in a timely manner using the
          contact form available on any of our websites or by email
          “info(at)languise.com”.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          12.2 Consequences of withdrawal: In the event of an effective
          withdrawal of a paid version, the services received by both parties
          must be returned immediately, but no later than within 14 days, and
          any benefits derived (e.g. interest) must be surrendered.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          13 Online dispute resolution for consumers
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          For our customers and consumers, the European Commission provides a
          platform for out-of-court online dispute resolution (OS platform),
          which can be accessed at https://www.ec.europa.eu/consumers/odr. You
          can find our email address in our legal notice. We are neither obliged
          nor willing to take part in the dispute resolution process.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] text-center font-semibold">
          AI use conditions
        </h2>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          1 Scope
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          1.1 These terms and conditions (“AI Terms”) apply to the access to and
          use of artificial intelligence services or services supported by
          artificial intelligence and functions (“AI Functions”) of LANGUISE
          (“LANGUISE” or “Software”) as Software-as-a-Service provided by
          BUILBRIDGE. The AI conditions are part of our General Terms and
          Conditions (“Terms and Conditions”), which also apply to the AI
          functions.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          2 Use of our AI features and obligations of the user
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.1 If you use our AI features, you may provide data to be processed
          by the Software (“AI Input”) and receive data generated and returned
          based on your AI Input (“AI Output “). If you use our AI features, the
          right to use our AI features includes the right to use any results of
          the AI features based on the input you provide.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.2 Your data is processed by AI and machine learning models to
          generate outputs. These are currently provided by a third party, by
          using Chat GPT API provided by OpenAI Inc., 3180 18th St, San
          Francisco, California 94110, USA as part of the AI-enabled features
          for the BUILBRIDGE (“AI-enabled features”).
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.3 Due to the novelty of AI technology, all AI-enabled functions are
          provided “as is”. If users of the customer account make use of
          AI-enabled features, this also means the instruction to BUILBRIDGE to
          transmit the relevant data to the respective Third-Party provider.
          OpenAI has declared to no longer use the data submitted via the API to
          train its models unless customers expressly consent to this use.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          “Our Commitments” OpenAI:{" "}
          <a
            href="https://openai.com/enterprise-privacy"
            className="text-[#11135C] underline"
          >
            https://openai.com/enterprise-privacy#our-commitments
          </a>
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.4 You are solely responsible for the content, development,
          operation, maintenance and use of your data used as AI input or AI
          output. You ensure that your use of our AI features and the use of
          your AI output (i) does not violate any applicable law, (ii) does not
          violate these AI Terms, our Terms and Conditions or any other
          agreement regarding the use of our Services or (iii) will not violate,
          violate or misappropriate our rights or the rights of any third party.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.5 You must ensure that the input of personal data, its use in
          relation to the AI functions and the use of the output, if it contains
          personal data, complies with the requirements of the GDPR, as you
          remain the data controller. We or our service providers process the
          data on your behalf and in accordance with the requirements of the
          concluded contract for order processing. When using our AI functions,
          it is prohibited to use and enter special data categories in
          accordance with Art. 9 GDPR or information that is subject to the
          protection of trade secrets of the user's company or a third party. It
          is also prohibited to use the AI functions for illegal purposes.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.6 Otherwise, the terms of use of our General Terms and Conditions,
          in particular Sections 4.2, 4.3 and Section 6, apply accordingly.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.7 If you use our AI features, you may not use them in a manner that
          violates OpenAI's policies, including but not limited to the Content
          Policy (OpenAI); Sharing and Publication Policy (OpenAI); Community
          Guidelines (OpenAI).
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          3 Scope of AI output
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          3.1 Our AI capabilities are designed to identify connections in data,
          intelligently connect data, draw conclusions and make predictions to
          automate processes, create content, provide inspiration and complete
          tasks faster.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          3.2 You acknowledge that due to the nature of AI and machine learning
          models, AI output may not be unique and our AI functions may produce
          the same or similar output as other third parties. You also
          acknowledge that the Output is not a work created by a natural person
          and is therefore generally not protected by copyright, at least prior
          to reasonable editing.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          3.3 You further acknowledge that AI models may produce inaccurate or
          offensive content that does not reflect the views of BUILBRIDGE or
          your own views. You should carefully consider the generated AI output
          before relying on, publishing or otherwise using it. AI output that
          appears accurate due to its level of detail may still contain
          inaccurate material.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          4 Warranty and liability
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          4.1 BUILBRIDGE assumes no liability for the results that can be
          achieved by AI models through your use of our AI functions. You
          acknowledge and agree that any content or data generated through the
          use of our AI features will be processed at your own risk and
          responsibility. In particular, BUILBRIDGE assumes no liability for the
          accuracy, quality, completeness, reliability or suitability of the
          content or results provided as part of the AI functions for the
          intended purpose.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          4.2 Notwithstanding anything to the contrary in our Terms and
          Conditions or other agreements with us, downtime of AI functions
          resulting from the failure of a third-party provider will not be
          included in the calculation of availability and downtime.
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          4.3 Otherwise, the warranty and liability provisions of our general
          terms and conditions, in particular numbers 7 and 8, apply
          accordingly.
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          5 Release of Liability
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6 mb-20">
          5.1 You release BUILBRIDGE from all claims, including claims for
          damages, brought by other users or other third parties against
          BUILBRIDGE due to the violation of their rights by (i) content posted
          by you or (ii) use of the AI functions by you or (iii) Content used by
          you as input can be claimed. You shall bear all reasonable costs
          incurred by us as a result of any infringement of third-party rights,
          including reasonable legal defense costs. All further rights and
          claims for damages of BUILBRIDGE remain unaffected by this.
        </p>
      </div>
    </div>
  );
}
