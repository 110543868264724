import React from "react";

export default function TextField({
  placeholder,
  type,
  value,
  onChange,
  show_password,
  onClick,
  showClicked,
  disabled = false,
  className,
}) {
  return (
    <div className={`${className} relative flex items-center`}>
      <input
        className={`border border-[#8B8B8B] sm:h-[40px] h-[35px] sm:text-[16px] text-[14px] sm:px-[20px] px-[10px] w-full rounded-[10px] font-sans focus:outline-none sm:placeholder:text-[16px] placeholder:text-[14px] ${value?"":"bg-transparent"}`}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      />
      {show_password ? (
        <img
          onClick={onClick}
          className={`w-[${showClicked ? "28.5" : "23"}px] h-[${
            showClicked ? "23" : "17"
          }px] absolute right-[17px] cursor-pointer`}
          src={`${
            showClicked ? "/assets/show-eye.svg" : "/assets/show-pass.png"
          }`}
          alt="Show Password"
        />
      ) : null}
    </div>
  );
}
