import React, { useEffect, useState } from "react";
import api from "../services/api";
import { useTranslation } from "react-i18next";

export default function InvoiceTable() {
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState([]);
  const [paginationNumber, setPaginationNumber] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultText, setResultText] = useState("");

  const getJapanDate = (timestamp, offset_month = 0, offset_year = 0) => {
    const date = new Date(timestamp);
    date.setMonth(date.getMonth() + offset_month);
    date.setFullYear(date.getFullYear() + offset_year);
    return date.toLocaleString("ja-JP", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "Asia/Tokyo",
    });
  };

  const convertCurrency = (currency, amount) => {
    const symbol = {
      jpy: "¥",
      usd: "$",
      eur: "€",
    };
    if (currency === "jpy") {
      return symbol[currency] + amount.toLocaleString();
    }
    return symbol[currency] + (amount / 100).toLocaleString();
  };

  useEffect(() => {
    setResultText(t("invoiceTableText1"));
    api
      .get("/payment/invoice/")
      .then((rst) => {
        if (rst.data.length === 0)
          setResultText(t("invoiceTableText2"));
        setInvoices(rst.data);
      })
      .catch((err) => {
        setResultText(t("invoiceTableText2"));
        setInvoices([]);
        console.log(err);
      });
  }, []);

  return (
    <div className="md:max-w-[1440px] md:w-full w-[100vw] md:px-0 px-4 mx-auto pb-10 overflow-x-auto">
      <table className="min-w-[700px] w-full text-[#555555]">
        <thead className="bg-gray-100 h-12">
          <tr>
            <th className="min-w-[80px]">{t("invoiceTableText3")}</th>
            <th className="min-w-[80px]">{t("invoiceTableText4")}</th>
            <th className="min-w-[80px]">{t("invoiceTableText5")}</th>
            <th className="min-w-[80px]">{t("invoiceTableText6")}</th>
            <th className="min-w-[80px]">{t("invoiceTableText7")}</th>
          </tr>
        </thead>
        <tbody>
          {invoices.length === 0 && (
            <tr>
              <td colSpan={5} className="h-20">
                {resultText}
              </td>
            </tr>
          )}
          {invoices.length > 0 &&
            invoices.map((item, index) => {
              if (
                index < (currentPage - 1) * paginationNumber ||
                index > currentPage * paginationNumber - 1
              )
                return null;
              return (
                <tr className="h-14 border-b-[1px]" key={index}>
                  <td>{getJapanDate(item.created * 1000)}</td>
                  <td>{t("invoiceTableText8")}</td>
                  <td>{item.number}</td>
                  <td>
                    {convertCurrency(
                      item.currency.toLowerCase(),
                      item.amount_paid
                    )}
                  </td>
                  <td>
                    <a
                      href={item.hosted_invoice_url}
                      target="blank"
                      className="text-[#4B5090] hover:underline hover:underline-offset-2"
                    >
                      {t("invoiceTableText9")}
                    </a>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {invoices.length > 0 && (
        <div className="flex min-w-[600px] w-full justify-between mt-6 text-[#555555] px-6">
          <div className="gap-6 flex flex-row items-center">
            <p>{t("invoiceTableText10")}</p>
            <select
              value={paginationNumber}
              onChange={(e) => setPaginationNumber(e.target.value)}
              className="w-12 h-10 border outline-none text-center"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
            <p className="text-sans">
              {(currentPage - 1) * paginationNumber + 1} -{" "}
              {currentPage * paginationNumber} / {invoices.length}
            </p>
            <div className="flex flex-row">
              {currentPage === 1 ? (
                <>
                  <span className="w-10 h-10 mt-[24px] pl-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 33.911 33.664"
                    >
                      <g transform="translate(-1239.828 -745.682)">
                        <path
                          d="M-15223.836-13886.5l-24,16.873,24,15.127"
                          transform="translate(16497 14633)"
                          fill="none"
                          stroke="#C0C0C0"
                          strokeWidth="4"
                        />
                        <line
                          y2="32"
                          transform="translate(1240.828 746.5)"
                          fill="none"
                          stroke="#C0C0C0"
                          strokeWidth="4"
                        />
                      </g>
                    </svg>
                  </span>
                  <span className="w-10 h-10 mt-[24px] pl-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 26.379 33.664"
                    >
                      <path
                        d="M-15223.836-13886.5l-24,16.873,24,15.127"
                        transform="translate(15249.64 13887.318)"
                        fill="none"
                        stroke="#C0C0C0"
                        strokeWidth="4"
                      />
                    </svg>
                  </span>
                </>
              ) : (
                <>
                  <button
                    className="w-10 h-10 mt-[10px] hover:bg-gray-100 pl-4"
                    onClick={() => setCurrentPage(1)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 33.911 33.664"
                    >
                      <g transform="translate(-1239.828 -745.682)">
                        <path
                          d="M-15223.836-13886.5l-24,16.873,24,15.127"
                          transform="translate(16497 14633)"
                          fill="none"
                          stroke="#707070"
                          strokeWidth="4"
                        />
                        <line
                          y2="32"
                          transform="translate(1240.828 746.5)"
                          fill="none"
                          stroke="#707070"
                          strokeWidth="4"
                        />
                      </g>
                    </svg>
                  </button>
                  <button
                    className="w-10 h-10 mt-[10px] hover:bg-gray-100 pl-4"
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 26.379 33.664"
                    >
                      <path
                        d="M-15223.836-13886.5l-24,16.873,24,15.127"
                        transform="translate(15249.64 13887.318)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="4"
                      />
                    </svg>
                  </button>
                </>
              )}
              {currentPage === Math.ceil(invoices.length / paginationNumber) ? (
                <>
                  <span className="w-10 h-10 mt-[24px] pl-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 26.379 33.664"
                    >
                      <path
                        d="M-15247.836-13886.5l24,16.873-24,15.127"
                        transform="translate(15248.411 13887.318)"
                        fill="none"
                        stroke="#C0C0C0"
                        strokeWidth="4"
                      />
                    </svg>
                  </span>
                  <span className="w-10 h-10 mt-[24px] pl-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 33.911 33.664"
                    >
                      <g transform="translate(-1315.589 -745.682)">
                        <path
                          d="M-15247.836-13886.5l24,16.873-24,15.127"
                          transform="translate(16564 14633)"
                          fill="none"
                          stroke="#C0C0C0"
                          strokeWidth="4"
                        />
                        <line
                          y2="32"
                          transform="translate(1348.5 746.5)"
                          fill="none"
                          stroke="#C0C0C0"
                          strokeWidth="4"
                        />
                      </g>
                    </svg>
                  </span>
                </>
              ) : (
                <>
                  <button
                    className="w-10 h-10 mt-[10px] hover:bg-gray-100 pl-4"
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 26.379 33.664"
                    >
                      <path
                        d="M-15247.836-13886.5l24,16.873-24,15.127"
                        transform="translate(15248.411 13887.318)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="4"
                      />
                    </svg>
                  </button>
                  <button
                    className="w-10 h-10 mt-[10px] hover:bg-gray-100 pl-4"
                    onClick={() =>
                      setCurrentPage(
                        Math.ceil(invoices.length / paginationNumber)
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 33.911 33.664"
                    >
                      <g transform="translate(-1315.589 -745.682)">
                        <path
                          d="M-15247.836-13886.5l24,16.873-24,15.127"
                          transform="translate(16564 14633)"
                          fill="none"
                          stroke="#707070"
                          strokeWidth="4"
                        />
                        <line
                          y2="32"
                          transform="translate(1348.5 746.5)"
                          fill="none"
                          stroke="#707070"
                          strokeWidth="4"
                        />
                      </g>
                    </svg>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
