import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../services/api";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../redux/actions/languiseAction";
import { getA8ID } from "../services/utils";
import { useTranslation } from "react-i18next";
import Storage from "../translations/storage";

export default function PaymentSuccess() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.LanguiseReducer.userInfo);
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [processFinished, setProcessFinished] = useState(false);
  const [agree, setAgree] = useState(true);
  const uiLang = useSelector((state) => state.LanguiseReducer.uiLang);
  const [lang, setLang] = useState("jp");

  useEffect(() => {
    setLang(uiLang);
  }, [uiLang]);

  useEffect(() => {
    api
      .put("/user/", {
        receive_notifications: agree,
      })
      .catch((err) => {
        console.log(err);
      });
  }, [agree]);

  useEffect(() => {
    api
      .post("/user/plan/", { id: id })
      .then((rst) => {
        dispatch(getUserInfo());
        setProcessFinished(true);
        const a8 = getA8ID();
        const { so, si } = rst.data;
        if (a8) {
          fetch(
            `https://px.a8.net/a8fly/earnings?a8=${a8}&pid=s00000026066001&so=${so}&si=${si}&currency=JPY`,
            {
              method: "GET",
              mode: "no-cors",
            }
          ).catch((err) => {
            console.log(err);
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, dispatch]);

  return (
    <div className="flex flex-col flex-grow md:mx-0 mx-5">
      <div className="flex !opacity-100 !translate-y-0 fixed inset-0 bg-black/40 z-30 !transition-all !ease-in-out !duration-300">
        <div className="flex flex-col max-w-[830px] w-full md:h-[400px] h-[300px] mx-auto my-auto z-50 bg-[#F0F1F8] px-[12px] py-[12px] rounded-lg">
          {processFinished ? (
            <>
              <div className="text-[#4B5090] font-semibold font-sans md:text-[30px] text-[20px] text-center md:mt-12 mt-8">
                {t("paymentSuccessPageText1")}
              </div>
              {lang !== "jp" && (
                <div className="text-[#6E6E6E] md:text-[16px] text-[12px]">
                  Enjoy Languise with
                </div>
              )}
              <div className="flex flex-row text-[#4B5090] font-sans text-center items-end mx-auto">
                <div className="md:text-[56px] text-[36px] text-center font-semibold">
                  {userInfo.plan}
                </div>
                <div className="md:text-[26px] text-[20px] text-center md:pb-3 pb-2 pl-2 font-semibold">
                  {t("plan")}
                </div>
              </div>
              {lang === "jp" && (
                <div className="text-[#6E6E6E] md:text-[16px] text-[12px]">
                  でLanguiseをお楽しみください。
                </div>
              )}
              <div className="flex flex-row mx-auto text-[#6E6E6E] md:text-[14px] text-[12px] md:mt-10 mt-6">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={agree}
                  onChange={(e) => setAgree(e.target.checked)}
                />
                {t("paymentSuccessPageText2")}
              </div>
              <button
                onClick={() => navigate("/")}
                className="bg-[#4B5090] text-white md:text-[16px] text-[14px] md:w-[170px] w-[120px] h-[40px] rounded-[10px] mx-auto mt-6 md:mb-10 mb-6"
              >
                {t("goHome")}
              </button>
            </>
          ) : (
            <>
              <div className="text-[#4B5090] font-semibold font-sans md:text-[30px] text-[20px] text-center my-auto">
                {t("paymentSuccessPageText3")}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
