import React from "react";

export default function BannedCountry() {
  return (
    <div className="flex flex-col flex-grow">
      <div className="flex flex-col xl:gap-[23px] sm:gap-4 gap-2 2xl:max-w-[1306px]w-full sm:mt-20 mt-12 mx-auto">
        <p className="w-full sm:text-[45px] text-[22px]  text-[#333876] font-sans text-center mb-[50px]">
          お住まいの国からはご利用頂けません
        </p>
        <div className=" text-[#333876] font-sans text-center">
          <p className="w-full sm:text-[32px] text-[16px] mb-20">
            詳しくは
            <a
              href="https://help.openai.com/en/articles/5347006-openai-api-supported-countries-and-territories"
              className="underline"
            >
              こちら
            </a>
            をご参照ください。
          </p>
        </div>
      </div>
    </div>
  );
}
