import React from "react";

export default function ConfirmModal({
  isOpen,
  text = "Do you want to continue now?",
  buttonText = "Ok",
  cancelText,
  onAgree,
  onCancel,
  swap = false,
}) {
  return (
    <>
      {isOpen && (
        <div className="flex bg-black/40 w-full h-screen z-[9999] fixed top-0 left-0 sm:px-0 px-5">
          <div className="flex flex-col md:max-w-[830px] max-w-max w-full mx-auto my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] rounded-[10px]">
            <div className="flex justify-end">
              <img
                onClick={() => onCancel(false)}
                className="w-[20px] h-[20px] cursor-pointer"
                src="/assets/cancel-modal.svg"
                alt="Cancel"
              />
            </div>
            <div className="text-[#4B5090] font-sans sm:text-[20px] text-[14px] text-center sm:mt-8 mt-4 px-10">
              {text}
            </div>
            {!swap ? (
              <div className="flex justify-center sm:my-12 my-6 sm:gap-10 gap-5 text-[16px]">
                <button
                  onClick={onAgree}
                  className="max-w-[160px] w-full bg-[#4B5090] h-[40px] text-white rounded-[10px]"
                >
                  {buttonText}
                </button>
                {cancelText && (
                  <button
                    onClick={() => onCancel(false)}
                    className="max-w-[160px] w-full bg-[#4B5090] h-[40px] text-white rounded-[10px]"
                  >
                    {cancelText}
                  </button>
                )}
              </div>
            ) : (
              <div className="flex justify-center sm:my-12 my-6 sm:gap-10 gap-5 text-[16px]">
                {cancelText && (
                  <button
                    onClick={() => onCancel(false)}
                    className="max-w-[160px] w-full bg-[#4B5090] h-[40px] text-white rounded-[10px]"
                  >
                    {cancelText}
                  </button>
                )}
                <button
                  onClick={onAgree}
                  className="max-w-[160px] w-full bg-[#4B5090] h-[40px] text-white rounded-[10px]"
                >
                  {buttonText}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
