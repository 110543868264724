import React, { useState, useEffect } from "react";
import CheckContent from "../components/CheckContent";
import AboutTranslation from "../components/AboutTranslation";
import AboutProofreading from "../components/AboutProofreading";
import AboutSummary from "../components/AboutSummary";
import Plans from "../components/Plans";
import { Helmet } from "react-helmet";

export default function AboutEn() {
  const [clicked, setClicked] = useState(1);
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    if (!document) return;
    setIsSticky(window.pageYOffset > 300);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>Languise - About</title>
        <meta
          name="description"
          content="ビジネスの新しいパートナー、先端AI技術搭載の全方位文章業務支援ツール。最新のchatGPTエンジンを核として、Languiseはビジネスシーンの文章業務を全面的にサポートします。世界100以上の言語に対応しており、複雑で多様なコミュニケーションニーズに応える能力を持っています。翻訳・校正・要約という、専門家でも手間をかける三大課題を、AIの卓越した技術で瞬時に解決。これにより、ミスのリスクを大幅に減少させつつ作業の効率性を飛躍的に向上させることが可能です。さらに、Languiseの真骨頂はそのファイル処理能力です。PDF、Word、PowerPointなどの主要なファイル形式に対して、特別な前処理なしで対応。写真や図、グラフの配置をそのまま保ちつつ、内容を精緻に処理。作業時間の短縮とともに高い品質の維持を実現します。"
        />
        <link rel="canonical" href="https://languise.com/about" />
      </Helmet>
      <div
        className="flex sm:justify-center justify-start items-center w-full bg-cover bg-center h-[240px] sm:h-[320px] lg:h-[360px] lg:pl-10"
        style={{
          background:
            "linear-gradient(90deg, #229CB9 0%, #2C574B 16%, #0F3D51 30%, #6C0964 70%, #065D77 86%, #030412 100%) 0% 0% no-repeat padding-box",
        }}
      >
        <div className="xl:max-w-[1060px] sm:max-w-[600px] md:max-w-[95%] px-6 sm:px-0 w-full flex flex-col">
          <h1 className="text-white font-sans xl:text-[42px] md:text-[32px] lg:text-[40px] sm:text-[24px] text-[18px] sm:tracking-normal text-start font-[600]">
            <p className="mb-4">
              Advanced AI-Powered <br /> High-Accuracy Document Processing Tool
            </p>
            <p>
              Translate, Proofread, and Summarize <br /> Documents Without
              Changing Their Format
            </p>
          </h1>
        </div>
      </div>
      {/* World Image Content */}
      <div className="xl:flex flex-row justify-center xl:max-w-[1145px] max-w-[1400px] w-full mx-auto xl:mt-[113px] sm:mt-[60px] mt-[26px] gap-[75px] xl:px-0 px-5">
        <div className="flex flex-col gap-6 xl:max-w-[575px] max-w-[1400px] w-full">
          <p className="font-sans md:text-xl sm:text-[16px] text-[14px] sm:leading-8 leading-[24px] text-[#222222] text-justify">
            Languise is a high-precision document support tool that combines
            proprietary document processing technology with the ChatGPT engine.
            It translates, proofreads, and summarizes PDF, Word, PowerPoint, and
            Excel files while preserving the layout of images, tables, and
            graphs. Supporting over 100 languages, Languise is ideal for both
            everyday business documents and specialized fields like medicine,
            engineering, and law.
          </p>
          <p className="font-sans md:text-xl sm:text-[16px] text-[14px] sm:leading-8 leading-[24px] text-[#222222] text-justify">
            You can download translated and proofread files or use the preview
            function to compare them side-by-side with the original.
            Additionally, a built-in chatbot answers questions about unfamiliar
            words, technical terms, and document-related queries, enhancing
            reading efficiency.
          </p>
          <p className="font-sans md:text-xl sm:text-[16px] text-[14px] sm:leading-8 leading-[24px] text-[#222222] text-justify">
            All features are backed by robust security. Processed documents and
            text data are deleted and not reused for AI learning or other
            purposes.
          </p>
        </div>
        <div className="flex justify-center w-full xl:mt-0 sm:mt-[30px] mt-[100px]">
          <img
            className="flex sm:max-w-[513px] max-w-[320px] w-full sm:max-h-[426px] max-h-[266px] h-full"
            src="/assets/1-img-globe.png"
            alt="Globale"
          />
        </div>
      </div>
      {/* Check Content */}
      <div className="flex flex-col xl:max-w-[1145px] max-w-[1400px] w-full mx-auto xl:mt-[104px] sm:mt-[70px] mt-[42.5px] gap-[25px] xl:px-0 px-5">
        <CheckContent
          title="Advanced AI Technology for High-Precision Text Generation"
          content="Provides natural and highly accurate text in all processing tasks."
        />
        <CheckContent
          title="Significantly Improve Document Workflow Efficiency"
          content="High-precision translation, proofreading, and summarization, along with a new preview feature, enhance the efficiency of all document-related tasks."
        />
        <CheckContent
          title="Reliable Security"
          content="All processed data is deleted and not reused."
        />
        <CheckContent
          title="Preserve Original File Format"
          content="Simply upload your file, and Languise will automatically read and process the content without altering the file format."
        />
      </div>
      <div className="flex xl:max-w-[1145px] max-w-[1400px] w-full mx-auto mt-[53px] xl:px-0 px-5">
        <div className="flex flex-col gap-[30px] rounded-[20px] bg-[#F0F1F8] px-5 sm:px-[60px] pt-8 sm:pt-[42px] pb-28">
          <p className="text-[#333876] xl:text-xl text-[16px] font-sans text-start font-[600]">
            <h3 className="xl:text-2xl mb-7 sm:mb-10 font-[600]">
              Supported Languages Include:
            </h3>
            <span className="xl:text-xl text-sm">
              English (US), English (UK), Spanish, French, German, Chinese
              (Simplified), Chinese (Traditional), Russian, Italian, Portuguese,
              Dutch, Arabic, Japanese, Korean, Turkish, Ukrainian, Swedish,
              Danish, Norwegian, Finnish, Greek, Hebrew, Indonesian, Malay,
              Thai, Czech, Hungarian, Polish, Romanian, Bulgarian, Serbian,
              Croatian, Slovenian, Latvian, Lithuanian, Hindi, Gujarati,
              Marathi, Tamil, Kannada, Malayalam, Urdu, Punjabi, and more.
            </span>
          </p>
        </div>
      </div>
      <div className="w-full" id="tabs-section">
        <div
          className={`flex w-full xl:gap-[30px] sm:gap-5 gap-[10px] bg-white justify-center xl:mt-[108px] sm:mt-[65px] mt-[41px] px-5 py-0 ${
            isSticky ? "sticky-tabs" : ""
          }`}
          id="tabs"
        >
          <div
            onClick={() => setClicked(1)}
            className={`xl:max-w-[380px] sm:max-w-[240px] max-w-[100px] w-full ${
              clicked === 1
                ? "xl:h-[136px] sm:h-[77px] h-[41px] !rounded-b-none"
                : "xl:h-[120px] sm:h-[70px] h-[37px]"
            } bg-[#EAEAEA] xl:rounded-[20px] sm:rounded-[15px] rounded-[10px] flex justify-center items-center text-[#333876] xl:text-[32px] sm:text-[20px] text-[12px] font-sans font-[600] cursor-pointer`}
          >
            Translation Feature
          </div>
          <div
            onClick={() => setClicked(2)}
            className={`xl:max-w-[380px] sm:max-w-[240px] max-w-[100px] w-full ${
              clicked === 2
                ? "xl:h-[136px] sm:h-[77px] h-[41px] !rounded-b-none"
                : "xl:h-[120px] sm:h-[70px] h-[37px]"
            } bg-[#EAEAEA] xl:rounded-[20px] sm:rounded-[15px] rounded-[10px] flex justify-center items-center text-[#333876] xl:text-[32px] sm:text-[20px] text-[12px] font-sans font-[600] cursor-pointer`}
          >
            Proofreading Feature
          </div>
          <div
            onClick={() => setClicked(3)}
            className={`xl:max-w-[380px] sm:max-w-[240px] max-w-[100px] w-full ${
              clicked === 3
                ? "xl:h-[136px] sm:h-[77px] h-[41px] !rounded-b-none"
                : "xl:h-[120px] sm:h-[70px] h-[37px]"
            } bg-[#EAEAEA] xl:rounded-[20px] sm:rounded-[15px] rounded-[10px] flex justify-center items-center text-[#333876] xl:text-[32px] sm:text-[20px] text-[12px] font-sans font-[600] cursor-pointer`}
          >
            Summarization Feature
          </div>
        </div>
        {clicked === 1 && <AboutTranslation />}
        {clicked === 2 && <AboutProofreading />}
        {clicked === 3 && <AboutSummary />}
      </div>
      <section className="pt-20 pb-16 bg-white">
        <div className="xl:max-w-[1145px] max-w-[1400px] w-full mx-auto px-8">
          <h2 className="text-center font-sans font-[600] text-xl md:text-3xl text-[#333876] lg:text-5xl mb-7 md:mb-9">
            Revolutionizing Reading with a New Comprehension Feature
          </h2>
          <p className="mt-4 text-sm sm:text-base font-sans text-left">
            Often, the purpose of translation is to "read." With Languise, you
            can not only download translated and proofread document files but
            also view the results on a preview screen. In addition to basic
            functions like adjusting text size, you can use the translation
            feature while viewing the document on the same screen and ask
            Languise questions about the document’s content or unknown terms.
            For example, you can read an economic report and ask about the
            latest economic outlook, or inquire about where specific information
            is located in a long academic paper.
          </p>
          <div className="mt-4">
            <img src="/assets/features-en.png" />
          </div>
        </div>
      </section>
      <div className="bg-[#F0F1F8]">
        <h2 className="font-sans xl:text-[50px] sm:text-[35px] text-[20px] text-center text-[#333876] xl:my-12 sm:my-5 my-2 font-[600] px-5">
          Experience New Language Processing <br className="lg:hidden" />
          with AI
        </h2>
      </div>
      <div className="bg-[#F0F1F8] xl:pt-[45px] sm:pt-[20px] pt-[27px]">
        <Plans />
      </div>
    </div>
  );
}
