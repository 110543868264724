import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import BannedCountry from "./pages/BannedCountry";
import { openAICountryList } from "./components/Languages";
import reportWebVitals from "./reportWebVitals";
import i18next from "./translations"

const root = ReactDOM.createRoot(document.getElementById("root"));

fetch("https://ipapi.co/json/")
  .then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  })
  .then((data) => {
    const countryCode = data.country_code;
    if (openAICountryList.indexOf(countryCode) === -1) {
      root.render(<BannedCountry />);
    } else {
      root.render(<App />);
    }
  })
  .catch((error) => {
    console.log(error);
    root.render(<App />);
  });

reportWebVitals();
