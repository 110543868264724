import React, { useState } from "react";
import TextField from "../../TextField";
import Notiflix from "notiflix";

import api from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { getCorporateUsers } from "../../../redux/actions/languiseAction";
import ConfirmModal from "../ConfirmModal";
import { useTranslation } from "react-i18next";

export default function AddUserModal({ addUserModalOpen, onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const userInfo = useSelector((state) => state.LanguiseReducer.userInfo);
  const addUser = (e) => {
    e.preventDefault();
    const inviteEmail = email.trim();
    if (inviteEmail === userInfo.email) return;
    if (addUserModalOpen !== 2) {
      const getDomain = (email) => email?.split("@")[1] || "";
      const userDomain = getDomain(userInfo.email);
      const inviteDomain = getDomain(inviteEmail);
      if (userDomain && userDomain !== inviteDomain) {
        Notiflix.Notify.failure(t("myPageMessage13"));
        return;
      }
    }

    const payload = {
      email: inviteEmail,
      admin: addUserModalOpen === 2 ? true : false,
    };

    api
      .post("/corporate/users/", payload)
      .then((rst) => {
        setEmail("");
        if (payload.admin) {
          setConfirmText(
            <p>
              {t("addUserModalText1")}
              <br />
              {t("addUserModalText2")}
              <br />
              {t("addUserModalText3")}
            </p>
          );
          setShowConfirm(true);
        } else {
          dispatch(getCorporateUsers());
          setConfirmText(<p>{inviteEmail + " " + t("addUserModalText11")}</p>);
          setShowConfirm(true);
          // onClose();
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setConfirmText(
            payload.admin ? (
              <p>{t("addUserModalText4")}</p>
            ) : (
              <p>
                {t("addUserModalText5")}
                <br />
                {t("addUserModalText6")}
              </p>
            )
          );
          setShowConfirm(true);
        } else if (error.response.status === 409) {
          setConfirmText(t("addUserModalText7"));
          setShowConfirm(true);
        }
        console.log(error);
      });
  };

  return (
    <div
      className={`${
        addUserModalOpen !== 0
          ? "flex !opacity-100 !translate-y-0"
          : "hidden !opacity-0 !translate-y-10"
      } fixed inset-0 bg-black/40 z-30 !transition-all !ease-in-out !duration-300`}
    >
      <div className="flex flex-col max-w-[830px] w-full mx-auto my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] rounded-lg">
        <div className="flex justify-end">
          <img
            onClick={onClose}
            className="w-[20px] h-[20px] cursor-pointer"
            src="/assets/cancel-modal.svg"
            alt="Cancel"
          />
        </div>
        <p className="text-[#4B5090] font-sans text-[20px] text-center">
          {addUserModalOpen === 1
            ? t("addUserModalText8")
            : t("addUserModalText9")}
        </p>
        <div className="flex flex-col max-w-[703px] w-full mx-auto gap-[10px] mt-[30px]">
          <div className="flex sm:flex-row flex-col sm:gap-8 sm:items-center items-start">
            <div className="flex flex-1 justify-end text-[#505050] text-[16px] font-sans">
              {t("email")}
            </div>
            <div className="max-w-[554px] w-full">
              <TextField
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="sample@example.com"
              />
            </div>
          </div>
        </div>
        <div className=" max-w-[703px] w-full mx-auto flex justify-end my-5">
          <button
            onClick={addUser}
            className="max-w-[105px] w-full bg-[#4B5090] hover:bg-[#6267b2] h-[40px] text-white rounded-[10px]"
          >
            {addUserModalOpen === 2 ? t("addUserModalText10") : t("add")}
          </button>
        </div>
      </div>
      {showConfirm && (
        <ConfirmModal
          isOpen={showConfirm}
          onAgree={() => {
            setShowConfirm(false);
            onClose();
          }}
          onCancel={() => {
            setShowConfirm(false);
            onClose();
          }}
          text={confirmText}
          buttonText={t("close")}
        />
      )}
    </div>
  );
}
