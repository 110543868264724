import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AboutEn from "./AboutEn"
import AboutJp from "./AboutJp"

export default function About() {
  const uiLang = useSelector((state) => state.LanguiseReducer.uiLang);
  const [lang, setLang] = useState("jp");

  useEffect(() => {
    setLang(uiLang);
  }, [uiLang]);
  
  return lang === "jp" ? <AboutJp /> : <AboutEn />;
}
