import React, { useEffect, useState } from "react";
import Notiflix from "notiflix";
import api from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../../redux/actions/languiseAction";
import { CustomSelect } from "../CustomSelect";
import { useTranslation } from "react-i18next";

export default function UpdateEnterprisePlanModal({ modalOpen, onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [planList, setPlanList] = useState([]);
  const [yearlyCost, setYearlyCost] = useState(0);
  const [monthlyCost, setMonthlyCost] = useState(0);
  const [currency, setCurrency] = useState("jpy");
  const [success, setSuccess] = useState(false);
  const sign = { jpy: "¥", usd: "$", eur: "€" };
  const userInfo = useSelector((state) => state.LanguiseReducer.userInfo);

  useEffect(() => {
    api
      .get("/user/enterpriseplan/")
      .then((res) => {
        setPlanList(
          res.data.map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.count,
            };
          })
        );
        setCount(res.data[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setCurrency(userInfo.currency);
  }, [userInfo]);

  useEffect(() => {
    if (count > 0) {
      const temp = planList.find((item) => item.value == count).price[
        currency || 'jpy'
      ];
      setYearlyCost(temp);
      setMonthlyCost(temp ? temp / 12 : 0);
    }
  }, [count, currency, planList]);

  const updatePlan = (e) => {
    e.preventDefault();
    api
      .put("/corporate/", { plan: count })
      .then((rst) => {
        dispatch(getUserInfo());
        setSuccess(true);
      })
      .catch((error) => {
        if (error.response.status === 406) {
          Notiflix.Notify.failure(t("updateEnterpriseModalText1"));
        }
        console.log(error);
      });
  };

  return (
    <div
      className={`${
        modalOpen
          ? "flex !opacity-100 !translate-y-0"
          : "hidden !opacity-0 !translate-y-10"
      } fixed inset-0 bg-black/40 z-30 !transition-all !ease-in-out !duration-300`}
    >
      <div className="flex flex-col max-w-[830px] w-full mx-auto my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] rounded-lg">
        <div className="flex justify-end">
          <img
            onClick={onClose}
            className="w-[20px] h-[20px] cursor-pointer"
            src="/assets/cancel-modal.svg"
            alt="Cancel"
          />
        </div>
        {!success ? (
          <>
            <p className="px-20 text-[#4B5090] font-sans text-[20px] text-center">
              {t("updateEnterpriseModalText2")}
            </p>
            <p className="px-20 mt-2 text-[#505050] font-sans text-[14px] text-start">
              {t("updateEnterpriseModalText3")}
            </p>
            <div className="flex flex-col px-20 w-full mx-auto gap-[10px] mt-[30px]">
              <div className="flex flex-row text-[12px] sm:text-[16px] border-b sm:border-b-2 border-[#707070] justify-between">
                <p className="text-[#4B5090]">
                  {t("updateEnterpriseModalText4")}
                </p>
                <CustomSelect
                  value={count}
                  onChange={setCount}
                  items={planList}
                />
              </div>
              <div className="flex flex-row text-[12px] sm:text-[16px] border-b sm:border-b-2 border-[#707070] justify-between">
                <p className="text-[#4B5090]">
                  {t("updateEnterpriseModalText5")}
                </p>
                <p className="text-[#505050]">
                  {sign[currency]}
                  {yearlyCost && yearlyCost.toLocaleString()}
                </p>
              </div>
              <div className="flex flex-row text-[12px] sm:text-[16px] border-b sm:border-b-2 border-[#707070] justify-between">
                <p className="text-[#4B5090]">
                  {t("updateEnterpriseModalText6")}
                </p>
                <p className="text-[#505050]">
                  {sign[currency]}
                  {!!monthlyCost && monthlyCost.toLocaleString()}
                </p>
              </div>
            </div>
            <div className="px-20 mx-auto flex justify-center my-5">
              <button
                onClick={updatePlan}
                className="px-20 w-full bg-[#4B5090] hover:bg-[#6267b2] h-[40px] text-white rounded-[10px]"
              >
                {t("updateEnterpriseModalText7")}
              </button>
            </div>
          </>
        ) : (
          <>
            <p className="px-20 text-[#4B5090] font-sans text-[20px] text-center mt-4">
              {t("updateEnterpriseModalText8")}{" "}
              {planList.find((item) => item.value === count).count}
              {t("updateEnterpriseModalText9")}
            </p>
            <div className="px-20 mx-auto flex justify-center my-10">
              <button
                onClick={() => (window.location.href = "/")}
                className="px-20 w-full bg-[#4B5090] hover:bg-[#6267b2] h-[40px] text-white rounded-[10px]"
              >
                {t("goHome")}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
