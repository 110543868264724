import React, { useState, useEffect } from "react";
import SubscriptionCard from "./SubscriptionCard";
import { useSelector } from "react-redux";
import { CurrencySelect } from "./Modal/CurrencySelect";
import { useTranslation } from "react-i18next";

const PlanDetails = [
  {
    title: "会員登録する",
    description: "",
    data: [
      { title: "翻訳機能" },
      { title: "校正機能" },
      { title: "要約機能" },
      { title: "プレビュー機能(20MB以下)" },
      { title: "毎月の利用回数", bold: "3回" },
      { title: "毎日のテキスト翻訳利用", bold: "10回" },
      { title: "インポートの文字数制限", bold: "1000" },
      { title: "Uploadファイルサイズ上限", bold: "5MB" },
      { title: "語調の変更" },
      { title: "要約における言語の指定" },
      { title: "ファイルエクスポート後の編集" },
      { title: "My辞書の数", bold: "1" },
      { title: "My辞書の登録可能数", bold: "20ペア" },
    ],
  },
  {
    title: "購入する",
    description: "",
    data: [
      { title: "翻訳機能" },
      { title: "校正機能" },
      { title: "要約機能" },
      { title: "プレビュー機能(20MB以下)" },
      { title: "文書の内容に対する質問" },
      { title: "高精度な翻訳機能の利用" },
      { title: "毎月の利用回数", bold: "15回" },
      { title: "テキスト翻訳利用無制限" },
      { title: "インポートの文字数制限", bold: "無制限" },
      { title: "Uploadファイルサイズ上限", bold: "10MB" },
      { title: "語調の変更" },
      { title: "要約における言語の指定" },
      { title: "タイトル生成機能の利用" },
      { title: "ファイルエクスポート後の編集" },
      { title: "Powered by Languiseの表記削除" },
      { title: "My辞書の数", bold: "1" },
      { title: "My辞書ファイルのUpload" },
      { title: "My辞書の登録可能数", bold: "1,000ペア" },
    ],
  },
  {
    title: "無料で試す",
    description: "30日間無料体験",
    data: [
      { title: "翻訳機能" },
      { title: "校正機能" },
      { title: "要約機能" },
      { title: "プレビュー機能(20MB以下)" },
      { title: "文書の内容に対する質問" },
      { title: "高精度な翻訳機能の利用" },
      { title: "毎月の利用回数", bold: "50回" },
      { title: "テキスト翻訳利用無制限" },
      { title: "インポートの文字数制限", bold: "無制限" },
      { title: "Uploadファイルサイズ上限", bold: "20MB" },
      { title: "語調の変更" },
      { title: "要約における言語の指定" },
      { title: "タイトル生成機能の利用" },
      { title: "ファイルエクスポート後の編集" },
      { title: "Powered by Languiseの表記削除" },
      { title: "My辞書の数", bold: "100" },
      { title: "My辞書ファイルのUpload" },
      { title: "My辞書の共有（Enterprise限定）" },
      { title: "My辞書の登録可能数", bold: "1,000ペア" },
    ],
  },
  {
    title: "購入する",
    description: "",
    data: [
      { title: "翻訳機能" },
      { title: "校正機能" },
      { title: "要約機能" },
      { title: "プレビュー機能(20MB以下)" },
      { title: "文書の内容に対する質問" },
      { title: "高精度な翻訳機能の利用" },
      { title: "毎月の利用回数", bold: "120回" },
      { title: "テキスト翻訳利用無制限" },
      { title: "インポートの文字数制限", bold: "無制限" },
      { title: "Uploadファイルサイズ上限", bold: "30MB" },
      { title: "語調の変更" },
      { title: "要約における言語の指定" },
      { title: "タイトル生成機能の利用" },
      { title: "ファイルエクスポート後の編集" },
      { title: "Powered by Languiseの表記削除" },
      { title: "My辞書の数", bold: "200" },
      { title: "My辞書ファイルのUpload" },
      { title: "My辞書の共有（Enterprise限定）" },
      { title: "My辞書の登録可能数", bold: "1,000ペア" },
    ],
  },
];

const PlanDetailsEn = [
  {
    title: "Sign up",
    description: "",
    data: [
      { title: "Translation" },
      { title: "Proofreading" },
      { title: "Summary" },
      { title: "Preview (20MB or less)" },
      { title: "Monthly usage", bold: "3 times" },
      { title: "Daily text translation usage", bold: "10 times" },
      { title: "Import character limit", bold: "1000" },
      { title: "Upload file size limit", bold: "5MB" },
      { title: "Change tone" },
      { title: "Specify language in summary" },
      { title: "Edit after file export" },
      { title: "Number of my dictionaries", bold: "1" },
      {
        title: "My dictionaries count",
        bold: "20 pairs",
      },
    ],
  },
  {
    title: "Purchase",
    description: "",
    data: [
      { title: "Translation" },
      { title: "Proofreading" },
      { title: "Summary" },
      { title: "Preview (20MB or less)" },
      { title: "Questions about the document" },
      { title: "Highly accurate translation" },
      { title: "Number of monthly uses", bold: "15 times" },
      { title: "Unlimited text translation" },
      { title: "Number of characters", bold: "Unlimited" },
      { title: "Maximum upload file size", bold: "10MB" },
      { title: "Change tone" },
      { title: "Specify language in summary" },
      { title: "Title generation" },
      { title: "Edit after exporting file" },
      { title: `Remove "Powered by Languise"` },
      { title: "Number of my dictionaries", bold: "1" },
      { title: "Upload my dictionary file" },
      {
        title: "Number of words in my dictionary",
        bold: "1,000 pairs",
      },
    ],
  },
  {
    title: "Try for free",
    description: "30-Day Free Trial",
    data: [
      { title: "Translation" },
      { title: "Proofreading" },
      { title: "Summary" },
      { title: "Preview (20MB or Less)" },
      { title: "Questions about the document" },
      { title: "Highly accurate translation" },
      { title: "Number of monthly uses", bold: "50 Times" },
      { title: "Unlimited text translation" },
      { title: "Number of characters", bold: "Unlimited" },
      { title: "Maximum upload file size", bold: "20MB" },
      { title: "Change Tone" },
      { title: "Specify language in summary" },
      { title: "Title generation" },
      { title: "Editing after exporting file" },
      { title: `Remove "Powered by Languise"` },
      { title: "Number of my dictionaries", bold: "100" },
      { title: "Uploading my dictionary file" },
      { title: "Share my dictionaries (Enterprise only)" },
      {
        title: "Number of words in my dictionary",
        bold: "1,000 pairs",
      },
    ],
  },
  {
    title: "Purchase",
    description: "",
    data: [
      { title: "Translation" },
      { title: "Proofreading" },
      { title: "Summary" },
      { title: "Preview (20MB or less)" },
      { title: "Questions about the document" },
      { title: "Highly accurate translation" },
      { title: "Number of monthly uses", bold: "120 times" },
      { title: "Unlimited text translation" },
      { title: "Number of characters", bold: "Unlimited" },
      { title: "Maximum upload file size", bold: "30MB" },
      { title: "Change tone" },
      { title: "Specify language in summary" },
      { title: "Title generation" },
      { title: "Edit after exporting file" },
      { title: `Remove "Powered by Languise"` },
      { title: "Number of my dictionaries", bold: "200" },
      { title: "Upload my dictionary file" },
      { title: "Share my dictionaries (Enterprise only)" },
      {
        title: "Number of words in my dictionary",
        bold: "1,000 pairs",
      },
    ],
  },
];

export default function Plans({ modal = false }) {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.LanguiseReducer.userInfo);
  const prices = useSelector((state) => state.LanguiseReducer.prices);
  const uiLang = useSelector((state) => state.LanguiseReducer.uiLang);
  const [lang, setLang] = useState("jp");
  const [planType, setPlanType] = useState(false);
  const [plan, setPlan] = useState([]);
  const [currency, setCurrency] = useState("jpy");
  const [planList, setPlanList] = useState(PlanDetails);

  useEffect(() => {
    setLang(uiLang);
    setPlanList(
      uiLang === "jp" ? PlanDetails : PlanDetailsEn
    );
  }, [uiLang]);

  useEffect(() => {
    if (userInfo.currency) {
      setCurrency(userInfo.currency);
      planList[0].title = t("plansText1");
    }
  }, [userInfo]);

  useEffect(() => {
    if (prices.yearly.length === 0) return;
    if (planType) setPlan(prices.yearly);
    else setPlan(prices.monthly);
  }, [planType, prices]);

  return (
    <div className="w-full bg-[#F0F1F8]">
      <p className="text-center text-[#11135C] font-sans sm:text-[30px] text-[16px] font-semibold">
        {modal ? t("plansText2") : t("plansText3")}
      </p>
      {modal && (
        <p className="text-center text-[#11135C] font-sans sm:text-[20px] mt-4 text-[14px]">
          {t("plansText4")}
        </p>
      )}
      {!modal && (
        <div className="sm:flex flex-row w-full mx-auto relative items-center">
          <div className="flex w-full justify-center items-center mt-[13px]">
            <div className="flex max-w-[260px] w-full bg-background-footer rounded-[20px] h-[38px] gap-[7px] justify-between items-center px-[6px]">
              <button
                onClick={() => setPlanType(false)}
                className={`ease-in duration-200 transition transform sm:text-[14px] text-[13px] sm:max-w-[80px] max-w-[65px] w-full leading-[30px] ${
                  planType === true
                    ? "bg-background-footer text-white"
                    : "bg-white text-[#11135C]"
                } h-[23px] flex justify-center items-center flex-1 rounded-[20px]`}
              >
                {t("plansText5")}
              </button>
              <button
                onClick={() => setPlanType(true)}
                className={`ease-in duration-200 transition flex flex-1 transform px-[3px] sm:text-[14px] text-[13px] leading-[30px] ${
                  planType === false
                    ? "bg-background-footer text-white"
                    : "bg-white text-[#11135C]"
                } h-[23px] flex justify-center items-center flex-1 rounded-[20px]`}
              >
                {t("plansText6")}
              </button>
            </div>
          </div>
          <CurrencySelect
            value={currency}
            onChange={(v) => setCurrency(v)}
            className="absolute sm:top-0 top-[-40px] xl:right-20 right-5"
          />
        </div>
      )}
      <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-5 xl:max-w-[1180px] w-full mx-auto sm:mt-[95px] mt-10 sm:mb-24 mb-10 px-5 xl:px-0">
        {plan.length > 0 &&
          planList.map((item, index) => (
            <SubscriptionCard
              key={index}
              data={item.data}
              description={planType ? "" : item.description}
              button={planType && index !== 0 ? t("plansText7") : item.title}
              plan={plan[index]}
              payment_cycle={planType}
              currency={currency}
            />
          ))}
      </div>
    </div>
  );
}
