import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../services/api";
import { useDispatch } from "react-redux";
import { getUserInfo, loginUser } from "../redux/actions/languiseAction";
import UpdatePaymentMethodModal from "../components/Modal/MyPage/UpdatePaymentMethodModal";
import { removeAllCookies } from "../services/utils";
import ConfirmModal from "../components/Modal/ConfirmModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function VerifyCorporateAdminUser() {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [text, setText] = useState("確認中です。 しばらくお待ちください。");
  const [companyName, setCompanyName] = useState("");

  const [showPaymentMethodModalOpen, setShowPaymentMethodModalOpen] =
    useState(false);

  const uiLang = useSelector((state) => state.LanguiseReducer.uiLang);
  const [lang, setLang] = useState("jp");

  useEffect(() => {
    setLang(uiLang);
  }, [uiLang]);

  const verify = () => {
    api
      .put("/corporate/admin/", { token })
      .then((res) => {
        setCompanyName(res.data.message);
        dispatch(getUserInfo());
        if (res.data.message === "Enterprise 50") {
          navigate("/")
        } else setShowPaymentMethodModalOpen(true);
      })
      .catch((error) => {
        console.log(error);
        setShowConfirmModal(true);
        if (error.response.status === 406) {
          setText(t("verifyUserPageText1"));
        } else if (error.response.status === 403) {
          setText(t("verifyUserPageText2"));
          removeAllCookies();
          dispatch(loginUser(false));
        } else {
          setText(t("verifyUserPageText3"));
        }
      });
  };

  const handlePaymentMethod = () => {
    setShowPaymentMethodModalOpen(false);
    if (lang === "jp") {
      setText(`${companyName}の管理者の変更が完了しました。`);
    } else {
      setText(`The administrator changed for ${companyName}.`);
    }
    setShowConfirmModal(true);
  };
  useEffect(() => {
    verify();
  }, []);

  return (
    <div className="flex flex-col flex-grow md:mx-0 mx-5">
      <UpdatePaymentMethodModal
        openModal={showPaymentMethodModalOpen}
        closeModal={handlePaymentMethod}
      />
      <ConfirmModal
        text={text}
        buttonText={t("goHome")}
        isOpen={showConfirmModal}
        onAgree={() => (window.location.href = "/")}
        onCancel={() => (window.location.href = "/")}
      />
    </div>
  );
}
