import React, { useEffect, useState } from "react";
import Notiflix from "notiflix";
import TextField from "../../TextField";
import api from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import {
  getCorporateUsers,
  getUserInfo,
} from "../../../redux/actions/languiseAction";
import { useTranslation } from "react-i18next";

export default function EditUserModal({
  editUserModalOpen,
  onClose,
  selectedList,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.LanguiseReducer.corporateUsers);
  const [corporateUserId, setCorporateUserId] = useState();
  const [email, setEmail] = useState("");
  const [isAdmin, setAdmin] = useState(false);

  useEffect(() => {
    const n = selectedList.indexOf(true);
    if (users.length === 0 || n === -1) {
      return;
    }
    const selectedUser = users[n];
    setCorporateUserId(selectedUser.id);
    setEmail(selectedUser.user.email);
    setAdmin(selectedUser.admin);
  }, [users, selectedList]);

  const handleClose = () => {
    onClose(false);
  };
  const editUser = (e) => {
    e.preventDefault();

    const payload = {
      corporate_user_id: corporateUserId,
      email,
      admin: isAdmin,
    };

    api
      .put("/corporate/users/", payload)
      .then((rst) => {
        dispatch(getCorporateUsers());
        dispatch(getUserInfo());
        setEmail("");
        onClose(false);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          Notiflix.Notify.failure(t("editUserModalText1"));
        } else if (error.response.status === 409) {
          Notiflix.Notify.failure(t("editUserModalText2"));
        }
        console.log(error);
      });
  };

  return (
    <>
      {editUserModalOpen && (
        <div className="flex !translate-y-0 fixed inset-0 bg-black/40 z-30 !transition-all !ease-in-out !duration-300">
          <div className="flex flex-col max-w-[830px] w-full sm:mx-auto mx-5 my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] rounded-lg">
            <div className="flex justify-end">
              <img
                onClick={handleClose}
                className="w-[20px] h-[20px] cursor-pointer"
                src="/assets/cancel-modal.svg"
                alt="Cancel"
              />
            </div>
            <p className="text-[#4B5090] font-sans text-[20px] text-center">
              {t("editUserModalText3")}
            </p>
            <div className="flex flex-col max-w-[703px] w-full mx-auto gap-[10px] mt-[30px]">
            <div className="flex sm:flex-row flex-col sm:gap-8 sm:items-center items-start">
                <div className="flex flex-1 justify-end text-[#505050] text-[16px] font-sans">
                  {t("email")}
                </div>
                <div className="max-w-[554px] w-full">
                  <TextField
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="sample@example.com"
                  />
                </div>
              </div>
              <div className="flex sm:flex-row flex-col sm:gap-8 sm:items-center items-start">
                <div className="flex flex-1 justify-end text-[#505050] text-[16px] font-sans">
                  {t("editUserModalText4")}
                </div>
                <select
                  onChange={(e) => setAdmin(e.target.value)}
                  defaultValue={isAdmin}
                  className="max-w-[554px] w-full border border-[#8B8B8B] h-[40px] px-[20px] rounded-[10px] bg-transparent font-sans focus:outline-none"
                >
                  <option
                    className="bg-background-main font-[18px] font-sans"
                    value={true}
                  >
                    {t("admin")}
                  </option>
                  <option
                    className="bg-background-main font-[18px] font-sans"
                    value={false}
                  >
                    {t("permission")}
                  </option>
                </select>
              </div>
            </div>
            <div className=" max-w-[703px] w-full mx-auto flex justify-end my-5">
              <button
                onClick={editUser}
                className="max-w-[105px] w-full bg-[#4B5090] hover:bg-[#6267b2] h-[40px] text-white rounded-[10px]"
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
