import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const summaryListJp = [
  {
    title: "全体概要",
    description: "（テキストの主要なテーマやメッセージを簡潔に説明）",
    value:
      "Extract 'discussion theme', 'all action items' and 'all specific future prospects' mentioned in the text and then summarize using all the information you extracted as accurately as possible. Please output in the tone of the abstract of the report.",
  },
  {
    title: "合意事項",
    description: "（合意が示された事項）",
    value:
      "Extract and list all action items that must be implemented after this discussion mentioned in the text. Examples of output (do not replicate this example) is 'start production in 2025.' Create your own response. Use bullet points for output.",
  },
  {
    title: "行動計画",
    description: "（誰が何をいつまでに実行するか）",
    value:
      "Who will do what and by when. Use bullet points for output. Examples of output is 'Matt conclude market report by the end of this month.'",
  },
  {
    title: "目的・論点",
    description: "（課題にされている内容）",
    value:
      "The point which is discussed deeply in meetings or discussions using bullet points (Examples of the output is 'What we have to do to expand sales?')",
  },
  {
    title: "問題点",
    description: "（提示されている課題や問題点）",
    value:
      "Issues or problems using bullet points (Examples of output is 'Due date by end of this month is difficult because of lack of human resources')",
  },
  {
    title: "背景情報",
    description: "（なぜこの議論が行われているかの理由）",
    value:
      "The reason why this topic was discussed (Output must be a sentence which explain the reason such as 'Because we must identify ways to increase market share.')",
  },
  {
    title: "方法論",
    description: "（課題の解決に用いられる方法や手段）",
    value:
      "Extract and list only the specific challenges and their corresponding solutions from the text provided. Ensure that each challenge is clearly paired with its appropriate solution in a list format.",
  },
  {
    title: "リスク",
    description: "（どんな場合にどんな損失を被る可能性があるか）",
    value:
      "Identify and extract all risk-related descriptions. Focusing specifically on the scenarios in which these risks could lead to potential losses. Summarize each risk and its associated potential loss clearly and concisely. Use bullet points for output.",
  },
  {
    title: "質問事項",
    description: "（投げかけられている問い）",
    value:
      "Extract all questions which are asked in the text. The output must be a written question that provides an understanding of the main subject or issue being asked. Using bullet points for outputs.",
  },
  {
    title: "具体的な事例",
    description: "（具体的な例やケーススタディの紹介）",
    value:
      "Identify and extract all specific past examples both of success and failure. Provide a detailed explanation of 'who did what and then how it happened'. An example of the output is '<Company name> made a partnership with a local company and penetrated the market successfully.' Use bullet points for output.",
  },
  {
    title: "将来展望",
    description: "（未来に関する予測や期待）",
    value:
      "Identify and extract all specific future prospects. Don`t include fact and future actions. For each statement include the specific details about what is expected to happen to what extent and how these changes or developments are anticipated. An example of the output is 'The economic outlook is expected to increase due to purchasing power.' Use bullet points for output.",
  },
  {
    title: "金額",
    description: "（売上や利益などの数字に関する情報）",
    value:
      "Identify and extract all numerical data such as sales and economic and analysis metrics. Provide a clear and detailed explanation of what the numbers represent and analyze and explain any factors mentioned that may contribute to the fluctuations or trends in these numbers. An example of the output is 'Sales is expected to increase 10% from the fact in 2024 10 million US$ to 11 million US$ in 2026 because of the entering new market.' Use bullet points for output.",
  },
  {
    title: "文献レビュー",
    description: "（参考にした研究や文献の名前や情報）",
    value:
      "Identify and extract the names of all reference. If there is any description or additional information provided about these references extract that information as well. An example of the output is '[Biochemistry market analytics 2024] This document provides market size and key players in the global biochemistry market.' Use bullet points for output.",
  },
  {
    title: "その他",
    description: "[自由記述]",
    value: "",
  },
];

export const summaryListEn = [
  {
    title: "Overall summary",
    description: "(Explain the main theme or message of the text)",
    value:
      "Extract 'discussion theme', 'all action items' and 'all specific future prospects' mentioned in the text and then summarize using all the information you extracted as accurately as possible. Please output in the tone of the abstract of the report.",
  },
  {
    title: "Agreements",
    description: "(Agreements shown)",
    value:
      "Extract and list all action items that must be implemented after this discussion mentioned in the text. Examples of output (do not replicate this example) is 'start production in 2025.' Create your own response. Use bullet points for output.",
  },
  {
    title: "Action plan",
    description: "(Who will do what and by when)",
    value:
      "Who will do what and by when. Use bullet points for output. Examples of output is 'Matt conclude market report by the end of this month.'",
  },
  {
    title: "Objectives/Points",
    description: "(What is being discussed)",
    value:
      "The point which is deeply discussed in meetings or discussions using bullet points (Examples of the output is 'What we have to do to expand sales?')",
  },
  {
    title: "Issues",
    description: "(Issues or problems presented)",
    value:
      "Issues or problems using bullet points (Examples of output is 'Due date by end of this month is difficult because of lack of human resources')",
  },
  {
    title: "Background information",
    description: "(Why is this debate happening?)",
    value:
      "The reason why this topic was discussed (Output must be a sentence which explain the reason such as 'Because we must identify ways to increase market share.')",
  },
  {
    title: "Methodology",
    description: "(Methods or means used to solve the issues)",
    value:
      "Extract and list only the specific challenges and their corresponding solutions from the text provided. Ensure that each challenge is clearly paired with its appropriate solution in a list format.",
  },
  {
    title: "Risks",
    description: "(What losses could be incurred in what cases)",
    value:
      "Identify and extract all risk-related descriptions. Focusing specifically on the scenarios in which these risks could lead to potential losses. Summarize each risk and its associated potential loss clearly and concisely. Use bullet points for output.",
  },
  {
    title: "Questions",
    description: "(Questions being asked)",
    value:
      "Extract all questions which are asked in the text. The output must be a written question that provides an understanding of the main subject or issue being asked. Use bullet points for outputs.",
  },
  {
    title: "Specific cases",
    description: "(Introduction of specific examples and case studies)",
    value:
      "Identify and extract all specific past examples both of success and failure. Provide a detailed explanation of 'who did what and then how it happened'. An example of the output is '<Company name> made a partnership with a local company and penetrated the market successfully.' Use bullet points for output.",
  },
  {
    title: "Future prospects",
    description: "(Predictions and expectations for the future)",
    value:
      "Identify and extract all specific future prospects. Don`t include fact and future actions. For each statement include the specific details about what is expected to happen to what extent and how these changes or developments are anticipated. An example of the output is 'The economic outlook is expected to increase due to purchasing power.' Use bullet points for output.",
  },
  {
    title: "Amount",
    description: "(Information about numbers such as sales and profits)",
    value:
      "Identify and extract all numerical data such as sales and economic and analysis metrics. Provide a clear and detailed explanation of what the numbers represent and analyze and explain any factors mentioned that may contribute to the fluctuations or trends in these numbers. An example of the output is 'Sales is expected to increase 10% from the fact in 2024 10 million US$ to 11 million US$ in 2026 because of the entering new market.' Use bullet points for output.",
  },
  {
    title: "Literature review",
    description: "(Names and information of references and studies)",
    value:
      "Identify and extract the names of all references. If there is any description or additional information provided about these references extract that information as well. An example of the output is '[Biochemistry market analytics 2024] This document provides market size and key players in the global biochemistry market.' Use bullet points for output.",
  },
  {
    title: "Other",
    description: "[Free text]",
    value: "",
  },
];

export default function SummarySelect({ value, number, onChange }) {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [label, setLabel] = useState();
  const [customValue, setCustomValue] = useState("");
  const [customExample, setCustomExample] = useState("");
  const selectRef = useRef();
  const uiLang = useSelector((state) => state.LanguiseReducer.uiLang);
  const [summaryList, setSummaryList] = useState(summaryListJp);

  useEffect(() => {
    setSummaryList(uiLang === "jp" ? summaryListJp : summaryListEn);
  }, [uiLang]);

  const handleChange = (v) => {
    if (v === "") {
      let text = "";
      if (customValue !== "") {
        text = "Identify and extract " + customValue + ".";
      }
      if (customExample !== "") {
        text += "An example of the output is " + customExample + ".";
      }
      text += " Use bullet points for output.";
      setOpen(false);
      onChange(number, text);
      return;
    }
    onChange(number, v);
    setOpen(false);
  };

  useEffect(() => {
    if (value === customValue) {
      setLabel(summaryList[summaryList.length - 1].title);
    } else {
      const item = summaryList.find((item) => item.value === value);
      if (!value) setLabel(`${t("summarySelectText1")}${number + 1}`);
      else if (item) setLabel(item.title);
      else setLabel(t("other"));
    }
  }, [customValue, number, value]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        if (selectRef.current && selectRef.current.contains(event.target)) {
          setOpen(true);
        } else {
          setOpen(false);
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={selectRef} className="relative flex">
      <div
        onClick={() => setOpen(!isOpen)}
        className={`flex flex-row w-full sm:h-10 h-8 items-center justify-between sm:px-2 px-4 gap-2 border border-[#8B8B8B] sm:rounded-[10px] rounded-lg text-[#4B5090] cursor-pointer ${
          isOpen ? "bg-[#e7e9fa]" : "bg-white"
        }`}
      >
        <span className="sm:text-[16px] text-[12px]">{label}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 15 12"
          className="sm:w-3 sm:h-3 w-3 h-2"
        >
          <path
            d="M7.5,0,15,12H0Z"
            transform="translate(15 12) rotate(180)"
            fill="#4b5090"
          />
        </svg>
      </div>
      {isOpen && (
        <div className="absolute flex flex-col border border-[#6E6E6E] sm:w-[520px] w-full sm:top-10 top-6 right-0 z-50">
          <div
            className="flex flex-row border text-start sm:py-2 py-1 px-4 hover:bg-[#F0F1F8] sm:text-[16px] text-[12px] bg-white text-[#333876] cursor-pointer"
            onClick={() => handleChange(undefined)}
          >
            {t("summarySelectText2")}
          </div>
          {summaryList.map((item) => (
            <div
              onClick={() => {
                item.title !== t("other") && handleChange(item.value);
              }}
              key={item.title}
              className={`flex flex-row border text-start sm:py-2 py-1 px-4 hover:bg-[#F0F1F8] sm:text-[16px] text-[12px] ${
                item.value === value ||
                (item.value === "" && customValue === value)
                  ? "bg-[#e7e9fa]"
                  : "bg-white gap-1"
              }`}
            >
              <span
                onClick={() => {
                  item.title === t("other") && handleChange(item.value);
                }}
                className="text-[#333876] whitespace-nowrap cursor-pointer"
              >
                {item.title === t("other") ? (
                  <div className="flex flex-col gap-1">
                    <p>{item.title}</p>
                    <p>{t("summarySelectText3")}</p>
                  </div>
                ) : (
                  item.title
                )}
              </span>
              {item.title === t("other") ? (
                <div className="flex flex-col w-full pr-5 gap-1">
                  <input
                    value={customValue}
                    placeholder={t("summarySelectText4")}
                    onChange={(e) => setCustomValue(e.target.value)}
                    className="w-full ml-5 px-2 text-[#707070] border outline-none placeholder:text-[12px]"
                  ></input>
                  <input
                    value={customExample}
                    placeholder={t("summarySelectText5")}
                    onChange={(e) => setCustomExample(e.target.value)}
                    className="w-full ml-5 px-2 text-[#707070] border outline-none placeholder:text-[12px]"
                  ></input>
                </div>
              ) : (
                <span className="text-[#707070] sm:text-[16px] text-[10px] cursor-pointer">
                  {item.description}
                </span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
