import React from "react";
import { Helmet } from "react-helmet";

export default function TermsJp() {
  return (
    <div className="flex flex-col">
      <Helmet>
        <title>Languise - Terms of service</title>
        <meta
          name="description"
          content="LANGUISEの使用に関する一般的な利用条件"
        />
        <link rel="canonical" href="https://languise.com/terms" />
      </Helmet>
      <div
        className="flex items-center bg-cover bg-center w-full sm:h-[360px] h-[220px]"
        style={{ backgroundImage: "url('/assets/5-bg.png')" }}
      >
        <div className="flex flex-col xl:gap-[23px] px-5 sm:px-0 sm:gap-4 gap-2 2xl:max-w-[1306px] max-w-[95%] w-full mx-auto">
          <h1 className="sm:text-[45px] text-[22px] text-[#333876] font-sans font-semibold text-start">
            "LANGUISE"の使用に関する一般的な利用条件
          </h1>
        </div>
      </div>

      <div className="2xl:max-w-[1306px] max-w-[95%] w-full mx-auto flex flex-col px-5 sm:px-0 text-start sm:text-[20px] text-[12px] text-[#222222] font-sans">
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          1 契約の主題
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          1.1
          これらの一般利用規約（以下、「LANGUISE」または「ソフトウェア」とも称する）は、BuilBridge
          UG (haftungsbeschränkt)
          （以下「BuilBridge」所有のAIベースのソフトウェアの利用に適用されます。所有者：BuilBridge、Niederkasseler
          Kirchweg 57 40547 Düsseldorf
          Germanyより提供されたものであり、ウェブサイト「languise.com」または「Languise
          App」にて提供されます。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          1.2
          LANGUISEは、BuilBridgeによってWebベースのSaaSソリューションとして運営されています。
          BuilBridgeまたはBuilBridgeによって委託されたサービスプロバイダー（例：Amazon
          Web
          Service）の独占的なEUベースのサーバーに保存および実行されているLANGUISEを利用することができます。これをもって、契約期間中に、インターネット接続およびブラウザアクセスを通じて、独自の目的のためにソフトウェアを使用し、データを保存および処理することができます。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          1.3
          これらのGTCは、ドイツ民法典（BGB）第14条に基づく企業と、ドイツ民法典第13条に基づく消費者の両方に適用されます。消費者とは、その法的取引が主に彼らの商業活動や独立個人事業に帰属しない目的で行われる自然人のことです。消費者向けの「追加条件」は、さらに消費者に適用されます。未成年者は、法的代理人の同意がある場合にのみ当社のソフトウェアを利用できます。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          1.4これらのGTCは、契約上の関係にのみ適用されます。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          1.5あなたが、該当するボタンをクリックすることで、注文をする際やウェブサイトまたはアプリのユーザーとして登録する際に、これらのGTCの有効性に同意したことになります。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          2 登録、登録に関する保証、契約の締結
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.1
          当社のサービスを利用するには、まずウェブサイトで登録する必要があります。一度だけ登録し、ユーザープロファイルを1つだけ作成することも認められます。登録は無料です。登録なしでの制限付き無料利用も（現在）提供されています。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.2
          アカウントは手動で登録するか、Googleアカウントのログインデータを使用して作成することもできます。これらの第三者プラットフォームアカウントの詳細を使用してLANGUISEアカウントを作成する場合、認証目的でこれらの情報にアクセスして使用することも可能です。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.3
          登録を完了することで、ライセンス契約を締結するオファーが提示され、登録時に提供したすべてのデータが真実で完全であることを保証しなくてはなりません。登録データに変更がある場合は直ちに報告する義務があります。登録によって締結されるライセンス契約には、「無料30日間試用版」のソフトウェアの使用が含まれます（第3.1節参照）。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.4
          BuilBridgeは、このオファーを受け入れることで、サービスのための対応するユーザーアカウントをアクティベートします。この承認により、契約が成立します。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.5
          ソフトウェアを自然人として使用しない場合、契約締結時にあなたとサービス、雇用、または職業訓練関係にある人のみがそれを使用することができます。
          あなた以外の人による直接または間接の使用、または文1で説明されている使用は許可されていません。
          あなたは、すべてのユーザーにこれらのGTCを遵守するよう義務付けたことを保証し、この点において、あなたはセクション6.7の規定に従ってBuilBridgeをすべての請求から解放します。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.6
          あなたは登録時に選択したパスワードやその他のアクセスデータを秘密に保ち、提供する権限のある者も同様にすることを義務付けられます。BuilBridgeはソフトウェアへのアクセス以外でパスワードを共有するよう求めることは一切ありません。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.7
          当社のウェブサイトで製品やサービスを紹介し広告することによって、特定の製品やサービスを販売する拘束力のある申し出を行うことはありません。ソフトウェアの有料版（つまり、第3.1項の定義における「有料版」）を購入したい場合は、「今すぐ購入」ボタンをクリックすることで、最初に非拘束の注文プロセスを開始することができます。該当の有料版に用意されている場合、次のステップで、該当の有料版のライセンスを何人のユーザー用に購入するかを指定することができます。そして、「注文を完了する/支払いの義務とともに注文」ボタンをクリックすることで、事前に選択したソフトウェアのバージョンに対する拘束条件の注文を行います。ご注文後すぐに、注文の受領確認メールをお送りします。当社との契約がいつ発効するかは、選択した支払方法によって異なります。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          3つの利用方法、価格、価格変更、および支払条件のバリエーション
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          3.1
          BuilBridgeは、登録ユーザーにウェブサイトで2つの利用オプションを提供しています。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          a) 無料だが機能的に制限されたバージョン（「無料版」）
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          b) 追加機能を備えた複数の有料バージョン（「有料版」）
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          3.2
          個々の有料バージョンの料金の詳細については、サイトの「価格」セクションで規制されています。そこで述べられている価格は拘束力があり、適用される法定税金は含まれていません。すべての料金は、契約の締結時に全期間の支払いが必要です。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          3.3 お支払いにはクレジットカード、またはGoogle
          Payを使用することができます。手数料が徴収できない場合、返金に関連する銀行手数料や同等の手数料を含む、発生原因に責任を持つ範囲で、発生したすべての費用を負担していただきます。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          3.4
          払うべき料金については、LANGUISEで請求書をダウンロードすることができます。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          4 サービスの種類と範囲
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          4.1
          BuilBridgeは、サーバーのソフトウェアがあるデータセンターのルーター出口（「転送ポイント」）で、現行バージョンのソフトウェアを使用できるようにします。ソフトウェアの動作に必要な計算能力、およびそのために必要なストレージとデータ処理スペースは、BuilBridgeが提供します。ただし、ITシステムと転送ポイント間のデータ接続の設定と維持については、BuilBridgeは責任を負いません。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          4.2
          BuilBridgeは、契約期間中、あなたにソフトウェアをあなた自身の目的のために使用し、ソフトウェアを使用してデータを保存および処理し、その結果のユーザーインターフェースを画面に表示するためにRAMに読み込むこと、およびユーザーインターフェースの結果のコピーを作成する非排他的で譲渡不可の権利を契約期間中に付与します。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          4.3
          契約上同意されたサービスは、契約当事者によって明示的に同意されていない限り、第三者に提供されてはなりません。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          5 ソフトウェアの利用可能性と変更
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          5.1
          BuilBridgeは最新の技術の枠組み内でソフトウェアの使用を可能にし、最大限の利用可能性を確保することを目指しています。BuilBridgeは、容量制限、サーバーのセキュリティまたは整合性、技術的な措置の導入が必要である場合、一時的にソフトウェアへのアクセスを制限する権利を留保し、サービスの適切な提供を確保することを目的として、全体または一部での使用を制限することがあります（たとえば、メンテナンス作業、更新、アップグレード中など）。合意の期間中、BuilBridgeは、ソフトウェアが合意に準拠し続けるために必要な更新を提供し、これらの更新について適切な通知を提供します。さらに、BuilBridgeは、ソフトウェアのハードウェアおよび/またはソフトウェアの変更（特にオペレーティングシステムやWebブラウザー）に対応するための更新やアップグレードを提供する義務はありません。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          5.2
          BuilBridgeによって提供されるサービスに関してBuilBridgeの制御を超える制限や障害が生じる可能性があります。これには、特に、BuilBridgeの代理で行動しない第三者による行為、BuilBridgeの影響下ではないインターネットの技術的条件、および不可抗力が含まれます。使用するハードウェア、ソフトウェア、技術インフラもサービスに影響を与える可能性があります。これらの状況がBuilBridgeによって提供されるサービスの可用性や機能に影響を与える限りでは、これは提供されるサービスの契約適合性に影響を与えません。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          5.3
          あなたはソフトウェアの機能の不具合、機能不全、または障害をできるだけ正確にBuilBridgeに直ちに報告しなければなりません。協力しない場合は、ドイツ民法典（BGB）の第536c条が適用されます。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          5.4
          BuilBridgeは、そのようなさらなる開発や変更が(i)必須法的要件を遵守するために必要である場合、(ii)単に証明済みもしくは現在の最新技術に適応することを意味し、または(iii)あなたの合理的な期待を裏切らず、ソフトウェアの使用可能な重要な機能の継続利用にとって合理的であるものに過ぎない場合に限り、ソフトウェアをさらに開発または変更する権利を有します。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          6 ユーザーの6つの義務
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          6.1
          ソフトウェアを使用するためには、製品の説明に記載されているシステム要件を満たす必要があります。
          これはあなた自身の責任です。ソフトウェアの使用には、十分なインターネット接続が必要であり、これはあなた自身で確認する必要があります。
          ソフトウェアのオフライン利用はできません。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          6.2
          あなたは、データを適切に定期的にバックアップすることに責任を負います。これは、契約処理の過程でBuilBridgeから提供される文書（例:
          請求書）にも適用されます。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          6.3
          もしBuilBridgeに保護されたコンテンツ（例：グラフィック、ブランド、テキスト、およびその他の著作権または商標法で保護されたコンテンツ）を提供する場合、あなたは契約の実行に必要な全ての権利をBuilBridgeに付与します。これには特に、関連するコンテンツを他のユーザにアクセス可能にする権利が含まれます。この際、提供された素材に対してBuilBridgeに対応する権利を付与するためには全ての必要な権利を有していることを保証してください。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          6.4
          あなたはウェブサイトのコンテンツやサービスを利用する際、適用される法律や第三者の権利に従う義務があります。特に、次のことは禁止されています
        </p>
        <ul className="list-outside pl-10 sm:mt-10 mt-2 sm:leading-9 leading-6">
          <li className="list-disc indent-4">
            このコンテンツが他のユーザーやBuilBridgeの従業員、他の個人や企業に関連しているかどうかにかかわらず違法または中傷的なコンテンツを使用しないでください。
          </li>
          <li className="list-disc indent-4">
            ポルノコンテンツを使用したり、青少年保護法に違反するコンテンツを使用したり、ポルノ製品や青少年保護法に違反する製品を広告したり、提供したり、配布したりすることはできません。
          </li>
          <li className="list-disc indent-4">
            このコンテンツが他のユーザーやBuilBridgeの従業員、他の個人や企業に関連しているかどうかにかかわらず違法または中傷的なコンテンツを使用しないでください。
          </li>
          <li className="list-disc indent-4">
            他のユーザーを理由もなく嫌がらせすること（特にスパムを通じて）（例：第7条UWGに準拠したもの）
          </li>
          <li className="list-disc indent-4">
            法律で保護されたコンテンツ（たとえば、著作権、商標、特許、デザインまたは実用新案法によって）を許可なく使用したり、法的に保護された商品やサービスを広告、提供、配布したりすること
            <p className="indent-0">
              不正競争的な行動を行ったり促進したりすること、プログレッシブ・ソリシテーション（チェーン、ピラミッド、またはピラミッドスキームなど）を推奨すること
            </p>
          </li>
        </ul>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          6.5 次のことを行うことも禁止されています：
        </p>
        <ul className="list-outside pl-10 sm:mt-10 mt-2 sm:leading-9 leading-6">
          <li className="list-disc indent-4">
            ウェブサイトの利用に関連して機構、ソフトウェア、またはスクリプトを使用することはできません。ただし、ウェブサイトやアプリで提供されているサービスの一環として提供されているインタフェース、特にAPIやソフトウェアを使用することができます。
          </li>
          <li className="list-disc indent-4">
            ウェブサイトや他のユーザーからのコンテンツの拡散および公開再生産。
          </li>
          <li className="list-disc indent-4">
            LANGUISEインフラの機能を損なう可能性のある任意の行動は、特にそれに過度の負荷をかけることを意味します。
          </li>
        </ul>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          6.6
          もし投稿したコンテンツやサービスの利用が第三者の権利や法的要件に違反している場合は、
          本契約に反するまたは違法な使用を直ちに停止します。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          6.7あなたはすべての請求からBuilBridgeを開放し、その中には他のユーザーや第三者があなたが投稿したコンテンツによる権利侵害またはソフトウェアの使用によってBuilBridgeに対して主張する損害賠償請求が含まれます。第三者の権利の侵害によって発生した合理的な費用はあなたが負担しなければなりません。このセクション6.7からの上記の義務は、BuilBridgeが侵害に対して責任を負わない限りは適用されません。BuilBridgeのさらなる権利と損害賠償請求は影響を受けません。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          7 保証
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          7.1
          あなたはソフトウェアのすべての欠陥を直ちにBuilBridgeに書面で報告しなければなりません。欠陥の書面通知は欠陥をできるだけ正確に記述し、それに対応するデータ処理環境を記述しなければなりません。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          7.2賃貸契約における保証に関する法的規制は一般的に適用されます。536b
          BGB（契約締結時または受領時の借手の欠陥についての知識）、536c
          BGB（賃貸期間中の欠陥;借手による欠陥の通知）が適用されます。ドイツ民法536a条1項に基づく契約締結時に存在する欠陥に対する無過失責任（貸主の損害賠償義務）は除外されています。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          7.3
          保証権は、お客様がソフトウェアを不適切、適さない、または不適切に扱ったり使用したりしたため、またはBuilBridgeが許可していないソフトウェアの変更によって欠陥が発生した場合には適用されません。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          8 BuilBridgeの責任
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          8.1
          BuilBridgeは、故意および重大な過失、生命、身体または健康への過失、保証の仮定、および商品責任法に基づく責任の場合において、契約上および非契約上の責任のすべての場合においてただちに責任を負います。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          8.2
          その他の場合、BuilBridgeは、重大な契約義務の単なる過失的な違反の場合にのみ責任を負います。つまり、契約の正当な履行に不可欠であり、お客様が定期的に依存できる契約上の義務であり、それは予見可能で典型的な損害の補償に限定されます。その他のすべての場合、BuilBridgeの責任は排除されます。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          8.3
          もしデータの損失による損害を受けた場合、関連するすべてのデータを定期的かつ完全にバックアップしていれば、損害は避けられた可能性がある場合は、BuilBridgeはその責任を負いません。定期的かつ完全なデータバックアップは、あなた自身で行うか、第三者に依頼して行う必要があり、その責任はあなた自身にあります。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          9 契約の終了
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          9.1登録フリーバーションのソフトウェアの契約は30日間有効です。いつでも理由や通知をすることなく、この契約を早期に終了することができます。ウェブサイトの各ページで入手可能なお問い合わせフォームを使用して、お客様のユーザー名と弊社のウェブサイトまたはアプリに登録された電子メールアドレスを提供してキャンセル通知をお送りください。また、「サブスクリプション」のアカウントエリアでも契約を終了することができます。フリーバージョンの登録後30日間が経過した後は、
          LANGUISEの有料バージョンとしてご利用いただけます。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          9.2
          LANGUISEの有料バージョンの契約は、例えば1か12か月の固定の最低利用期間を持つ定期契約として締結されます。最低利用期間の長さは、注文プロセスで決定されます。これらの契約条件のセクション11における消費者に対する対照規定に従う場合を除き、有料バージョンの定期契約は、最低利用期間の満了後に、定期利用期間に相当する期間で自動延長されますが、契約が適切なタイミングで事前に終了しない限りです。有料バージョンの定期利用契約は、注文プロセスで予約された最低利用期間の終了後またはその後の各延長期間の終了までの30日前までに、理由を問わずにあなたまたはBuilBridgeによってキャンセルすることができます。終了は、LANGUISEのウェブサイトのどのページからでもアクセスできるコンタクトフォームまたはメールでBuilBridgeに宣言することができます。キャンセルする際には、LANGUISEウェブサイトに登録されたユーザー名とメールアドレスを提供する必要があります。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          9.3
          双方がソフトウェアの使用契約を重要な理由なしで通知なしに終了する権利は影響を受けません。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          9.4
          もし正当な理由がある場合、BuilBridgeは終了に関係なく、次の制裁を課すこともできます。
        </p>
        <ul className="list-outside pl-10 sm:mt-10 mt-2 sm:leading-9 leading-6">
          <li className="list-disc indent-4">
            投稿した侵害コンテンツを削除する
          </li>
          <li className="list-disc indent-4">警告の発行</li>
          <li className="list-disc indent-4">
            BuilBridgeが提供するサービスへのアクセスを一時的に遮断します。特定された法的違反が解消されるまで、アクセスを制限します。
          </li>
        </ul>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          10 最終規定
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          10.1
          これらの契約条件の変更や追加は、書面によって行われなければなりません。特に、この書面形式の規定の放棄についても同様です。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          10.2
          個々の条項が無効となった場合、他の条項の効力には影響が及びません。当事者は、無効な条項を、経済的な意図に最も近い内容で、法的に許容される方法で置き換えることに同意します。同様の原則は契約の欠如に適用されます。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          10.3
          ドイツ連邦共和国の法律が適用され、国際商品の売買に関する国連条約を除外します。
          もし、あなたが消費者であり、契約が締結された時点であなたの居住地が他の国にある場合、第1文での法の選択によってその国の強制的な法的規定の適用は影響を受けません。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          10.4
          もしmerchantである場合、専属の管轄地はデュッセルドルフです。それ以外の場合、該当する法的規定が地方および国際的な管轄に適用されます。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] text-center font-semibold">
          消費者向けの追加条件
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          以下の条件は消費者にのみ適用されます。残りの契約条件との衝突が発生した場合、このセクションの規定が優先されます。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          11消費者取引の条件と支払い条件
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          もし、消費者として有料版を利用契約を結んだ場合、この契約は、9.2節に反して、最低利用期間が終了した後、無期限に延長されます。この場合、注文手続きで予約された最低利用期間が終了した後、1か月の通知期間を経ていつでも有料版をキャンセルすることができます。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          12キャンセルポリシー（消費者のみ）
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          12.1 撤回権:
          消費者として、14日以内に理由を示さずにこの契約から撤回する権利があります。撤回期間は契約が締結された日から算出されます。デジタルコンテンツに関する撤回権の除外について、14.3節以下をご覧ください。キャンセル期限を守るためには、当社のウェブサイトのどこでも利用可能な問い合わせフォームか、「info(at)languise.com」宛にメールで契約の撤回の意思を明確に示す声明を適時送信するだけで十分です。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          12.2
          離脱の影響：有料版の効果的な離脱が発生した場合、両当事者が受け取ったサービスは直ちに、14日以内には返却され、得られた利益（たとえば利息）は放棄されなければならない。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          13 オンライン消費者向け紛争解決
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          当社のお客様と消費者のために、欧州委員会は、店外のオンライン紛争解決（OSプラットフォーム）のためのプラットフォームを提供し、これはhttps://www.ec.europa.eu/consumers/odrからアクセスすることができます。当社のメールアドレスは、法的通知に記載されています。当社は、紛争解決プロセスに参加することは義務づけられておらず、また望んでいません。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] text-center font-semibold">
          AI利用条件
        </h2>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          1 範囲
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          1.1
          これらの条件（「AI条件」）は、LANGUISE（以下「LANGUISE」または「ソフトウェア」）がBuilBridgeによって提供されるソフトウェアとしてのAIサービスまたはAIによってサポートされるサービス、および機能（「AI機能」）のアクセスおよび利用に適用されます。
          AI条件は、AI機能にも適用される当社の一般条件と条件（以下「条件と条件」）の一部です。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          2 私たちのAI機能の利用とユーザーの義務
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.1
          もし当社のAI機能を使用する場合、ソフトウェアによって処理されるデータ（「AI入力」）を提供することができ、提供されたAI入力に基づいて生成され返還されるデータ（「AI出力」）を受け取ることができます。当社のAI機能を使用する場合、提供する入力に基づいてAI機能の結果を使用する権利が含まれます。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.2
          あなたのデータはAIと機械学習モデルによって処理され、出力を生成します。これらは現在、第三者によって提供されており、OpenAI
          Inc.が提供するChat GPT
          APIを使用しています。これは、BuilBridgeのAI機能の一部として、アメリカ合衆国カリフォルニア州サンフランシスコの3180
          18th St, San Francisco, California 94110に位置するOpenAI
          Inc.から提供されています。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.3
          AI技術の新奇さにより、すべてのAI機能はそのまま提供されます。顧客アカウントのユーザーがAI機能を利用する場合、これはBUILBRIDGE
          UGに関連データを対応する第三者プロバイダーに送信する指示でもあります。OpenAIは、顧客が明示的に同意しない限り、APIを介して提出されたデータをモデルを訓練するために使用しないことを宣言しています。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          私たちのコミットメント　OpenAI:
          <a href="https://openai.com/enterprise-privacy">
            https://openai.com/enterprise-privacy#our-commitments
          </a>
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.4
          コンテンツ、開発、運用、保守、およびAI入力またはAI出力として使用されるデータについては、あなたが単独で責任を負います。あなたは、弊社のAI機能の使用およびあなたのAI出力の使用が、(i)適用される法律に違反しないこと、(ii)
          これらのAI条件、弊社の利用規約、または弊社のサービスの利用に関する他の契約に違反しないこと、または(iii)弊社の権利または第三者の権利を侵害し、違反すること、または不適切に利用しないことを保証します。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.5
          あなたは、個人データの入力、AI機能に関連する利用、および出力の利用が、GDPRの要件に準拠していることを確認しなければなりません。あなたはデータコントローラーであるためです。私たちまたは当社のサービスプロバイダーは、契約上の取引に準拠して、あなたの代理でデータを処理します。AI機能を使用する際には、GDPR第9条に従って特別なデータカテゴリを使用したり入力したりすることや、ユーザーの会社または第三者の取引秘密の保護対象情報を使用することが禁止されています。また、AI機能を違法な目的で使用することも禁止されています。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.6 それ以外の場合、特に4.2節、4.3節および第6節が適用されます。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          2.7
          もしあなたが私たちのAI機能を使用する場合、OpenAIのポリシーに違反する方法で使用しないでください。これには、
          Content Policy（OpenAI）; Sharing and Publication Policy（OpenAI）;
          Community Guidelines（OpenAI）などが含まれます。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          3 AI出力の範囲
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          3.1
          私たちのAIの能力は、データの中でのつながりを特定し、知的にデータを繋げ、結論を導き、予測を行い、プロセスを自動化し、コンテンツを作成し、インスピレーションを提供し、タスクをより迅速に完了させるよう設計されています。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          3.2
          あなたは、AIや機械学習モデルの性質により、AIの出力が一意でない場合があり、当社のAI機能が他の第三者と同様または類似した出力を生成する可能性があることを認めます。また、出力は自然人によって作成された作品ではなく、したがって一般的には合理的な編集が行われる前には著作権の保護の対象とはならない事を認めます。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          3.3
          さらに、AIモデルが不正確または攻撃的なコンテンツを生成する可能性があることを認めますが、これはBuilBridgeやあなた自身の意見を反映しているものではありません。AIの生成された出力を信頼し、公開し、またはそれ以外の方法で使用する前に注意深く検討すべきです。詳細さによって正確に見えるAIの出力であっても、不正確な素材を含んでいる可能性があります。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          4 保証と責任
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          4.1
          BuilBridgeは、AIモデルの利用によって得られる結果について一切の責任を負いません。アイディーの機能を使用して生成されるコンテンツやデータは、利用者自身のリスクと責任において処理されることを認識し、同意するものとします。特に、AI機能の一部として提供されるコンテンツや結果の正確性、品質、完全性、信頼性、適合性について、BuilBridgeは一切の責任を負いません。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          4.2
          弊社の利用条件またはその他の契約に反することを無視したサードパーティプロバイダの障害によるAI機能の停止時間は、可用性および停止時間の計算に含まれません。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          4.3
          そうでない場合、特に番号7および8の一般条件と責任の保証規定が適用されます。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          5 責任の解放
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6 mb-20">
          5.1
          あなたは、（i）あなたが投稿したコンテンツ、または（ii）あなたがAI機能を使用したこと、または（iii）あなたが入力として使用したコンテンツによってBuilBridgeが他のユーザーまたは第三者から提起された権利侵害の請求を含む、すべての請求からBuilBridgeを解放します。第三者の権利の侵害によって発生した合理的な費用は、あなたが負担しなければなりません。合理的な法的防御の費用を含む、当社が受けた合理的なコストは全てあなたが負担します。BuilBridgeのすべてのその他の権利と損害賠償請求は、これによって影響を受けません。
        </p>
      </div>
    </div>
  );
}
