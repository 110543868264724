import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { noTokenApi } from "../services/api";
import { getA8ID, setA8ID } from "../services/utils";
import { useDispatch } from "react-redux";
import { showLoginModal } from "../redux/actions/languiseAction";
import { useTranslation } from "react-i18next";

export default function VerifyUser() {
  const { t } = useTranslation();
  const { token } = useParams();
  const [isVerify, setVerify] = useState(null);
  const [errorString, setErrorString] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (token !== "") {
      noTokenApi
        .get(`/user/verify/${token}`)
        .then((res) => {
          setVerify(true);
          if (res.data.a8) {
            setA8ID(res.data.a8);
          }
          const a8 = getA8ID();
          const so = `free_${Date.now()}`;
          if (a8) {
            setA8ID(res.data.a8);
            fetch(
              `https://px.a8.net/a8fly/earnings?a8=${a8}&pid=s00000026066001&so=${so}&si=10-1-10-a8free&currency=JPY`,
              {
                method: "GET",
                mode: "no-cors",
              }
            ).catch((err) => {
              console.log(err);
            });
          }
        })
        .catch((err) => {
          setVerify(false);
          setErrorString(err.response.data);
        });
    }
  }, [token]);

  return (
    <div className="flex flex-col flex-grow">
      <div className="flex flex-col xl:gap-[23px] sm:gap-4 gap-2 2xl:max-w-[1306px] max-w-[95%] w-full sm:mt-20 mt-12 mx-auto">
        {isVerify === null && (
          <p className="w-full sm:text-[45px] text-[22px]  text-[#333876] font-sans text-center mb-[200px]">
            {t("verifyUserPageText4")}
          </p>
        )}
        {!isVerify ? (
          <p className="w-full sm:text-[45px] text-[22px]  text-[#333876] font-sans text-center mb-[200px]">
            <br />
            {errorString === "Signature has expired" ? (
              t("verifyUserPageText5")
            ) : (
              <>
              {t("verifyUserPageText6")}
                <br />
                {t("verifyUserPageText7")}
              </>
            )}
          </p>
        ) : (
          <div className=" text-[#333876] font-sans text-center">
            <p className="w-full sm:text-[45px] text-[22px] mb-20">
            {t("verifyUserPageText8")}
            </p>
            <Link
              className="sm:text-[20px] text-[16px] underline"
              to="/"
              onClick={() => dispatch(showLoginModal(true))}
            >
                {t("verifyUserPageText9")}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
