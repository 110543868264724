import React, { useState, useEffect } from "react";
import CheckContent from "../components/CheckContent";
import AboutTranslation from "../components/AboutTranslation";
import AboutProofreading from "../components/AboutProofreading";
import AboutSummary from "../components/AboutSummary";
import Plans from "../components/Plans";
import { Helmet } from "react-helmet";

export default function AboutJp() {
  const [clicked, setClicked] = useState(1);
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    if (!document) return;
    setIsSticky(window.pageYOffset > 300);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>Languise - About</title>
        <meta
          name="description"
          content="ビジネスの新しいパートナー、先端AI技術搭載の全方位文章業務支援ツール。最新のchatGPTエンジンを核として、Languiseはビジネスシーンの文章業務を全面的にサポートします。世界100以上の言語に対応しており、複雑で多様なコミュニケーションニーズに応える能力を持っています。翻訳・校正・要約という、専門家でも手間をかける三大課題を、AIの卓越した技術で瞬時に解決。これにより、ミスのリスクを大幅に減少させつつ作業の効率性を飛躍的に向上させることが可能です。さらに、Languiseの真骨頂はそのファイル処理能力です。PDF、Word、PowerPointなどの主要なファイル形式に対して、特別な前処理なしで対応。写真や図、グラフの配置をそのまま保ちつつ、内容を精緻に処理。作業時間の短縮とともに高い品質の維持を実現します。"
        />
        <link rel="canonical" href="https://languise.com/about" />
      </Helmet>
      <div
        className="flex sm:justify-center justify-start items-center w-full bg-cover bg-center h-[200px] sm:h-[320px] lg:h-[360px] lg:pl-10"
        style={{
          background:
            "linear-gradient(90deg, #229CB9 0%, #2C574B 16%, #0F3D51 30%, #6C0964 70%, #065D77 86%, #030412 100%) 0% 0% no-repeat padding-box",
        }}
      >
        <div className="xl:max-w-[1060px] sm:max-w-[600px] md:max-w-[95%] max-w-[400px] px-6 sm:px-0 w-full flex flex-col">
          <h1 className="text-white font-sans xl:text-[50px] md:text-[36px] lg:text-[40px] sm:text-[30px] text-[20px] sm:leading-[85px] sm:tracking-normal tracking-[-3px] leading-[27px] text-start font-[600]">
            先端AI搭載の高精度文書業務支援ツール
            <br />
            文書ファイルをそのまま翻訳・校正・要約
          </h1>
        </div>
      </div>
      {/* World Image Content */}
      <div className="xl:flex flex-row justify-center xl:max-w-[1145px] max-w-[1400px] w-full mx-auto xl:mt-[113px] sm:mt-[60px] mt-[26px] gap-[75px] xl:px-0 px-5">
        <div className="flex flex-col gap-6 xl:max-w-[575px] max-w-[1400px] w-full">
          <p className="font-sans md:text-xl sm:text-[16px] text-[14px] sm:leading-8 leading-[24px] text-[#222222] text-justify">
            Languise は独自の文書処理技術とchatGPT
            エンジンの融合により誕生した、PDF 、Word 、PowerPoint 、Excel
            などの文書ファイルを画像や表、グラフなどの配置を維持したまま翻訳・校正・要約できる、高精度な文書業務支援ツールです。世界中の100
            以上の言語に対応し、日常のビジネス文書はもちろん、医学や工学、法律などの専門的な用途でもご利用頂けます
          </p>
          <p className="font-sans md:text-xl sm:text-[16px] text-[14px] sm:leading-8 leading-[24px] text-[#222222] text-justify">
            翻訳や校正したファイルはダウンロードできるだけでなく、プレビュー機能を使って処理前後のファイルを横並びで閲覧することもできます。わからない単語や専門用語、文書に関する疑問などの質問に答えるチャットbot
            機能も搭載しており、読解をより効率的に進める事ができます。
          </p>
          <p className="font-sans md:text-xl sm:text-[16px] text-[14px] sm:leading-8 leading-[24px] text-[#222222] text-justify">
            これら全ての機能でセキュリティも完備しています。処理した文書やテキスト等のデータは全て削除され、AI
            の学習等に二次利用されません。
          </p>
        </div>
        <div className="flex justify-center w-full xl:mt-0 sm:mt-[30px] mt-[100px]">
          <img
            className="flex sm:max-w-[513px] max-w-[320px] w-full sm:max-h-[426px] max-h-[266px] h-full"
            src="/assets/1-img-globe.png"
            alt="Globale"
          />
        </div>
      </div>
      {/* Check Content */}
      <div className="flex flex-col xl:max-w-[1145px] max-w-[1400px] w-full mx-auto xl:mt-[104px] sm:mt-[70px] mt-[42.5px] gap-[25px] xl:px-0 px-5">
        <CheckContent
          title="先端AI技術による高度な文章生成能力"
          content="全ての処理において自然で正確性の高い文章を提供します。"
        />
        <CheckContent
          title="文章業務の作業効率を大きく改善"
          content="高精度な翻訳・校正・要約と新しいプレビュー機能が、全ての文章業務の効率を引き上げます。"
        />
        <CheckContent
          title="安心のセキュリティ"
          content="処理にかけたデータは全て削除され、二次利用されません。"
        />
        <CheckContent
          title="ファイルもそのまま"
          content="ファイルをアップロードするだけで、中の文章をLanguiseが自動で読み取って処理します。"
        />
      </div>
      <div className="flex xl:max-w-[1145px] max-w-[1400px] w-full mx-auto mt-[53px] xl:px-0 px-5">
        <div className="flex flex-col gap-[30px] rounded-[20px] bg-[#F0F1F8] px-5 sm:px-[60px] pt-8 sm:pt-[42px] pb-28">
          <p className="text-[#333876] xl:text-xl text-[16px] font-sans text-start font-[600]">
            <h3 className="xl:text-2xl mb-7 sm:mb-10 font-[600]">
              対応言語の一例
            </h3>
            <span className="xl:text-xl text-sm">
              英語(US)、英語(UK)、スペイン語、フランス語、ドイツ語、中国語（簡体字）、中国語（繁体字）、ロシア語、イタリア語、ポルトガル語、オランダ語、アラビア語、日本語、韓国語、トルコ語、ウクライナ語、スウェーデン語、デンマーク語、ノルウェー語、フィンランド語、ギリシャ語、ヘブライ語、インドネシア語、マレー語、タイ語、チェコ語、ハンガリー語、ポーランド語、ルーマニア語、ブルガリア語、セルビア語、クロアチア語、スロベニア語、ラトビア語、リトアニア語、ヒンディー語、グジャラート語、マラーティー語、タミル語、カンナダ語、マラヤーラム語、ウルドゥー語、パンジャーブ語、その他
            </span>
          </p>
        </div>
      </div>
      <div className="w-full" id="tabs-section">
        <div
          className={`flex w-full xl:gap-[30px] sm:gap-5 gap-[10px] bg-white justify-center xl:mt-[108px] sm:mt-[65px] mt-[41px] px-5 py-0 ${
            isSticky ? "sticky-tabs" : ""
          }`}
          id="tabs"
        >
          <div
            onClick={() => setClicked(1)}
            className={`xl:max-w-[380px] sm:max-w-[240px] max-w-[100px] w-full ${
              clicked === 1
                ? "xl:h-[136px] sm:h-[77px] h-[41px] !rounded-b-none"
                : "xl:h-[120px] sm:h-[70px] h-[37px]"
            } bg-[#EAEAEA] xl:rounded-[20px] sm:rounded-[15px] rounded-[10px] flex justify-center items-center text-[#333876] xl:text-[32px] sm:text-[20px] text-[12px] font-sans font-[600] cursor-pointer`}
          >
            翻訳機能
          </div>
          <div
            onClick={() => setClicked(2)}
            className={`xl:max-w-[380px] sm:max-w-[240px] max-w-[100px] w-full ${
              clicked === 2
                ? "xl:h-[136px] sm:h-[77px] h-[41px] !rounded-b-none"
                : "xl:h-[120px] sm:h-[70px] h-[37px]"
            } bg-[#EAEAEA] xl:rounded-[20px] sm:rounded-[15px] rounded-[10px] flex justify-center items-center text-[#333876] xl:text-[32px] sm:text-[20px] text-[12px] font-sans font-[600] cursor-pointer`}
          >
            校正機能
          </div>
          <div
            onClick={() => setClicked(3)}
            className={`xl:max-w-[380px] sm:max-w-[240px] max-w-[100px] w-full ${
              clicked === 3
                ? "xl:h-[136px] sm:h-[77px] h-[41px] !rounded-b-none"
                : "xl:h-[120px] sm:h-[70px] h-[37px]"
            } bg-[#EAEAEA] xl:rounded-[20px] sm:rounded-[15px] rounded-[10px] flex justify-center items-center text-[#333876] xl:text-[32px] sm:text-[20px] text-[12px] font-sans font-[600] cursor-pointer`}
          >
            要約機能
          </div>
        </div>
        {clicked === 1 && <AboutTranslation />}
        {clicked === 2 && <AboutProofreading />}
        {clicked === 3 && <AboutSummary />}
      </div>
      <section className="pt-20 pb-16 bg-white">
        <div className="xl:max-w-[1145px] max-w-[1400px] w-full mx-auto px-8">
          <h2 className="text-center font-sans font-[600] text-xl md:text-3xl text-[#333876] lg:text-5xl mb-7 md:mb-9">
            「読む」の常識を変える新しい読解機能
          </h2>
          <p className="mt-4 text-sm sm:text-base font-sans text-left">
            多くの場合、翻訳の目的は「読む」事だと思います。Languiseを使えば翻訳や校正処理した文書ファイルをダウンロードするだけでなく、プレビュー画面で結果を見ることもできます。
            文字サイズの拡大や縮小という基本動作はもちろん、文書を見ながら同じ画面で翻訳機能を使えたり、文書の内容や不明な用語などについてLanguiseに質問ができます。
            例えば経済レポートを読みながら最新の経済見通しについて質問したり、長い学術論文の中で自分が欲しい情報がどこに記載されているか尋ねることもできます。
          </p>
          <div className="mt-4">
            <img src="/assets/features.png" />
          </div>
        </div>
      </section>
      <div className="bg-[#F0F1F8]">
        <h2 className="font-sans xl:text-[50px] sm:text-[35px] text-[20px] text-center text-[#333876] xl:my-12 sm:my-5 my-2 font-[600] px-5">
          AIによる新らしい言語処理を
          <br className="sm:hidden" />
          体験してください
        </h2>
      </div>
      <div className="bg-[#F0F1F8] xl:pt-[45px] sm:pt-[20px] pt-[27px]">
        <Plans />
      </div>
    </div>
  );
}
