import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SecurityEn from "./SecurityEn";
import SecurityJp from "./SecurityJp";

export default function Security() {
  const uiLang = useSelector((state) => state.LanguiseReducer.uiLang);
  const [lang, setLang] = useState("jp");

  useEffect(() => {
    setLang(uiLang);
  }, [uiLang]);

  return lang === "jp" ? <SecurityJp /> : <SecurityEn />;
}
