import React from "react";
import { useTranslation } from "react-i18next";

import Title from "./Title";
import Card from "./Card";

export default function AboutTranslationTab() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center w-full bg-[#EAEAEA] mt-4 relative top-[-16px]">
      <Title marginTop="" title={t("aboutTab1Title")} />
      <div className="max-w-[1078px] mx-auto">
        <div className="grid lg:grid-cols-2 xl:justify-between justify-center md:gap-[60px] sm:gap-10 max-w-[1430px] w-full mx-auto xm:mt-[132px] mt-[40px] md:mt-[130px] 2md:px-0 px-5">
          <Card
            width="528px"
            smWidth="128px"
            smHeight="auto"
            thumbnail="1-icon-brain.png"
            alternative="Brain"
            title={t("aboutItem1Title")}
            thumbnailSize={{ width: "190px", height: "174px" }}
            content={t("aboutItem1Content")}
          />
          <Card
            width="490px"
            height="168px"
            smWidth="110px"
            smHeight="auto"
            thumbnail="1-icon-security.png"
            alternative="Security"
            title={t("aboutItem2Title")}
            thumbnailSize={{ width: "144px", height: "163px" }}
            content={t("aboutItem2Content")}
          />
        </div>
        <div className="bg-white rounded-2xl flex-col gap-4 md:flex-row pt-10 mt-20 lg:mt-24 mx-4 md:mx-8 xl:mx-0 flex">
          <div className="w-full md:w-1/2 px-4 sm:px-16 pt-0 md:pt-8 text-left text-[#333876]">
            <h3 className="pl-6 text-xl font-[600] font-sans text-center md:text-left">
              {t("aboutTab1FeatureTitle")}
            </h3>
            <p className="text-base mt-9 font-sans mb-8">
              {t("aboutTab1FeatureContent")}
            </p>
          </div>
          <div className="w-full md:w-1/2 h-[316px] overflow-hidden">
            <img
              className={`max-w-[200%] w-[110%]`}
              src={`/assets/about-book.jpg`}
              alt="Translated Book"
            />
          </div>
        </div>
      </div>

      <Title marginTop="150px" title={t("aboutTab2ExampleTitle")} />
      <div className="grid grid-cols-1 lg:grid-cols-2 max-w-[1430px] w-full gap-[7px] justify-center md:mt-[52.5px] mx-auto mt-10 md:mb-[127px] sm:mb-8 mb-4 px-4 text-left">
        <div className="bg-[#f3f4ff] p-5 rounded-xl">
          <h3 className="text-base md:text-xl text-[#333876] font-semibold font-sans ps-8">
            元テキスト
          </h3>
          <p className="mt-4 text-sm sm:text-base font-sans pb-6 ps-5 pe-2">
            In the context of quantum computing, the phenomenon of superposition
            allows qubits to exist simultaneously in multiple states, which
            exponentially increases the computational power compared to
            classical bits. For example, a quantum computer with 100 qubits can
            represent 2^100 states at once, whereas a classical computer would
            require 1.27x10^30 classical bits to achieve the same.
          </p>
          <div className="rounded-2xl p-4 bg-[#C2C4EB]">
            <h3 className="text-base md:text-xl text-[#333876] font-semibold font-sans ps-8">
              Languise
            </h3>
            <p className="text-sm sm:text-base font-normal pb-0 sm:pb-4 font-sans mt-4">
              量子コンピューティングの文脈において、重ね合わせの現象によって、キュビットが複数の状態で同時に存在することが可能となり、これにより古典的なビットに比べて指数関数的に計算能力が向上します。例えば、100個のキュビットを持つ量子コンピューターは一度に2^100個の状態を表現できますが、古典コンピューターでは同じことを実現するために1.27x10^30個の古典的なビットが必要となります。
            </p>
          </div>
          <div className="rounded-2xl p-4 bg-[#E8E8E8] mt-4">
            <h3 className="text-base md:text-xl text-[#333876] font-semibold font-sans ps-8">
              翻訳ソフトA
            </h3>
            <p className="text-sm sm:text-base font-normal pb-4 font-sans mt-4">
              量子コンピューティングの文脈では、重ね合わせ現象によって量子ビットが複数の状態で同時に存在できるようになり、古典的なビットに比べて計算能力が指数関数的に向上します。例えば、100個の量子ビットを持つ量子コンピュータは、一度に2^100の状態を表現することができます。
            </p>
            <p className="text-sm sm:text-base font-normal font-sans mt-4 text-[#FF0000] border border-[#FF0000] px-4 py-[2px]">
              {t("aboutOmissionOccurs")}
            </p>
          </div>
        </div>
        <div className="bg-[#f3f4ff] p-5 rounded-xl">
          <h3 className="text-base md:text-xl text-[#333876] font-semibold font-sans ps-8">
            元テキスト
          </h3>
          <p className="mt-4 text-sm sm:text-base font-sans pb-6 ps-5 pe-2">
            Despite the complexity of the situation and the myriad of
            conflicting interests, the negotiators managed to reach a mutually
            acceptable compromise without sacrificing the core principles of
            either party.
          </p>
          <div className="rounded-2xl p-4 bg-[#C2C4EB]">
            <h3 className="text-base md:text-xl text-[#333876] font-semibold font-sans ps-8">
              Languise
            </h3>
            <p className="text-sm sm:text-base font-normal pb-0 sm:pb-4 font-sans mt-4">
              状況の複雑さや相反する多くの利害関係にも関わらず、交渉者たちは両者の基本原則を犠牲にすることなく、相互に受け入れられる妥協点に到達することができました。
            </p>
          </div>
          <div className="rounded-2xl p-4 bg-[#E8E8E8] mt-4">
            <h3 className="text-base md:text-xl text-[#333876] font-semibold font-sans ps-8">
              翻訳ソフトA
            </h3>
            <p className="text-sm sm:text-base font-normal font-sans text-right pe-20 text-[#FF0000]">
              {t("aboutOmissionOccursDirectly1")}
            </p>
            <p className="text-sm sm:text-base font-normal pb-0 sm:pb-4 font-sans">
              <span className="border border-[#FF0000]">
                状況が複雑で、利害が無数に対立していたにもかかわらず、交渉者
              </span>
              たちは、両当事者の基本原則を犠牲にすることなく、互いに受け入れ可能な妥協点に達することができました。
            </p>
          </div>
          <div className="rounded-2xl p-4 bg-[#E8E8E8] mt-4">
            <h3 className="text-base md:text-xl text-[#333876] font-semibold font-sans ps-8">
              翻訳ソフトB
            </h3>
            <p className="text-sm sm:text-base font-normal font-sans mt-4">
              状況の複雑さと無数の利害の対立にも関わらず、交渉担当者は、どちらの当事者の中核となる
              <span className="border border-[#FF0000]">
                原則を犠牲にすることなく、なんとか相互に受
              </span>
              け入れられる妥協点に達することができた。
            </p>
            <p className="text-sm sm:text-base font-normal font-sans text-right pe-20 text-[#FF0000]">
              {t("aboutOmissionOccursDirectly2")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
