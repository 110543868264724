import React from "react";

export default function CheckContent({ title, content }) {
  return (
    <div className="flex xl:gap-[22px] sm:gap-[16px] gap-[10px]">
      <div className="xl:max-w-[65px] sm:max-w-[42px] max-w-[20px] w-full xl:h-[50px] sm:h-[38px] h-[26px] flex justify-center items-center">
        <img
          className="xl:w-[40.5px] sm:w-[30px] w-5 xl:h-[28.5px] sm:h-[21px] h-[14px]"
          src="/assets/check.png"
          alt="Check"
        />
      </div>
      <div className="flex flex-col xl:gap-[23px] sm:gap-[15px] gap-2">
        <h2 className="text-[#11135C] xl:text-[40px] sm:text-[28px] text-[16px] font-sans text-start font-[600]">
          {title}
        </h2>
        <p className="text-[#6E6E6E] xl:text-[20px] sm:text-[17px] text-[12px] font-sans text-start">
          {content}
        </p>
      </div>
    </div>
  );
}
