import React from "react";
import InvoiceTable from "../components/InvoiceTable";
import { useTranslation } from "react-i18next";

export default function MyInvoice() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col w-full flex-grow">
      <div className="md:max-w-[1440px] md:w-full w-[100vw] pl-10 mx-auto mt-10 mb-5 text-left text-[#4B5090]">
          {t("invoicePageText1")}
      </div>
      <InvoiceTable />
    </div>
  );
}
