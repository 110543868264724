import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export function CheckBox({ checked, text, onClick }) {
  const [isChecked, setChecked] = useState(false);
  const handleClick = () => {
    onClick();
  };
  useEffect(() => {
    setChecked(checked);
  }, [checked]);
  return (
    <div
      onClick={handleClick}
      className="flex items-center gap-[6px] sm:pl-[10px] pl-0 cursor-pointer"
    >
      <input
        type="checkbox"
        checked={isChecked}
        onChange={() => setChecked(!isChecked)}
        className="!w-[15px] !h-[15px]"
      />
      <p className="text-[#6E6E6E] xl:text-[16px] sm:text-[15px] text-[14px] font-sans">
        {text}
      </p>
    </div>
  );
}

export default function CheckBoxContent({ className="", onChange }) {
  const { t } = useTranslation();
  const [atmosphere, setAtmosphere] = useState(0);
  const [atmosphereText, setAtmosphereText] = useState("");
  const [gender, setGender] = useState(0);
  const [startAge, setStartAge] = useState("");
  const [endAge, setEndAge] = useState("");
  const [country, setCountry] = useState("");
  const [purpose, setPurpose] = useState("");

  useEffect(() => {
    const params = {
      atmosphere,
      atmosphereText,
      gender,
      startAge,
      endAge,
      country,
      purpose,
    };
    onChange(params);
  }, [
    atmosphere,
    atmosphereText,
    gender,
    startAge,
    endAge,
    country,
    purpose,
    onChange,
  ]);
  return (
    <div
      className={`${className} md:max-w-[1300px] w-full 2xl:px-0 px-5 justify-start mx-auto flex-col sm:mb-auto mb-6 `}
    >
      <div className="md:flex flex-row xl:gap-[35px] lg:gap-3 sm:gap-[10px] gap-1 w-full justify-between">
        <div className="flex xl:gap-[35px] lg:gap-3 sm:gap-[10px] gap-14 sm:pl-[20px]">
          <div className="flex flex-col sm:gap-3 gap-1">
            <p className="xl:text-[20px] sm:text-[17px] max-w-[200px] text-start text-[16px] font-sans font-semibold text-[#4B5090]">
              {t("checkBoxContentText1")}
            </p>
            <CheckBox
              checked={atmosphere === 0}
              text={t("checkBoxContentText2")}
              onClick={() => setAtmosphere(0)}
            />
            <CheckBox
              checked={atmosphere === 1}
              text={t("checkBoxContentText3")}
              onClick={() => setAtmosphere(1)}
            />
            <CheckBox
              checked={atmosphere === 2}
              text={t("checkBoxContentText4")}
              onClick={() => setAtmosphere(2)}
            />
            <CheckBox
              checked={atmosphere === 3}
              text={t("checkBoxContentText5")}
              onClick={() => setAtmosphere(3)}
            />
          </div>

          <div className="flex flex-col items-start sm:gap-3 gap-1">
            <p className="xl:text-[20px] max-w-[200px] text-start sm:text-[17px] text-[16px] font-sans font-semibold text-[#4B5090]">
              {t("checkBoxContentText6")}
            </p>
            <CheckBox
              checked={gender === 0}
              text={t("checkBoxContentText2")}
              onClick={() => setGender(0)}
            />
            <CheckBox
              checked={gender === 1}
              text={t("checkBoxContentText7")}
              onClick={() => setGender(1)}
            />
            <CheckBox
              checked={gender === 2}
              text={t("checkBoxContentText8")}
              onClick={() => setGender(2)}
            />
          </div>
        </div>
        {atmosphere === 3 && (
          <div className="md:hidden flex flex-row gap-2 items-start justify-start sm:pl-[10px] max-w-[345px] pl-0  w-full sm:mt-0">
            <input
              value={atmosphereText}
              onChange={(e) => setAtmosphereText(e.target.value)}
              className="max-w-[160px] md:max-w-[140px] w-full h-[30px] rounded-[10px] border border-[#707070] focus:outline-none px-[7px] font-sans sm:mt-0 mt-[6px]"
              type="text"
              placeholder={t("checkBoxContentText9")}
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) => (e.target.placeholder = t("checkBoxContentText9"))}
            />
          </div>
        )}

        <div className="flex xl:gap-[35px] lg:gap-3 sm:gap-[10px] gap-9 sm:mt-0 mt-4">
          <div className="flex flex-col sm:gap-3 gap-1 items-start">
            <p className="xl:text-[20px] max-w-[200px] text-start sm:text-[17px] text-[16px] test-start font-sans font-semibold text-[#4B5090]">
              {t("checkBoxContentText10")}
            </p>
            <CheckBox
              checked={startAge === "" && endAge === ""}
              text={t("checkBoxContentText2")}
              onClick={() => {
                setStartAge("");
                setEndAge("");
              }}
            />
            <div className="flex gap-[7px] sm:pl-[10px] pl-0 items-center">
              <input
                value={startAge}
                onChange={(e) => setStartAge(e.target.value)}
                className="lg:w-[50px] sm:w-[40px] w-[50px] h-[30px] rounded-[10px] border border-[#707070] focus:outline-none text-center font-sans"
                type="text"
              />
              <span className="text-[23px] text-[#6E6E6E] font-sans">~</span>
              <input
                value={endAge}
                onChange={(e) => setEndAge(e.target.value)}
                className="lg:w-[50px] sm:w-[40px] w-[50px] h-[30px] rounded-[10px] border border-[#707070] focus:outline-none text-center font-sans"
                type="text"
              />
              <span className="xl:text-[16px] sm:text-[15px] text-[14px] text-[#6E6E6E] text-sans">
                {t("checkBoxContentText11")}
              </span>
            </div>
          </div>

          <div className="flex flex-col sm:gap-3 gap-1">
            <p className="xl:text-[20px] max-w-[200px] text-start sm:text-[17px] text-[16px] test-start font-sans font-semibold text-[#4B5090]">
              {t("checkBoxContentText12")}
            </p>
            <CheckBox
              checked={country === ""}
              text={t("checkBoxContentText2")}
              onClick={() => {
                setCountry("");
              }}
            />
            <input
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="xl:max-w-[160px] sm:max-w-[140px] max-w-[160px] w-full h-[30px] rounded-[10px] border border-[#707070] focus:outline-none px-[7px] font-sans sm:ml-[10px] ml-0 mt-[2px]"
              type="text"
              placeholder={t("checkBoxContentText13")}
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) =>
                (e.target.placeholder = t("checkBoxContentText13"))
              }
            />
          </div>
        </div>

        <div className="flex flex-col flex-1 sm:gap-3 gap-[10px] items-start sm:mt-0 mt-3">
          <p className="xl:text-[20px] max-w-[200px] text-start sm:text-[17px] text-[16px] font-sans font-semibold text-[#4B5090]">
            {t("checkBoxContentText14")}
          </p>
          <div className="sm:flex flex-row gap-2 items-start justify-start sm:pl-[10px] max-w-[345px] pl-0  w-full sm:mt-0 mt-[-14px]">
            <p className="sm:hidden flex justify-start text-[#6E6E6E] sm:text-[10px] text-[8px] font-sans text-start">
              {t("checkBoxContentText15")}
            </p>
            <input
              value={purpose}
              onChange={(e) => setPurpose(e.target.value)}
              className="sm:max-w-[160px] max-w-[345px] w-full h-[30px] rounded-[10px] border border-[#707070] focus:outline-none px-[7px] font-sans sm:mt-0 mt-[6px]"
              type="text"
              placeholder={t("checkBoxContentText16")}
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) =>
                (e.target.placeholder = t("checkBoxContentText16"))
              }
            />
            <p className="lg:flex hidden text-[#6E6E6E] xl:text-[16px] sm:text-[15px] text-[14px] mt-1 font-sans">
              {t("checkBoxContentText17")}
            </p>
          </div>
          <div className="flex flex-col max-w-[345px] w-full gap-[10px] sm:pl-[10px] pl-0">
            <p className="sm:flex hidden sm:justify-start justify-center text-[#6E6E6E] xl:text-[17px] sm:text-[14px] text-[13px] font-sans text-start">
              {t("checkBoxContentText18")}
            </p>
            <p className="sm:flex hidden sm:justify-start justify-center max-w-[200px] text-[#6E6E6E] sm:text-[10px] text-[8px] font-sans text-start">
              {t("checkBoxContentText15")}
            </p>
          </div>
        </div>
      </div>
      {atmosphere === 3 && (
        <div className="md:flex hidden gap-2 items-start justify-start sm:pl-[10px] max-w-[345px] pl-0 w-full mt-2 ml-[20px]">
          <input
            value={atmosphereText}
            onChange={(e) => setAtmosphereText(e.target.value)}
            className="max-w-[160px] w-full h-[30px] rounded-[10px] border border-[#707070] focus:outline-none px-[7px] font-sans sm:mt-0 mt-[6px]"
            type="text"
            placeholder={t("checkBoxContentText9")}
            onFocus={(e) => (e.target.placeholder = "")}
            onBlur={(e) => (e.target.placeholder = t("checkBoxContentText9"))}
          />
        </div>
      )}
    </div>
  );
}
