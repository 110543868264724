import * as Actions from "../constant";
import api from "../../services/api";

export const showLoginModal = (payload) => async (dispatch) => {
  dispatch({
    type: Actions.SHOW_LOGIN,
    payload,
  });
};

export const loginUser = (payload) => async (dispatch) => {
  dispatch({
    type: Actions.LOGIN_USER,
    payload,
  });
};

export const getUserInfo = () => async (dispatch) => {
  api
    .get("/user/")
    .then((response) => {
      dispatch({
        type: Actions.GET_USER_INFO,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actions.GET_USER_INFO,
        payload: {},
      });
    });
};

export const postTranslateText = (formData) => async (dispatch) => {
  api
    .post("/translate/text/", formData)
    .then((response) => {
      dispatch({
        type: Actions.POST_TRANSLATE,
        payload: response.data.result,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actions.POST_TRANSLATE,
        payload: "",
      });
    });
};

export const postTranslateFile = (formData) => async (dispatch) => {
  api
    .post("/translate/file/", formData)
    .then((response) => {
      dispatch({
        type: Actions.POST_TRANSLATE_FILE,
        payload: response.data.filename,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actions.POST_TRANSLATE_FILE,
        payload: "",
      });
    });
};

export const detectLanguage = (text) => async (dispatch) => {
  if (text === "") {
    dispatch({
      type: Actions.DETECT_LANGUAGE,
      payload: "Detect language",
    });
    return;
  }
  api
    .post("/assistance/detectlang/", { text })
    .then((response) => {
      let lang = response.data.language;
      if (lang === "English") lang = "English (US)";
      if (lang === "Chinese") lang = "Chinese (Simplified)";
      dispatch({
        type: Actions.DETECT_LANGUAGE,
        payload: lang,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actions.DETECT_LANGUAGE,
        payload: "Detect language",
      });
    });
};

export const getDictionaryList = () => async (dispatch) => {
  api
    .get("/dictionary/folder/")
    .then((response) => {
      dispatch({
        type: Actions.GET_DICTIONARY_LIST,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actions.GET_DICTIONARY_LIST,
        payload: [],
      });
    });
};

export const loadSeverStatus = () => async (dispatch) => {
  api
    .get("/management/maintenance/")
    .then((response) => {
      dispatch({
        type: Actions.IS_UNDER_MAINTENANCE,
        payload: response.data.is_under_maintenance,
      });
    })
    .catch((err) => {
      dispatch({
        type: Actions.IS_UNDER_MAINTENANCE,
        payload: false,
      });
    });
};

export const getDictionaryContentList = (formData) => async (dispatch) => {
  const params = new URLSearchParams();

  for (const [key, value] of formData) {
    params.append(key, value);
  }
  api
    .get("/dictionary/detail/", {
      params, // Use the constructed URLSearchParams here
    })
    .then((response) => {
      dispatch({
        type: Actions.GET_DICTIONARY_CONTENT_LIST,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actions.GET_DICTIONARY_CONTENT_LIST,
        payload: [],
      });
    });
};

export const getCorporateUsers = () => (dispatch) => {
  api
    .get("/corporate/users/")
    .then((response) => {
      dispatch({
        type: Actions.CORPORATE_USERS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actions.CORPORATE_USERS,
        payload: [],
      });
    });
};

export const getPlans = () => (dispatch) => {
  api
    .get("/user/plan/")
    .then((response) => {
      dispatch({
        type: Actions.PLANS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actions.PLANS,
        payload: [],
      });
    });
};

export const getEnterprisePlans = () => (dispatch) => {
  api
    .get("/user/enterpriseplan/")
    .then((response) => {
      dispatch({
        type: Actions.ENTERPRISE_PLANS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actions.ENTERPRISE_PLANS,
        payload: [],
      });
    });
};

export const getPrices = () => (dispatch) => {
  api
    .get("/payment/products/")
    .then((response) => {
      dispatch({
        type: Actions.PRICES,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actions.PRICES,
        payload: { monthly: [], yearly: [] },
      });
    });
};

export const changeUiLang = (payload) => async (dispatch) => {
  dispatch({
    type: Actions.CHANGE_UI_LANG,
    payload,
  });
};
