import React from "react";
import { useTranslation } from "react-i18next";

import CardVertical from "./CardVertical";
import Title from "./Title";

export default function AboutSummary() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center w-full bg-[#EAEAEA] mt-4 relative top-[-16px]">
      <Title marginTop="" title={t("aboutTab3Title")} />
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 justify-between xl:gap-[42px] sm:gap-4 xl:max-w-[1347px] max-w-[1400px] w-full mx-auto xm:mt-[95px] sm:mt-[65px] mt-0 2xl:px-0 px-5">
        <CardVertical
          width="105px"
          height="165px"
          smWidth="80px"
          smHeight="125px"
          thumbnail="3-hand.png"
          alternative="Hand"
          title={t("aboutTab3Card1Title")}
          content={t("aboutTab3Card1Content")}
        />
        <CardVertical
          width="145px"
          height="160px"
          smWidth="110px"
          smHeight="121px"
          thumbnail="3-layer-document.png"
          alternative="Documentation"
          title={t("aboutTab3Card2Title")}
          content={t("aboutTab3Card2Content")}
        />
        <CardVertical
          style={{ marginTop: "25px" }}
          width="180px"
          height="138px"
          smWidth="137px"
          smHeight="105px"
          thumbnail="3-layer-title.png"
          alternative="Title"
          title={t("aboutTab3Card3Title")}
          content={t("aboutTab3Card3Content")}
        />
        <CardVertical
          width="150px"
          height="170px"
          smWidth="110px"
          smHeight="125px"
          thumbnail="3-security.png"
          alternative="Security"
          title={t("aboutTab3Card4Title")}
          content={t("aboutTab3Card4Content")}
        />
      </div>

      <Title marginTop="150px" title={t("aboutTab3ExampleTitle")} />
      <div className="max-w-[1092px] w-full text-left flex flex-col gap-8 md:gap-16 mt-16 mx-auto mb-8 px-4 sm:px-8 xl:px-0">
        <div className="flex flex-col lg:flex-row justify-between gap-4">
          <div className="flex justify-center order-2 lg:order-none">
            <img src="/assets/usecase-4.png" alt="Translate use case" />
          </div>
          <div className="mt-4 max-w-[450px] order-1 lg:order-none mx-auto lg:mx-0">
            <h3 className="text-xl xl:text-3xl text-center lg:text-left text-[#333876] font-semibold font-sans">
              {t("aboutTab3Example1Title")}
            </h3>
            <p className="mt-4 text-base sm:text-xl font-sans mx-auto lg:mx-0">
              {t("aboutTab3Example1Content")}
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between gap-4">
          <div className="mt-4 max-w-[450px] order-1 lg:order-none mx-auto lg:mx-0">
            <h3 className="text-xl xl:text-3xl text-center lg:text-left text-[#333876] font-semibold font-sans">
              {t("aboutTab3Example2Title")}
            </h3>
            <p className="mt-4 text-base sm:text-xl font-sans mx-auto lg:mx-0">
              {t("aboutTab3Example2Content")}
            </p>
          </div>
          <div className="flex justify-center order-2 lg:order-none">
            <img src="/assets/usecase-5.png" alt="Translate use case" />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between gap-4">
          <div className="flex justify-center order-2 lg:order-none">
            <img src="/assets/usecase-6.png" alt="Translate use case" />
          </div>
          <div className="mt-4 max-w-[450px] order-1 lg:order-none mx-auto lg:mx-0">
            <h3 className="text-xl xl:text-3xl text-center lg:text-left text-[#333876] font-semibold font-sans">
              {t("aboutTab3Example3Title")}
            </h3>
            <p className="mt-4 text-base sm:text-xl font-sans mx-auto lg:mx-0">
              {t("aboutTab3Example3Content")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
