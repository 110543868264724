import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { noTokenApi } from "../services/api";
import { removeAllCookies } from "../services/utils";
import { loginUser } from "../redux/actions/languiseAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

export default function ChangeEmail() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token } = useParams();
  const [isVerify, setVerify] = useState(null);
  const [errorString, setErrorString] = useState("");

  useEffect(() => {
    if (token !== "") {
      noTokenApi
        .get(`/user/verify/${token}`)
        .then((res) => {
          setVerify(true);
          removeAllCookies();
          dispatch(loginUser(false));
          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
        })
        .catch((err) => {
          setVerify(false);
          setErrorString(err.response.data);
        });
    }
  }, [dispatch, setVerify, token]);

  return (
    <div className="flex flex-col flex-grow">
      <div className="flex flex-col xl:gap-[23px] sm:gap-4 gap-2 2xl:max-w-[1306px] max-w-[95%] w-full sm:mt-20 mt-12 mx-auto">
        {isVerify === null && (
          <p className="w-full sm:text-[45px] text-[22px]  text-[#333876] font-sans text-center mb-[200px]">
            {t("changeEmailText1")}
          </p>
        )}
        {!isVerify ? (
          <p className="w-full sm:text-[45px] text-[22px]  text-[#333876] font-sans text-center mb-[200px]">
            <br />
            {errorString === "Signature has expired" ? (
              t("changeEmailText2")
            ) : (
              <>
                {t("changeEmailText3")}
                <br />
                {t("changeEmailText4")}
              </>
            )}
          </p>
        ) : (
          <p className="w-full sm:text-[45px] text-[22px]  text-[#333876] font-sans text-center mb-[200px]">
            {t("changeEmailText5")}
          </p>
        )}
      </div>
    </div>
  );
}
