import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function DicSelect({
  dictionaryList,
  selectedDictionary,
  setSelectedDictionary,
  setCurrentDictionaryId,
  editable=false,
  className,
}) {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const selectDictionary = (dictionaryName, id) => {
    setSelectedDictionary(dictionaryName);
    setCurrentDictionaryId(id);
  };

  return (
    <div className={`relative flex flex-1 ${className}`}>
      <div
        onClick={() => setOpen(!isOpen)}
        className="flex flex-1 justify-between items-center sm:h-[60px] h-[50px] border border-[#4B5090] sm:pl-[28px] pl-5 sm:pr-[22px] pr-3 sm:rounded-[20px] rounded-[10px] bg-transparent cursor-pointer"
      >
        <p className="text-[#6E6E6E] font-sans">
          {selectedDictionary || t("selectDictionary")}
        </p>
        <img
          className="w-[15px] h-[8px]"
          src="/assets/triangle.png"
          alt="Triangle"
        />
      </div>
      {isOpen && (
        <div className="z-[100] absolute w-full sm:mt-[65px] mt-[52px] max-h-[150px] border border-[#707070] rounded-[10px] bg-white">
          {dictionaryList ? (
            dictionaryList.map((item, index) => (
              ((editable && item.editable) || (!editable)) && 
              <div
                onClick={() => {
                  setOpen(false);
                  selectDictionary(item.dictionary_name, item.id);
                }}
                key={index}
                className="flex justify-center items-center h-[35px] cursor-pointer hover:bg-[#f0f0f0] rounded-[10px]"
              >
                {item.dictionary_name}
              </div>
            ))
          ) : (
            <div className="flex justify-start items-center h-[35px] cursor-pointer rounded-[10px]">
              No Dictionary
            </div>
          )}
        </div>
      )}
    </div>
  );
}
