import * as Actions from "../constant";

const initialState = {
  isAuthenticated: false,
  showLoginModal: false,
  isLoading: false,
  userInfo: {},
  translateTextResult: "",
  translateFileResult: "",
  detectedLanguage: "Detect language",
  dictionaryList: [],
  dictionaryContentList: [],
  corporateUsers: [],
  plans: [],
  enterprisePlans: [],
  prices: { monthly: [], yearly: [] },
  uiLang: "en",
  isUnderMaintenance: false,
};

export const LanguiseReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SHOW_LOGIN:
      return {
        ...state,
        showLoginModal: action.payload,
      };
    case Actions.LOGIN_USER:
      return {
        ...state,
        isAuthenticated: true,
      };
    case Actions.GET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case Actions.POST_TRANSLATE:
      return {
        ...state,
        translateTextResult: action.payload,
      };
    case Actions.POST_TRANSLATE_FILE:
      return {
        ...state,
        translateFileResult: action.payload,
      };
    case Actions.DETECT_LANGUAGE:
      return {
        ...state,
        detectedLanguage: action.payload,
      };
    case Actions.GET_DICTIONARY_LIST:
      return {
        ...state,
        dictionaryList: action.payload,
      };
    case Actions.GET_DICTIONARY_CONTENT_LIST:
      return {
        ...state,
        dictionaryContentList: action.payload,
      };
    case Actions.SET_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case Actions.CORPORATE_USERS:
      return {
        ...state,
        corporateUsers: action.payload,
      };
    case Actions.PLANS:
      return {
        ...state,
        plans: action.payload,
      };
    case Actions.ENTERPRISE_PLANS:
      return {
        ...state,
        enterprisePlans: action.payload,
      };
    case Actions.PRICES:
      return {
        ...state,
        prices: action.payload,
      };
    case Actions.CHANGE_UI_LANG:
      return {
        ...state,
        uiLang: action.payload,
      };
    case Actions.IS_UNDER_MAINTENANCE:
      return {
        ...state,
        isUnderMaintenance: action.payload,
      };
    default:
      return state;
  }
};
