import React from "react";

import LoginModalContent from "./LoginModalContent";
import RegisterModalContent from "./RegisterModalContent";

export default function AuthModal({
  openModal,
  goToNextStep,
  goToPreviousStep,
  currentStep,
  transitionDirection,
}) {
  function getAnimationClass(step, direction) {
    if (currentStep === step) {
      return direction === "right" ? "slide-in-right" : "slide-in-left";
    } else {
      return direction === "right" ? "slide-out-right" : "slide-out-left";
    }
  }

  function renderStepContent() {
    switch (currentStep) {
      case 1:
        return (
          <LoginModalContent
            goToNextStep={goToNextStep}
            getAnimationClass={getAnimationClass}
            transitionDirection={transitionDirection}
            openModal={openModal}
          />
        );
      case 2:
        return (
          <RegisterModalContent
            goToPreviousStep={goToPreviousStep}
            getAnimationClass={getAnimationClass}
            transitionDirection={transitionDirection}
            openModal={openModal}
          />
        );
      default:
        return null;
    }
  }

  return (
    <div className="fixed flex z-50 top-0 left-0 w-full bg-black/40">
      {openModal && renderStepContent()}
    </div>
  );
}
