import React from "react";
import { useTranslation } from "react-i18next";

import Title from "./Title";
import CardVertical from "./CardVertical";

export default function AboutProofreading() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center w-full bg-[#EAEAEA] mt-4 relative top-[-16px]">
      <Title marginTop="" title={t("aboutTab2Title")} />
      <div className="grid md:grid-cols-3 grid-cols-1 justify-between xl:gap-[42px] sm:gap-4 xl:max-w-[1000px] max-w-[1400px] w-full xm:mt-[95px] sm:mt-[65px] mt-0 mx-auto 2xl:px-0 px-5">
        <CardVertical
          width="159px"
          height="163px"
          smWidth="130px"
          smHeight="133px"
          thumbnail="2-time.png"
          alternative="Time"
          title={t("aboutTab2Card1Title")}
          content={t("aboutTab2Card1Content")}
        />
        <CardVertical
          width="178px"
          height="144px"
          smWidth="140px"
          smHeight="112px"
          thumbnail="2-message.png"
          alternative="Message"
          title={t("aboutTab2Card2Title")}
          content={t("aboutTab2Card2Content")}
        />
        <CardVertical
          width="142px"
          height="162px"
          smWidth="110px"
          smHeight="125px"
          thumbnail="2-security.png"
          alternative="Security"
          title={t("aboutTab2Card3Title")}
          content={t("aboutTab2Card3Content")}
        />
      </div>
      <Title marginTop="150px" title={t("aboutTab2ExampleTitle")} />
      <div className="max-w-[1092px] w-full text-left flex flex-col gap-8 md:gap-16 mt-16 mx-auto mb-8 px-4 sm:px-8 xl:px-0">
        <div className="flex flex-col lg:flex-row justify-between gap-4">
          <div className="flex justify-center order-2 lg:order-none">
            <img src="/assets/usecase-1.png" alt="Translate use case" />
          </div>
          <div className="mt-4 max-w-[450px] order-1 lg:order-none mx-auto lg:mx-0">
            <h3 className="text-xl xl:text-3xl text-center lg:text-left text-[#333876] font-semibold font-sans">
              {t("aboutTab2Example1Title")}
            </h3>
            <p className="mt-4 text-base sm:text-xl font-sans mx-auto lg:mx-0">
              {t("aboutTab2Example1Content")}
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between gap-4">
          <div className="mt-4 max-w-[450px] order-1 lg:order-none mx-auto lg:mx-0">
            <h3 className="text-xl xl:text-3xl text-center lg:text-left text-[#333876] font-semibold font-sans">
              {t("aboutTab2Example2Title")}
            </h3>
            <p className="mt-4 text-base sm:text-xl font-sans mx-auto lg:mx-0">
              {t("aboutTab2Example2Content")}
            </p>
          </div>
          <div className="flex justify-center order-2 lg:order-none">
            <img src="/assets/usecase-2.png" alt="Translate use case" />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between gap-4">
          <div className="flex justify-center order-2 lg:order-none">
            <img src="/assets/usecase-3.png" alt="Translate use case" />
          </div>
          <div className="mt-4 max-w-[450px] order-1 lg:order-none mx-auto lg:mx-0">
            <h3 className="text-xl xl:text-3xl text-center lg:text-left text-[#333876] font-semibold font-sans">
              {t("aboutTab2Example3Title")}
            </h3>
            <p className="mt-4 text-base sm:text-xl font-sans mx-auto lg:mx-0">
              {t("aboutTab2Example3Content")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
