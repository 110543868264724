import React, { useState, useEffect } from "react";
import Notiflix from "notiflix";
import { useDispatch, useSelector } from "react-redux";
import {
  getDictionaryList,
  getDictionaryContentList,
  getCorporateUsers,
  getPlans,
  loginUser,
  getUserInfo,
} from "../redux/actions/languiseAction";

import Select from "../components/Select";
import DictionaryTable from "../components/DictionaryTable";
import UserDataTable from "../components/UserDataTable";
import TextField from "../components/TextField";

import ChangeLoginMethodModal from "../components/Modal/MyPage/ChangeLoginMethodModal";
import ChangeAccountPasswordModal from "../components/Modal/MyPage/ChangeAccountPasswordModal";
import ChangeAccountNameModal from "../components/Modal/MyPage/ChangeAccountNameModal";
import ChangeAccountAddressModal from "../components/Modal/MyPage/ChangeAccountAddressModal";
import AddEnterpriseModal from "../components/Modal/AddEnterpriseModal";

import AddModal from "../components/Modal/MyPage/AddModal";
import EditModal from "../components/Modal/MyPage/EditModal";
import DeleteDicModal from "../components/Modal/MyPage/DeleteDicModal";
import EditDicDetailModal from "../components/Modal/MyPage/EditDicDetailModal";
import DeleteDicDetailModal from "../components/Modal/MyPage/DeleteDicDetailModal";
import ShareDictionaryModal from "../components/Modal/MyPage/ShareDictionaryModal";
import AddUserModal from "../components/Modal/MyPage/AddUserModal";
import EditUserModal from "../components/Modal/MyPage/EditUserModal";
import ConfirmModal from "../components/Modal/ConfirmModal";
import UpdatePaymentMethodModal from "../components/Modal/MyPage/UpdatePaymentMethodModal";
import api from "../services/api";
import { useNavigate } from "react-router-dom";
import { PaymentIcon } from "react-svg-credit-card-payment-icons";
import UpdateEnterprisePlanModal from "../components/Modal/MyPage/UpdateEnterprisePlanModal";
import { getAccessToken, removeAllCookies } from "../services/utils";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function MyPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.LanguiseReducer.userInfo);
  const dictionaryList = useSelector(
    (state) => state.LanguiseReducer.dictionaryList
  );
  const dictionaryContentList = useSelector(
    (state) => state.LanguiseReducer.dictionaryContentList
  );
  const corporateUsers = useSelector(
    (state) => state.LanguiseReducer.corporateUsers
  );

  const dicInputRef = React.createRef();
  const [selectedTab, setSelectedTab] = useState(3);
  const [addDictionaryModalOpen, setAddDictionaryModalOpen] = useState(false);
  const [editDictionaryModalOpen, setEditDictionaryModalOpen] = useState(false);
  const [deleteDictionaryModalOpen, setDeleteDictionaryModalOpen] =
    useState(false);
  const [currentDictionaryId, setCurrentDictionaryId] = useState("");
  const [selectedDictionary, setSelectedDictionary] = useState("");

  const [newDicWord, setNewDicWord] = useState("");
  const [newDicResult, setNewDicResult] = useState("");

  const [pageSelected, setPageSelected] = useState(false);
  const [checkedDicList, setCheckedDicList] = useState([]);
  const [editDicDetailModalOpen, setEditDicDetailModalOpen] = useState(false);
  const [deleteDicDetailModalOpen, setDeleteDicDetailModalOpen] =
    useState(false);
  const [shareDictionaryModalOpen, setShareDictionaryModalOpen] =
    useState(false);

  const [selectedCorporateUsers, setSelectedCorporateUser] = useState([]);
  const [addUserModalOpen, setAddUserModalOpen] = useState(0);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [isDeleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [updatePlanModelOpen, setUpdatePlanModalOpen] = useState(false);

  const isAuthenticated = getAccessToken();
  const [changeLoginMethodModalOpen, setChangeLoginMethodModalOpen] =
    useState(false);
  const [changeAccountPasswordModalOpen, setChangeAccountPasswordModalOpen] =
    useState(false);
  const [changeAccountNameModalOpen, setChangeAccountNameModalOpen] =
    useState(false);
  const [changeAccountAddressModalOpen, setChangeAccountAddressModalOpen] =
    useState(false);
  const [changePaymentMethodModalOpen, setChangePaymentMethodModalOpen] =
    useState(false);
  const [confirmAnnualOpen, setConfirmAnnualOpen] = useState(false);
  const [removeConfirmModal, setRemoveConfirmModal] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState("");

  const [notifyOpen, setNotifyOpen] = useState(false);
  const [notifyText, setNotifyText] = useState("");

  const [isEnterpriseTrial, setIsEnterpriseTrail] = useState(userInfo.enterprise_trial);

  const toggleChangeLoginMethodModal = () => {
    setChangeLoginMethodModalOpen(!changeLoginMethodModalOpen);
    document.body.style.overflowY = !changeLoginMethodModalOpen
      ? "hidden"
      : "auto";
  };

  const toggleChangeAccountPasswordModal = () => {
    setChangeAccountPasswordModalOpen(!changeAccountPasswordModalOpen);
    document.body.style.overflowY = !changeAccountNameModalOpen
      ? "hidden"
      : "auto";
  };

  const toggleChangeAccountNameModal = () => {
    setChangeAccountNameModalOpen(!changeAccountNameModalOpen);
    document.body.style.overflowY = !changeAccountNameModalOpen
      ? "hidden"
      : "auto";
  };

  const toggleChangeAccountAddressModal = () => {
    setChangeAccountAddressModalOpen(!changeAccountAddressModalOpen);
    document.body.style.overflowY = !changeAccountAddressModalOpen
      ? "hidden"
      : "auto";
  };

  const toggleChangePaymentMethodModal = () => {
    setChangePaymentMethodModalOpen(!changePaymentMethodModalOpen);
    document.body.style.overflowY = !changePaymentMethodModalOpen
      ? "hidden"
      : "auto";
  };
  //---------------------

  const handleSelectDictionary = (item, add) => {
    setCheckedDicList((prevSelectedList) => {
      const isItemInList = prevSelectedList.includes(item);
      if (add && !isItemInList) {
        return [...prevSelectedList, item];
      } else if (!add && isItemInList) {
        return prevSelectedList.filter((i) => i !== item);
      }
      return prevSelectedList;
    });
  };

  const handleEditDicDetailModalClose = () => {
    setEditDicDetailModalOpen(false);
    setCheckedDicList([]);
    document.body.style.overflowY = "auto";
  };

  const handleDeleteDicDetailModalClose = () => {
    setDeleteDicDetailModalOpen(false);
    document.body.style.overflowY = "auto";
  };

  const handleSelectCorporateUser = (items) => {
    setSelectedCorporateUser(items);
  };

  const handleDeleteCorporateUser = () => {
    if (selectedCorporateUsers.indexOf(true) === -1) {
      setNotifyText(t("myPageMessage1"));
      setNotifyOpen(true);
      return;
    }
    if (
      selectedCorporateUsers.filter(
        (value, i) => value && corporateUsers[i].admin
      )[0]
    ) {
      setNotifyText(
        <p>
          {t("myPageMessage2")}
          <br />
          {t("myPageMessage3")}
        </p>
      );
      setNotifyOpen(true);
      return;
    }
    setDeleteUserModalOpen(true);
  };
  const deleteCorporateUser = () => {
    if (selectedCorporateUsers.indexOf(true) === -1) {
      return;
    }
    const temp = [];
    for (let i = 0; i < selectedCorporateUsers.length; i++) {
      if (selectedCorporateUsers[i]) {
        const corporateUser = corporateUsers[i].id;
        temp.push(corporateUser);
      }
    }
    const formData = new FormData();
    formData.append("id", [...temp]);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    api
      .delete("/corporate/users/", config)
      .then((res) => {
        setSelectedCorporateUser([]);
        dispatch(getCorporateUsers());
        setDeleteUserModalOpen(false);
      })
      .catch((err) => {
        Notiflix.Notify.failure(t("operationFailure"));
        console.log(err);
      });
  };

  const handlePageSelected = () => {
    setPageSelected(!pageSelected);
  };

  const newDictionaryContent = (e) => {
    e.preventDefault();

    if (newDicWord === "") {
      setNotifyText(t("myPageMessage5"));
      setNotifyOpen(true);
      return;
    }
    if (newDicResult === "") {
      setNotifyText(t("myPageMessage6"));
      setNotifyOpen(true);
      return;
    }

    const formData = new FormData();
    formData.append("dictionary_id", Number(currentDictionaryId));
    formData.append("reading", newDicWord);
    formData.append("alternative_reading", newDicResult);

    api
      .post("/dictionary/detail/", formData)
      .then((res) => {
        dispatch(getDictionaryContentList(formData));
        setNewDicWord("");
        setNewDicResult("");
      })
      .catch((err) => {
        Notiflix.Notify.failure(t("operationFailure"));
        console.log(err);
      });
  };

  const editDictionaryDetail = (e) => {
    if (!currentDictionaryId || currentDictionaryId === "") {
      setNotifyText(t("myPageMessage7"));
      setNotifyOpen(true);
      return;
    }

    if (checkedDicList.length > 1) {
      setNotifyText(t("myPageMessage8"));
      setNotifyOpen(true);
      return;
    }

    setEditDicDetailModalOpen(true);
    document.body.style.overflowY = "hidden";
  };

  const deleteDictionaryDetail = (e) => {
    if (checkedDicList.length === 0) {
      setNotifyText(t("myPageMessage9"));
      setNotifyOpen(true);
      return;
    }
    const dicDetailId = [];
    for (let i = 0; i < checkedDicList.length; i++) {
      dicDetailId.push(checkedDicList[i].id);
    }

    setDeleteDicDetailModalOpen(true);
    if (!deleteDicDetailModalOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }

    return dicDetailId;
  };

  const openShareModal = () => {
    if (!currentDictionaryId) {
      setShareDictionaryModalOpen(false);
      setNotifyText(t("myPageMessage10"));
      setNotifyOpen(true);
      return;
    }
    setShareDictionaryModalOpen(true);
  };
  const addModalOpen = () => {
    setAddDictionaryModalOpen(!addDictionaryModalOpen);

    if (!addDictionaryModalOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  };

  const editModalOpen = () => {
    if (!currentDictionaryId) {
      setNotifyText(t("myPageMessage11"));
      setNotifyOpen(true);
      return;
    }
    setEditDictionaryModalOpen(!editDictionaryModalOpen);

    if (!editDictionaryModalOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  };

  const deleteModalOpen = () => {
    if (!currentDictionaryId) {
      setNotifyText(t("myPageMessage11"));
      setNotifyOpen(true);
      return;
    }
    setDeleteDictionaryModalOpen(!deleteDictionaryModalOpen);

    if (!deleteDictionaryModalOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  };

  const handleUploadDictionary = () => {
    if (currentDictionaryId === "") {
      setNotifyText(t("myPageMessage8"));
      setNotifyOpen(true);
      return;
    }
    const inputElement = dicInputRef.current;

    if (inputElement) {
      inputElement.click();
    }
  };

  const handleDocChange = async (event) => {
    const file = event.target.files.item(0);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("dictionary_id", currentDictionaryId);
    api
      .post("/dictionary/upload/", formData)
      .then((res) => {
        const formData = new FormData();
        formData.append("dictionary_id", Number(currentDictionaryId));
        dispatch(getDictionaryContentList(formData));
        event.target.value = null;
      })
      .catch((err) => {
        Notiflix.Notify.failure(t("operationFailure"));
        console.log(err);
      });
  };

  const downloadDictionary = () => {
    api
      .get("/dictionary/download/", {
        params: { dictionary_id: currentDictionaryId },
      })
      .then((res) => {
        const href = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "dictionary.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(href);
      })
      .catch((err) => {
        Notiflix.Notify.failure(t("operationFailure"));
        console.log(err);
      });
  };

  const deleteAccountData = () => {
    if (deleteCategory === "corporate") {
      api
        .delete("/corporate/")
        .then((res) => {
          window.location.href = "/";
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (deleteCategory === "account") {
      api
        .delete("/user/")
        .then((res) => {
          removeAllCookies();
          dispatch(loginUser(false));
          window.location.href = "/";
        })
        .catch((err) => {
          if (err.response.status === 406) setNotifyText(t("myPageMessage12"));
          setNotifyOpen(true);
          console.log(err);
        });
    }
  };

  const handleChangeAnnual = () => {
    api
      .put("/user/plan/")
      .then((res) => {
        dispatch(getUserInfo());
        setConfirmAnnualOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setIsEnterpriseTrail(userInfo.enterprise_user ? !!userInfo.enterprise_trial : false)
  }, [userInfo])

  useEffect(() => {
    const formData = new FormData();
    formData.append("dictionary_id", Number(currentDictionaryId));

    if (isAuthenticated) {
      dispatch(getDictionaryList());
      dispatch(getDictionaryContentList(formData));
      dispatch(getCorporateUsers());
      dispatch(getPlans());
    }
  }, [dispatch, isAuthenticated, currentDictionaryId]);

 useEffect(() => {
  dispatch(getUserInfo());
 }, [])

  return (
    <div className="flex flex-col w-full flex-grow">
      <Helmet>
        <title>Languise - MyPage</title>
      </Helmet>
      {addDictionaryModalOpen && <AddModal openModal={addModalOpen} />}
      {editDictionaryModalOpen && (
        <EditModal
          openModal={editModalOpen}
          currentDictionaryId={currentDictionaryId}
          currentDictionaryName={selectedDictionary}
          changeCurrentDictionary={setSelectedDictionary}
        />
      )}
      {deleteDictionaryModalOpen && (
        <DeleteDicModal
          currentDictionaryId={currentDictionaryId}
          deleteModalOpen={deleteModalOpen}
          deleteDictionaryModalOpen={deleteDictionaryModalOpen}
        />
      )}
      <EditDicDetailModal
        openModal={editDicDetailModalOpen}
        onClose={handleEditDicDetailModalClose}
        currentDictionaryData={checkedDicList[0]}
        dictionaryId={currentDictionaryId}
      />
      <DeleteDicDetailModal
        openModal={deleteDicDetailModalOpen}
        onClose={handleDeleteDicDetailModalClose}
        deleteDicDetailId={deleteDictionaryDetail}
        dictionaryId={currentDictionaryId}
      />
      <ShareDictionaryModal
        openModal={shareDictionaryModalOpen}
        onClose={() => setShareDictionaryModalOpen(false)}
        dictionaryId={currentDictionaryId}
      />
      <AddUserModal
        addUserModalOpen={addUserModalOpen}
        onClose={() => setAddUserModalOpen(0)}
      />
      {isEnterpriseTrial ? (
        <AddEnterpriseModal
          openModal={updatePlanModelOpen}
          onClose={() => setUpdatePlanModalOpen(false)}
        />
      ) : (
        <UpdateEnterprisePlanModal
          modalOpen={updatePlanModelOpen}
          onClose={() => setUpdatePlanModalOpen(false)}
        />
      )}
      <EditUserModal
        editUserModalOpen={editUserModalOpen}
        onClose={setEditUserModalOpen}
        selectedList={selectedCorporateUsers}
      />
      <ConfirmModal
        isOpen={isDeleteUserModalOpen}
        text={t("myPageText1")}
        buttonText={t("myPageText2")}
        onAgree={deleteCorporateUser}
        onCancel={setDeleteUserModalOpen}
      />
      <ConfirmModal
        isOpen={removeConfirmModal}
        text={
          <div>
            {deleteCategory === "corporate" ? (
              <div>
                <p className="sm:text-[20px] text-[18px]">{t("myPageText3")}</p>
                <p className="sm:px-20 px-5 sm:text-[16px] text-[14px] text-start pt-4">
                  {t("myPageText4")}
                </p>
              </div>
            ) : (
              <div>
                <p className="sm:text-[20px] text-[18px]">{t("myPageText5")}</p>
                <p className="sm:px-20 px-5 sm:text-[16px] text-[14px] text-start pt-4">
                  {t("myPageText6")}
                </p>
              </div>
            )}
          </div>
        }
        buttonText={
          deleteCategory === "corporate" ? t("myPageText7") : t("myPageText2")
        }
        onAgree={deleteAccountData}
        onCancel={() => setRemoveConfirmModal(false)}
      />
      <ConfirmModal
        isOpen={notifyOpen}
        text={notifyText}
        buttonText={t("close")}
        onAgree={() => setNotifyOpen(false)}
        onCancel={() => setNotifyOpen(false)}
      />

      <div className="w-full md:h-[104px] h-[80px] bg-[#4B5090]">
        <div className="sm:flex flex-row md:max-w-[1130px] max-w-[95%] w-full mx-auto justify-between sm:mt-0 mt-[-16px]">
          <div className="relative 2xl:right-[42px] right-0 flex gap-2 md:mt-[22px] mt-[38px] sm:w-[50%] w-full">
            <div
              onClick={() => setSelectedTab(3)}
              className={`max-w-[230px] w-full ${
                selectedTab === 3
                  ? "sm:h-[82px] h-[60px] !rounded-b-none bg-white"
                  : "sm:h-[73px] h-[55px] bg-[#EAEAEA]"
              } sm:rounded-[20px] rounded-[10px] flex justify-center items-center text-[#333876] sm:text-[17px] text-[13px] font-sans font-[600] cursor-pointer`}
            >
              {t("myPageText8")}
            </div>
            <div
              onClick={() => setSelectedTab(1)}
              className={`max-w-[230px] w-full ${
                selectedTab === 1
                  ? "sm:h-[82px] h-[60px] !rounded-b-none bg-white"
                  : "sm:h-[73px] h-[55px] bg-[#EAEAEA]"
              } sm:rounded-[20px] rounded-[10px] flex justify-center items-center text-[#333876] sm:text-[19px] text-[14px] font-sans font-[600] cursor-pointer`}
            >
              {t("myPageText9")}
            </div>
            <div
              onClick={() => setSelectedTab(2)}
              className={`max-w-[230px] w-full ${
                selectedTab === 2
                  ? "sm:h-[82px] h-[60px] !rounded-b-none bg-white"
                  : "sm:h-[73px] h-[55px] bg-[#EAEAEA]"
              } sm:rounded-[20px] rounded-[10px] ${
                userInfo.enterprise_admin ? "flex" : "hidden"
              } justify-center items-center text-[#333876] sm:text-[17px] text-[13px] font-sans font-[600] cursor-pointer`}
            >
              Enterprise
            </div>
          </div>
        </div>
      </div>
      {selectedTab === 1 && (
        <div className="w-full sm:h-[320px] h-[387px]">
          <div className="flex flex-col 2xl:max-w-[1130px] max-w-[1400px] w-full mx-auto h-full 2xl:px-0 px-5">
            <div className="flex gap-[29px] justify-between items-center sm:mt-[51px] mt-5">
              <p className="text-[#4B5090] sm:text-[20px] text-[24px] font-sans">
                {t("myPageText9")}
              </p>
              <div className="flex flex-1 justify-end gap-[15px] relative">
                <Select
                  dictionaryList={dictionaryList}
                  selectedDictionary={selectedDictionary}
                  setSelectedDictionary={setSelectedDictionary}
                  setCurrentDictionaryId={setCurrentDictionaryId}
                  editable={true}
                  className="sm:flex hidden"
                />
                <button
                  onClick={addModalOpen}
                  className="flex justify-center items-center sm:w-[60px] w-[39px] sm:h-[60px] h-[39px] sm:rounded-[15px] rounded-[10px] border border-[#4B5090]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 27 27"
                    className="sm:w-7 w-5"
                  >
                    <g transform="translate(-1246.5 -2912.5)">
                      <line
                        y2="22"
                        transform="translate(1260.5 2915)"
                        fill="none"
                        stroke="#4b5090"
                        strokeLinecap="round"
                        strokeWidth="5"
                      ></line>
                      <line
                        x2="22"
                        transform="translate(1249 2926)"
                        fill="none"
                        stroke="#4b5090"
                        strokeLinecap="round"
                        strokeWidth="5"
                      ></line>
                    </g>
                  </svg>
                </button>
                <button
                  onClick={editModalOpen}
                  className="flex justify-center items-center sm:w-[60px] w-[39px] sm:h-[60px] h-[39px] sm:rounded-[15px] rounded-[10px] border border-[#4B5090]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 36.156 36.156"
                    className="sm:w-8 w-5"
                  >
                    <g transform="translate(-971.405 3130.456) rotate(-135)">
                      <g transform="translate(1496 2880)" fill="none">
                        <path d="M5.113,0l5.113,8.948H0Z" stroke="none"></path>
                        <path
                          d="M 5.113253116607666 4.03113842010498 L 3.446364879608154 6.948196887969971 L 6.78014087677002 6.948196887969971 L 5.113253116607666 4.03113842010498 M 5.113253116607666 -2.86102294921875e-06 L 10.22650337219238 8.948196411132812 L 2.86102294921875e-06 8.948196411132812 L 5.113253116607666 -2.86102294921875e-06 Z"
                          stroke="none"
                          fill="#4b5090"
                        ></path>
                      </g>
                      <path
                        d="M10.227,26.205H8.767V3.2H7.305V26.2H0V0H10.227V26.2Z"
                        transform="translate(1496 2888.948)"
                        fill="#4b5090"
                      ></path>
                      <path
                        d="M0,0H10.227a0,0,0,0,1,0,0V1.474a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V0A0,0,0,0,1,0,0Z"
                        transform="translate(1496 2916.432)"
                        fill="#4b5090"
                      ></path>
                    </g>
                  </svg>
                </button>
                <button
                  onClick={deleteModalOpen}
                  className="flex justify-center items-center sm:w-[60px] w-[39px] sm:h-[60px] h-[39px] sm:rounded-[15px] rounded-[10px] border border-[#4B5090]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 22.897 31.649"
                    className="sm:w-6 w-5"
                  >
                    <g transform="translate(-1454.49 -3077)">
                      <path
                        d="M1.393,0H20.48a1.393,1.393,0,0,1,1.393,1.393L18.962,25.076a1.393,1.393,0,0,1-1.393,1.393H4.294A1.393,1.393,0,0,1,2.9,25.076L0,1.393A1.393,1.393,0,0,1,1.393,0Z"
                        transform="translate(1455 3082.179)"
                        fill="#4b5090"
                      />
                      <line
                        x2="1.393"
                        y2="11.145"
                        transform="translate(1460.258 3089.85)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        y2="11"
                        transform="translate(1465.999 3089.833)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x1="1.393"
                        y2="11.145"
                        transform="translate(1470.223 3089.85)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x2="20.897"
                        transform="translate(1455.49 3080.09)"
                        fill="none"
                        stroke="#4b5090"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <line
                        x2="5.572"
                        transform="translate(1463.152 3078)"
                        fill="none"
                        stroke="#4b5090"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                    </g>
                  </svg>
                </button>
              </div>
            </div>

            <div className="mt-[15px]">
              <Select
                dictionaryList={dictionaryList}
                selectedDictionary={selectedDictionary}
                setSelectedDictionary={setSelectedDictionary}
                setCurrentDictionaryId={setCurrentDictionaryId}
                className="sm:hidden flex"
              />
            </div>

            <div className="flex flex-col gap-[10px] w-full mx-auto sm:mt-[31px] mt-4">
              <div className="sm:flex flex-row gap-6 items-center justify-between">
                <p className="text-[#505050] sm:text-[16px] text-[14px] text-start font-sans">
                  {t("myPageText10")}
                </p>
                <TextField
                  value={newDicWord}
                  onChange={(e) => setNewDicWord(e.target.value)}
                  className="flex flex-1 sm:mt-0 mt-2"
                  placeholder="登録したいワード"
                />
              </div>
              <div className="sm:flex flex-row gap-6 items-center justify-between">
                <p className="text-[#505050] sm:text-[16px] text-[14px] text-start font-sans">
                  {t("myPageText11")}
                </p>
                <TextField
                  value={newDicResult}
                  onChange={(e) => setNewDicResult(e.target.value)}
                  className="flex flex-1 sm:mt-0 mt-2"
                  placeholder={t("myPageText12")}
                />
              </div>
              <div className="flex sm:justify-end justify-end sm:mt-0 mt-[10px]">
                <button
                  onClick={newDictionaryContent}
                  className="bg-[#F0F1F8] sm:max-w-[105px] max-w-full w-full h-10 font-sans border border-[#4B5090] text-[#4B5090] text-[16px] rounded-[10px] hover:bg-[#e1e3f5] active:bg-[#e2e5ff]"
                >
                  {t("myPageText13")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {selectedTab === 2 && (
        <div className="w-full md:h-[144px] h-[200px]">
          <div className="w-full h-full mx-auto flex sm:flex-row flex-col items-center justify-center sm:gap-8 gap-4">
            <button
              onClick={() => setAddUserModalOpen(1)}
              className="border-[2px] border-[#4B5090] rounded-[20px] max-w-[286px] w-full sm:h-[60px] h-10 flex items-center sm:justify-center justify-between text-[#333876] xl:text-[20px] sm:text-[18px] text-[18px] xl:leading-[36px] sm:leading-[25px] leading-[23px] font-semibold font-sans xl:gap-10 sm:gap-5 gap-2 hover:bg-[#e8eafd] sm:mx-0 mx-auto sm:px-0 px-9"
            >
              {t("myPageText14")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#4B5090"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
            </button>
            {!userInfo.enterprise_agent && (
              <>
                <button
                  onClick={() => setAddUserModalOpen(2)}
                  className="border-[2px] border-[#4B5090] rounded-[20px] max-w-[286px] w-full sm:h-[60px] h-10 flex items-center sm:justify-center justify-between text-[#333876] xl:text-[20px] sm:text-[18px] text-[18px] xl:leading-[36px] sm:leading-[25px] leading-[23px] font-semibold font-sans xl:gap-10 sm:gap-5 gap-2 hover:bg-[#e8eafd] sm:mx-0 mx-auto sm:px-0 px-9"
                >
                  {t("myPageText15")}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#4B5090"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21.5 2v6h-6M2.5 22v-6h6M2 11.5a10 10 0 0 1 18.8-4.3M22 12.5a10 10 0 0 1-18.8 4.2" />
                  </svg>
                </button>
                <button
                  onClick={() => setUpdatePlanModalOpen(true)}
                  className="border-[2px] border-[#4B5090] rounded-[20px] max-w-[286px] w-full sm:h-[60px] h-10 flex items-center sm:justify-center justify-between text-[#333876] xl:text-[20px] sm:text-[18px] text-[18px] xl:leading-[36px] sm:leading-[25px] leading-[23px] font-semibold font-sans xl:gap-10 sm:gap-5 gap-2 hover:bg-[#e8eafd] sm:mx-0 mx-auto sm:px-0 px-9"
                >
                  {t("myPageText16")}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#4B5090"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21.5 2v6h-6M2.5 22v-6h6M2 11.5a10 10 0 0 1 18.8-4.3M22 12.5a10 10 0 0 1-18.8 4.2" />
                  </svg>
                </button>
              </>
            )}
          </div>
        </div>
      )}
      {selectedTab === 1 && (
        <div className="w-full bg-[#4B5090] flex-grow">
          <div className="2xl:max-w-[1096px] max-w-[1400px] w-full mx-auto mt-8 flex justify-between 2xl:px-0 px-5">
            <div className="flex gap-[15px]">
              <button
                onClick={editDictionaryDetail}
                className="flex w-[34px] h-[34px] items-center justify-center border-[2px] border-white rounded-[3px]"
              >
                <img
                  className="w-[20px] h-[20px]"
                  src="/assets/white-pencil.png"
                  alt="Pencil"
                />
              </button>
              <button
                onClick={deleteDictionaryDetail}
                className="flex w-[34px] h-[34px] items-center justify-center border-[2px] border-white rounded-[3px]"
              >
                <img
                  className="w-[15.5px] h-[19px]"
                  src="/assets/white-trash.png"
                  alt="Trash"
                />
              </button>
            </div>
            <div className="flex gap-[15px]">
              <button
                onClick={handleUploadDictionary}
                className="flex w-[34px] h-[34px] items-center justify-center border-[2px] border-white rounded-[3px]"
              >
                <img
                  className="w-[19px] h-[20.2px]"
                  src="/assets/en-upload.png"
                  alt="Upload"
                />
                <input
                  type="file"
                  onChange={(event) => {
                    handleDocChange(event);
                  }}
                  style={{ display: "none" }}
                  accept=".csv"
                  ref={dicInputRef}
                />
              </button>
              <button
                onClick={downloadDictionary}
                className="flex w-[34px] h-[34px] items-center justify-center border-[2px] border-white rounded-[3px]"
              >
                <img
                  className="w-[14px] h-[20.2px]"
                  src="/assets/en-download.png"
                  alt="Download"
                />
              </button>
              {userInfo.enterprise_user && (
                <button
                  onClick={openShareModal}
                  className="flex w-[34px] h-[34px] items-center justify-center border-[2px] border-white rounded-[3px]"
                >
                  <img
                    className="w-[21px] h-[23px]"
                    src="/assets/en-share.png"
                    alt="Share"
                  />
                </button>
              )}
            </div>
          </div>
          <div className="2xl:max-w-[1130px] xl:max-w-[1400px] w-full mx-auto mt-[18px] mb-[80px] 2xl:px-0 px-5">
            <DictionaryTable
              data={dictionaryContentList}
              onHandleSelect={handlePageSelected}
              pageSelected={pageSelected}
              onCheckedDicList={handleSelectDictionary}
            />
          </div>
        </div>
      )}
      {selectedTab === 2 && (
        <div className="w-full bg-[#4B5090] flex-grow">
          <div className="max-w-[1096px] w-full mx-auto mt-8 flex justify-between sm:px-0 px-5">
            <div className="flex gap-[15px]">
              <button
                className="flex w-[34px] h-[34px] items-center justify-center border-[2px] border-white rounded-[3px]"
                onClick={handleDeleteCorporateUser}
              >
                <img
                  className="w-[15.5px] h-[19px]"
                  src="/assets/white-trash.png"
                  alt="Trash"
                />
              </button>
            </div>
          </div>
          <div className="max-w-[1130px] w-full mx-auto mt-[18px] xl:mb-[80px] sm:mb-[50px] mb-9 xl:px-0 px-5">
            <UserDataTable
              users={corporateUsers}
              onCheckedList={handleSelectCorporateUser}
            />
            {!userInfo.enterprise_agent && (
              <div className="flex w-full justify-end">
                <button
                  onClick={() => {
                    setDeleteCategory("corporate");
                    setRemoveConfirmModal(true);
                  }}
                  className="border border-[#CCCCCC] hover:border-[#fd9797] text-[#CCCCCC] hover:text-[#fd9797] px-3 py-1 rounded-lg"
                >
                  {t("myPageText17")}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {selectedTab === 3 && (
        <div className="w-full text-[#555555] pt-20">
          <ChangeLoginMethodModal
            openModal={changeLoginMethodModalOpen}
            closeModal={toggleChangeLoginMethodModal}
          />
          <ChangeAccountNameModal
            openModal={changeAccountNameModalOpen}
            closeModal={toggleChangeAccountNameModal}
          />
          <ChangeAccountPasswordModal
            openModal={changeAccountPasswordModalOpen}
            closeModal={toggleChangeAccountPasswordModal}
          />
          <ChangeAccountAddressModal
            openModal={changeAccountAddressModalOpen}
            closeModal={toggleChangeAccountAddressModal}
          />
          <UpdatePaymentMethodModal
            openModal={changePaymentMethodModalOpen}
            closeModal={toggleChangePaymentMethodModal}
          />
          <ConfirmModal
            isOpen={confirmAnnualOpen}
            text={
              <p>
                {t("myPageText18")}
                <br />
                {t("myPageText19")}
              </p>
            }
            buttonText={t("myPageText20")}
            onAgree={handleChangeAnnual}
            onCancel={() => setConfirmAnnualOpen(false)}
          />
          <div className="flex flex-row sm:w-[540px] mx-auto py-4 pl-4 border-t items-center">
            <div className="w-[180px] text-start">
              <p>{t("accountName")}</p>
              <p className="text-[10px] text-[#4B5090]">{t("myPageText21")}</p>
            </div>
            <div className="w-[240px] text-start">{userInfo.name}</div>
            <div className="w-[120px] items-center justify-center">
              <button
                onClick={toggleChangeAccountNameModal}
                className="border border-[#707070] hover:bg-[#EEEEEE] h-[33px] rounded-[10px] px-4 text-[#4B5090]"
              >
                {t("update")}
              </button>
            </div>
          </div>
          <div className="flex flex-row sm:w-[540px] mx-auto py-4 pl-4 border-t items-center">
            <div className="w-[180px] text-start">{t("myPageText23")}</div>
            <div className="w-[240px] text-start truncate">
              {userInfo.country} {userInfo.address}
            </div>
            <div className="w-[120px] items-center justify-center">
              <button
                onClick={toggleChangeAccountAddressModal}
                className="border border-[#707070] hover:bg-[#EEEEEE] h-[33px] rounded-[10px] px-4 text-[#4B5090]"
              >
                {t("update")}
              </button>
            </div>
          </div>
          <div className="flex flex-row sm:w-[540px] mx-auto py-4 pl-4 border-t items-center">
            <div className="w-[180px] text-start">{t("myPageText31")}</div>
            <div className="w-[240px] text-start">{t("email")}</div>
            <div className="w-[120px] items-center justify-center">
              <button
                onClick={toggleChangeLoginMethodModal}
                className="border border-[#707070] hover:bg-[#EEEEEE] h-[33px] rounded-[10px] px-4 text-[#4B5090]"
              >
                {t("update")}
              </button>
            </div>
          </div>
          <div className="flex flex-row sm:w-[540px] mx-auto py-4 pl-4 border-t items-center">
            <div className="w-[180px] text-start">{t("password")}</div>
            <div className="w-[240px] text-start">********</div>
            <div className="w-[120px] items-center justify-center">
              <button
                onClick={toggleChangeAccountPasswordModal}
                className="border border-[#707070] hover:bg-[#EEEEEE] h-[33px] rounded-[10px] px-4 text-[#4B5090] whitespace-nowrap"
              >
                {t("update")}
              </button>
            </div>
          </div>
          <div className="flex flex-row sm:w-[540px] mx-auto py-4 pl-4 border-t items-center">
            <div className="w-[180px] text-start">{t("myPageText25")}</div>
            <div className="w-[250px] text-start">
              {userInfo.enterprise_user
                ? userInfo.enterprise_trial
                  ? "Enterprise TEST 50"
                  : `Enterprise ${100 * (userInfo.plan + 1)}`
                : `${userInfo.plan}${t("plan")}`}
            </div>
            <div className="w-[120px] items-center justify-center">
              {!userInfo.enterprise_user && (
                <button
                  onClick={() => navigate("/priceplan")}
                  className="border border-[#707070] hover:bg-[#EEEEEE] h-[33px] rounded-[10px] px-4 text-[#4B5090]"
                >
                  {t("update")}
                </button>
              )}
            </div>
          </div>
          {!!userInfo.enterprise_id && (
            <div className="flex flex-row sm:w-[540px] mx-auto py-4 pl-4 border-t items-center">
              <div className="w-[180px] text-start">Enterprise ID</div>
              <div className="w-[240px] text-start">
                {userInfo.enterprise_id}
              </div>
              <div className="w-[120px] items-center justify-center"></div>
            </div>
          )}
          {userInfo.period_end !== 0 && userInfo.plan !== "Free" && (
            <div className="flex flex-row sm:w-[540px] mx-auto py-4 pl-4 border-t items-center">
              <div className="w-[180px] text-start">{t("myPageText26")}</div>
              <div className="w-[180px] text-start">
                {new Date(userInfo.period_end * 1000).toLocaleString("ja-JP", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  timeZone: "Asia/Tokyo",
                })}
              </div>
              {userInfo.period_interval === "month" && (
                <div className="w-[180px] items-center justify-center">
                  <button
                    onClick={() => setConfirmAnnualOpen(true)}
                    className="border border-[#707070] hover:bg-[#EEEEEE] h-[33px] rounded-[10px] px-4 text-[#4B5090]"
                  >
                    {t("myPageText27")}
                  </button>
                  <p className="text-[9px] text-[#4B5090]">
                    {t("myPageText28")}
                  </p>
                </div>
              )}
            </div>
          )}
          {userInfo.period_end !== 0 && (
            <div className="flex flex-row sm:w-[540px] mx-auto py-4 pl-4 border-t items-center">
              <div className="w-[180px] text-start">{t("myPageText29")}</div>
              <div className="flex w-[240px] text-start gap-6">
                <PaymentIcon type={userInfo.card_brand} />
                ****{userInfo.card_number}
              </div>
              <div className="w-[120px] items-center justify-center">
                <button
                  onClick={toggleChangePaymentMethodModal}
                  className="border border-[#707070] hover:bg-[#EEEEEE] h-[33px] rounded-[10px] px-4 text-[#4B5090]"
                >
                  {t("update")}
                </button>
              </div>
            </div>
          )}
          <div className="flex flex-row sm:w-[540px] mx-auto py-4 pl-4 border-t items-center justify-center">
            <button
              onClick={() => {
                setDeleteCategory("account");
                setRemoveConfirmModal(true);
              }}
              className="h-[33px] rounded-[10px] px-4 text-[##B1B1B1] hover:underline hover:text-[#ff6d6d]"
            >
              {t("myPageText30")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
