import React from "react";

export default function CardVertical({
  width,
  smWidth,
  height,
  smHeight,
  thumbnail,
  alternative,
  title,
  content,
}) {
  return (
    <div className="flex flex-col max-w-[400px] lg:gap-[54.5px] gap-[17.5px] mx-auto xm:mt-0 mt-10">
      <div className={`md:h-[165px] h-[${smHeight}]`}>
        <img
          className={`h-full mx-auto`}
          src={`/assets/${thumbnail}`}
          alt={alternative}
        />
      </div>
      <div className="flex flex-col gap-4">
        <h3 className="text-[#222222] xl:text-[20px] sm:text-[18px] text-[16px] font-sans text-center font-[600]">
          {title}
        </h3>
        <p className="text-[#222222] xl:text-[16px] sm:text-[15px] text-[14px] xl:leading-[32px] sm:leading-[28px] leading-[25px] font-sans text-start">
          {content}
        </p>
      </div>
    </div>
  );
}
