import React, { useState, useEffect, useRef } from "react";

export function CurrencySelect({ value="jpy", onChange, className }) {
  const currencyList = ["jpy", "usd", "eur"];
  const currencyFlag = {
    jpy: "japan-flag.png",
    usd: "usa-flag.png",
    eur: "eur-flag.png",
  };

  const [isOpen, setOpen] = useState(false);
  const selectRef = useRef();
  const handleSelect = (item) => {
    onChange(item);
    setOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && selectRef.current.contains(event.target)) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={className} ref={selectRef}>
      <div
        onClick={() => setOpen(!isOpen)}
        className="flex sm:w-[134px] w-[70px] sm:h-[50px] h-[27px] border border-[#4B5090] justify-between sm:px-[10px] px-2 items-center cursor-pointer"
      >
        <img
          className="sm:max-w-[48px] max-w-[25px] w-full sm:h-[28px] h-[15px]"
          src={`/assets/${currencyFlag[value]}`}
          alt="Flag"
        />
        <div className="text-[#11135C] sm:text-[20px] text-[10px] font-sans font-bold">
          {value.toUpperCase()}
        </div>
      </div>
      {isOpen && (
        <div className="border border-[#6E6E6E] bg-white">
          {currencyList.map((item) => (
            <div
              onClick={() => handleSelect(item)}
              key={item}
              className="flex border border-[#CCCCCC] hover:bg-[#F0F1F8] sm:w-[132px] w-[68px] sm:h-[50px] h-[27px] justify-between sm:px-[10px] px-2 py-2 items-center cursor-pointer"
            >
              <img
                className="sm:max-w-[48px] max-w-[25px] w-full sm:h-[28px] h-[15px]"
                src={`/assets/${currencyFlag[item]}`}
                alt="Flag"
              />
              <div className="text-[#11135C] sm:text-[20px] text-[10px] font-sans font-bold">
                {item.toUpperCase()}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
