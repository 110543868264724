import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TermsEn from "./TermsEn";
import TermsJp from "./TermsJp";

export default function Terms() {
  const uiLang = useSelector((state) => state.LanguiseReducer.uiLang);
  const [lang, setLang] = useState("jp");

  useEffect(() => {
    setLang(uiLang);
  }, [uiLang]);

  return lang === "jp" ? <TermsJp /> : <TermsEn />;
}
