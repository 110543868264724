import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { noTokenApi } from "../services/api";
import TextField from "../components/TextField";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";

export default function ResetPassword() {
  const { t } = useTranslation();
  const { token } = useParams();
  const [isVerify, setVerify] = useState(null);
  const [errorString, setErrorString] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (token !== "") {
      noTokenApi
        .get(`/user/verify/${token}`)
        .then((res) => {
          setVerify(true);
          setEmail(res.data.email);
        })
        .catch((err) => {
          setVerify(false);
          setErrorString(err.response.data);
        });
    }
  }, [token]);

  const changePassword = () => {
    noTokenApi
      .put("/user/resetpassword/", {
        email,
        password,
      })
      .then((rst) => {
        Notiflix.Notify.success(t("resetPasswordPageMessage1"));
      })
      .catch((error) => {
        Notiflix.Notify.failure(t("operationFailure"));
        console.log(error);
      });
  };

  return (
    <div className="flex flex-col flex-grow">
      <div className="flex flex-col xl:gap-[23px] sm:gap-4 gap-2 2xl:max-w-[1306px] max-w-[95%] w-full sm:mt-20 mt-12 mx-auto">
        {isVerify === null && (
          <p className="w-full sm:text-[45px] text-[22px]  text-[#333876] font-sans text-center mb-[200px]">
            {t("resetPasswordPageText1")}
          </p>
        )}
        {!isVerify ? (
          <p className="w-full sm:text-[45px] text-[22px]  text-[#333876] font-sans text-center mb-[200px]">
            <br />
            {errorString === "Signature has expired" ? (
              t("resetPasswordPageText2")
            ) : (
              <>
                {t("resetPasswordPageText3")}
                <br />
                {t("resetPasswordPageText4")}
              </>
            )}
          </p>
        ) : (
          <div className="flex w-full h-full m-auto mt-20">
            <div className="flex flex-col max-w-[830px] w-full min-h-[474px] mx-auto my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] rounded-lg">
              <p className="text-[#4B5090] font-sans text-[20px] text-center">
                {t("resetPasswordPageText5")}
              </p>
              <div className="flex flex-col max-w-[420px] w-full mx-auto gap-4 mt-[37px]">
                <div className="flex flex-col gap-1">
                  <p className="text-start font-sans text-[#505050] text-[12px]">
                    {t("email")}
                  </p>
                  <TextField type="text" value={email} disabled={true} />
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-start font-sans text-[#505050] text-[12px]">
                    {t("resetPasswordPageText6")}
                  </p>
                  <TextField
                    type={`${showPassword ? "text" : "password"}`}
                    value={password}
                    showClicked={showPassword}
                    onClick={() => setShowPassword(!showPassword)}
                    onChange={(e) => setPassword(e.target.value)}
                    show_password={true}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-start font-sans text-[#505050] text-[12px]">
                    {t("resetPasswordPageText7")}
                  </p>
                  <TextField
                    type="password"
                    value={verifyPassword}
                    onChange={(e) => setVerifyPassword(e.target.value)}
                  />
                </div>
                <div className="flex flex-col justify-center mt-[14px] gap-6">
                  <button
                    onClick={changePassword}
                    className="w-full bg-transparent h-[40px] border border-[#8B8B8B] text-[#8B8B8B] rounded-[10px] hover:bg-[#4B5090] hover:text-white"
                  >
                    {t("resetPasswordPageText8")}
                  </button>
                  <button
                    onClick={() => navigate("/")}
                    className="w-full bg-transparent h-[40px] border border-[#8B8B8B] text-[#8B8B8B] rounded-[10px] hover:bg-[#4B5090] hover:text-white"
                  >
                    {t("resetPasswordPageText9")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
