import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function UserDataTable({ users, onCheckedList }) {
  const { t } = useTranslation();
  const [checkedList, setCheckedList] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const handleCheckedAll = () => {
    setCheckedAll(!checkedAll);
    const temp = new Array(users.length).fill(!checkedAll);
    setCheckedList([...temp]);
    onCheckedList([...temp]);
  };
  const handleChecked = (index) => {
    return () => {
      const temp = [...checkedList];
      temp[index] = !temp[index];
      setCheckedList([...temp]);
      setCheckedAll(temp.indexOf(false) === -1);
      onCheckedList([...temp]);
    };
  };

  useEffect(() => {
    const temp = new Array(users.length).fill(false);
    setCheckedList([...temp]);
  }, [users]);

  return (
    <div className="flex flex-col">
      <div className="max-w-[1130px] w-full h-full mx-auto sm:flex flex-row items-start py-5 overflow-auto">
        <div className="w-full min-w-[400px] border md:rounded-[20px] rounded-[10px] overflow-hidden">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-[#E9E9F0] h-[60px]">
                <th className="w-12 border-r border-[#CCCCCC]">
                  <input
                    className="w-[16px] h-[16px]"
                    type="checkbox"
                    checked={checkedAll || false}
                    onChange={handleCheckedAll}
                  />
                </th>
                <th className="border-r text-[#6E6E6E] text-[16px] font-sans border-[#CCCCCC]">
                  {t("userName")}
                </th>
                <th className="border-r text-[#6E6E6E] text-[16px] font-sans border-[#CCCCCC]">
                  {t("email")}
                </th>
                <th className="text-[#6E6E6E] text-[16px] font-sans">
                {t("userCategory")}
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((item, index) => (
                <tr className="bg-white h-[46px]" key={item.id}>
                  <td className="max-w-[60px] border-r border-[#CCCCCC]">
                    <input
                      className="w-[16px] h-[16px]"
                      type="checkbox"
                      checked={checkedList[index] || false}
                      onChange={handleChecked(index)}
                    />
                  </td>
                  <td className="border-r text-[#6E6E6E] text-[16px] font-sans border-[#CCCCCC]">
                    {item.user.name}
                  </td>
                  <td className="border-r text-[#6E6E6E] text-[16px] font-sans border-[#CCCCCC]">
                    {item.user.email}
                  </td>
                  <td className="border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans">
                    {item.admin ? t("admin") : t("user")}
                  </td>
                </tr>
              ))}
              <tr className="bg-[#E9E9F0] h-[24px] md:rounded-br-[20px] md:rounded-bl-[20px] rounded-br-[10px] rounded-bl-[10px]">
                <td className="max-w-[60px] justify-center border-r border-[#CCCCCC]"></td>
                <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                <td className="border-r border-[#CCCCCC] text-[#6E6E6E] text-[16px] font-sans"></td>
                <td className="text-[#6E6E6E] text-[16px] font-sans"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
