import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import { countryList } from "../../Languages";
import { CustomSelect } from "../CustomSelect";
import Notiflix from "notiflix";

export default function AddEnterpriseModal({ openModal, onClose, refresh }) {
  const [email, setEmail] = useState("");
  const [count, setCount] = useState(0);
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState("");
  const [address, setStreet] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [planList, setPlanList] = useState([]);
  const [agentID, setAgentID] = useState("");
  const [errorMessage, setErrorMessage] = useState({});

  useEffect(() => {
    api
      .get("/user/enterpriseplan/")
      .then((res) => {
        setPlanList(
          res.data.map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.count,
            };
          })
        );
        setCount(res.data[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const validateForm = () => {
    const temp = {};
    if (!agentID) temp.agentID = "必要事項を入力して下さい";
    if (!email) temp.email = "必要事項を入力して下さい";
    if (!company) temp.company = "必要事項を入力して下さい";
    if (!country) temp.country = "必要事項を入力して下さい";
    if (!address) temp.address = "必要事項を入力して下さい";
    if (!postalCode) temp.postalCode = "必要事項を入力して下さい";
    return temp;
  };

  const handleSubmit = async (e) => {
    const validationErrors = validateForm();
    setErrorMessage({ ...validationErrors });
    if (Object.keys(validationErrors).length > 0) return;

    const rootUrl = window.location.origin;

    try {
      await api.post("/management/agent/", {
        company_name: company,
        country,
        address,
        postal_code: postalCode,
        plan: count,
        success_url: `${rootUrl}/corporate/success`,
        cancel_url: window.location.href,
        agent: 1,
        agent_id: agentID,
        email,
      });
      refresh();
      onClose();
    } catch (err) {
      if (err.response.data.message === "The email address does not exist") {
        Notiflix.Notify.failure("メールアドレスが存在しません。");
      } else if (
        err.response.data.message === "The user have already corporate account"
      ) {
        Notiflix.Notify.failure(
          "ユーザーはすでに法人アカウントを持っています。"
        );
      } else if (
        err.response.data.message === "The company have already exist"
      ) {
        Notiflix.Notify.failure("会社はすでに存在しています。");
      }
      console.log(err);
    }
  };

  return (
    <div
      className={`${
        openModal
          ? "flex !opacity-100 !translate-y-0"
          : "hidden !opacity-0 !translate-y-10"
      } fixed inset-0 bg-black/40 z-[9999] !transition-all !ease-in-out !duration-300 lg:px-0 px-5`}
    >
      <div className="relative flex flex-col max-w-[830px] w-full max-h-[98vh] overflow-auto mx-auto my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] rounded-lg">
        <div className="flex justify-end">
          <img
            onClick={onClose}
            className="w-[20px] h-[20px] mt-2 mr-4 cursor-pointer"
            src="/assets/cancel-modal.svg"
            alt="Cancel"
          />
        </div>
        <div className="text-center text-[30px] text-[#4B5090]">
          新規企業登録
        </div>
        <div className="flex flex-col max-w-[420px] w-full mx-auto sm:mt-[30px] mt-4 gap-4">
          <div className="flex flex-row text-[12px] sm:text-[16px] border-b sm:border-b-2 border-[#707070] justify-between">
            <p className="text-[#4B5090]">月間後利用可能回数</p>
            <CustomSelect value={count} onChange={setCount} items={planList} />
          </div>
        </div>
        <div className="flex flex-col max-w-[420px] w-full mx-auto gap-2 mt-4">
          <div className="flex flex-col items-center gap-1">
            <div className="flex gap-2 w-full text-start text-[#505050] sm:text-[16px] text-[13px] font-sans">
              Agent ID
              <span className="text-start text-red-600 text-sm">
                {errorMessage.agentID}
              </span>
            </div>
            <div className="sm:max-w-[450px] max-w-full w-full">
              <input
                type="text"
                value={agentID}
                onChange={(e) => setAgentID(e.target.value)}
                className={`${
                  company ? "bg-white" : "bg-transparent"
                } w-full h-[35px] sm:h-[40px] border border-[#8B8B8B] rounded-[10px] px-2 sm:px-5 outline-none`}
              />
            </div>
          </div>
          <div className="flex flex-col items-center gap-1">
            <div className="flex gap-2 w-full text-start text-[#505050] sm:text-[16px] text-[13px] font-sans">
              メールアドレス
              <span className="text-start text-red-600 text-sm">
                {errorMessage.email}
              </span>
            </div>
            <div className="sm:max-w-[450px] max-w-full w-full">
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`${
                  company ? "bg-white" : "bg-transparent"
                } w-full h-[35px] sm:h-[40px] border border-[#8B8B8B] rounded-[10px] px-2 sm:px-5 outline-none`}
              />
            </div>
          </div>
          <div className="flex flex-col items-center gap-1">
            <div className="flex gap-2 w-full text-start text-[#505050] sm:text-[16px] text-[13px] font-sans">
              会社名
              <span className="text-start text-red-600 text-sm">
                {errorMessage.company}
              </span>
            </div>
            <div className="sm:max-w-[450px] max-w-full w-full">
              <input
                type="text"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                className={`${
                  company ? "bg-white" : "bg-transparent"
                } w-full h-[35px] sm:h-[40px] border border-[#8B8B8B] rounded-[10px] px-2 sm:px-5 outline-none`}
              />
            </div>
          </div>
          <div className="flex flex-col items-center gap-1">
            <div className="flex gap-2 w-full text-start text-[#505050] sm:text-[16px] text-[13px] font-sans">
              国
              <span className="text-start text-red-600 text-sm">
                {errorMessage.country}
              </span>
            </div>
            <div className="sm:max-w-[450px] max-w-full w-full">
              <CustomSelect
                value={country}
                onChange={setCountry}
                items={countryList}
                className="h-[35px] sm:h-[40px]"
              />
            </div>
          </div>
          <div className="flex flex-col items-center gap-1">
            <div className="flex gap-2 w-full text-start text-[#505050] sm:text-[16px] text-[13px] font-sans">
              住所
              <span className="text-start text-red-600 text-sm">
                {errorMessage.address}
              </span>
            </div>
            <div className="sm:max-w-[450px] max-w-full w-full">
              <input
                type="text"
                value={address}
                onChange={(e) => setStreet(e.target.value)}
                placeholder="住所"
                className={`${
                  address ? "bg-white" : "bg-transparent"
                } w-full h-[35px] sm:h-[40px] border border-[#8B8B8B] rounded-[10px] px-2 sm:px-5 outline-none`}
              />
            </div>
          </div>
          <div className="flex flex-col items-center gap-1">
            <div className="flex gap-2 w-full text-start text-[#505050] sm:text-[16px] text-[13px] font-sans">
              郵便コード
              <span className="text-start text-red-600 text-sm">
                {errorMessage.postalCode}
              </span>
            </div>
            <div className="sm:max-w-[450px] max-w-full w-full">
              <input
                type="text"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                placeholder="郵便コード"
                className={`${
                  postalCode ? "bg-white" : "bg-transparent"
                } w-full h-[35px] sm:h-[40px] border border-[#8B8B8B] rounded-[10px] px-2 sm:px-5 outline-none`}
              />
            </div>
          </div>
        </div>
        <div className=" max-w-[420px] w-full mx-auto flex justify-end mt-6 mb-10">
          <button
            onClick={handleSubmit}
            className="w-full border border-[#8B8B8B] bg-[#5056ac] hover:bg-[#6267b2] h-[35px] sm:h-[40px] text-white hover:text-white rounded-[10px] sm:text-[16px] text-[14px]"
          >
            決定
          </button>
        </div>
      </div>
    </div>
  );
}
