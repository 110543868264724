import React, { useState } from "react";
import { PuffLoader } from "react-spinners";
import TextField from "../TextField";
import { noTokenApi } from "../../services/api";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { getA8ID } from "../../services/utils";

export default function RegisterModalContent({
  goToPreviousStep,
  getAnimationClass,
  transitionDirection,
  openModal,
}) {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [showClicked, setShowClicked] = useState(false);
  const [bgColor, setBgColor] = useState("");
  const [textColor, setTextColor] = useState("#8B8B8B");
  const [agree, setAgree] = useState(false);

  const [status, setStatus] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  const [errorText, setErrorText] = useState("");

  const handleRegister = async (e) => {
    e.preventDefault();
    const a8 = getA8ID();
    const validationErrors = validateForm();
    setErrorMessage({ ...validationErrors });
    if (Object.keys(validationErrors).length === 0) {
      setBgColor("#4B5090");
      setTextColor("white");
    } else {
      setBgColor("");
      setTextColor("#8B8B8B");
    }
    if (Object.keys(validationErrors).length > 0) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("a8", a8);
    noTokenApi
      .post("/user/register/", formData)
      .then((res) => {
        setErrorText("");
        setLoading(false);
        setStatus(true);
        window.scrollTo(0, 0);
        window.localStorage.removeItem("popup_date");
      })
      .catch((err) => {
        if (err.response.status === 400 && err.response.data.email) {
          setErrorText(t("registerMessage4"));
        }
        setLoading(false);
        setStatus(false);
      });
  };

  const validateForm = () => {
    const temp = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    if (!agree) temp.agree = t("registerMessage5");
    if (!name) temp.name = t("requireUsername");
    if (!emailRegex.test(email)) temp.email = t("requireEmail");
    if (!passwordRegex.test(password)) temp.password = t("passwordRule");
    if (!passwordRegex.test(verifyPassword))
      temp.verifyPassword = t("passwordRule");
    if (password !== verifyPassword)
      temp.verifyPassword = t("notMatchPassword");
    return temp;
  };

  return (
    <div className="flex bg-black/40 w-full h-screen z-[9999] fixed top-0 left-0 sm:px-0 px-5">
      <div className="flex flex-col max-w-[830px] w-full mx-auto my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] pb-10 rounded-[10px]">
        {isLoading && (
          <div className="flex fixed top-0 z-[9999] left-0 w-full items-center justify-center h-full">
            <PuffLoader
              css={css`
                display: block;
                margin: 0 auto;
                border-color: red;
              `}
              size={150}
              color={"#11135C"}
              loading={isLoading}
            />
          </div>
        )}
        <div className="flex justify-between">
          <div
            className="flex gap-[8.5px] items-center cursor-pointer"
            onClick={goToPreviousStep}
          >
            <img
              className="w-[16px] h-[11px]"
              src="/assets/previous.png"
              alt="Previous"
            />
            <p className="text-[#8B8B8B] text-[16px] font-sans">{t("back")}</p>
          </div>
          <img
            onClick={openModal}
            className="w-[20px] h-[20px] cursor-pointer"
            src="/assets/cancel-modal.svg"
            alt="Cancel"
          />
        </div>
        {!status && (
          <>
            <p className="text-[#4B5090] text-[20px] font-sans text-center font-[600]">
              {t("registerUser")}
            </p>
            <div className="flex flex-col max-w-[420px] w-full mx-auto gap-4 mt-[35px]">
              <div className="flex flex-col gap-1">
                <p className="text-[#505050] text-[12px] font-sans text-start">
                  {t("accountName")}
                </p>
                <TextField
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Languise"
                />
                <span className="text-start text-red-600 text-sm">
                  {errorMessage.name}
                </span>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-[#505050] text-[12px] font-sans text-start">
                  {t("email")}
                </p>
                <TextField
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="name@email.com"
                />
                <span className="text-start text-red-600 text-sm">
                  {errorMessage.email}
                </span>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-[#505050] text-[12px] font-sans text-start">
                  {t("password")}
                </p>
                <TextField
                  type={`${showClicked ? "text" : "password"}`}
                  value={password}
                  showClicked={showClicked}
                  onClick={() => setShowClicked(!showClicked)}
                  onChange={(event) => setPassword(event.target.value)}
                  show_password={true}
                  placeholder={t("password")}
                />
                <span className="text-start text-red-600 text-sm">
                  {errorMessage.password}
                </span>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-[#505050] text-[12px] font-sans text-start">
                  {t("confirmPassword")}
                </p>
                <TextField
                  type={`${showClicked ? "text" : "password"}`}
                  value={verifyPassword}
                  showClicked={showClicked}
                  onClick={() => setShowClicked(!showClicked)}
                  onChange={(event) => setVerifyPassword(event.target.value)}
                  show_password={true}
                  placeholder={t("confirmPassword")}
                />
                <span className="text-start text-red-600 text-sm">
                  {errorMessage.verifyPassword}
                </span>
              </div>
              <span className="text-start text-red-600 text-sm">
                {errorText}
              </span>
            </div>
            <div className="flex justify-center sm:text-[14px] text-[12px] items-center">
              <input
                type="checkbox"
                checked={agree}
                onChange={(e) => setAgree(e.target.checked)}
                className="cursor-pointer"
              ></input>
              <span className="ml-2">
                {t("agreementText1")}
                <a
                  href="/terms"
                  target="black"
                  className="text-[#4B5090] hover:underline font-semibold"
                >
                  {t("terms")}
                </a>
                {t("agreementText2")}
                <a
                  href="/privacy"
                  target="black"
                  className="text-[#4B5090] hover:underline font-semibold"
                >
                  {t("privacy")}
                </a>
                {t("agreementText3")}
              </span>
            </div>
            <span className="text-center text-red-600 text-sm">
              {errorMessage.agree}
            </span>
            <button
              onClick={handleRegister}
              className={`ease-in duration-100 flex flex-col bg-[${bgColor}] max-w-[420px] w-full mx-auto border justify-center items-center border-[#707070] text-[${textColor}] text-[16px] font-sans h-[40px] rounded-[10px] active:bg-[#5d63af] active:text-white mt-[30px]`}
            >
              {t("decide")}
            </button>
          </>
        )}

        {status && (
          <div className="text-[#4B5090] text-[25px] font-sans text-center font-[600] py-12">
            <div className="mb-6">{t("registerMessage1")}</div>
            <div className="text-[18px] pt-6">{t("registerMessage2")}</div>
            <div className="text-[18px] pt-6">{t("registerMessage3")}</div>
          </div>
        )}
      </div>
    </div>
  );
}
