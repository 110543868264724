import axios from "axios";
import { loginUser, showLoginModal } from "../redux/actions/languiseAction";
import { useDispatch } from "react-redux";
import {
  getAccessToken,
  getRefreshToken,
  removeAllCookies,
  setCookie,
} from "./utils";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const noTokenApi = axios.create({
  baseURL: baseURL,
});

const api = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

const apiWithoutCred = axios.create({
  baseURL: baseURL,
  withCredentials: false,
});

const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSet, setIsSet] = useState(false)

  useEffect(() => {
    const resInterceptor = api.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const refreshToken = getRefreshToken();

        if (error.response.status === 403) {
          navigate("/");
        }
        if (error.response.status === 401) {
          if (!refreshToken) {
            navigate("/");
            dispatch(showLoginModal(true));
            return Promise.reject(error);
          }
          const formData = new FormData();
          formData.append("refresh_token", refreshToken);
          return axios
            .post(`${baseURL}/user/refresh-token/`, formData)
            .then((response) => {
              setCookie(response);
              api.defaults.headers["Authorization"] =
                "Bearer " + response.data.access_token;
              window.location.reload();
            })
            .catch((err) => {
              removeAllCookies();
              dispatch(loginUser(false));
            });
        }

        return Promise.reject(error);
      }
    );
    setIsSet(true)
    return () => {
      api.interceptors.response.eject(resInterceptor);
    };
  }, [dispatch, navigate]);
  return isSet && children;
};

export default api;
export { AxiosInterceptor };
export { apiWithoutCred}
