import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../services/api";

export default function Maintenance() {
  const uiLang = useSelector((state) => state.LanguiseReducer.uiLang);
  const [lang, setLang] = useState("jp");
  const [shutdownTime, setShutdownTime] = useState("");
  const [shutdown_time, setshutdown_time] = useState("");
  const isUnderMaintenance = useSelector((state) => state.LanguiseReducer.isUnderMaintenance);

  useEffect(() => {
    setLang(uiLang);
  }, [uiLang]);

  useEffect(() => {
    api
      .get("/management/maintenance/")
      .then((response) => {
        setshutdown_time(response.data.shutdown_time)
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (shutdown_time) {
      const date = new Date(shutdown_time);
      if(lang != "jp")
      date.setHours(date.getHours() - 9);

      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month number (01-12)
      const day = String(date.getDate()).padStart(2, "0"); // Day (01-31)
      const hours = String(date.getHours()).padStart(2, "0"); // Hours (00-23)
      const minutes = String(date.getMinutes()).padStart(2, "0"); // Minutes (00-59)

      const formattedDate =
        lang === "jp"
          ? `${month}月${day}日 ${hours}:${minutes}` // Japanese format (Month)月(Day)日 hh:mm
          : `${month}/${day} ${hours}:${minutes} UTC`; // English format MM/DD hh:mm

      setShutdownTime(formattedDate); // Set the formatted shutdown time
    }
  }, [shutdown_time, lang])

  if(!isUnderMaintenance) return <></>

  return (
    <>
      {lang === "jp" ? (
        <div className="flex justify-center items-center min-h-[calc(100vh-197px)]">
          <h2>
            現在メンテナンス中です。
            <br /> 終了時間は以下を予定しています。
            <br />
            {shutdownTime} <br />
            ご迷惑をおかけして申し訳ございません。
          </h2>
        </div>
      ) : (
        <div className="flex justify-center items-center min-h-[calc(100vh-197px)]">
          <h2>
            We are currently undergoing maintenance.
            <br /> The service is scheduled to finish at the following time.
            <br />
            {shutdownTime} <br />
            We apologize for your inconvenience.
          </h2>
        </div>
      )}
    </>
  );
}
