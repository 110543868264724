import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AddEnterpriseModal from "../components/Modal/AddEnterpriseModal";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function Enterpise() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [openAddEnterpriseModal, setOpenAddEnterpriseModal] = useState(false);

  const userInfo = useSelector((state) => state.LanguiseReducer.userInfo);

  const handleCloseAddEnterpriseModal = () => {
    setOpenAddEnterpriseModal(!openAddEnterpriseModal);
    if (!openAddEnterpriseModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  };

  const naviagterEnterprise = () => {
    if ((!userInfo.enterprise_user && !userInfo.enterprise_admin) || userInfo.enterprise_trial) {
      handleCloseAddEnterpriseModal();
    } else {
      navigate("/mypage");
    }
  };

  return (
    <div className="flex flex-col">
      <AddEnterpriseModal
        openModal={openAddEnterpriseModal}
        onClose={handleCloseAddEnterpriseModal}
      />
      <Helmet>
        <title>Languise - Enterprise</title>
        <meta
          name="description"
          content="複数アカウントのご契約を検討される。お客様への特別なオファーです。アカウントを個別に契約していただく必要はありません。チーム全体のご利用回数に応じた金額を一括でお支払い頂けます。マネージャーボードで利用中の各アカウントの詳細を確認できるだけでなく、プランの変更やアカウントの追加・削除もお客様ご自身で行えますので必要な数のアカウントを適切なプラン選択でご利用頂けます。"
        />
        <link rel="canonical" href="https://languise.com/enterprise" />
      </Helmet>
      <div
        className="flex items-center bg-cover bg-center w-full xl:h-[596px] h-[261px]"
        style={{ backgroundImage: "url('/assets/4-bg.png')" }}
      >
        <div className="flex flex-col xl:gap-[23px] lg:px-0 px-5 gap-4 max-w-[1000px] w-full mx-auto">
          <h1 className="flex text-[#4B5090] xl:text-[45px] sm:text-[25px] text-[18px] font-sans font-[600] text-start">
            {t("enterprisePageText1")}
            <br />
            {t("enterprisePageText2")}
          </h1>
          <div className="flex w-full">
            <button
              onClick={naviagterEnterprise}
              className="xl:max-w-[434px] sm:max-w-[350px] max-w-[174px] w-full sm:h-[65px] h-[26px] sm:rounded-[10px] rounded-[5px] bg-[#405090] hover:bg-[#11135C] text-white xl:text-[20px] sm:text-[16px] text-[8px] leading-[24px] font-sans"
            >
              {t("enterprisePageText3")}
            </button>
          </div>
        </div>
      </div>
      <div className="2xl:max-w-[1069px] sm:max-w-[1400px] max-w-full w-full flex flex-col xl:mt-[94px] sm:mt-[45px] mt-[26px] justify-center items-center mx-auto xm:px-0 px-5">
        <h2 className="text-[#11135C] xl:text-[50px] sm:text-[32px] text-[20px] xl:leading-[80px] sm:leading-[55px] leading-[40px] font-sans font-[600]">
          Languise Enterprise
        </h2>
        <p className="text-[#222222] xl:text-[20px] sm:text-[16px] text-[14px] xl:leading-[32px] sm:leading-[28px] leading-[24px] font-sans text-start max-w-[1000px] w-full xl:mt-[50px] sm:mt-[35px] mt-[29px]">
          {t("enterprisePageText4")}
          <br />
          {t("enterprisePageText5")}
        </p>
        <div className="flex flex-col sm:gap-[49px] gap-[30px] xl:mt-[119px] sm:mt-[90px] mt-[73px]">
          <div className="sm:flex flex-row gap-[25px]">
            <div className="flex gap-[12px]">
              <div className="xl:max-w-[160px] sm:max-w-[120px] max-w-[100px] w-full xl:h-[160px] sm:h-[120px] h-[100px flex justify-center items-center">
                <img
                  className="xl:w-[160px] sm:w-[120px] w-[100px] xl:h-[160px] sm:h-[120px] h-[100px]"
                  src="/assets/4-percent.png"
                  alt="Check"
                />
              </div>
              <div className="flex flex-col gap-[23px] max-w-[340px] w-full justify-center items-start">
                <p className="text-[#222222] xl:text-[20px] sm:text-[18px] text-[16px] font-sans text-start font-[600]">
                  {t("enterprisePageText6")}
                </p>
                <p className="text-[#222222] xl:text-[16px] sm:text-[15px] text-[14px] font-sans text-start">
                  {t("enterprisePageText7")}
                </p>
              </div>
            </div>
            <div className="flex gap-[12px] sm:mt-0 mt-[30px]">
              <div className="xl:max-w-[160px] sm:max-w-[120px] max-w-[100px] w-full xl:h-[160px] sm:h-[120px] h-[100px flex justify-center items-center">
                <img
                  className="xl:w-[160px] sm:w-[120px] w-[100px] xl:h-[160px] sm:h-[120px] h-[100px]"
                  src="/assets/4-msg.png"
                  alt="Check"
                />
              </div>
              <div className="flex flex-col gap-[23px] max-w-[340px] w-full justify-center items-start">
                <p className="text-[#222222] xl:text-[20px] sm:text-[18px] text-[16px] font-sans text-start font-[600]">
                  {t("enterprisePageText8")}
                </p>
                <p className="text-[#222222] xl:text-[16px] sm:text-[15px] text-[14px] font-sans text-start">
                  {t("enterprisePageText9")}
                </p>
              </div>
            </div>
          </div>

          <div className="sm:flex flex-row gap-[25px]">
            <div className="flex gap-[12px]">
              <div className="xl:max-w-[160px] sm:max-w-[120px] max-w-[100px] w-full xl:h-[160px] sm:h-[120px] h-[100px flex justify-center items-center">
                <img
                  className="xl:w-[160px] sm:w-[120px] w-[100px] xl:h-[160px] sm:h-[120px] h-[100px]"
                  src="/assets/4-setting.png"
                  alt="Check"
                />
              </div>
              <div className="flex flex-col gap-[23px] max-w-[340px] w-full justify-center items-start">
                <p className="text-[#222222] xl:text-[20px] sm:text-[18px] text-[16px] font-sans text-start font-[600]">
                  {t("enterprisePageText10")}
                </p>
                <p className="text-[#222222] xl:text-[16px] sm:text-[15px] text-[14px] font-sans text-start">
                  {t("enterprisePageText11")}
                </p>
              </div>
            </div>
            <div className="flex gap-[12px] sm:mt-0 mt-[30px]">
              <div className="xl:max-w-[160px] sm:max-w-[120px] max-w-[100px] w-full xl:h-[160px] sm:h-[120px] h-[100px flex justify-center items-center">
                <img
                  className="xl:w-[160px] sm:w-[120px] w-[100px] xl:h-[160px] sm:h-[120px] h-[100px]"
                  src="/assets/4-hand.png"
                  alt="Check"
                />
              </div>
              <div className="flex flex-col gap-[23px] max-w-[340px] w-full justify-center items-start">
                <p className="text-[#222222] xl:text-[20px] sm:text-[18px] text-[16px] font-sans text-start font-[600]">
                  {t("enterprisePageText12")}
                </p>
                <p className="text-[#222222] xl:text-[16px] sm:text-[15px] text-[14px] font-sans text-start">
                  {t("enterprisePageText13")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <p className="sm:w-full w-[200px] xl:mt-[115px] sm:mt-[60px] mt-[36px] text-[#11135C] xl:text-[40px] sm:text-[28px] text-[20px] xl:leading-[80px] sm:leading-[50px] leading-[30px] text-center font-[600] font-sans">
          {t("enterprisePageText14")}
        </p>
        <div className="md:flex flex-row md:max-w-[1069px] max-w-full w-full xl:gap-10 gap-0 justify-center items-center xl:mt-[119px] sm:mt-[60px] mt-[37px]">
          <div className="flex md:max-w-[360px] w-full justify-center">
            <img
              className="max-w-[358px] w-full"
              src="/assets/4-pencil.png"
              alt="Pencil"
            />
          </div>
          <div className="flex flex-col xl:w-full md:gap-10 gap-5 justify-start md:mt-0 mt-10">
            <div className="flex items-center justify-start">
              <img
                className="xl:w-[65px] sm:w-[50px] w-[39px] xl:h-[65px] sm:h-[50px] h-[39px] relative md:left-[20px] left-0 z-[40]"
                src="/assets/4-check.png"
                alt="Check"
              />
              <div className="flex relative md:right-0 right-[20px] z-[30] xl:w-[600px] md:w-[420px] w-full xl:h-[107px] sm:h-[75px] h-[59px] items-center md:px-[45px] px-[26px] xl:rounded-[30px] sm:rounded-[20px] rounded-[10px] bg-[#E9E9F0] text-[#212121] xl:text-[25px] sm:text-[17px] text-[13px] text-start font-[600] font-sans">
                {t("enterprisePageText15")}
              </div>
            </div>

            <div className="flex items-center justify-start">
              <img
                className="xl:w-[65px] sm:w-[50px] w-[39px] xl:h-[65px] sm:h-[50px] h-[39px] relative md:left-[20px] left-0 z-[40]"
                src="/assets/4-check.png"
                alt="Check"
              />
              <div className="flex relative md:right-0 right-[20px] z-[30] xl:w-[600px] md:w-[420px] w-[100%] xl:h-[107px] sm:h-[75px] h-[59px] items-center md:px-[45px] px-[26px] xl:rounded-[30px] sm:rounded-[20px] rounded-[10px] bg-[#E9E9F0] text-[#212121] xl:text-[25px] sm:text-[17px] text-[13px] text-start font-[600] font-sans">
                {t("enterprisePageText16")}
              </div>
            </div>

            <div className="flex items-center justify-start">
              <img
                className="xl:w-[65px] sm:w-[50px] w-[39px] xl:h-[65px] sm:h-[50px] h-[39px] relative md:left-[20px] left-0 z-[40]"
                src="/assets/4-check.png"
                alt="Check"
              />
              <div className="flex relative md:right-0 right-[20px] z-[30] xl:w-[600px] md:w-[420px] w-[100%] xl:h-[107px] sm:h-[75px] h-[59px] items-center md:px-[45px] px-[26px] xl:rounded-[30px] sm:rounded-[20px] rounded-[10px] bg-[#E9E9F0] text-[#212121] xl:text-[25px] sm:text-[17px] text-[13px] text-start font-[600] font-sans">
                {t("enterprisePageText17")}
              </div>
            </div>
          </div>
        </div>
        <div className="flex sm:flex-row flex-col gap-2 sm:gap-6 w-full justify-center items-center sm:items-end sm:my-[50px] my-[70px] xl:px-0 px-5">
          <div className="flex flex-col gap-[16px] sm:max-w-[250px] max-w-full w-full mb-[7px] items-center order-2 sm:order-1">
            <p className="text-[#6E6E6E] text-[20px] text-center leading-[24px] font-sans font-semibold sm:mt-0 mt-[26px]">
              {t("enterprisePageText18")}
            </p>
            <button
              onClick={() => navigate("/dl")}
              className="max-w-[250px] w-full h-[65px] rounded-[10px] bg-[#6E6E6E] font-sans font-semibold text-white text-[20px] text-center sm:mt-0 mt-2"
            >
              {t("downloadDocument")}
            </button>
          </div>
          <div
            onClick={naviagterEnterprise}
            className="flex flex-col sm:max-w-[330px] w-full h-[120px] gap-[6px] justify-center items-center bg-[#11135C] rounded-[20px] order-1 sm:order-2 cursor-pointer"
          >
            <p className="text-white text-[16px] font-sans font-semibold">
              Languise Enterprise
            </p>
            <p className="text-white text-[30px] font-sans font-semibold">
              {t("apply")}
            </p>
          </div>
          <button
            onClick={() => navigate("/contactus")}
            className="max-w-[250px] w-full h-[65px] rounded-[10px] bg-[#6E6E6E] text-white text-[20px] text-center mb-[7px] sm:mt-0 mt-[26px] order-3"
          >
            {t("qa")}
          </button>
        </div>
      </div>
    </div>
  );
}
