import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../../redux/actions/languiseAction";
import api from "../../../services/api";
import TextField from "../../TextField";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";

export default function ChangeAccountNameModal({ openModal, closeModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [newName, setNewName] = useState("");
  const user = useSelector((state) => state.LanguiseReducer.userInfo);
  const changeUsername = () => {
    if (newName === "") return;
    api
      .put("/user/", {
        name: newName,
      })
      .then((rst) => {
        Notiflix.Notify.success(t("changeAccountNameModalText1"));
        dispatch(getUserInfo());
        setNewName("");
        closeModal();
      })
      .catch((error) => {
        Notiflix.Notify.failure(t("operationFailure"));
        console.log(error);
      });
  };
  return (
    <div
      className={`${
        openModal
          ? "flex !opacity-100 !translate-y-0"
          : "hidden !opacity-0 !translate-y-10"
      } fixed inset-0 bg-black/40 z-30 !transition-all !ease-in-out !duration-300`}
    >
      <div className="flex flex-col max-w-[830px] w-full min-h-[370px] sm:mx-auto mx-5 my-auto z-[50] bg-[#F0F1F8] px-[12px] py-[12px] rounded-lg">
        <div className="flex justify-end">
          <img
            onClick={closeModal}
            className="w-[20px] h-[20px] cursor-pointer"
            src="/assets/cancel-modal.svg"
            alt="Cancel"
          />
        </div>
        <p className="text-[#4B5090] font-sans text-[20px] text-center">
        {t("changeAccountNameModalText3")}
        </p>
        <div className="flex flex-col max-w-[420px] w-full mx-auto gap-4 mt-[37px]">
          <div className="flex flex-col gap-1">
            <p className="text-start font-sans text-[#505050] text-[12px]">
            {t("changeAccountNameModalText4")}
            </p>
            <TextField value={user.name || ""} disabled={true} />
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-start font-sans text-[#505050] text-[12px]">
              {t("changeAccountNameModalText5")}
            </p>
            <TextField
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>
          <div className="flex justify-center mt-[14px]">
            <button
              onClick={changeUsername}
              className="w-full bg-transparent h-[40px] border border-[#8B8B8B] text-[#8B8B8B] rounded-[10px] hover:bg-[#4B5090] hover:text-white"
            >
              {t("update")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
