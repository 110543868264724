export const SHOW_LOGIN = "SHOW_LOGIN";
export const POST_TRANSLATE = "POST_TRANSLATE";
export const POST_TRANSLATE_FILE = "POST_TRANSLATE_FILE";
export const DETECT_LANGUAGE = "DETECT_LANGUAGE";
export const SET_LOADING = "SET_LOADING";
export const LOGIN_USER = "LOGIN_USER";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_DICTIONARY_LIST = "GET_DICTIONARY_LIST";
export const GET_DICTIONARY_CONTENT_LIST = "GET_DICTIONARY_CONTENT_LIST";
export const CORPORATE_USERS = "CORPORATE_USERS";
export const PLANS = "PLANS";
export const ENTERPRISE_PLANS = "ENTERPRISE_PLANS";
export const PRICES = "PRICES";
export const CHANGE_UI_LANG = "CHANGE_UI_LANG";
export const IS_UNDER_MAINTENANCE = "IS_UNDER_MAINTENANCE";
