import React, {useEffect} from "react";
import { Helmet } from "react-helmet";

export default function PrivacyPolicyJp() {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = "CookieDeclaration";
    script.src = "https://consent.cookiebot.com/79fd8692-01be-4249-ba10-83d26285c018/cd.js";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="flex flex-col">
      <Helmet>
        <title>Languise - Privacy Policy</title>
        <meta name="description" content="Languiseのプライバシーポリシー" />
        <link rel="canonical" href="https://languise.com/privacy" />
      </Helmet>
      <div
        className="flex items-center bg-cover bg-center w-full sm:h-[360px] h-[220px]"
        style={{ backgroundImage: "url('/assets/5-bg.png')" }}
      >
        <div className="flex flex-col xl:gap-[23px] px-5 sm:px-0 sm:gap-4 gap-2 2xl:max-w-[1306px] max-w-[95%] w-full mx-auto">
          <h1 className="sm:text-[45px] text-[22px] text-[#333876] font-sans font-semibold text-start">
            Languiseのプライバシーポリシー
          </h1>
        </div>
      </div>

      <div className="2xl:max-w-[1306px] max-w-[95%] w-full mx-auto flex flex-col px-5 sm:px-0 text-start sm:text-[20px] text-[12px] text-[#222222] font-sans">
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          一般
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          私たちは個人データの保護に真剣に取り組んでいます。個人データを大切に取り扱い、EU一般データ保護規則（GDPR）およびドイツ連邦データ保護法（BDSG）など、法令に従って処理しています。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          次に、弊社のウェブサイトを利用する際に、個人情報がどのように扱われるかについて概要を説明します。個人データとは、個人を特定できる情報（名前、メールアドレス、電話番号など）のことです。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          このウェブサイトでのデータ処理の管理者は次のとおりです：
        </p>
        <p className="sm:leading-9 leading-6 font-semibold">
          BuilBridge UG (haftungsbeschränkt)
        </p>
        <p className="sm:leading-9 leading-6">Niederkasseler Kirchweg 57,</p>
        <p className="sm:leading-9 leading-6">40547 Düsseldorf Germany</p>
        <p className="sm:leading-9 leading-6">TEL：+49（0）173 9303239</p>
        <p className="sm:leading-9 leading-6">Email : info(at)languise.com</p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          管理者は、単独または他者と共同で個人データの処理の目的および手段を決定する自然人または法人です。GDPR第7条4項。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          データがどのように収集され、保存され、どのように使用され、どのような目的で使用されるか
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          a) ウェブサイトを訪れる際
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          当社のウェブサイトを訪れると、お使いのデバイスで使用されているブラウザが自動的に当社のウェブサイトのサーバーに情報を送信します。この情報は一時的に、いわゆるログファイルに保存されます。次の情報が自動的に収集され、自動的に削除されるまで保存されます：
        </p>
        <ul className="list-outside pl-10 sm:mt-10 mt-2 sm:leading-9 leading-6">
          <li className="list-disc indent-4">
            要求元のコンピューターのIPアドレス
          </li>
          <li className="list-disc indent-4">アクセスの日時</li>
          <li className="list-disc indent-4">取得したファイルの名前とURL</li>
          <li className="list-disc indent-4">
            アクセス元のウェブサイト（いわゆる「リファラURL」）
          </li>
          <li className="list-disc indent-4">
            お使いのブラウザ、必要であれば、コンピュータのオペレーティングシステム、およびアクセスプロバイダーの名前
          </li>
        </ul>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          上記のデータは、以下の目的のために私たちによって処理されます。
        </p>
        <ul className="list-outside pl-10 sm:mt-10 mt-2 sm:leading-9 leading-6">
          <li className="list-disc indent-4">
            ウェブサイトのスムーズな接続を確保するため
          </li>
          <li className="list-disc indent-4">
            当社のウェブサイトを快適にご利用いただくため
          </li>
          <li className="list-disc indent-4">
            システムのセキュリティと安定性の評価を行うため
          </li>
          <li className="list-disc indent-4">さらなる管理目的のため</li>
        </ul>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          データ処理の法的根拠はGDPR第6条１項１文ｆ号です。当社の正当な利益は、上記に列挙されているデータ収集目的に起因します。収集されたデータを使用して、あなたの身元に関する結論を導き出すことはありません。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          また、ウェブサイトを訪れる際に、私たちはクッキーや分析サービスを使用します。詳細については、このプライバシーポリシーの下をご確認ください。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          b) 当社のお問い合わせフォームのご利用、Eメールでのお問い合わせ
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          もしご質問等ありましたら、ウェブサイトに用意されているフォームか、弊社のEメールアドレスをご利用いただけます。お名前と共に有効なEメールアドレスを入力していただく必要があります。リクエストの送信元を把握し、お問い合わせに対応させていただくためです。その他の情報は任意で提供いただけます。ご提供いただいた情報は、お客様のお問い合わせに対応するためや、お客様から弊社にご依頼いただいた製品やサービスの提供のために使用いたします。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          これらの場合、データ処理は、あなたの自発的に与えられた同意に基づいて、GDPR第6条1項１文a号に従って行われるか、契約の履行または契約前の措置に関しては、GDPR第6条1項1文ｂ号に基づいて（契約の履行または契約前の措置）または、GDPR第6条1項1文f号に基づいて（当社の正当な利益または第三者の正当な利益の保護を目的とするが、あなたの利益が優先する場合を除く）。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          a) 第三者へのデータ開示
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          他のコントローラへの個人データの転送
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          あなたの個人データは、契約の履行に必要な場合、または私たちや第三者があなたのデータを提供することに合法的な利益がある場合、またはあなたが同意した場合のみ、他の第三者に転送されます。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          法的根拠の詳細については、"転送のための法的根拠"の節に記載されています。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          BuilBridgeは、ウェブサイトを通じて提供された個人データを収集または処理を第三者への開示または販売、第三者のマーケティング目的のため使用、第三者の代理でホスト名リングのために使用致しません。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          b) サービスプロバイダへの伝送
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          外部のサービスプロバイダーと契約し、プログラミングやデータホスティングなどのタスクを委託しています。私たちはこれらのサービスプロバイダーは慎重に選定し、定期的にモニタリングしており、特に彼らが保管するデータの慎重な取り扱いと保護についてです。すべてのサービスプロバイダーには、機密保持と法的要件への遵守を義務付けています。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          データ転送のための法的根拠
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          あなたの個人データを以下の目的以外のために第三者に送信することはありません。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          私たちは、次の場合にのみ、第三者とあなたの個人情報を共有します：
        </p>
        <ul className="list-outside pl-10 sm:mt-10 mt-2 sm:leading-9 leading-6">
          <li className="list-disc indent-4">
            あなたは、GDPR 第６条１項１文a号に従って明示的な同意を与えた。
          </li>
          <li className="list-disc indent-4">
            法的に許容されており、GDPR第6条１項１文b号あなたとの契約関係の実行に必要。
          </li>
          <li className="list-disc or indent-4">
            GDPR第6条１項１文ｆ号基づく開示は、法的な請求を主張し、行使し、または防御するために必要であり、あなたがデータを開示しないことに正当な関心を持っているとは想定されない理由がない場合に必要です。
          </li>
          <li className="list-disc indent-4">
            GDPR第６条１項１文c号 に基づく開示義務が法的義務である場合。
          </li>
        </ul>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          EEA域外の受取人に転送
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          BuilBridgeは、個人情報をEEA域外の受信者にも転送する場合があります。この場合、受信者には、たとえばEU委員会の十分性認定決定（例：日本）に基づいてデータ保護の適切なレベルを事前に確保すること、またはEU標準契約条項（SCC）に基づく受信者との合意、または開示への同意などによるGDPR第49条等の例外に基づき、個人データの保護を確保します。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          ご希望なら、第三国の受信者の概要と適切なデータ保護レベルを確保するために合意された具体的な取り決めの写しを提示致します。そのためには管理者の情報をご利用ください。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          あなたのデータに関する権利は何ですか？
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          あなたには以下の権利があります：
        </p>
        <ul className="list-outside pl-10 sm:mt-10 mt-2 sm:leading-9 leading-6">
          <li className="list-disc indent-4">
            GDPR第15条に従い、私たちが処理するあなたの個人データについて情報の開示請求を行うことができます。具体的には、処理の目的に関する情報、個人データのカテゴリ、あなたのデータが開示された受取人のカテゴリ、計画されている保持期間、訂正、削除、処理の制限または抗議の権利、苦情を申し立てる権利、私たちから収集されていない場合のデータの出典、およびプロファイリングを含む自動的な意思決定の存在、また適切な場合は、その詳細に関する重要な情報が提供されます。
          </li>
          <li className="list-disc indent-4">
            GDPR第16条に従い、私たちが保管している個人データの修正または補完を即座に要求すること。
          </li>
          <li className="list-disc indent-4">
            GDPR第１７条に従い、当社が保管する個人データの削除を要求することができます。ただし、言論と情報の自由の権利の行使、法的義務の履行、公共の利益のための処理、または法的請求の主張、行使、または防衛のために必要な場合を除きます。
          </li>
          <li className="list-disc indent-4">
            あなたがデータの正確性に疑問を呈しており、その処理が不法であるにもかかわらず、あなたがその削除を拒否し、かつ私たちがそのデータを必要としなくなったけれども、あなたが法的請求を主張、行使または防御するため、またはあなたがGDPR第21条に基づいて処理に異議を唱えた場合に、GDPR第18条に従い、あなたの個人データの処理の制限を要求すること。
          </li>
          <li className="list-disc indent-4">
            GDPR第２０条に従い、私たちに提供された個人データを構造化された標準的な機械可読形式で受け取るか、他の管理者に直接転送させる権利
          </li>
          <li className="list-disc indent-4">
            GDPR第7条３項に従い、あなたは私たちに与えられた同意を撤回することができます。そのような場合、その同意に基づくデータ処理を将来的に継続することはできません。
          </li>
          <li className="list-disc indent-4">
            GDPR第７７条に基づいて監督機関に苦情を申し立てる場合は、通常、ご自宅や勤務先の管轄区域の監督機関、または弊社の本社に連絡することができます。
          </li>
        </ul>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          分析と第三者ツール
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          当社のウェブサイトを訪れる際には、あなたのネットサーフィン活動に関する統計分析が行われる可能性があります。これは主にクッキーと分析ツールを使用して行われます。あなたのネットサーフィン活動の分析は通常匿名であり、つまり、このデータからあなたを特定することはできません。この分析に異議を唱えることも、特定のツールを使用しないことで防ぐこともできます。詳細な情報は本プライバシーポリシー以下にあります。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          この分析に異議を申し立てることができます。この件に関する選択肢を行使する方法については、以下でご案内いたします。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          クッキー
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          私たちのウェブページの一部はクッキーを使用しています。クッキーはコンピューターに害を与えることはありませんし、ウイルスも含んでいません。クッキーは、ウェブサイトをより使いやすく、効率的、かつ安全にするのに役立ちます。クッキーとは、コンピュータに保存され、ブラウザによって保存される小さなテキストファイルです。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          私たちが使用するほとんどのクッキーは、いわゆる「セッションクッキー」です。あなたの訪問後に自動的に削除されます。他のクッキーは、削除するまでデバイスのメモリに残ります。これらのクッキーによって、次回サイトを訪れる際にあなたのブラウザを認識することが可能になります。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          ブラウザの設定を変更して、クッキーの利用について通知を受けるようにできます。そのようにして、ケースバイケースでクッキーを受け入れるか拒否するかを決めることができます。または、ブラウザを特定の条件下で自動的にクッキーを受け入れるように設定することもできます。または、常にクッキーを拒否するように、あるいはブラウザを閉じると自動的にクッキーを削除するようにブラウザを設定することもできます。クッキーを無効にすると、このウェブサイトの機能が制限される場合があります。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          電子通信を許可するために必要なクッキー、または利用したい特定の機能を提供するために必要なクッキーは、GDPR
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          第６条１項１文f号に基づいて保存されます。ウェブサイトの運営者は、技術的なエラーを抑制して最適化されたサービスを提供するためにクッキー保存に関して正当な利益を有します。他のクッキー（たとえば、あなたのネットサーフィン活動を分析するために使用されるものなど）が保存されている場合は、このプライバシーポリシーで別途取り扱われます。
        </p>
        <div id="CookieDeclaration"></div>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          プラグインとツール
        </h2>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          技術的および組織的なセキュリティ対策
        </h2>
        私たちは適切な技術的および組織的なセキュリティ対策をもって、個人データの偶発的または意図的な改ざん、部分的または完全な損失、破壊、または第三者による未承認のアクセスを防止します。当社のセキュリティ対策は、技術の進歩に合わせて常に改善されていきます。
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          SSLまたはTLS暗号化
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          このサイトは、セキュリティ上の理由や機密情報の送信の保護のために、SSLまたはTLS暗号化を使用しています。お問い合わせなどの機密コンテンツの送信を保護するためです。ブラウザのアドレスバーが"http://"から"https://"に変わり、ロックアイコンが表示されると、暗号化された接続であることを認識することができます。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          SSLまたはTLS暗号化が有効になっている場合、私たちに転送されるデータは第三者によって読まれることはありません。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          インターネットを通じたデータ転送(例えば、電子メール経由の通信時)には、セキュリティの脆弱性の可能性があります。第三者によるデータへのアクセスを完全に保護することはできません。
        </p>
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          保持期間
        </h2>
        原則として、私たちはオンラインサービスおよび関連サービスの提供に必要な限り、またはさらなる保存に正当な利益がある限り、お客様のデータを保存します。その他のケースでは、法的義務を遵守するために保持する必要がある個人情報を除いて、削除します（たとえば、税金や商業上の保存の要件によりある期間記録を保持する必要がある場合など、たとえば契約書や請求書）。
        <h2 className="sm:mt-20 mt-5 text-[#11135C] sm:text-[26px] text-[12px] font-semibold">
          プロモーションメールへの反対
        </h2>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          私たちはここに明確に、ウェブサイトの法的通知要件の文脈で公開された連絡先情報を使用して、明示的に要求されていない宣伝物や情報資料を送信することを禁止します。ウェブサイトの運営者は、要求されていない広告資料（電子メールスパムなど）を受信した場合、特定の法的措置を取る権利を留保します。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6">
          BuilBridgeは、このプライバシーポリシーを定期的に更新する権利を留保します。
        </p>
        <p className="sm:mt-10 mt-2 sm:leading-9 leading-6 mb-20">
          最終更新 2024年1月19日
        </p>
      </div>
    </div>
  );
}
